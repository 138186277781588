import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';


@Injectable({
  providedIn: 'root'
})
export class ReponseAudioService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) {}

    soundPost(sound:any, reponseAudioId:number) {
      const url = this.constants.apiGetPostEndpoint + "/reponseAudio/" + reponseAudioId + "/audio";
      return this.httpClient.post(url, sound);
    }

    getSound(reponseAudioId:number) {
      const url = this.constants.apiGetPostEndpoint + '/getReponseAudioSound/' + reponseAudioId;
      const options = this.headerService.getOptions();
      return this.httpClient.get(url, options);
    }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
#content #main-card {
  display: flex;
  flex-direction: column;
}
#content #main-card ion-button {
  width: 80px;
  top: 8px;
  left: 8px;
  padding-left: 8px;
}
#content #main-card .cont-card {
  font-weight: 600;
  font-size: 24px;
  line-height: 44px;
  padding: 8px;
}
#content #main-card .header-image {
  background-color: white;
}
#content #main-card #titre-cont-card {
  text-align: center;
}
#content #main-card .cont-row {
  display: flex;
  flex-direction: row;
  font-weight: 300;
  font-size: 12px;
  line-height: 22px;
  align-items: center;
  gap: 8px;
}
#content #main-card .cont-row p {
  padding: 0;
  margin: 0;
}
#content #main-card .desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-detail-reward/modal-detail-reward.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EAEA,uBAAA;EACA,YAAA;AAAF;AAEE;EACE,aAAA;EACA,sBAAA;AAAJ;AAEI;EAEE,WAAA;EACA,QAAA;EACA,SAAA;EACA,iBAAA;AADN;AAII;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;AAFN;AAII;EACE,uBAAA;AAFN;AAKI;EACE,kBAAA;AAHN;AAMI;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,QAAA;AAJN;AAKM;EACE,UAAA;EACA,SAAA;AAHR;AAMI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;AAJN","sourcesContent":["#content {\r\n  display: flex;\r\n  flex-direction: column;\r\n  // align-items: center;\r\n  justify-content: center;\r\n  height: 100%;\r\n\r\n  #main-card {\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n    ion-button {\r\n      // position: absolute;\r\n      width: 80px;\r\n      top: 8px;\r\n      left: 8px;\r\n      padding-left: 8px;\r\n    }\r\n\r\n    .cont-card {\r\n      font-weight: 600;\r\n      font-size: 24px;\r\n      line-height: 44px;\r\n      padding: 8px;\r\n    }\r\n    .header-image {\r\n      background-color: white;\r\n    }\r\n\r\n    #titre-cont-card {\r\n      text-align: center;\r\n    }\r\n\r\n    .cont-row {\r\n      display: flex;\r\n      flex-direction: row;\r\n      font-weight: 300;\r\n      font-size: 12px;\r\n      line-height: 22px;\r\n      align-items: center;\r\n      gap: 8px;\r\n      p {\r\n        padding: 0;\r\n        margin: 0;\r\n      }\r\n    }\r\n    .desc {\r\n      font-weight: 400;\r\n      font-size: 16px;\r\n      line-height: 29px;\r\n      display: flex;\r\n      align-items: center;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

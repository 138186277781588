import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ErrorNotificationService } from 'src/app/services/error-notification.service';
import { PlateauTechniqueService } from 'src/app/services/PlateauTechnique/plateau-technique.service';
import { ReponsesService } from 'src/app/services/Reponses/reponses.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-technical-platform',
  templateUrl: './technical-platform.page.html',
  styleUrls: ['./technical-platform.page.scss'],
})
export class TechnicalPlatformPage implements OnInit {
  constructor(
    private navCtrl: NavController,
    private activatedRoute: ActivatedRoute,
    private tpService: PlateauTechniqueService,
    private reponsesService: ReponsesService,
    private loadingService: LoadingService,
    private errorNotificationService: ErrorNotificationService
  ) {}

  questions: any[];
  responseEvent = [];


  userId: any;
  technicalPlatformId: any;
  oldReponses = [];
  structureReponses = [];
  loadingQuestions: boolean = false;
  loadingReponses: boolean = false;

  alreadyAnswered:boolean=false;

  ngOnInit() {
    // To get the technical-Platform Id and the UserId
    /*const userId = this.activatedRoute.snapshot.paramMap.get('userId');
    const technicalPlatformId = this.activatedRoute.snapshot.paramMap.get(
      'technicalPlatformId'
    );
    this.getTechnicalPlatform(userId, technicalPlatformId);
    */
    this.userId = this.activatedRoute.snapshot.paramMap.get('userId');
    this.technicalPlatformId = this.activatedRoute.snapshot.paramMap.get(
      'technicalPlatformId'
    );

    this.loadingService.showLoading().then(
      () => {
        this.getTechnicalPlatform(this.userId, this.technicalPlatformId);
        this.getPreviousAnswers()
      }
    );
  }

  assignReponse(event: any, question) {
    let reponseAEditer = this.structureReponses.find(element => element.id_question === question.id);

    if(reponseAEditer !== undefined) {
      reponseAEditer.id_plateau_technique = this.technicalPlatformId;
      reponseAEditer.reponse = event.detail.value;
    } else {
      this.structureReponses.push({
        "id_question": question.id,
        "id_plateau_technique": this.technicalPlatformId,
        "reponse": event.detail.value
      });
    }
  }

  submitResponse() {
    if(this.alreadyAnswered) {
      this.errorNotificationService.presentGeneralErrorAlert(true, "Vous avez déjà répondu aux questions");
    } else if(this.structureReponses.length !== this.questions.length) {
      this.sendErrorToast();
    } else {
      this.loadingService.showLoading();
      this.reponsesService.PostSeveralReponses(this.userId, this.structureReponses).subscribe(
        () => {
          this.loadingService.dismissLoading();
          this.navCtrl.navigateRoot('/tabs/tab2');
        },
        error => {
          this.loadingService.dismissLoading();
          let message = "Erreur: une erreur s'est produite lors de la soummission des réponses";

          if(error.status === 404 || 403 || 422) {
            message = error.error;
          }

          this.errorNotificationService.presentGeneralErrorAlert(true, message);
        }
      );
    }
  }

  controlQuestionsForm(){
    let check:boolean = true;
    if(this.responseEvent.length > 0){
      for (let i = 0; i < this.responseEvent.length; i++) {
        if(this.responseEvent[i] === undefined || this.responseEvent[i] === ''){
          check = false;
        }
      }
    } else {
      check = false;
    }
    return check;
  }

  getTechnicalPlatform(userId: any, technicalPlatformId: any) {
    this.loadingQuestions = true;
    this.tpService.getPlateauxTechniquesForOneUtilisateur(userId).subscribe(
      (technicalPlatform) => {
        const technicalPlatforms: any[] = technicalPlatform['hydra:member'];
        for (let i = 0; i < technicalPlatforms.length; i++) {
          const tp = technicalPlatforms[i];
          if (tp.id == technicalPlatformId) {
            this.questions = tp.questions;
          }
        }
        this.loadingQuestions = false;
        this.checkLoading();
      },
      (error) => {
        this.loadingQuestions = false;
        this.checkLoading();
        this.errorNotificationService.presentHttpStatusErrorAlert(error.code);
      }
    );
  }

  getPreviousAnswers() {
    this.loadingReponses = true;
    this.reponsesService.getReponsesUtilisateur(this.userId, this.technicalPlatformId).subscribe(
      data => {
        this.oldReponses = [];

        for(let reponse of data["hydra:member"]) {
          this.oldReponses[reponse.question.id] = reponse.reponse;
          this.alreadyAnswered=true;
        }

        this.loadingReponses = false;
        this.checkLoading();
      },
      error => {
        this.loadingReponses = false;
        this.checkLoading();
      }
    );
  }

  checkLoading() {
    if(this.loadingQuestions || this.loadingReponses) {
      this.loadingService.dismissLoading();
    }
  }

  backAction() {
    this.navCtrl.back;
  }

  backToMain() {
    this.navCtrl.navigateRoot('/tabs/tab2');
  }

  sendErrorToast(){
    this.errorNotificationService.presentGeneralErrorAlert(true, "Nous n'avez pas repondu à toutes les questions");
  }
}

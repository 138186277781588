import { Etape } from "./etape";
import { Recompense } from "./recompense";

export class Niveau {
    [x: string]: any;
    '@id': string;


    // added by Pierre
    nom?:string;
    numeroNiveau?:number;
    etapes?:Etape[];

    
    ville?:string;
    description?:string;
    recompense?:Recompense;
  }
  
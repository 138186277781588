import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class EntrepriseRecompenseService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

    getEntreprises() {
    const url = this.constants.apiGetPostEndpoint + '/entreprise_recompenses';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(entreprise: any) {
    const url = this.constants.apiGetPostEndpoint + "/entreprise_recompenses";
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {nom: entreprise["nom_nouveau"]}, httpHeaders);
  }

  edite(entreprise:any, lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();

    return this.httpClient.patch(url, {
      nom: entreprise["nom_edition"],
    },httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  messageFacebook:string = "Bonjour à tous ! 👋 Je suis actuellement à la recherche d'un stage professionnel dans [indiquer le domaine] pour une durée d'un mois minimum à deux mois maximum. Passionné(e) par [mentionner vos intérêts ou domaines d'expertise], je suis motivé(e) à mettre mes compétences en pratique et à apprendre au sein d'une entreprise dynamique.Soutenu par le programme de formation de l'ADEP à Roubaix et son action Prends-Toi En Main!, elle m'a permis d'acquérir une solide base théorique que je suis désireux(se) de mettre en pratique dans un environnement professionnel. Je suis particulièrement intéressé(e) par [mentionner des domaines spécifiques d'intérêt].";
  messageTwitter:string = "🔍 À la recherche d'un stage pro dans [domaine] pour 1-2 mois. Mon parcours en [domaine d'études] soutenu par l'ADEP Roubaix. Des idées ou contacts ? Merci pour votre soutien ! 💼✨";
  hashTagsTwitter:string="RechercheDeStage";
  constructor() { }

  ngOnInit() {}

}

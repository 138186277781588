// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#infos {
  width: 50%;
  margin-top: 20px;
  margin-left: 25%;
}

#div-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}

#travel-div {
  display: flex;
}

#travel-data {
  width: 44%;
  margin-left: 3%;
}

.text-in-right {
  text-align: right;
}

#disclaimer {
  margin-top: 16px;
  padding: 16px 8px 8px 8px;
}

#google-map {
  width: 50%;
  display: flex;
  justify-content: center;
}

#questions-button {
  margin-right: 8px;
}

@media (max-width: 1100px) {
  #travel-div {
    flex-direction: column;
  }
  #travel-data {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 16px;
  }
  #google-map {
    width: 80%;
    margin-left: 10%;
  }
}
@media (max-width: 700px) {
  #infos {
    width: 98%;
    margin-left: 1%;
  }
  #travel-data {
    width: 100%;
    margin-left: 0;
  }
  #google-map {
    width: 100%;
    margin-left: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plateau-technique/plateau-technique.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,UAAA;EACA,eAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,UAAA;EACA,aAAA;EACA,uBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI;IACI,sBAAA;EACN;EAEE;IACI,UAAA;IACA,gBAAA;IACA,mBAAA;EAAN;EAGE;IACI,UAAA;IACA,gBAAA;EADN;AACF;AAIA;EACI;IACI,UAAA;IACA,eAAA;EAFN;EAKE;IACI,WAAA;IACA,cAAA;EAHN;EAME;IACI,WAAA;IACA,cAAA;EAJN;AACF","sourcesContent":["#infos {\r\n    width: 50%;\r\n    margin-top: 20px;\r\n    margin-left: 25%;\r\n}\r\n\r\n#div-buttons {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    padding-right: 16px;\r\n    margin-top: 8px;\r\n    margin-bottom: 8px;\r\n}\r\n\r\n#travel-div {\r\n    display: flex;\r\n}\r\n\r\n#travel-data {\r\n    width: 44%;\r\n    margin-left: 3%;\r\n}\r\n\r\n.text-in-right {\r\n    text-align: right;\r\n}\r\n\r\n#disclaimer {\r\n    margin-top: 16px;\r\n    padding: 16px 8px 8px 8px;\r\n}\r\n\r\n#google-map {\r\n    width: 50%;\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\n#questions-button {\r\n    margin-right: 8px;\r\n}\r\n\r\n@media (max-width: 1100px) {\r\n    #travel-div {\r\n        flex-direction: column;\r\n    }\r\n\r\n    #travel-data {\r\n        width: 80%;\r\n        margin-left: 10%;\r\n        margin-bottom: 16px;\r\n    }\r\n\r\n    #google-map {\r\n        width: 80%;\r\n        margin-left: 10%;\r\n    }\r\n}\r\n\r\n@media (max-width: 700px) {\r\n    #infos {\r\n        width: 98%;\r\n        margin-left: 1%;\r\n    }\r\n\r\n    #travel-data {\r\n        width: 100%;\r\n        margin-left: 0;\r\n    }\r\n\r\n    #google-map {\r\n        width: 100%;\r\n        margin-left: 0;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { LoadingService } from 'src/app/services/loading.service';
import { CompetenceCleCustomService } from 'src/app/services/DescriptionCompetenceCleCustom/competence-cle-custom.service';
import { UtilisateurObjectifService } from 'src/app/services/UtilisateurObjectif/utilisateur-objectif.service';
import { ToastService } from 'src/app/services/Toast/toast.service';

@Component({
  selector: 'app-template-form-descriptions-sous-objectifs-competences-cles',
  templateUrl: './template-form-descriptions-sous-objectifs-competences-cles.component.html',
  styleUrls: ['./template-form-descriptions-sous-objectifs-competences-cles.component.scss'],
})
export class TemplateFormDescriptionsSousObjectifsCompetencesClesComponent implements OnInit {
  @Input("descriptionCompetenceCles") descriptionCompetenceCles:any;
  @Input("sousObjectif") sousObjectif:any;
  @Input("idCompetenceCleObjectifPrincipal") idCompetenceCleObjectifPrincipal:number;
  @Input("userId") userId:number;

  private utilisateurObjectifs:any;
  competenceCleForm1 = new FormGroup({
    competenceCleEdition: new FormControl('')
  });
  competenceCleForm2 = new FormGroup({
    competenceCleEdition: new FormControl('')
  });
  competenceCleForm3 = new FormGroup({
    competenceCleEdition: new FormControl('')
  });
  competenceCleForm4 = new FormGroup({
    competenceCleEdition: new FormControl('')
  });

  descriptionAutreCompetenceCleForm1 = new FormGroup({
    competenceCleAutreEdition: new FormControl('')
  });
  descriptionAutreCompetenceCleForm2 = new FormGroup({
    competenceCleAutreEdition: new FormControl('')
  });
  descriptionAutreCompetenceCleForm3 = new FormGroup({
    competenceCleAutreEdition: new FormControl('')
  });
  descriptionAutreCompetenceCleForm4 = new FormGroup({
    competenceCleAutreEdition: new FormControl('')
  });

  autreDescriptionCompetenceCle1:boolean=false;
  autreDescriptionCompetenceCle2:boolean=false;
  autreDescriptionCompetenceCle3:boolean=false;
  autreDescriptionCompetenceCle4:boolean=false;

  autreDescriptionCompetenceCleEdite:object = {
    0: false,
    1: false,
    2: false,
    3: false
  };

  constructor(
    private errorNotificationService:ErrorNotificationService,
    private loadingService:LoadingService,
    private competenceCleCustomService:CompetenceCleCustomService,
    private utilisateurObjectifService:UtilisateurObjectifService,
    private toast:ToastService,
  ) {}

  ngOnInit() {
    this.utilisateurObjectifs = this.sousObjectif.UtilisateurObjectifs;
    //on ne garde que les UtilisateurObjectif lies au SousObjectif en cours
    this.utilisateurObjectifs = this.utilisateurObjectifs.filter(element => element.utilisateur.id === this.userId);
    this.setFormValues();
  }

  setFormValues() {
    for(let index of [0,1,2,3]) {
      let liens = this.getCompetencesClesVars(index);

      if(!!this.utilisateurObjectifs[0]["descriptionCompetenceCleCustoms"]
      && this.utilisateurObjectifs[0]["descriptionCompetenceCleCustoms"][3-index]
      && this.utilisateurObjectifs[0]["descriptionCompetenceCleCustoms"][3-index]["estValide"]) {

        //la liste deroulante est settee sur autre pour voir le champ texte
        liens.competenceCleForm.patchValue({
          "competenceCleEdition": "Autre"
        });

        //l'objectifCustom est affiche dans le champ texte
        liens.descriptionAutreCompetenceCleForm.patchValue({
          "competenceCleAutreEdition": this.utilisateurObjectifs[0]["descriptionCompetenceCleCustoms"][3-index].nom
        });

        switch(3-index) {//rend le champ texte de la description custom visible
          case 0:
            this.autreDescriptionCompetenceCle1 = true;
            break;

          case 1:
            this.autreDescriptionCompetenceCle2 = true;
            break;

          case 2:
            this.autreDescriptionCompetenceCle3 = true;
            break;

          case 3:
              this.autreDescriptionCompetenceCle4 = true;
              break;
        }
      } else if(!!this.utilisateurObjectifs[0]["descriptionsCompetencesCles"][index]) {
        liens.competenceCleForm.patchValue({
          "competenceCleEdition": this.utilisateurObjectifs[0]["descriptionsCompetencesCles"][index]
        });
      }
    }
  }

  descriptionCompetenceCleChange(event, indexDescription) {
    if(event.detail.value === " Autre ") {
      this.alertError(
        "",
        "Attention",
        "Vore objectif personnalisé devra être validé par un administrateur pour être visible"
      );
      this.manageAutreDescriptionCompetenceCle(indexDescription, true);
      //reset du textArea
      this.resetDescriptionAutreCompetenceCleForm(indexDescription);
    } else {
      let descriptionCustomVersStandard = this.manageAutreDescriptionCompetenceCle(indexDescription, false);
      let cible = event.detail.value.id;
      let presenceDoublon = this.utilisateurObjectifs[0]["descriptionsCompetencesCles"].find(
        object => object.id === cible
      );

      //dans la situation ou on passe d'1 valeur à "autre" (sans valider) 
      //pour finallement revenir à la valeur de base, on empeche 1
      //appel API inutile 
      if(!!!this.utilisateurObjectifs[0]["descriptionsCompetencesCles"][indexDescription]
      || this.utilisateurObjectifs[0]["descriptionsCompetencesCles"][indexDescription]["id"] !== event.detail.value.id)
      {
        if(!!!presenceDoublon) {
          //on veux passer d'1 DescriptionCompetenceCleCustom
          //a 1 DescriptionCompetenceCle
          if(descriptionCustomVersStandard) {
            this.loadingService.showLoading();
            //on supprime la DescriptionCompetenceCleCustom et on lie
            //avec la DescriptionCompetenceCle voulue
            this.competenceCleCustomService.supprime(
              this.utilisateurObjectifs[0]["descriptionCompetenceCleCustoms"][3 - indexDescription].id
            ).subscribe(
              () => {
                //passage description custom vers std
                this.utilisateurObjectifService.ajouteLiaisonDescriptionCompetenceCle(
                  this.utilisateurObjectifs[0]["id"], event.detail.value.id
                ).subscribe(
                  () => {
                    this.loadingService.dismissLoading();
                  },
                  error => {
                    this.alertError("enregistrement");
                    this.loadingService.dismissLoading();;
                  }
                );
              },
              error => {
                this.alertError("enregistrement");
                this.loadingService.dismissLoading();
              }
            )
          } else {//on passe d'une DescriptionCompetenceCle a une autre
            let dataDescriptionCompetenceCle = {
              old: null,
              new: null,
              idUtilisateurObjectif: this.utilisateurObjectifs[0]["id"]
            };

            let idNouveauDescriptionCompetenceCle = event.detail.value["@id"].split("/descriptionCompetenceCle/");
            dataDescriptionCompetenceCle.new = parseInt(idNouveauDescriptionCompetenceCle[1]);
            let data = [];
            this.utilisateurObjectifs[0]["descriptionsCompetencesCles"].forEach((arrayItem,index) => {
              if(index !== indexDescription) {
                data.push("descriptionCompetenceCle/" + arrayItem.id);
              }else {
                dataDescriptionCompetenceCle.old = arrayItem.id;
              }
            });

            data.push(event.detail.value["@id"]);
            this.editeDescriptif("competenceCle", data, dataDescriptionCompetenceCle, indexDescription, this.idCompetenceCleObjectifPrincipal);
          }
        } else if(!!presenceDoublon) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            '"' + event.detail.value.nom  + '" a déja été lié à cet objectif',
            "Ok"
          );

          //remet la valeur d'origine MAIS trigger le ionChange qui
          //provoque un 2nd passage avec !!!presenceDoublon
          // let formTarget = this.getCompetencesClesVars(indexDescription);
          // formTarget.competenceCleForm.patchValue({
          //   "competenceCleEdition": this.objectif["descriptionsCompetencesCles"][indexDescription]
          // });
        }
      }
    }
  }

  getCompetencesClesVars(index:number) {
    let vars = {
      competenceCleForm: null,
      descriptionAutreCompetenceCleForm:null,
    }

    switch(index) {
      case 0:
        vars.competenceCleForm = this.competenceCleForm1;
        vars.descriptionAutreCompetenceCleForm = this.descriptionAutreCompetenceCleForm4
        break;

      case 1:
        vars.competenceCleForm = this.competenceCleForm2;
        vars.descriptionAutreCompetenceCleForm = this.descriptionAutreCompetenceCleForm3
        break;

      case 2:
        vars.competenceCleForm = this.competenceCleForm3;
        vars.descriptionAutreCompetenceCleForm = this.descriptionAutreCompetenceCleForm2
        break;

      case 3:
        vars.competenceCleForm = this.competenceCleForm4;
        vars.descriptionAutreCompetenceCleForm = this.descriptionAutreCompetenceCleForm1
        break;

      default:
        break;
    }
    return vars;
  }

  editeDescriptif(type:string, liens:string[], indexesDescriptionCompetenceCle:any=null, indexDescription:number, idCompetenceCleObjectifPrincipal:number) {
    this.loadingService.showLoading();
    this.utilisateurObjectifService.editeDescriptifs(
      this.utilisateurObjectifs[0]["id"], type, liens, idCompetenceCleObjectifPrincipal
    ).subscribe(
      data => {
        let infos = data;
        //Object.values converti un object d'objets en array d'objets (format attendu ds this.objectif)
        this.utilisateurObjectifs[0]["descriptionsCompetencesCles"] = Object.values(infos["descriptionsCompetencesCles"]);
        let descriptionCompetenceCleCustoms = infos["descriptionCompetenceCleCustoms"];

        const found = descriptionCompetenceCleCustoms.find((element) => 
          element.estValide === false && element.descriptionCompetenceCle.id === indexesDescriptionCompetenceCle.old
        );

        //empeche cet appel s'il n'y a pas de descriptionCompCleCustom ac estValide===false liee
        if(typeof(found) !== "undefined") {
          this.competenceCleCustomService.nonValideEditeCompetenceCleLiee(indexesDescriptionCompetenceCle).subscribe(
            () => {
              //
            },
            error => {
              this.alertError("enregistrement");
            }
          );
        }

        this.loadingService.dismissLoading();
      },
      error => {
        this.loadingService.dismissLoading();
        this.alertError("chargement");
        this.loadingService.dismissLoading();
      }
    )
  }

  manageAutreDescriptionCompetenceCle(indexDescription:number, etat) {
    let stateMemo;
    switch(indexDescription) {
      case 0:
        stateMemo = this.autreDescriptionCompetenceCle4;
        this.autreDescriptionCompetenceCle4 = etat;
        break;

      case 1:
        stateMemo = this.autreDescriptionCompetenceCle3;
        this.autreDescriptionCompetenceCle3 = etat;
        break;

      case 2:
        stateMemo = this.autreDescriptionCompetenceCle2;
        this.autreDescriptionCompetenceCle2 = etat;
        break;

      case 3:
        stateMemo = this.autreDescriptionCompetenceCle1;
        this.autreDescriptionCompetenceCle1 = etat;
        break;

      default:
        break;
    }

    if(!etat) {
      return stateMemo;
    }
  }

  resetDescriptionAutreCompetenceCleForm(indexDescription:number) {
    switch(indexDescription) {
      case 0:
        this.descriptionAutreCompetenceCleForm4.patchValue({
          "competenceCleAutreEdition": ""
        });
        break;

      case 1:
        this.descriptionAutreCompetenceCleForm3.patchValue({
          "competenceCleAutreEdition": ""
        });
        break;

      case 2:
        this.descriptionAutreCompetenceCleForm2.patchValue({
          "competenceCleAutreEdition": ""
        });
        break;

      case 3:
        this.descriptionAutreCompetenceCleForm1.patchValue({
          "competenceCleAutreEdition": ""
        });
        break;

      default:
        break;
    }
  }

  submitAutreDescriptionCompetenceCle(numeroForm:number) {
    let descriptionCustomBase = this.utilisateurObjectifs[0]["descriptionCompetenceCleCustoms"][numeroForm-1];
    let formValues;

    switch(numeroForm) {
      case 1:
        formValues = this.descriptionAutreCompetenceCleForm1.value;
        break;

      case 2:
        formValues = this.descriptionAutreCompetenceCleForm2.value;
        break;

      case 3:
        formValues = this.descriptionAutreCompetenceCleForm3.value;
        break;

      case 4:
        formValues = this.descriptionAutreCompetenceCleForm4.value;
        break;

      default:
        break;
    }

    this.loadingService.showLoading()

    if(!!descriptionCustomBase && Object.keys(descriptionCustomBase).length !== 0) { //edition description custom
      this.competenceCleCustomService.edite(
        descriptionCustomBase.id,
        formValues.competenceCleAutreEdition
      ).subscribe(
        () => {
          this.loadingService.dismissLoading();
          this.toast.presentToast("bottom", "Enregistré", "success");
        },
        error => {
          this.alertError("enregistrement");
        }
      );
    } else { //creation description custom
      let urlDescriptionCompetenceCle=null;

      if(!!this.utilisateurObjectifs[0]["descriptionsCompetencesCles"][4 - numeroForm]) {
        urlDescriptionCompetenceCle = "descriptionCompetenceCle/" + this.utilisateurObjectifs[0]["descriptionsCompetencesCles"][4 - numeroForm].id
      }

      this.competenceCleCustomService.cree(
        formValues.competenceCleAutreEdition,
        "utilisateur_objectifs/" + this.utilisateurObjectifs[0]["id"],
        urlDescriptionCompetenceCle
      ).subscribe(
        (data) => {
          this.utilisateurObjectifs[0]["descriptionCompetenceCleCustoms"][numeroForm-1] = {
            id: data["id"],
            nom: data["nom"],
            estValide: data["estValide"]
          };

          this.loadingService.dismissLoading();
          this.toast.presentToast("bottom", "Enregistré", "success");
        },
        error => {
          this.alertError("enregistrement");
        }
      )
    }
  }

  clickOnObjectifCustom(event, indexInput:number) {
    let message = "Toute modification de votre objectif personnalisé devra être validé pour être visible.";
    message += " Dans l'attente de cette validation, l'objectif par défaut sera affiché";

    if(event.target.value !== "" && !this.autreDescriptionCompetenceCleEdite[indexInput]) {
      this.autreDescriptionCompetenceCleEdite[indexInput] = true;
      this.alertError(
        "",
        "Attention",
        message
      );
    }
  }

  alertError(type:string, titre?:string, message?:string) {
    titre = type === null ? "Erreur" : titre;

    switch(type) {
      case "enregistrement":
        message = "Un problème a été rencontré lors de l'enregistrement des données";
        break;

      case "chargement":
        message = "Un problème a été rencontré lors du chargement des données";
        break;

      default:
        break;
    }
    
    this.errorNotificationService.presentGeneralErrorAlert(
      false,
      titre,
      message,
      "Ok"
    );
  }

  //pour le formulaire d'edition
  //coche les checkboxes des options selectionnees
  compareWith(o1, o2) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }
}

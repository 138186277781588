import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { RecompenseService } from '../../services/Recompenses/recompense.service'
import { TypeRecompenseService } from 'src/app/services/TypesRecompense/type-recompense.service';
import { EntrepriseRecompenseService } from 'src/app/services/EntreprisesRecompense/entreprise-recompense.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';

@Component({
  selector: 'app-recompense',
  templateUrl: './recompense.component.html',
  styleUrls: ['./recompense.component.scss'],
})
export class RecompenseComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;

  url_edition:string;
  index_recompense:number;
  mode_edition:boolean=true;
  private entreprises: any;
  private types: any;
  private recompenses: any;
  loading_recompenses:boolean = false;
  loading_types: boolean=false;
  loading_entreprises:boolean = false;
  type_edition_text:string;
  entreprise_edition_text:string;

  nouveauForm = new FormGroup({
    intitule_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    code_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    type_nouveau: new FormControl('', [Validators.required]),
    entreprise_nouveau: new FormControl('', [Validators.required]),
    ville_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
    image_recompense_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    image_code_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),

  });

  editionForm = new FormGroup({
    intitule_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    code_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    type_edition: new FormControl('', [Validators.required]),
    entreprise_edition: new FormControl('', [Validators.required]),
    ville_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
    image_recompense_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    image_code_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
  });

  constructor(private recompenseService: RecompenseService,
              private entrepriseRecompenseService: EntrepriseRecompenseService,
              private typeRecompenseService: TypeRecompenseService,
              private errorNotificationService: ErrorNotificationService,
              private loadingService: LoadingService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.getRecompenses();
        this.getEntreprises();
        this.getTypes();
      }
    );
  }

  //recuperation des types d'objectifs enregistres en bdd
  getRecompenses() {
    this.loading_recompenses = true;
    this.recompenseService.getRecompenses().subscribe(
      data => {
        this.loading_recompenses = false;
        let infos = data;
        this.recompenses = infos["hydra:member"];
        this.checkLoading();
      },
      () => {
        this.loading_recompenses = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getTypes() {
    this.loading_types = true;
    this.typeRecompenseService.getTypesRecompenses().subscribe(
      data => {
        let infos = data;
        this.types = infos["hydra:member"];
        this.loading_types = false;
        this.checkLoading();
      },
      () => {
        this.loading_types = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getEntreprises() {
    this.loading_entreprises = true;
    this.entrepriseRecompenseService.getEntreprises().subscribe(
      data => {
        let infos = data;
        this.entreprises = infos["hydra:member"];
        this.loading_entreprises = false;
        this.checkLoading();
      },
      () => {
        this.loading_entreprises = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  checkLoading() {
    if(!this.loading_entreprises && !this.loading_types && !this.loading_recompenses) {
        this.loadingService.dismissLoading();
    }
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  ouvreModaleNouveau() {
    this.mode_edition = false;
    this.modal.present();
  }

  ouvreModaleEdition(recompense:any) {
    this.mode_edition = true;
    this.type_edition_text = recompense.typeRecompense.intitule;
    this.entreprise_edition_text = recompense.entreprise.nom;

    this.editionForm.patchValue({
      "intitule_edition": recompense.intitule,
      "code_edition": recompense.code,
      "type_edition": recompense.typeRecompense,
      "entreprise_edition": recompense.entreprise,
      "ville_edition": recompense.ville,
      "image_recompense_edition": recompense.imageRecompense,
      "image_code_edition": recompense.imageCode
    });

    this.url_edition = recompense["@id"];
    this.index_recompense = this.recompenses.lastIndexOf(recompense);
    this.modal.present();
  }

  SelectValueChange() {
    this.type_edition_text = this.editionForm.controls["type_edition"].value["intitule"];
    this.entreprise_edition_text = this.editionForm.controls["entreprise_edition"].value["nom"];
  }

  supprimeAlert(recompense:any) {
    this.index_recompense = this.recompenses.lastIndexOf(recompense);
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.supprime.bind(this),
      [recompense["@id"]]
    );
  }

  submitNouveau() {
    this.loadingService.showLoading();
    const formValues = this.nouveauForm.value;
    const memo_type = formValues["type_nouveau"];
    const memo_entreprise = formValues["entreprise_nouveau"];

    this.recompenseService.cree(formValues).subscribe(
      data => {
        const nouvel_objet:any = data;
        this.loadingService.dismissLoading();

        let ajout = {
          "@id": nouvel_objet["@id"],
          "intitule": nouvel_objet["intitule"],
          "code": nouvel_objet["code"],
          "typeRecompense": memo_type,
          "entreprise": memo_entreprise,
          "ville": nouvel_objet["ville"],
          "imageRecompense": nouvel_objet["imageRecompense"],
          "imageCode": nouvel_objet["imageCode"],
        };

        this.recompenses.push(ajout);
        this.modal.dismiss();
        this.nouveauForm.reset();
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error["hydra:description"],
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'enregistrement a échoué", "danger");
        }
        this.loadingService.dismissLoading();
      }
    )
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;
    const memo_type = formValues["type_edition"];
    const memo_entreprise = formValues["entreprise_edition"];

    this.recompenseService.edite(formValues, this.url_edition).subscribe(
      data => {
        let objet_edite:any = data;
        this.loadingService.dismissLoading();
        this.recompenses[this.index_recompense] = {
          "@id": objet_edite["@id"],
          "intitule": objet_edite.intitule,
          "code": objet_edite.code,
          "typeRecompense": memo_type,
          "entreprise": memo_entreprise,
          "ville": objet_edite["ville"],
          // "urlImage": objet_edite["urlImage"],
          "imageRecompense": objet_edite["imageRecompense"],
          "imageCode": objet_edite["imageCode"],
        };

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Modifié", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    );
  }

  supprime(lien:string) {
    this.loadingService.showLoading();
    this.recompenseService.supprime(lien[0]).subscribe(
      () => {
        this.loadingService.dismissLoading();
        this.recompenses.splice(this.index_recompense, 1);
        this.toastService.presentToast("bottom", "Supprimé", "success");
      },
      error => {
        this.loadingService.dismissLoading();

        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
        }
      }
    )
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }

  public handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = '../../../assets/img/no_image.png';
  }
}

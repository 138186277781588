import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from 'src/app/config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurNiveauxService {
  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  setRecompense(recompense_id: string, id: string) {
    const url = this.constants.apiGetPostEndpoint + '/utilisateur_niveaux_choix/'+id;
    const httpHeaders = this.headerService.getPatchHeader();
    return this.httpClient.patch(url, {
      recompense: "/recompenses/"+recompense_id
    }, httpHeaders);
  }

  editDesactive(utilisateurNiveauId:number, desactive:boolean) {
    const url = this.constants.apiEndpoint + "/utilisateur_niveaux/" + utilisateurNiveauId;
    const httpHeaders = this.headerService.getPatchHeader();
    return this.httpClient.patch(url, {"desactive": desactive}, httpHeaders);
  }

  /*
  edite(objectif_utilisateur:any, sous_objectifs_associes, lien: string) {    
    const url = this.constants.apiEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();
    let data = this.prepareData(objectif_utilisateur);
    data["estValide"] = objectif_utilisateur["objectif_atteint_edition"];

    if(sous_objectifs_associes.length > 0) {
      data["sousObjectifsUtilisateur"] = sous_objectifs_associes;
    }
    return this.httpClient.patch(url, data, httpHeaders);
  }
  */
}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export class Constants {

  constructor() {
    this.getBirthDateLimit();
    this.getRdvDatesLimits();
  }

  // lien prod
  public readonly apiEndpoint: string = 'https://prendstoienmain.com/api/public';
  public readonly apiGetPostEndpoint: string = 'https://prendstoienmain.com/api/public';
  public readonly apiPatchDeleteEndpoint: string = 'https://prendstoienmain.com';

  // liens preprod
  // public readonly apiEndpoint: string = 'https://prendstoienmain.com/preprod/api/public';
  // public readonly apiGetPostEndpoint: string = 'https://prendstoienmain.com/preprod/api/public';
  // public readonly apiPatchDeleteEndpoint: string = 'https://prendstoienmain.com';

  // liens preprod emeraude
  // public readonly apiEndpoint: string = 'https://prendstoienmain.com/emeraude/api/public';
  // public readonly apiGetPostEndpoint: string = 'https://prendstoienmain.com/emeraude/api/public';
  // public readonly apiPatchDeleteEndpoint: string = 'https://prendstoienmain.com';

  // lien local
  // public readonly apiEndpoint: string = 'http://localhost:8000';
  // public readonly apiGetPostEndpoint: string = 'http://localhost:8000';
  // public readonly apiPatchDeleteEndpoint: string = 'http://localhost:8000';

  public readonly urlYPK:string = "https://adep-roubaix.hop3team.com/api/v1/";

  public reservioId:string = "79575e6c-8012-49e5-bfa0-82564b42c81e";
  public urlReservio:string = "https://api.reservio.com/v2/";
  public reservioToken:string = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6Ijc1YzZlZjlhMGQ2MTA1MzA2YTAxZGFkZTA3YWNjZTQ0YWQ1OGQwODgzNzhiYmVjMmQzOTRkN2EyMzUxOWMxNDZjODQ0NjI5YWM0Yzk1MGExIn0.eyJhdWQiOiI1ZWI3ODIyZC1iMGNiLTQyZTItYTIwYS1kMTFjNjc4ZjNhM2MiLCJqdGkiOiI3NWM2ZWY5YTBkNjEwNTMwNmEwMWRhZGUwN2FjY2U0NGFkNThkMDg4Mzc4YmJlYzJkMzk0ZDdhMjM1MTljMTQ2Yzg0NDYyOWFjNGM5NTBhMSIsImlhdCI6MTcxNjU1OTYyOSwibmJmIjoxNzE2NTU5NjI5LCJleHAiOjE4NzQzMjYwMjksInN1YiI6IjI0NDA0MTUiLCJzY29wZXMiOlsiYWRtaW4iXX0.d9s9yhx3nFa0vS6-CcxX9W4T1Y0PxVJwsY_6HlRK86XCSURV82dELrb0MF4mcyRFzjukRlzGOPMgWILill5hc6geSirpkm_wFo7n1fWzuv4awjzPXhxf8qwEAKZch61NF9HrWZ4TVm8i6cGC9JUeuGReNqQdwJpNFOlFSEX3IM0"

  public minDate="1922-01-01T00:00:00";
  public maxDate="2099-12-31T23:59:59";
  public mode="prod"; //possibilites: "local", "preprod", "prod", "emeraude"

  public minBirthDate;
  public maxBirthDate;
  private maxAge = 100;
  private minAge = 18;
  public minRdvDate;
  public maxRdvDate;

  getBirthDateLimit() {
    this.minBirthDate = new Date();
    this.minBirthDate.setFullYear(this.minBirthDate.getFullYear() - this.maxAge);
    this.minBirthDate = this.minBirthDate.getFullYear() + "-" + this.minBirthDate.getMonth()+1
    +"-" + this.minBirthDate.getDate();
    this.maxBirthDate = new Date();
    this.maxBirthDate.setFullYear(this.maxBirthDate.getFullYear() - this.minAge);
    let mois = this.maxBirthDate.getMonth()+1;
    mois = mois < 10 ? "0"+mois : mois;
    this.maxBirthDate = this.maxBirthDate.getFullYear() + "-" + mois
    +"-" + this.maxBirthDate.getDate();
  }

  getRdvDatesLimits() {
    let now = new Date();
    let yearSixMonthsAgo = now.getFullYear();
    let yearInSixMonths = now.getFullYear();
    let sixMonthsPast:any = now.getMonth() - 5; //+1-6
    let sixMonthsFuture:any = now.getMonth() + 7; //+1+6

    if(sixMonthsPast < 1) {
      sixMonthsPast = 12 + sixMonthsPast;
      yearSixMonthsAgo--;
    }

    if(sixMonthsFuture > 12) {
      sixMonthsFuture = sixMonthsFuture - 12;
      yearInSixMonths++;
    }

    sixMonthsPast = sixMonthsPast < 10 ? "0" + sixMonthsPast : sixMonthsPast;
    sixMonthsFuture = sixMonthsFuture < 10 ? "0" + sixMonthsFuture : sixMonthsFuture;
    this.minRdvDate = yearSixMonthsAgo + "-" + sixMonthsPast + "-" + now.getDate();
    this.maxRdvDate = yearInSixMonths + "-" + sixMonthsFuture + "-" + now.getDate();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { TypeObjectifService } from '../../services/TypesObjectif/type-objectif.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';

@Component({
  selector: 'app-type-objectif',
  templateUrl: './type-objectif.component.html',
  styleUrls: ['./type-objectif.component.scss'],
})
export class TypeObjectifComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  url_edition:string;
  index_type:number;
  mode_edition:boolean=true;
  private types: any;
  loading_types: boolean=false;

  nouveauForm = new FormGroup({
    intitule_nouveau: new FormControl('', [Validators.required, Validators.maxLength(100)]),
  });

  editionForm = new FormGroup({
    intitule_edition: new FormControl('', [Validators.required, Validators.maxLength(100)]),
  });

  constructor(private typeObjectifService: TypeObjectifService,
              private errorNotificationService: ErrorNotificationService,
              private loadingService: LoadingService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.getTypes();
      }
    );
  }
  
  //recuperation des types d'objectifs enregistres en bdd
  getTypes() {
    this.typeObjectifService.getTypes().subscribe(
      data => {
        let infos = data;
        this.types = infos["hydra:member"];
        this.loadingService.dismissLoading();
      },
      () => {
        this.loading_types = false;
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  ouvreModaleNouveau() {
    this.mode_edition = false;
    this.modal.present();
  }

  customNewFormValid() {
    if(this.nouveauForm.controls["intitule_nouveau"].value === ""
    || this.nouveauForm.invalid) {
      return true;
    }
    return false;
  }

  submitNouveau() {
    this.loadingService.showLoading();
    const formValues = this.nouveauForm.value;

    this.typeObjectifService.cree(formValues).subscribe(
      data => {
        const nouvel_objet:any = data;
        this.loadingService.dismissLoading();
        let ajout = {
          "@id": nouvel_objet["@id"],
          "intitule": nouvel_objet.intitule,
        };

        this.types.push(ajout);
        this.modal.dismiss();
        this.nouveauForm.reset();
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error["hydra:description"],
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'enregistrement a échoué", "danger");
        }
        this.loadingService.dismissLoading();
      }
    )
  }

  ouvreModaleEdition(type:any) {
    this.mode_edition = true;
    this.url_edition = type["@id"];
    this.editionForm.patchValue({
      "intitule_edition": type.intitule,
    });
    this.modal.present();
    this.index_type = this.types.lastIndexOf(type);
  }

  customEditFormValid() {
    if(this.editionForm.controls["intitule_edition"].value === ""
    || this.editionForm.invalid) {
      return true;
    }
    return false;
  }

  supprimeAlert(type:any) {
    this.index_type = this.types.lastIndexOf(type);
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.supprime.bind(this),
      [type["@id"]]
    );
  }

  getData(ajout_base, objet_lie) {
    switch(objet_lie["@type"]) {
      case "EntrepriseStage":
        ajout_base["entrepriseStage"] = objet_lie;
        break;

      case "CompetenceCle":
        ajout_base["competenceCle"] = objet_lie;
        break;

      case "SoftSkill":
        ajout_base["softSkill"] = objet_lie;
        break;

      case "Atelier":
        ajout_base["atelier"] = objet_lie;
        break;
    };
    return ajout_base;
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;

    this.typeObjectifService.edite(formValues, this.url_edition).subscribe(
      data => {
        let objet_edite:any = data;
        this.loadingService.dismissLoading();
        this.types[this.index_type] = {
          "@id": objet_edite["@id"],
          "intitule": objet_edite.intitule,
        };

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Modifié", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    );
  }

  supprime(lien:string) {
    this.loadingService.showLoading();
    this.typeObjectifService.supprime(lien[0]).subscribe(
      () => {
        this.loadingService.dismissLoading();
        this.types.splice(this.index_type, 1);
        this.toastService.presentToast("bottom", "Supprimé", "success");
      },
      error => {
        this.loadingService.dismissLoading();

        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
        }
      }
    )
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UtilisateurService } from 'src/app/services/Utilisateur/utilisateur.service';
import { LoadingService } from 'src/app/services/loading.service';
import { GoogleMapService } from 'src/app/services/GoogleMap/google-map.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { ExternService } from 'src/app/services/Extern/extern.service';
import { GeneralService } from 'src/app/services/General/general.service';

@Component({
  selector: 'app-stage-user',
  templateUrl: './stage-user.component.html',
  styleUrls: ['./stage-user.component.scss'],
})
export class StageUserComponent implements OnInit {
  userId:number;
  entrepriseStage:any;
  // mapLoaded:boolean = false;
  currentUser:any;
  // travelMode:string = "DRIVE";
  // travelDistance:string;
  // travelDuration:string;
  // vertices: google.maps.LatLngLiteral[] = [];
  // options: google.maps.MapOptions = {};
  isIpad:boolean=false;

  constructor(private router: Router,
              private location: Location,
              private utilisateurService:UtilisateurService,
              private loadingService:LoadingService,
              private googleMapService:GoogleMapService,
              private errorNotificationService: ErrorNotificationService,
              private generalService: GeneralService,
              private externService:ExternService) { 
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state;
    this.userId = state.userId;
    this.entrepriseStage = state.entrepriseStage;
  }

  ngOnInit() {
    this.isIpad = this.generalService.isIpad();

    this.loadingService.showLoading().then(
      () => {
        this.getMeData();
      }
    );
  }

  getMeData() {
    this.utilisateurService.getMe().subscribe(
      data => {
        this.currentUser = data["data"];

        if(this.generalService.isMobile()) {
          this.getExternalMap(this.entrepriseStage.adresse, this.entrepriseStage.ville);
        } else {
          this.loadingService.dismissLoading();
        }

        //this.getMap();
      },
      error => {
        //console.log(error);
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getExternalMap(address:string, town:string) {
    this.externService.getLocation(address, town).subscribe(
      data => {
        let coordinates = data["features"][0].geometry.coordinates;

        var iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
        let destination = coordinates[1] + ',' + coordinates[0];

        if(iOS || this.isIpad) {
          window.open('maps://?q=' + destination, '_system');
        } else {
          let label = encodeURI(this.entrepriseStage.nom);
          window.open('geo:0,0?q=' + destination + '(' + label + ')', '_system');
        }
        this.loadingService.dismissLoading();
      },
      error => {
        console.log(error);
        this.loadingService.dismissLoading();
      }
    )
  }

  /*getMap() {
    let startPoint = "";
    let endPoint= "";

    if(!!this.currentUser["adresse"] && !!this.currentUser["ville"] && !!this.currentUser["codePostal"]) {
      startPoint = this.currentUser["adresse"] + " " + this.currentUser["codePostal"] + " " + this.currentUser["ville"];
    }

    if(!!this.entrepriseStage.adresse && !!this.entrepriseStage.ville
      && !!this.entrepriseStage.codePostal) {
        endPoint = this.entrepriseStage.adresse + " " + this.entrepriseStage.codePostal
          + " " + this.entrepriseStage.ville;
    }

    this.googleMapService.getWay(startPoint, endPoint, this.travelMode).subscribe(
      data => {
        if(!!data["routes"]) {
          //en cas de besoin pour la suite
          //"distance en metres": data["routes"][0].distanceMeters
          this.travelDistance = data["routes"][0].localizedValues.distance.text;
          this.travelDuration = data["routes"][0].localizedValues.duration.text;
          let coordinates = this.googleMapService.decodePolyline(data["routes"][0]["polyline"].encodedPolyline);
          let steps = [];
          
          coordinates.forEach((coordinate, index) => {
            steps.push({
              lat: coordinate[0],
              lng: coordinate[1]
            });

            if(index === 0) {
              this.options = {
                center: {
                  lat: coordinate[0],
                  lng: coordinate[1]
                },
                zoom: 16
              }
            }
          })

          this.vertices = steps;
          this.mapLoaded = true;
        } else {
          this.travelDistance = "Aucune donnée";
          this.travelDuration = "Aucune donnée";
        }
        
        this.loadingService.dismissLoading();
      },
      error => {
        //console.log(error);
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  modeChange(event) {
    this.travelMode = event.detail.value;
    this.loadingService.showLoading().then(
      () => {
        this.getMap();
      }
    );
  }*/

  goBack() {
    this.location.back();
  }
}

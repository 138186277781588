import { Component, OnInit, ViewChild } from '@angular/core';
import { ExternService } from 'src/app/services/Extern/extern.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ErrorNotificationService } from 'src/app/services/error-notification.service';
import { GeneralService } from 'src/app/services/General/general.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { IonModal } from '@ionic/angular';
import { UtilisateurService } from 'src/app/services/Utilisateur/utilisateur.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
})
export class BookingsComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;

  private loadingServices:boolean=false;
  private loadingHolidays:boolean=false;
  public services=[];
  public currentService;
  public firstService;
  public events=[];
  public displayedEvents=[];
  //public currentEvent;
  public currentEvents=[];
  public bookings=[];
  public currentEditedBookingIndex:number;
  public currentEditedBookingReservioId:string;
  public modalMode:string; //attendus "create" ou  "edit"
  public users;
  public availableUsers;
  public currentSelectedUser;
  public userSearchText:string="";
  public filteredUsersList;
  public currentResource;
  public currentDate;
  public currentStartHour:string="";
  public currentEndHour:string="";
  public currentShortDay:string="";
  public selectedDates:string[]
  public previousMonth
  public currentMonth;
  public nextMonth;
  public previousYear;
  public currentYear;
  public nextYear;
  public minCalendar;
  public maxCalendar;
  public loadingBookingData:boolean=false;
  public closedDates=[];
  public loadingBuisinessHolidays:boolean=false;
  public buisinessHolidays=[];
  public today;
  public oldEventsMode:boolean=false;
  public smartphoneMode:boolean=false;

  createForm = new FormGroup({
    userIdReservio: new FormControl('', [Validators.required, Validators.maxLength(255)]),
  });

  constructor(private externService:ExternService,
    private loadingService:LoadingService,
    private errorNotificationService:ErrorNotificationService,
    private generalService:GeneralService,
    private utilisateurService:UtilisateurService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe([
      "(max-width : 400px)"
    ]).subscribe((result: BreakpointState) => {
      if(result.matches) {
        this.smartphoneMode = true;
      } else {
        this.smartphoneMode = false;
      }
    })
  }

  ngOnInit() {
    this.today = new Date().toLocaleDateString("fr-FR");
    this.today = this.today.split("/");
    this.today = this.today[2] + "-" + this.today[1] + "-" + this.today[0];
    this.getDatesLimits();

    this.loadingService.showLoading().then(
      () => {
        this.getLocalAllReservioServices();
        this.getUsersLimitedData();
        this.getClosedDates();
        this.getBuisinessHolidays();
      }
    );
  }

  getClosedDates() {
    this.loadingHolidays = true;

    this.externService.getFrenchPublicHolidays().subscribe(
      data => {
        this.closedDates = data["currentYear"].concat(data["nextYear"]);
        this.loadingHolidays = false;
        this.checkLoading();
      },
      () => {
        this.loadingHolidays = false;
        this.checkLoading();
      }
    )
  }

  getBuisinessHolidays() {
    this.loadingBuisinessHolidays = true;

    this.externService.getBuisinessHolidays().subscribe(
      data => {
        let received:any = data;
        this.buisinessHolidays = received;
        this.loadingBuisinessHolidays = false;
        this.checkLoading();
      },
      () => {
        this.loadingBuisinessHolidays = false;
        this.checkLoading();
      }
    );
  }

  //determine les jours actives/desactives sur le calendrier des resources dispos
  isAvailableDateEnabled = (dateIsoString: string): boolean => {
    if(!this.closedDates.includes(dateIsoString)//les jours feries ne sont pas dispos
    && !this.buisinessHolidays.includes(dateIsoString)) {
      return true;
    } else {
      return false;
    }
  };

  getDatesLimits() {
    let standardDate = new Date();
    this.currentMonth = standardDate.getMonth()+1;
    this.nextMonth = this.currentMonth === 12 ? 1 : this.currentMonth + 1;
    this.previousMonth = this.currentMonth === 1 ? 12 : standardDate.getMonth();
    this.currentMonth = this.currentMonth < 10 ? "0" + this.currentMonth : this.currentMonth;
    this.nextMonth = this.nextMonth < 10 ? "0" + this.nextMonth : this.nextMonth;
    this.previousMonth = this.previousMonth < 10 ? "0" + this.previousMonth : this.previousMonth;
    this.currentYear = standardDate.getFullYear();
    this.previousYear = this.currentYear - 1;
    this.nextYear = this.currentYear + 1;
    this.minCalendar = this.previousMonth === "12" ? this.previousYear : this.currentYear;
    this.minCalendar += "-" + this.previousMonth + "-01";
    this.maxCalendar = this.nextMonth === "01" ? this.nextYear : this.currentYear;
    this.maxCalendar += "-" + this.nextMonth + "-31";
  }

  getUsersLimitedData() {
    this.utilisateurService.getAllLimitedData().subscribe(
      data => {
        this.users = data;
        this.filteredUsersList = data;
      },
      error => {
        console.log(error);
      }
    )
  }

  getLocalAllReservioServices() {
    this.loadingServices = true;
    this.externService.getAllLocalReservioServices().subscribe(
      data => {
        this.services = data["services"];
        this.firstService = this.services[0];
        this.currentService = this.firstService;
        this.loadingServices = false;
        this.checkLoading();
      },
      () => {
        this.loadingServices = false;
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          false,
          "Erreur",
          "un problème a été rencontré lors du chargement des services",
          "Ok"
        );
      }
    )
  }

  checkLoading() {
    if(!this.loadingServices && !this.loadingHolidays
      && !this.loadingBuisinessHolidays
    ) {
      this.loadingService.dismissLoading();
    }
  }

  serviceChange(event) {
    this.currentService = event.detail.value;
    this.selectedDates = [];
    this.currentEvents=[];
    this.currentStartHour = "";
    this.currentEndHour = "";
    this.setEventsCalandarDates(this.selectedDates);
  }

  selectedDateChange(event) {
    this.currentDate = this.generalService.convertDateTimeToLocal(event.detail.value);
    this.changeDisplayedBooking(this.currentDate);
    this.oldEventsMode = this.currentDate < this.today;
  }

  changeDisplayedBooking(date) {
    this.availableUsers = [].concat(this.users);//"reset"
    let shortDay = this.generalService.dateToShortDay(date);
    this.currentShortDay = shortDay;
    let startNumber:number;
    let startHour:any = "00:00";
    let endNumber:number;
    let endHour:any = "00:00";

    if(typeof(this.currentResource) !== "undefined") {
      if(this.currentResource["openingHours"].length > 0) {
        for(let openingHour of this.currentResource["openingHours"]) {
          if(openingHour["days"].includes(shortDay)) {
            startNumber = openingHour.start;
            startHour = this.generalService.secondsToTime(startNumber);
            startHour = startHour["hour"] + ":" + startHour["min"];
            endNumber = openingHour.end;
            endHour = this.generalService.secondsToTime(endNumber);
            endHour = endHour["hour"] + ":" + endHour["min"];
            this.currentStartHour = startHour;
            this.currentEndHour = endHour;
            break;
          }
        }
      }

      startHour = date + " " + startHour;
      this.loadingBookingData = true;

      this.externService.getLocalEventsByDateAndResource(
        startHour, this.currentResource.idReservio
      ).subscribe(
        data => {  
          if(data["events"] !== null && data["events"].length > 0) {
            let usersToRemove:string[] = [];

            for(let event of data["events"]) {
              //situation qui ne devrait pas exister normallement mais
              //presente avec les donnees de test
              if(event.bookings[0].utilisateur !== null) {
                let currentIdReservio = event.bookings[0].utilisateur.idReservio;

                if(!usersToRemove.includes(currentIdReservio)) {
                  usersToRemove.push(currentIdReservio);
                }
              }

              //si dernier element parcouru
              if(event === data["events"][data["events"].length - 1]) {
                this.removeFromAvailableUsers(usersToRemove);
              }
            }

            this.currentEvents = data["events"];
          } else {
            this.currentEvents = [];
          }

          this.loadingBookingData = false;
        },
        () => {
          this.loadingBookingData = false;
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            "un problème a été rencontré lors du chargement des rendez-vous",
            "Ok"
          );
        }
      );
    }
  }

  removeFromAvailableUsers(idReservioUsers) {
    for(let idReservioUser of idReservioUsers) {
      let user = this.availableUsers.find((element) => element.idReservio === idReservioUser);

      if(!!user) {
        let index = this.availableUsers.indexOf(user);

        if(index > -1) {
          this.availableUsers.splice(index, 1);
        }
      }
    }
  }

  resourceSelection(selectedResource, element) {
    let resourceItems = document.getElementsByClassName("resource-item");
    this.currentResource = selectedResource;
    let days = [];
    let previousMonthDates = [];
    let nextMonthDates = [];
    this.currentStartHour = "";
    this.currentEndHour = "";

    Array.from(resourceItems).forEach(function(eventItem){
      eventItem["classList"].remove("selected");
    });

    element.target.classList.add("selected");
    
    for(let openingHours of selectedResource.openingHours) {
      days = days.concat(openingHours.days);
    }
    
    let currentMonthDates = this.generalService.getWantedSeveralDays(
      this.currentYear,
      this.currentMonth,
      days
    );

    let yearForPreviousMonth = this.previousMonth === "12" ? this.previousYear : this.currentYear;
    previousMonthDates = this.generalService.getWantedSeveralDays(
      yearForPreviousMonth,
      this.previousMonth,
      days
    );

    let yearForNextMonth = this.nextMonth === "01" ? this.nextYear : this.currentYear;
    nextMonthDates = this.generalService.getWantedSeveralDays(
      yearForNextMonth,
      this.nextMonth,
      days
    );

    this.selectedDates = previousMonthDates.concat(currentMonthDates).concat(nextMonthDates);
    this.setEventsCalandarDates(this.selectedDates);

    if(!!this.currentDate) {
      this.changeDisplayedBooking(this.currentDate);
    }
  }

  callCreationModal() {
    this.modalMode = "create";
    this.modal.present();
  }

  createBooking() {
    this.loadingService.showLoading();

    this.externService.createEventAndBooking(
      this.currentDate + " " + this.currentStartHour,
      this.currentDate + " " + this.currentEndHour,
      this.currentResource.idReservio,
      this.currentSelectedUser.idReservio
    ).subscribe(
      data => {
        //retrait de l'utilisateur de la liste des availableUsers
        let index = this.availableUsers.indexOf(this.currentSelectedUser);

        if(index > -1) {
          this.availableUsers.splice(index, 1);
        }

        this.currentEvents.push(data["event"]);
        this.userSearchText = "";
        this.loadingService.dismissLoading();
      },
      error => {
        this.loadingService.dismissLoading();
        let message = "un problème a été rencontré lors de la création du rendez-vous";

        if(error.status === 422) {
          message = error.error;
        }

        this.errorNotificationService.presentGeneralErrorAlert(
          false,
          "Erreur",
          message,
          "Ok"
        );
      }
    )
  }

  extractUniqueDates(events) {
    let dates = [];

    if(events.length < 1) {
      return dates;
    }

    for(let event of events) {
      let localStart = this.generalService.convertDateTimeToLocal(event.start);
      if(!dates.includes(localStart)) {
        dates.push(localStart);
      }
      
      if(event === events[events.length - 1]) {
        return dates;
      }
    }
  }

  setEventsCalandarDates(dates:string[]) {
    let calendar:HTMLIonDatetimeElement = document.querySelector("#EventsCalandar");

    if(!!calendar) {
      calendar.highlightedDates = (param) => {
        if(dates.includes(param)) {
          return {
            textColor: 'rgb(68, 10, 184)',
            backgroundColor: 'rgb(211, 200, 229)',
          };
        }
        return undefined
      }
    }
  }

  userSearchChange() {
    this.filteredUsersList = this.availableUsers.filter(element => {
      return element.nom.startsWith(this.userSearchText);
    });

    let searchList = document.getElementById("user-search-list");

    if(this.filteredUsersList.length < 1) {
      searchList.classList.add("userSelected");
    } else {
      searchList.classList.remove("userSelected");
    }
  }

  searchUserItemClick(user) {
    this.currentSelectedUser = user;
    this.userSearchText = user.nom + " " + user.prenom;
    this.userSearchChange();
    let searchList = document.getElementById("user-search-list");
    searchList.classList.add("userSelected");
  }

  checkBookingAvailable() {
    // verifie que la date choisie sur ion-datetime soit parmis les dates surlignees
    if(!!this.currentDate && !!this.selectedDates && this.selectedDates.length > 0) {
      let found = this.selectedDates.find((element) => element === this.currentDate);
      return !!found;
    }
    return false;
  }

  cancelBooking(event) {
    this.loadingService.showLoading();

    this.externService.deleteEventAndBooking(event.id, event.bookings[0].id).subscribe(
      () => {
        let index = this.currentEvents.indexOf(event);

        if(index > -1) {
          this.currentEvents.splice(index, 1);
        }

        //ajout de l'utilisateur dont le rendez vous est annulé
        //à la liste des utilisateurs dispos
        let user = event.bookings[0].utilisateur;
        if(!!user) {
          this.availableUsers.push(user);

          this.availableUsers.sort((userOne, userTwo) => {
            if(userOne.nom > userTwo.nom) {
              return 1;
            }

            if(userOne.nom < userTwo.nom) {
              return -1;
            }

            return 0;
          })
        }

        this.loadingService.dismissLoading();
      },
      () => {
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          false,
          "Erreur",
          "un problème a été rencontré lors de la suppression du rendez-vous",
          "Ok"
        );
      }
    );
  }
}

/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Constants } from '../config/constants';

@Injectable({
  providedIn: 'root'
})
export class TokenManagementService {

  constructor(
    private constants: Constants,
    private httpClient: HttpClient,
    private jwtHelperService: JwtHelperService
    ) { }

  setToken(token: string) {
    try {
      window.sessionStorage.setItem('token', token);
    } catch (error) {
      throw Error;
    }
  }

  getToken(): Promise<string> | any {
      try {
        return window.sessionStorage.getItem('token');
      } catch {
        throw Error;
      }
  }

  setRefreshToken(refreshToken: string) {
    try {
      window.sessionStorage.setItem('refreshToken', refreshToken);
    } catch {
      throw Error;
    }
  }

  getRefreshToken(): string | never {
    try {
      return window.sessionStorage.getItem('refreshToken');
    } catch {
      throw Error;
    }
  }

  refreshToken() {
    const url = this.constants.apiEndpoint + '/api/token/refresh';
    return this.httpClient.post(url, {refresh_token: this.getRefreshToken()});
  }
}

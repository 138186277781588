import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectifService {
  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getObjectifs(pageNumber:number=1, nom?:string, id?:number) {
    let url = this.constants.apiGetPostEndpoint + '/objectifs?page=' + pageNumber;

    if(!!nom) {
      url += "&nom=" + nom;
    }

    if(!!id) {
      url += "&id=" + id;
    }

    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getObjectifsByName(nom:string) {
    const url = this.constants.apiGetPostEndpoint + '/objectifs?nom=' + nom + "&pagination=false";
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getObjectifsPrincipaux() {
    const url = this.constants.apiGetPostEndpoint + '/getMainObjectifs';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  /*getSousObjectifs() {
    const url = this.constants.apiGetPostEndpoint + '/getSubObjectifs';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }*/

  cree(type: any) {    
    const url = this.constants.apiGetPostEndpoint + "/objectifs";
    const httpHeaders = this.headerService.getPostHeader();
    //let sous_objectifs = this.prepareSousObjectifsUrls(type["sous_objectifs_nouveau"]);//base

    return this.httpClient.post(url, {
      "nom": type["nom_nouveau"],
      "duree": typeof(type["duree_nouveau"])==="string" ? parseInt(type["duree_nouveau"], 10) : type["duree_nouveau"],
      "typeObjectif": type["type_nouveau"]["@id"],
      "description": type["description_nouveau"],
      // "IsSousObjectif": type["is_sous_objectif_nouveau"],
      // "sousObjectifs": sous_objectifs===null ? '' : sous_objectifs,
    }, httpHeaders);
  }

  edite(type:any, lien: string) {    
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();
    //let sous_objectifs = this.prepareSousObjectifsUrls(type["sous_objectifs_edition"]);

    return this.httpClient.patch(url, {
      "nom": type["nom_edition"],
      "duree": typeof(type["duree_edition"])==="string" ? parseInt(type["duree_edition"], 10) : type["duree_edition"],
      "typeObjectif": type["type_edition"]["@id"],
      "description": type["description_edition"],
      // "IsSousObjectif": type["is_sous_objectif_edition"],
      // "sousObjectifs": sous_objectifs,
    }, httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }

  /*prepareSousObjectifsUrls(sous_objectifs) {
    let urls = [];

    if(!!sous_objectifs) {
      for(let sous_objectif of sous_objectifs) {
        urls.push(sous_objectif["@id"]);
      }
    }
    return urls;
  }*/
}

import { NgModule, LOCALE_ID } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
// import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './material/material.module';
import { JwtModule } from '@auth0/angular-jwt';

// no use ?
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';

import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './admin/login/login.component';
import { MainAdminComponent } from './admin/main-admin/main-admin.component';
import { SituationFamilialeComponent } from './admin/situation-familiale/situation-familiale.component';
import { DepartementComponent } from './admin/departement/departement.component';
import { NationnaliteComponent } from './admin/nationnalite/nationnalite.component';
import { NiveauEtudesComponent } from './admin/niveau-etudes/niveau-etudes.component';
import { CiviliteComponent } from './admin/civilite/civilite.component';
import { ModeDeplacementComponent } from './admin/mode-deplacement/mode-deplacement.component';
import { EntrepriseStageComponent } from './admin/entreprise-stage/entreprise-stage.component';
import { CompetenceCleComponent } from './admin/competence-cle/competence-cle.component';
import { SoftSkillComponent } from './admin/soft-skill/soft-skill.component';
import { AtelierComponent } from './admin/atelier/atelier.component';
import { TypeRecompenseComponent } from './admin/type-recompense/type-recompense.component';
import { EntrepriseRecompenseComponent } from './admin/entreprise-recompense/entreprise-recompense.component';
import { TypeFormuleComponent } from './admin/type-formule/type-formule.component';
import { TypeObjectifComponent } from './admin/type-objectif/type-objectif.component';
import { SituationProfessionnelleComponent } from './admin/situation-professionnelle/situation-professionnelle.component';
import { EtapeComponent } from './admin/etape/etape.component';
import { RecompenseComponent } from './admin/recompense/recompense.component';
import { NiveauComponent } from './admin/niveau/niveau.component';
import { FormuleComponent } from './admin/formule/formule.component';
import { ObjectifComponent } from './admin/objectif/objectif.component';
import { UtilisateurComponent } from './admin/utilisateur/utilisateur.component';
import { PlateauTechniqueComponent } from './admin/plateau-technique/plateau-technique.component';
import { DescriptionPersonnaliseeComponent } from './admin/description-personnalisee/description-personnalisee.component';
import { DescriptionAtelierComponent } from './admin/description-atelier/description-atelier.component';
import { DescriptionSoftSkillComponent } from './admin/description-soft-skill/description-soft-skill.component';
import { DescriptionCompetenceCleComponent } from './admin/description-competence-cle/description-competence-cle.component';
import { QuestionsComponent } from './admin/questions/questions.component';
import { PlateauUserComponent } from './admin/plateau-user/plateau-user.component';
import { ObjectifsUtilisateursComponent } from './admin/objectifs-utilisateurs/objectifs-utilisateurs.component';
import { IntervenantComponent } from './admin/intervenant/intervenant.component';
import { ModalDetailRewardComponent } from './components/modal-detail-reward/modal-detail-reward.component';
import { ModalDetailActivityComponent } from './components/modal-detail-activity/modal-detail-activity.component';
import { SessionRowComponent } from './components/modal-detail-activity/session-row.component';
import { AuthInterceptor } from './helpers/auth-interceptor';
import { RewardsPage } from './pages/rewards/rewards.page';
import { RewardItemCardComponent } from './components/reward-item-card/reward-item-card.component';
import { RewardDetailComponent } from './components/reward-item-card/reward-detail/reward-detail.component';
import { RewardCardComponent } from './components/reward-card/reward-card.component';
import { RewardCardTypeComponent } from './components/reward-card-type/reward-card-type.component';
import { RewardsPageModule } from './components/reward-item-card/reward-item-card.component.module';
import { SharedComponentModule } from './shared/shared-component.module';
import { ModalDetailObjectifComponent } from './components/modal-detail-objectif/modal-detail-objectif.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TemplateFormDescriptionsSousObjectifsCompetencesClesComponent } from './components/template-form-descriptions-sous-objectifs-competences-cles/template-form-descriptions-sous-objectifs-competences-cles.component';
import { TemplateFormDescriptionsSousObjectifsAtelierComponent } from './components/template-form-descriptions-sous-objectifs-atelier/template-form-descriptions-sous-objectifs-atelier.component';
import { TemplateFormDescriptionsSousObjectifsSoftSkillComponent } from './components/template-form-descriptions-sous-objectifs-soft-skill/template-form-descriptions-sous-objectifs-soft-skill.component';
import { TemplateFormDescriptionCustomComponent } from './components/template-form-description-custom/template-form-description-custom.component';
import { StatistiquesComponent } from './components/statistiques/statistiques.component';
import { ReponseAudioComponent } from './components/reponse-audio/reponse-audio.component';
import { LogsComponent } from './admin/logs/logs.component';
import { PlateauTechniqueUserComponent } from './pages/plateau-technique/plateau-technique.component';
import { StageUserComponent } from './pages/stage-user/stage-user.component';
import { ValidationCompteComponent } from './components/validation-compte/validation-compte.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartComponent } from '@swimlane/ngx-charts';
import { QuestionsAudioComponent } from './admin/questions-audio/questions-audio.component';
import { ReponsesAudioUserComponent } from './admin/reponses-audio-user/reponses-audio-user.component';
import { ShareComponent } from './components/share/share.component';
import { RendezVousIndividuelsComponent } from './admin/rendez-vous-individuels/rendez-vous-individuels.component';
import { Tab4Component } from './tab4/tab4.component';
import { BookingsComponent } from './admin/bookings/bookings.component';
import { DescriptionCustomComponent } from './admin/description-custom/description-custom.component';




// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function tokenGetter() {
  return window.sessionStorage.getItem('token');
}

const appRoutes: Routes = [];

@NgModule({
declarations: [
  AppComponent,
  ModalDetailActivityComponent,
  ModalDetailObjectifComponent,
  ModalDetailRewardComponent,
  SessionRowComponent,
  LoginComponent,
  MainAdminComponent,
  SituationFamilialeComponent,
  DepartementComponent,
  NationnaliteComponent,
  NiveauEtudesComponent,
  SituationProfessionnelleComponent,
  CiviliteComponent,
  ModeDeplacementComponent,
  EntrepriseStageComponent,
  CompetenceCleComponent,
  SoftSkillComponent,
  AtelierComponent,
  TypeRecompenseComponent,
  EntrepriseRecompenseComponent,
  TypeFormuleComponent,
  TypeObjectifComponent,
  ObjectifComponent,
  EtapeComponent,
  RecompenseComponent,
  NiveauComponent,
  FormuleComponent,
  UtilisateurComponent,
  ObjectifsUtilisateursComponent,
  IntervenantComponent,
  PlateauTechniqueComponent,
  QuestionsComponent,
  PlateauUserComponent,
  DescriptionPersonnaliseeComponent,
  DescriptionAtelierComponent,
  DescriptionSoftSkillComponent,
  DescriptionCompetenceCleComponent,
  ReponseAudioComponent,
  QuestionsAudioComponent,
  ReponsesAudioUserComponent,
  // RewardCardTypeComponent,
  // RewardItemCardComponent,
  // RewardDetailComponent,
  // RewardCardComponent,
  TemplateFormDescriptionsSousObjectifsCompetencesClesComponent,
  TemplateFormDescriptionsSousObjectifsAtelierComponent,
  TemplateFormDescriptionsSousObjectifsSoftSkillComponent,
  TemplateFormDescriptionCustomComponent,
  StatistiquesComponent,
  LogsComponent,
  PlateauTechniqueUserComponent,
  StageUserComponent,
  ValidationCompteComponent,
  DescriptionCustomComponent,


  //ChartComponent,  //useless ?



  ShareComponent,
  RendezVousIndividuelsComponent,
  Tab4Component,
  BookingsComponent,


],

imports: [
    SharedComponentModule,
    BrowserModule,
     IonicModule.forRoot(),
      AppRoutingModule,
      BrowserAnimationsModule,

      //no use ?
      /*MatFormFieldModule,
      MatInputModule,
      MatTableModule,*/

      MaterialModule,
      MatIconModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatTabsModule,
      // RouterModule.forRoot(appRoutes),//local
      RouterModule.forRoot(appRoutes, { useHash: true }),//preprod - prod

      /*AgmCoreModule.forRoot({
        apiKey: 'AIzaSyDS9-_jHd3ZJyh0dHWI6MxbmANiVmeutdM'
      }),*/
      GoogleMapsModule,
      NgxChartsModule,

      JwtModule.forRoot({
        config: {
          tokenGetter,
          allowedDomains: [],
          disallowedRoutes: [],
        },
      })
    ],
    // exports:[
    //   RewardItemCardComponent,
    //   RewardDetailComponent,
    //   RewardCardComponent,
    //   RewardCardTypeComponent,
    // ],

  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' }
  ],
  bootstrap: [AppComponent],
})


export class AppModule {
  constructor() {}
}
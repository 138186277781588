import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class TypeRecompenseService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

    getTypesRecompenses() {
    const url = this.constants.apiEndpoint + '/type_recompenses';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(type: any) {
    const url = this.constants.apiEndpoint + "/type_recompenses";
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {intitule: type["intitule_nouveau"]}, httpHeaders);
  }

  edite(type:any, lien: string) {
    const url = this.constants.apiEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();

    return this.httpClient.patch(url, {
      intitule: type["intitule_edition"],
    },httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { LogsService } from 'src/app/services/Logs/logs.service';
import { ErrorNotificationService } from '../../services/error-notification.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
})
export class LogsComponent implements OnInit {
  selectedDates:any = [];
  highlightedDates = [];
  loadingDates:boolean=false;
  loadingLog:boolean=false;

  constructor(private loadingService:LoadingService,
              private logsService:LogsService,
              private errorNotificationService:ErrorNotificationService) { }

  ngOnInit() {
    let today = new Date().toLocaleDateString("fr-FR");
    let todayUS = today.split("/");
    today = todayUS[2] + "-" + todayUS[1] + "-" + todayUS[0];
    let datetime = document.querySelector('ion-datetime');
    datetime.value = today;
    
    this.loadingService.showLoading().then(
      () => {
        this.loadingDates = true;
        this.loadingLog = true;
        this.getCalendarDates();
        this.loadLogs(today);
      }
    );
  }

  getCalendarDates() {
    this.logsService.getDates().subscribe(
      data => {
        let infos:any = data;

        for(let date of infos) {
          let dateUS = date.split("-");
          dateUS = dateUS[2] + "-" + dateUS[1] + "-" + dateUS[0];
          this.highlightedDates.push(dateUS);
        }

        let datetime = document.querySelector('ion-datetime');
        datetime.highlightedDates = (param) => {

          if(this.highlightedDates.includes(param)) {
            return {
              textColor: 'rgb(68, 10, 184)',
              backgroundColor: 'rgb(211, 200, 229)',
            };
          }
          return undefined
        }

        this.loadingDates = false;
        this.checkLoading();
      },
      () => {
        this.loadingDates = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  loadLogs(date:string) {
    const textArea = document.querySelector('ion-textarea');
    this.logsService.getLogs(date).subscribe(
      data => {
        const infos:any = data;
        let logText = this.FormatLogText(infos);
        textArea.value = logText;
        this.loadingLog = false;
        this.checkLoading();
      },
      error => {
        if(error.status === 404) {
          textArea.value = "Aucun historique enregistré pour cette date";
        } else {
          this.errorNotificationService.presentGeneralErrorAlert(
            true,
            "Erreur",
            "Un problème a été rencontré lors du chargement des données",
            "Ok"
          );
        }
        this.loadingLog = false;
        this.checkLoading();
      }
    )
  }

  selectedDateChange(event) {
    this.loadingService.showLoading().then(
      () => {
        this.loadingLog = true;
        this.loadLogs(event.detail.value);
      }
    );
  }

  checkLoading() {
    if(!this.loadingDates && !this.loadingLog) {
      this.loadingService.dismissLoading();
    }
  }

  FormatLogText(infos:string) {
    let final = "";
    let lignes = infos.split("\n");
    const regex = / avec les informations suivantes :/;

    for(let index in lignes) {
      if(parseInt(index) === 0) {
        final = lignes[0] + "\n";
      } else {
        if(regex.test(lignes[index])) {
          final += "\n\n";
        }

        final += lignes[index] + "\n";
      }
    }

    return final;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurObjectifService {
  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  creation(descriptionId:number, type:string, objectifId:number, niveauId:number, etapeId:number) {
    const url = this.constants.apiEndpoint + "/utilisateur_objectifs";
    const httpHeaders = this.headerService.getPostHeader();

    let data = {
      objectif: "/objectifs/" + objectifId,
      niveau: "/niveaux/" + niveauId,
      etape: "/etapes/" + etapeId
    };

    switch(type) {
      case "atelier":
        data["descriptionAtelier"] = "/descriptionAtelier/" + descriptionId;
        break;

      case "softSkill":
        data["descriptionSoftSkill"] = "/descriptionSoftSkill/" + descriptionId;
        break;

      default:
        break;
    }  

    return this.httpClient.post(url, data, httpHeaders); 
  }

  editeDescriptifs(idUtilisateurObjectif:number, typeDescriptif:string, liens:string[], idObjectifPrincipal?:number) {
    let url = this.constants.apiPatchDeleteEndpoint;
    switch(this.constants.mode) {
      case "local":
        url += "/utilisateur_objectifs/";
        break;

      case "preprod":
        url += "/preprod/api/public/utilisateur_objectifs/";
        break;

      case "emeraude":
        url += "/preprod/api/public/utilisateur_objectifs/";
        break;

      case "prod":
        url += "/api/public/utilisateur_objectifs/";
        break;
    }
    url += idUtilisateurObjectif;
    const httpHeaders = this.headerService.getPatchHeader();
    let data = {};
    let dataParameter;

    switch(typeDescriptif) {
      case "softSkill":
        data["descriptionSoftSkill"] = liens[0];
        dataParameter="/soft_skills/";
        break;

      case "competenceCle":
        data["descriptionsCompetencesCles"] = liens;
        dataParameter="/competence_cles/";
        break;

      case "atelier":
        data["descriptionAtelier"] = liens[0];
        dataParameter="/ateliers/";
        break;

      /*case "objectifCustom":  ==> utiliser service dedie
        //data["descriptionObjectifCustom"] = liens[0];
        //descriptionObjectifCustom
        break;*/

      default:
        //return error msg
        break;
    }

    if(!!idObjectifPrincipal) {
      data[typeDescriptif] = dataParameter + idObjectifPrincipal;
    }

    return this.httpClient.patch(url, data, httpHeaders);
  }

  ajouteLiaisonDescriptionCompetenceCle(
    idUtilisateurObjectif:number,
    idDescriptionCompetenceCle:number
  ) {
    const url = this.constants.apiPatchDeleteEndpoint + "/utilisateurObjectif/" + idUtilisateurObjectif + "/ajoutDescriptionCompetenceCle";
    const httpHeaders = this.headerService.getPatchHeader();
    return this.httpClient.patch(url, {"id_description_competence_cle": idDescriptionCompetenceCle}, httpHeaders);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Etape } from 'src/app/Models/etape';
import { Objectif } from 'src/app/Models/objectif';
import { Utilisateur } from 'src/app/Models/utilisateur';
import { ErrorNotificationService } from 'src/app/services/error-notification.service';
import { EtapeService } from 'src/app/services/etape/etape.service';
import { ObjectifsUtilisateurService } from 'src/app/services/ObjectifsUtilisateurs/objectifs-utilisateur.service';
import { UtilisateurService } from 'src/app/services/Utilisateur/utilisateur.service';
import { Niveau } from 'src/app/Models/niveau';
import { Constants } from '../../config/constants';

@Component({
  selector: 'app-modal-detail-activity',
  templateUrl: './modal-detail-activity.component.html',
  styleUrls: ['./modal-detail-activity.component.scss'],
})
export class ModalDetailActivityComponent implements OnInit {
  now: Date = new Date();
  etape: Etape;
  cEtape: Etape = new Etape();
  cUtilisateur: Utilisateur = new Utilisateur();
  etatEtapeUtilisateur: Etape = new Etape();
  cObjectifs: Objectif[] = new Array();
  macaronClass: string = 'imgGrayscale';
  donneesChargees: boolean = false;
  donneesObjectifsChargees: boolean = false;
  userId:any = this.cUtilisateur.id;
  etapeDesactivee:boolean=false;
  etapeBilanIntermediaire:boolean=false;

  @Input() niveau: Niveau;
  @Input() idEtapeBilanIntermediaire: number;
  @Input() idNiveauBilanIntermediaire: number;
  @Input() niveau1a5Etape3:boolean; //determine s'il s'agit de la 3eme etape de l'un des 5 premiers niveaux

  constructor(
    private modalCtrl: ModalController,
    private etapeService: EtapeService,
    private errorNotificationService: ErrorNotificationService,
    private utilisateurService: UtilisateurService,
    private objectifsUtilisateurService: ObjectifsUtilisateurService,
    private constants: Constants,
  ) {}

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  ngOnInit() {
    if(this.niveau.id === this.idNiveauBilanIntermediaire
    && parseInt(this.etape.id) === this.idEtapeBilanIntermediaire) {
      this.etapeBilanIntermediaire = true;
    }

    this.getEtapeInfo();
  }

  navigateToImmersionFacile() {
    window.open('https://immersion-facile.beta.gouv.fr/accueil-beneficiaires');
  }

  getEtapeInfo() {
    this.etapeService.getEtape(this.etape.id).subscribe(
      (donneesEtapeReponse) => {
        const donneesEtape: Etape = donneesEtapeReponse;
        this.cEtape = donneesEtape;
        const stepStringId: string = '/etapes/';
        let etapeId: any = this.cEtape['@id'].slice(stepStringId.length);

        if(this.constants.mode === "prod" || this.constants.mode === "preprod" || this.constants.mode === "emeraude") {
          etapeId = (etapeId+"").split("lic/etapes/");
          etapeId = etapeId[1];
        }

        this.utilisateurService.getMe().subscribe(
          (donneesUtilisateurReponse) => {
            const donnesUtilisateur: Utilisateur =
              donneesUtilisateurReponse['data'];
            this.cUtilisateur = donnesUtilisateur;
            let utilisateurNiveaux = this.cUtilisateur.utilisateurNiveaux;
            let cUtilisateurNiveau = utilisateurNiveaux.find((utilisateurNiveau) => {
              return utilisateurNiveau.niveau.id === this.niveau.id
            });

            if(cUtilisateurNiveau.desactive) {
              this.etapeDesactivee = true;
              this.donneesObjectifsChargees = true;
            } else {
              this.cUtilisateur.etapeNiveaux.forEach((etNiveau) => {
                if (etapeId == etNiveau.etape.id) {
                  this.etatEtapeUtilisateur = etNiveau;
                  if (this.etatEtapeUtilisateur.termine) {
                    this.macaronClass = '';
                  }
                }
              });
              this.donneesChargees = true;
                this.objectifsUtilisateurService
                .getUtilisateurObjectifs(this.cUtilisateur.id, false, this.niveau.id, null, parseInt(etapeId), true)
                .subscribe(
                  (donneesObjectifsUtilisateurReponse) => {
                    const objectifsUtilisateur: Objectif[] =
                      donneesObjectifsUtilisateurReponse['hydra:member'];
                    this.cObjectifs = this.triObjectifs(objectifsUtilisateur);
                    this.donneesObjectifsChargees = true;
                  },
                  (error) => {
                    this.errorNotificationService.presentHttpStatusErrorAlert(
                      error.code
                    );
                  }
                );
            }
          },
          (error) => {
            this.errorNotificationService.presentHttpStatusErrorAlert(
              error.code
            );
          }
        );
      },
      (error) => {
        this.errorNotificationService.presentHttpStatusErrorAlert(error.code);
      }
    );
  }

  triObjectifs(objectifs) {
    let competenceCle = objectifs.filter((element) => element["competenceCle"] !== null);
    let softSkill = objectifs.filter((element) => element["softSkill"] !== null);
    let atelier = objectifs.filter((element) => element["atelier"] !== null);
    let others = objectifs.filter((element) => 
      element["competenceCle"] === null && element["softSkill"] === null && element["atelier"] === null
    );

    return competenceCle.concat(softSkill).concat(atelier).concat(others);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { MatTable } from '@angular/material/table';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { CompetenceCleCustomService } from 'src/app/services/DescriptionCompetenceCleCustom/competence-cle-custom.service';
import { DescriptionObjectifCustomService } from 'src/app/services/DescriptionObjectifCustom/description-objectif-custom.service';
import { ToastService } from '../../services/Toast/toast.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UtilisateurService } from 'src/app/services/Utilisateur/utilisateur.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-description-personnalisee',
  templateUrl: './description-personnalisee.component.html',
  styleUrls: ['./description-personnalisee.component.scss'],
})
export class DescriptionPersonnaliseeComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild("descriptionsTable") descriptionsTable!: MatTable<any>;

  private descriptionsCompetencesClesCustoms:any[];
  private descriptionsObjectifsCustoms:any[];
  public fusedDescriptions:any[];
  descriptionsCompetencesClesChargees:boolean=false;
  descriptionsObjectifsCharges:boolean=false;
  globalCurrentPage:number=1;
  maxPageNumber:number;
  competencesClesCustomsMaxPageNumber:number;
  objectifsCustomsMaxPageNumber:number;
  currentCompetencesClesCustomsPage:number=1;
  currentObjectifsCustomsPage:number=1;
  competencesClesCustomsPerPage:number = 5;
  objectifsCustomsPerPage:number = 5;
  totalQuantityCompetencesClesCustoms:number;
  totalQuantityObjectifsCustoms:number;
  getUserTimeout:any=null;
  showSearchLoader:boolean=false;
  foundUsers: any[];
  nombreResultatsRecherche:number;
  selectedUserId:number;

  editForm = new FormGroup({
    description: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    validation: new FormControl(false, [Validators.required]),
    type: new FormControl(),
    id: new FormControl()
  });

  searchForm = new FormGroup({
    nom: new FormControl('', [Validators.maxLength(50)]),
    prenom: new FormControl('', [Validators.maxLength(50)]),
    selectedUser: new FormControl('')
  });

  displayedColumns:string[]=[
    "nom utilisateur",
    "prenom utilisateur",
    "objectif",
    "description personnalisee",
    "validite",
    "editForm",
    "deleteForm"
  ];

  constructor(private competenceCleCustomService:CompetenceCleCustomService,
              private descriptionObjectifCustomService:DescriptionObjectifCustomService,
              private toastService: ToastService,
              private errorNotificationService: ErrorNotificationService,
              private utilisateurService: UtilisateurService,
              private loadingService: LoadingService,
              private router: Router) { }

  ngOnInit() {
    this.getDescriptionsCompetencesClesCustom();
    this.getDescriptionsObjectifsCustoms();
  }

  backToMain() {
    this.searchForm.reset();
    this.searchForm.setValue({
      prenom: "",
      nom: "",
      selectedUser: ""
    });

    this.foundUsers = [];
    this.nombreResultatsRecherche = 0;
    this.router.navigate(['/admin_main']);
  }

  getDescriptionsCompetencesClesCustom() {
    this.competenceCleCustomService.getAll(this.currentCompetencesClesCustomsPage).subscribe(
      data => {
        let infos = data;
        this.descriptionsCompetencesClesCustoms = infos["hydra:member"];
        this.competenceCleCustomService.getTotalQuantity().subscribe(
          data => {
            this.totalQuantityCompetencesClesCustoms = data["hydra:member"][0]["totalItems"];
            this.descriptionsCompetencesClesChargees=true;

            if(this.descriptionsObjectifsCharges) {
              this.getFusedData();
            }
          },
          error => {
            this.errorNotification("chargement");
          }
        );
      },
      error => {
        this.errorNotification("chargement");
      }
    );
  }

  getDescriptionsObjectifsCustoms() {
    this.descriptionObjectifCustomService.getAll(this.currentObjectifsCustomsPage).subscribe(
      data => {
        let infos = data;
        this.descriptionsObjectifsCustoms = infos["hydra:member"];
        this.descriptionObjectifCustomService.getTotalQuantity().subscribe(
          data => {
            this.totalQuantityObjectifsCustoms = data["hydra:member"][0]["totalItems"];
            this.descriptionsObjectifsCharges = true;

            if(this.descriptionsCompetencesClesChargees) {
              this.getFusedData();
            }
          },
          error => {
            this.errorNotification("chargement");
          }
        );
      },
      error => {
        this.errorNotification("chargement");
      }
    );
  }

  getFusedData() {
    this.fusedDescriptions = this.descriptionsCompetencesClesCustoms.concat(
      this.descriptionsObjectifsCustoms
    );

    this.fusedDescriptions.sort(function(a,b) {
      if(a["estValide"] && !b["estValide"]) {
        return -1;
      }

      if(!a["estValide"] && b["estValide"]) {
        return 1;
      }

      return 0;
    });

    this.updateMaxPageNumber();
  }

  closeModal() {
    this.modal.dismiss();
  }

  showEditForm(element) {
    this.editForm.patchValue({
      "description": element.nom,
      "validation": element.estValide,
      "type": element["@type"],
      "id": element.id
    });
    this.modal.present();
  }

  submitEdition() {
    const formValues = this.editForm.value;

    if(formValues.type === "DescriptionCompetenceCleCustom") {
      this.loadingService.showLoading();
      this.competenceCleCustomService.edite(
        formValues.id,
        formValues.description,
        null,
        null,
        formValues.validation,
        "admin"
      ).subscribe(
        data => {
          this.updateTable(formValues.type, data);
          this.loadingService.dismissLoading();
          this.toastService.presentToast("bottom", "Modification enregistrée", "success");
        },
        error => {
          this.loadingService.dismissLoading();
          this.errorNotification("enregistrement");
        }
      )
    } else if(formValues.type === "DescriptionObjectifCustom") {
      this.loadingService.showLoading();
      this.descriptionObjectifCustomService.edite(
        formValues.id,
        null,
        formValues.description,
        formValues.validation,
        "admin"
      ).subscribe(
        data => {
          this.updateTable(formValues.type, data);
          this.loadingService.dismissLoading();
          this.toastService.presentToast("bottom", "Modification enregistrée", "success");
        },
        error => {
          this.loadingService.dismissLoading();
          this.errorNotification("enregistrement");
        }
      )
    }
  }

  showDeleteWarning(element) {
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.deleteDescription.bind(this),
      [{
        "id": element.id,
        "type": element["@type"]
      }]
    );
  }

  deleteDescription(element) {
    switch(element[0].type) {
      case "DescriptionCompetenceCleCustom":
        this.competenceCleCustomService.supprime(element[0].id).subscribe(
          data => {
            this.removeElementFromFusedDescription(element[0].id);
          },
          () => {
            this.errorNotification("suppression");
          }
        );
        break;

      case "DescriptionObjectifCustom":
        this.descriptionObjectifCustomService.supprime(element[0].id).subscribe(
          data => {
            this.removeElementFromFusedDescription(element[0].id);
          },
          () => {
            this.errorNotification("suppression");
          }
        );
        break;

      default:
        this.errorNotificationService.presentGeneralErrorAlert(
          false,
          "Erreur",
          "vous essayer de supprimer un élément de type inconnu",
          "Ok"
        );
        break;
    }
  }

  removeElementFromFusedDescription(id:number) {
    let itemToDelete = this.fusedDescriptions.find((element) => {
      return element.id === id
    });
    let index = this.fusedDescriptions.indexOf(itemToDelete);

    if(index > -1) {
      this.fusedDescriptions.splice(index, 1);

      if(this.fusedDescriptions.length < 1) {
        this.descriptionsCompetencesClesChargees = false;
        this.descriptionsObjectifsCharges = false;
        this.getDescriptionsCompetencesClesCustom();
        this.getDescriptionsObjectifsCustoms();
      }

      this.descriptionsTable.renderRows();
    }
  }

  updateTable(type:string, data) {
    let elementBase = this.fusedDescriptions.find(
      element => element.id === data.id 
      && element["@type"] === type
    );
    let index = this.fusedDescriptions.indexOf(elementBase);
    this.fusedDescriptions[index] = data;
    this.descriptionsTable.renderRows();
    this.closeModal();
  }

  changePage(pageNumber:number) {
    this.fusedDescriptions = [];
    this.descriptionsCompetencesClesCustoms = [];
    this.descriptionsObjectifsCustoms = [];

    if(this.competencesClesCustomsMaxPageNumber >= pageNumber) {
      this.descriptionsCompetencesClesChargees = false;
      this.currentCompetencesClesCustomsPage = pageNumber;

      if(!!this.selectedUserId) {
        this.getDescriptionsCompetencesClesCustomByUserId(this.selectedUserId);
      } else {
        this.getDescriptionsCompetencesClesCustom();
      }
    }

    if(this.objectifsCustomsMaxPageNumber >= pageNumber) {
      this.descriptionsObjectifsCharges = false;
      this.currentObjectifsCustomsPage = pageNumber;

      if(!!this.selectedUserId) {
        this.getDescriptionsObjectifsCustomsByUserId(this.selectedUserId);
      } else {
        this.getDescriptionsObjectifsCustoms();
      }
    }

    this.globalCurrentPage = pageNumber;
  }

  updateMaxPageNumber() {
    this.competencesClesCustomsMaxPageNumber =
    this.totalQuantityCompetencesClesCustoms/this.competencesClesCustomsPerPage;
    this.competencesClesCustomsMaxPageNumber = Math.ceil(this.competencesClesCustomsMaxPageNumber);
    this.objectifsCustomsMaxPageNumber =
    this.totalQuantityObjectifsCustoms/this.objectifsCustomsPerPage;
    this.objectifsCustomsMaxPageNumber = Math.ceil(this.objectifsCustomsMaxPageNumber);
    this.maxPageNumber = Math.max(
      this.competencesClesCustomsMaxPageNumber,
      this.objectifsCustomsMaxPageNumber
    );
  }

  errorNotification(type:string, customMessage?:string) {
    let message = "";

    switch(type) {
      case "chargement":
        message = "Un problème a été rencontré lors du chargement des données";
        break;

      case "enregistrement":
        message = "Un problème a été rencontré lors de l'enregistrement des données";
        break;

      case "suppression":
        message = "Un problème a été rencontré lors de la suppression des données";
        break;

      default:
        message = customMessage;
        break;
    }

    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Erreur",
      message,
      "Ok"
    );
  }

  getSearchText() {
    if(typeof(this.nombreResultatsRecherche) !== "undefined" && this.nombreResultatsRecherche > 0) {
      return this.nombreResultatsRecherche + " résultat(s)";
    }

    return "Aucun résultat";
  }

  getUsersNames() {
    if(this.getUserTimeout !== null) {
      clearTimeout(this.getUserTimeout);
      this.getUserTimeout = null;
    }

    this.getUserTimeout = window.setTimeout(() => {
      if(!this.searchForm.invalid) {
        this.showSearchLoader = true;
  
        //dans le cas ou on vide les champs nom et prenoms du form de recherche
        //on re-affiche tout les résultats
        if(this.searchForm.value.nom === ""
        && this.searchForm.value.prenom === "") {
          this.searchForm.reset();
          this.searchForm.setValue({
            prenom: "",
            nom: "",
            selectedUser: ""
          });
  
          this.foundUsers = [];
          this.nombreResultatsRecherche = 0;
  
          this.loadingService.showLoading().then(
            () => {
              this.globalCurrentPage = 1;
              this.descriptionsCompetencesClesChargees=false;
              this.descriptionsObjectifsCharges = false;
              this.getDescriptionsCompetencesClesCustom();
              this.getDescriptionsObjectifsCustoms();

              this.showSearchLoader = false;
              this.loadingService.dismissLoading();
            }
          );
        } else {
          this.utilisateurService.getUtilisateursNames(
            this.searchForm.value.nom,
            this.searchForm.value.prenom
          ).subscribe(
            data => {
              let infos = data;
              this.foundUsers = infos["hydra:member"];
              this.nombreResultatsRecherche = this.foundUsers.length;
              this.showSearchLoader = false;
            },
            () => {
              this.errorNotification("chargement");
              this.showSearchLoader = false;
            }
          )
        }
      }
    }, 150);
  }

  searchByName(event) {
    this.fusedDescriptions = [];
    this.descriptionsObjectifsCharges = false;
    this.descriptionsCompetencesClesChargees = false;

    if(!!event.detail.value) {
      this.getDescriptionsCompetencesClesCustomByUserId(event.detail.value);
      this.getDescriptionsObjectifsCustomsByUserId(event.detail.value);
      this.selectedUserId = event.detail.value;
    } else {
      this.getDescriptionsCompetencesClesCustom();
      this.getDescriptionsObjectifsCustoms();
      this.selectedUserId = null;
    }
  }

  getDescriptionsCompetencesClesCustomByUserId(userId:number) {
    this.competenceCleCustomService.getQuantityForAUSer(userId).subscribe(
      data => {
        this.totalQuantityCompetencesClesCustoms = data["hydra:member"][0]["totalItems"];
        this.competenceCleCustomService.getAllByUser(userId, this.currentCompetencesClesCustomsPage).subscribe(
          data => {
            this.descriptionsCompetencesClesCustoms = data["hydra:member"];
            this.descriptionsCompetencesClesChargees=true;

            if(this.descriptionsObjectifsCharges) {
              this.getFusedData();
            }
          },
          error => {
            this.errorNotification("chargement");
          }
        );
      },
      error => {
        this.errorNotification("chargement");
      }
    );
  }

  getDescriptionsObjectifsCustomsByUserId(userId:number) {
    this.descriptionObjectifCustomService.getQuantityForAUSer(userId).subscribe(
      data => {
        this.totalQuantityObjectifsCustoms = data["hydra:member"][0]["totalItems"];
        this.descriptionObjectifCustomService.getAllByUser(userId, this.currentObjectifsCustomsPage).subscribe(
          data => {
            this.descriptionsObjectifsCustoms = data["hydra:member"];
            this.descriptionsObjectifsCharges = true;

            if(this.descriptionsCompetencesClesChargees) {
              this.getFusedData();
            }
          },
          error => {
            this.errorNotification("chargement");
          }
        );
      },
      error => {
        this.errorNotification("chargement");
      }
    );
  }
}
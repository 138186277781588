import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { PlateauTechniqueService } from 'src/app/services/PlateauTechnique/plateau-technique.service';
import { IntervenantService } from 'src/app/services/Intervenants/intervenant.service';
import { QuestionsService } from 'src/app/services/Questions/questions.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-plateau-technique',
  templateUrl: './plateau-technique.component.html',
  styleUrls: ['./plateau-technique.component.scss'],
})
export class PlateauTechniqueComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;

  private plateaux: any[];
  private questions: any[];
  private intervenants: any[];
  loading_plateaux: boolean=false;
  loading_questions: boolean=false;
  loading_intervenants: boolean=false;
  mode_edition:boolean=true;
  nom_nouveau:string;
  nom_edition:string;
  referent_nouveau:string;
  referent_edition:string;
  date_nouveau:Date;
  date_edition:Date;
  adresse_nouveau:string
  adresse_edition:string
  code_postal_nouveau:string;
  code_postal_edition:string;
  ville_nouveau:string;
  ville_edition:string;
  index_plateau: number;
  url_edition:string;

  nouveauForm = new FormGroup({
    nom_nouveau: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    referent_nouveau: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    adresse_nouveau: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    ville_nouveau: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    code_postal_nouveau: new FormControl('', [Validators.required, Validators.maxLength(20)]),
    date_nouveau: new FormControl(new Date().toISOString().slice(0, -1), [Validators.required]),
    questions_nouveau: new FormControl(''),
    intervenants_nouveau: new FormControl(''),
    description_nouveau: new FormControl('', [Validators.required, Validators.maxLength(100)]),
  });

  editionForm = new FormGroup({
    nom_edition: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    referent_edition: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    adresse_edition: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    ville_edition: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    code_postal_edition: new FormControl('', [Validators.required, Validators.maxLength(20)]),
    date_edition: new FormControl(new Date().toISOString().slice(0, -1), [Validators.required]),
    questions_edition: new FormControl(''),
    intervenants_edition: new FormControl(''),
    description_edition: new FormControl('', [Validators.required, Validators.maxLength(100)]),
  });

  constructor(private plateauTechniqueService: PlateauTechniqueService,
              private questionsService: QuestionsService,
              private loadingService: LoadingService,
              private intervenantsService: IntervenantService,
              private errorNotificationService: ErrorNotificationService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.getPlateauxTechniques();
        this.getQuestions();
        this.getIntervenants();
      }
    );
  }

  getPlateauxTechniques() {
    this.loading_plateaux = true;
    this.plateauTechniqueService.getPlateauxTechniques().subscribe(
      data => {
        let infos = data;
        this.plateaux = infos["hydra:member"];
        this.loading_plateaux = false;
        this.checkLoading();
      },
      () => {
        this.loading_plateaux = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getQuestions() {
    this.loading_questions = true;
    this.questionsService.getQuestions().subscribe(
      data => {
        let infos = data;
        this.questions = infos["hydra:member"];
        this.loading_questions = false;
        this.checkLoading();
      },
      () => {
        this.loading_questions = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getIntervenants() {
    this.loading_intervenants = true;
    this.intervenantsService.getIntervenants().subscribe(
      data => {
        let infos = data;
        this.intervenants= infos["hydra:member"];
        this.loading_intervenants = false;
        this.checkLoading();
      },
      () => {
        this.loading_intervenants = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  checkLoading() {
    if(!this.loading_plateaux && !this.loading_questions
      && !this.loading_intervenants) {
        this.loadingService.dismissLoading();
    }
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  ouvreModaleNouveau() {
    this.mode_edition = false;
    this.modal.present();
  }

  ouvreModaleEdition(plateau:any) {
    this.mode_edition = true;
    this.url_edition = plateau["@id"];

    this.editionForm.patchValue({
      "nom_edition": plateau.intitule,
      "referent_edition": plateau.referentStructure,
      "date_edition": this.dateConverter(plateau.date),
      "adresse_edition": plateau.adresse,
      "code_postal_edition": plateau.codePostal,
      "ville_edition": plateau.ville,
      "intervenants_edition": plateau.intervenants,
      "questions_edition": plateau.questions,
      "description_edition": plateau.description
    });

    this.modal.present();
    this.index_plateau = this.plateaux.lastIndexOf(plateau);
  }

  supprimeAlert(plateau:any) {
    this.index_plateau = this.plateaux.lastIndexOf(plateau);
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.supprime.bind(this),
      [plateau["@id"]]
    );
  }

  submitNouveau() {
    this.loadingService.showLoading();
    const formValues = this.nouveauForm.value;
    let memo_questions = formValues.questions_nouveau;
    let memo_intervenants = formValues.intervenants_nouveau;

    this.plateauTechniqueService.cree(formValues).subscribe(
      data => {
        let nouvel_objet:any = data;
        this.loadingService.dismissLoading();

        this.plateaux.push({
          "@id": nouvel_objet["@id"],
          "intitule": nouvel_objet.intitule,
          "referentStructure": nouvel_objet.referentStructure,
          "date": nouvel_objet.date,
          "adresse": nouvel_objet.adresse,
          "codePostal": nouvel_objet.codePostal,
          "ville": nouvel_objet.ville,
          "intervenants": memo_intervenants,
          "questions": memo_questions,
          "description": nouvel_objet.description
        });

        this.modal.dismiss();
        this.nouveauForm.reset();
        this.nouveauForm.patchValue({
          "date_nouveau": new Date().toISOString().slice(0, -1),
        });
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error["hydra:description"],
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'enregistrement a échoué", "danger");
        }
        this.loadingService.dismissLoading();
      }
    )
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;
    let memo_questions = formValues.questions_edition;
    let memo_intervenants = formValues.intervenants_edition;

    this.plateauTechniqueService.edite(formValues, this.url_edition).subscribe(
      data => {
        let objet_edite:any = data;
        this.loadingService.dismissLoading();

        this.plateaux[this.index_plateau] = {
          "@id": objet_edite["@id"],
          "intitule": objet_edite.intitule,
          "referentStructure": objet_edite.referentStructure,
          "date": objet_edite.date,
          "adresse": objet_edite.adresse,
          "codePostal": objet_edite.codePostal,
          "ville": objet_edite.ville,
          "intervenants": memo_intervenants,
          "questions": memo_questions,
          "description": objet_edite.description
        };

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Modifié", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    )
  }

  supprime(lien:string) {
    this.loadingService.showLoading();
    this.plateauTechniqueService.supprime(lien[0]).subscribe(
      () => {
        this.loadingService.dismissLoading();
        this.plateaux.splice(this.index_plateau, 1);
        this.toastService.presentToast("bottom", "Supprimé", "success");
      },
      error => {
        this.loadingService.dismissLoading();

        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
        }
      }
    )
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }

  dateConverter(dateFromBDD) {
    let date_string = dateFromBDD.split("T");
    return date_string[0];
  }

  //pour le formulaire d'edition
  //coche les checkboxes des options selectionnees
  compareWith(o1, o2) {
    return o1 && o2 ? o1["@id"] === o2["@id"] : o1 === o2;
  }
}
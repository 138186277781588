import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectifsUtilisateurService {

  constructor(
    private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService
    ) { }

  getUtilisateurObjectifs(
    utilisateur_id:number,
    getSousObjectifs:boolean=true,
    niveauId?:number,
    numeroNiveau?:number,
    etapeId?:number,
    getAll?:boolean
  ) {
    const url = this.constants.apiEndpoint + '/utilisateur_objectifs_all_about';
    const httpHeaders = this.headerService.getPostHeader();

    let data = {
      "userId": utilisateur_id,
      "getSousObjectifs": getSousObjectifs,
    };

    if(!!getAll) {
      data["groupName"] = "read:utilisateurObjectifAllForOneUser";
    }

    if(!!niveauId) {
      data["niveauId"] = niveauId;
    }

    if(!!numeroNiveau) {
      data["numeroNiveau"] = numeroNiveau;
    }

    if(!!etapeId) {
      data["etapeId"] = etapeId;
    }

    return this.httpClient.post(url, data, httpHeaders);
  }

  getUtilisateurSousObjectifs(utilisateur_id, sous_objectifs_ids, etape_id) {
    const url = this.constants.apiGetPostEndpoint + "/getUtilisateurObjectifsFromSousObjectifs";
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {
      utilisateur_id: utilisateur_id,
      sous_objectifs_ids: sous_objectifs_ids,
      etape_id: etape_id
    }, httpHeaders);
  }

  edite(objectif_utilisateur:any, /*sous_objectifs_associes,*/ lien: string) {    
    const url = this.constants.apiEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();
    let data = this.prepareData(objectif_utilisateur);
    data["estValide"] = objectif_utilisateur["objectif_atteint_edition"];

    /*if(sous_objectifs_associes.length > 0) {
      data["sousObjectifsUtilisateur"] = sous_objectifs_associes;
    }*/
    return this.httpClient.patch(url, data, httpHeaders);
  }

  prepareData(data:any) {
    let donnees = {};
    switch(data["element_edition"]["@type"]) {
      case "EntrepriseStage":
        donnees["entrepriseStage"] = data["element_edition"]["@id"];
        break;
      case "CompetenceCle":
        donnees["competenceCle"] = data["element_edition"]["@id"];
        break;
      case "Atelier":
        donnees["atelier"] = data["element_edition"]["@id"];
        break;
      case "SoftSkill":
        donnees["softSkill"] = data["element_edition"]["@id"];
        break;
      case "PlateauTechnique":
        donnees["PlateauTechnique"] = data["element_edition"]["@id"];
        break;
    }
    return donnees;
  }

  getOneByUtilisateurAndObjectif(utilisateur_id, objectif_id) {
    const url = this.constants.apiGetPostEndpoint + '/utilisateur_objectif/' + objectif_id  + '/' + utilisateur_id;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  /*normallement inutile. Je laisse cette methode au cas ou
  voir plus d'explications dans objectifs-utilisateurs.component
  au dessus de la methode supprimeAlert()*/
  /*supprime(lien: string) {
    const url = this.constants.apiEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }*/
}

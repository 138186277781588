import { Component, Input, OnInit } from '@angular/core';
import { RewardsTypeService } from 'src/app/services/rewards-type.service';

@Component({
  selector: 'app-reward-card-type',
  templateUrl: './reward-card-type.component.html',
  styleUrls: ['./reward-card-type.component.scss'],
})
export class RewardCardTypeComponent implements OnInit {
  title: string = null;
  @Input() type: string;

  currentType: any;

  constructor(private typesServices: RewardsTypeService) {}

  ngOnInit() {
    // this.title = this.typesServices.TYPES.find(type => type.enName === <string>this.type).frName;
    this.title=this.type;
  }
}

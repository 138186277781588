/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Constants } from '../config/constants';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TokenManagementService } from '../services/token-management.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private constants: Constants,
        private tokenManagementService: TokenManagementService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const isApiUrl = request.url.startsWith(this.constants.apiEndpoint);
        const token = this.tokenManagementService.getToken();

        if (token && isApiUrl) {
            request = this.addToken(request, token);
        }

        return next.handle(request)
            .pipe(catchError(error => {
                if (error.status === 401 && error.error.message === 'Expired JWT Token') {
                    return this.refreshToken()
                        .pipe(
                            switchMap(() => next.handle(this.addToken(request, this.tokenManagementService.getToken())))
                        );
                }
                return throwError(error);
            })
            );
    }

    refreshToken(): Observable<any> {
        return this.tokenManagementService.refreshToken()
            .pipe(
                map(data => {
                    const tokenData: any = data;
                    this.tokenManagementService.setToken(tokenData.token);
                })
            );
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }
}




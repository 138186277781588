import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { NiveauService } from 'src/app/services/Niveau/niveau.service';
import { EtapeService } from 'src/app/services/etape/etape.service';
import { RecompenseService } from 'src/app/services/Recompenses/recompense.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';

@Component({
  selector: 'app-niveau',
  templateUrl: './niveau.component.html',
  styleUrls: ['./niveau.component.scss'],
})
export class NiveauComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  url_edition:string;
  index_niveau:number;
  mode_edition:boolean=true;
  public etapes:any;
  public niveaux: any[];
  public recompenses: any[];
  loading_niveaux: boolean=false;
  loading_etapes:boolean = false;
  loading_recompenses:boolean = false;
  currentPage:number=1;
  maxPageNumber:number;
  niveauxPerPage:number = 5;
  totalQuantityNiveaux:number;

  nouveauForm = new FormGroup({
    nom_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    description_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    numero_nouveau: new FormControl('', [Validators.required, Validators.min(1), Validators.max(9999)]),
    etapes_nouveau: new FormControl('', [Validators.required]),
    recompenses_nouveau: new FormControl('', [Validators.required]),
    ville_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
  });

  editionForm = new FormGroup({
    nom_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    description_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    numero_edition: new FormControl('', [Validators.required, Validators.min(1), Validators.max(9999)]),
    etapes_edition: new FormControl('', [Validators.required]),
    recompenses_edition: new FormControl('', [Validators.required]),
    ville_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
  });

  constructor(private etapeService: EtapeService,
              private niveauService: NiveauService,
              private recompenseService: RecompenseService,
              private errorNotificationService: ErrorNotificationService,
              private loadingService: LoadingService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.getNiveaux();
        this.getEtapes();
        this.getRecompenses();
      }
    );
  }

  //recuperation des types d'objectifs enregistres en bdd
  getNiveaux(pageNumber:number=1) {
    this.loading_niveaux = true;
    this.niveauService.getPaginatedNiveaux(pageNumber).subscribe(
      data => {
        this.loading_niveaux = false;
        let infos = data;
        this.totalQuantityNiveaux = infos["hydra:totalItems"];
        this.currentPage = pageNumber;
        this.updateMaxPageNumber();
        this.niveaux = infos["hydra:member"];        
        this.checkLoading();
      },
      () => {
        this.loading_etapes = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getEtapes() {
    this.loading_etapes = true;
    this.etapeService.getEtapes(null, null, true).subscribe(
      data => {
        let infos = data;
        this.etapes = infos["hydra:member"];
        this.loading_etapes = false;
        this.checkLoading();
      },
      () => {
        this.loading_etapes = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getRecompenses() {
    this.loading_recompenses = true;
    this.recompenseService.getRecompenses().subscribe(
      data => {
        let infos = data;
        this.recompenses = infos["hydra:member"];
        this.loading_recompenses = false;
        this.checkLoading();
      },
      () => {
        this.loading_recompenses = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  checkLoading() {
    if(!this.loading_niveaux && !this.loading_etapes
      && !this.loading_recompenses) {
        this.loadingService.dismissLoading();
    }
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  updateMaxPageNumber() {
    this.maxPageNumber = Math.ceil(this.totalQuantityNiveaux/this.niveauxPerPage);
  }

  changePage(pageNumber:number) {
    this.loadingService.showLoading().then(
      () => {
        this.getNiveaux(pageNumber);
      }
    );
  }

  ouvreModaleNouveau() {
    this.mode_edition = false;
    this.modal.present();
  }

  //pour le formulaire d'edition
  //coche les checkboxes des options selectionnees
  compareWith(o1, o2) {
    return o1 && o2 ? o1["@id"] === o2["@id"] : o1 === o2;
  }

  ouvreModaleEdition(niveau:any) {
    this.mode_edition = true;
    this.editionForm.patchValue({
      "nom_edition": niveau.nom,
      "description_edition": niveau.description,
      "numero_edition": niveau.numeroNiveau,
      "etapes_edition": niveau.etapes,
      "recompenses_edition": niveau.recompenses,
      "ville_edition": niveau.ville
    });

    this.url_edition = niveau["@id"];
    this.index_niveau = this.niveaux.lastIndexOf(niveau);
    this.modal.present();
  }
  
  supprimeAlert(niveau:any) {
    this.index_niveau = this.niveaux.lastIndexOf(niveau);
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.supprime.bind(this),
      [niveau["@id"]]
    );
  }

  submitNouveau() {
    this.loadingService.showLoading();
    const formValues = this.nouveauForm.value;
    let memo_etapes = formValues.etapes_nouveau;
    let memo_recompense = formValues.recompenses_nouveau;

    this.niveauService.cree(formValues).subscribe(
      data => {
        const nouvel_objet:any = data;
        this.loadingService.dismissLoading();
        let ajout = {
          "@id": nouvel_objet["@id"],
          "nom": nouvel_objet["nom"],
          "numeroNiveau": nouvel_objet["numeroNiveau"],
          "description": nouvel_objet["description"],
          "etapes": memo_etapes,
          "recompenses": memo_recompense,
          "ville": nouvel_objet["ville"],
        };

        this.currentPage++;
        this.updateMaxPageNumber();
        this.niveaux.push(ajout);
        this.modal.dismiss();
        this.nouveauForm.reset();
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'enregistrement a échoué", "danger");
        }
        this.loadingService.dismissLoading();
      }
    )
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;
    const memo_etapes = formValues["etapes_edition"];
    const memo_recompenses = formValues["recompenses_edition"];

    this.niveauService.edite(formValues, this.url_edition).subscribe(
      data => {
        let objet_edite:any = data;
        this.loadingService.dismissLoading();
        this.niveaux[this.index_niveau] = {
          "@id": objet_edite["@id"],
          "nom": objet_edite.nom,
          "numeroNiveau": objet_edite["numeroNiveau"],
          "description": objet_edite.description,
          "etapes": memo_etapes,
          "recompenses": memo_recompenses,
          "ville": objet_edite.ville
        };

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Modifié", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    );
  }

  supprime(lien:string) {
    this.loadingService.showLoading();
    this.niveauService.supprime(lien[0]).subscribe(
      () => {
        this.loadingService.dismissLoading();
        this.currentPage--;
        this.updateMaxPageNumber();

        if(this.niveaux.length < 1) {
          this.loadingService.showLoading().then(
            () => {
              if(this.currentPage > 1) {
                this.currentPage--;
              }
              this.getNiveaux();
            }
          );
        }
        this.niveaux.splice(this.index_niveau, 1);
        this.toastService.presentToast("bottom", "Supprimé", "success");
      },
      error => {
        this.loadingService.dismissLoading();

        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
        }
      }
    )
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }
}

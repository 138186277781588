// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  color: white;
}

#main-paragraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 20px 0;
  text-align: center;
}

#share-block {
  display: flex;
  justify-content: center;
}

.share-button {
  margin: 0 8px 0 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/share/share.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,qBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":["* {\r\n    color: white;\r\n}\r\n\r\n#main-paragraph {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    margin: 20px 0 20px 0;\r\n    text-align: center;\r\n}\r\n\r\n#share-block {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\n.share-button {\r\n    margin: 0 8px 0 8px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

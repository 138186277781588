import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class EtapeService {
  constructor(private httpClient: HttpClient,
  private constants: Constants,
  private headerService: HeadersService) { }

  getEtapes(pageNumber?:number, nom?:string, unpaginatedResults?:boolean) {
    let url = this.constants.apiGetPostEndpoint + '/etapes';
    let isPageParameter = false;

    if(!!pageNumber) {
      isPageParameter = true;
      url += "?&page=" + pageNumber;
    }

    if(!!nom) {
      if(isPageParameter) {
        url += "&nom=" + nom;
      } else {
        url += "?&nom=" + nom;
      }
    }

    if(!!unpaginatedResults) {
      if(isPageParameter || !!nom) {
        url += "&pagination=false";
      } else {
        url += "?&pagination=false";
      }
    }
    
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getEtape(id: string) {
    const url = this.constants.apiGetPostEndpoint + '/etapes/' + id;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getEtapesNames(nom:string) {
    const httpHeaders = this.headerService.getGetHeader();
    const url = this.constants.apiGetPostEndpoint + '/etapes?&nom=' +  nom + "&pagination=false";
    return this.httpClient.get(url, httpHeaders);
  }

  cree(etape: any) {    
    const url = this.constants.apiGetPostEndpoint + "/etapes";
    const httpHeaders = this.headerService.getPostHeader();
    let urls_objectifs = [];
    let idsQuestionsAudio = [];

    for(let objectif of etape["objectifs_nouveau"]) {
      urls_objectifs.push(objectif["@id"]);
    }

    for(let questionAudio of etape["questions_audio_nouveau"]) {
      idsQuestionsAudio.push(questionAudio["id"]);
    }

    // duree calculee automatiquement par l'api
    return this.httpClient.post(url, {
      nom: etape["nom_nouveau"],
      numeroEtape: etape["numero_nouveau"],
      objectif: urls_objectifs,
      descriptionDebut: etape["description_debut_nouveau"],
      estLienDescriptionDebut: etape["est_lien_description_debut_nouveau"],
      descriptionFin: etape["description_fin_nouveau"],
      imageMonument: etape["image_monument_nouveau"],
      questionsAudio: idsQuestionsAudio
    }, httpHeaders);
  }

  edite(etape:any, lien: string) {    
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();
    let urls_objectifs = [];
    let idsQuestionsAudio = [];

    for(let objectif of etape["objectifs_edition"]) {
      urls_objectifs.push(objectif["@id"]);
    }

    for(let questionAudio of etape["questions_audio_edition"]) {
      idsQuestionsAudio.push(questionAudio["id"]);
    }

    return this.httpClient.patch(url, {
      "nom": etape["nom_edition"],
      numeroEtape: etape["numero_edition"],
      "objectifs": urls_objectifs,
      descriptionDebut: etape["description_debut_edition"],
      estLienDescriptionDebut: etape["est_lien_description_debut_edition"],
      descriptionFin: etape["description_fin_edition"],
      imageMonument: etape["image_monument_edition"],
      questionsAudio: idsQuestionsAudio
    }, httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

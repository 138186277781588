import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class ReponsesService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getForOneUserAndOnePlateau(id_user: number, id_plateau: number) {
    const url = this.constants.apiGetPostEndpoint + '/reponses_utilisateur/' + id_user + "_" + id_plateau;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getReponsesUtilisateur(user_id, plateau_technique_id) {
    const url = this.constants.apiGetPostEndpoint + '/reponses_utilisateur/' + user_id + "_" + plateau_technique_id;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  PostSeveralReponses(id_user: number, structureReponses: any) {
    const url = this.constants.apiGetPostEndpoint + '/poste_reponses';
    const httpHeaders = this.headerService.getPostHeader();

    return this.httpClient.post(url, {
      id_utilisateur: id_user,
      structureReponses: structureReponses
    }, httpHeaders);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor() { }

  getLocalDateTime() {
    let dateISO = new Date().toLocaleString();
    let dateTime = dateISO.split(" ");
    let date:any = dateTime[0];
    let time = dateTime[1];
    date = date.split("/");
    date = date[2] + "-" + date[1] + "-" + date[0];
    return date + "T" + time;
  }

  convertDateTimeToLocal(date) {
    let currentDate = date.split("T");
    return currentDate[0];
  }

  convertDateToUSFormat(date) {
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let day = date.getDate();
    month = month < 10 ? "0"+month : month;
    day = day < 10 ? "0"+day : day;
    return year + "-" + month + "-" + day;
  }

  dayToNumberConverter(dayString:string) {
    const days = {
      "su": 0,
      "mo": 1,
      "tu": 2,
      "we": 3,
      "th": 4,
      "fr": 5,
      "sa": 6
    };

    return !!days[dayString] ? days[dayString] : "unknown";
  }

  dateToShortDay(date) {
    let days = ["su", "mo", "tu", "we", "th", "fr", "sa"];
    let classicDate = new Date(date);
    return days[classicDate.getDay()];
  }

  secondsToTime(seconds:number) {
    let hours = Math.trunc(seconds/3600);
    let min = Math.trunc((seconds%3600)/60);
    let stringHours  = hours < 10 ? "0"+hours : hours;
    let stringMin = min < 10 ? "0"+min : min;

    return {
      "hour": stringHours+"",
      "min": stringMin+""
    };
  }

  onlySpaceStringSecurity(text:string): string|null {
    text = text.replace(/\s/g, "");
    text = text === "" ? null : text;
    return text;
  }

  isMobile() {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }

  isIpad() {
    return /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
  }

  getAllWantedDays(currentYear:number|string, currentMonth:number|string, days:string[]) {
    let localIncludedAvailableDates = [];
    let count:number = 0;
    let nextMonth:number = parseInt(currentMonth + "") + 1;
    let nextYear:number = parseInt(currentYear + "") + 1;
    nextMonth = nextMonth > 12 ? 1 : nextMonth;

    for(let day of days) { //on parcours les jours d'ouvertures de la resource
      //on recupere toutes les dates pour le mois en cours et le mois suivant qui correspondent au jour en cours
      let currentMonthDays = this.getWantedDays(currentYear+"", currentMonth+"", day);

      let nextMonthDays;
      if(nextMonth === 1) {
        nextMonthDays = this.getWantedDays(nextYear+"", nextMonth+"", day);
        nextMonthDays = nextMonthDays.map(element => nextYear + "-0" + nextMonth + "-" + element);
      } else {
        nextMonthDays = this.getWantedDays(currentYear+"", nextMonth +"", day);
        nextMonthDays = nextMonthDays.map(element => currentYear + "-" + nextMonth + "-" + element);
      }

      currentMonthDays = currentMonthDays.map(element => currentYear + "-" + currentMonth + "-" + element);
      let allDays = currentMonthDays.concat(nextMonthDays);

      //on cumule les dates recuperees lors des differents passages
      localIncludedAvailableDates = localIncludedAvailableDates.concat(allDays);

      count++;
      //si on a parcouru tous les elements, on passe à l'etape suivante
      if(count === days.length) {
        return localIncludedAvailableDates;
      }
    }
  }

  getWantedDays(year:string, month:string, day:string) {
    let yearNumber = parseInt(year, 10);
    let monthNumber = parseInt(month, 10);
    monthNumber -= 1; //par defaut 0=janvier, 1=fevrier....  permet d'envoyer 1=janvier,2=fevrier... en parametre
    let date = new Date(yearNumber, monthNumber, 1);
    let wantedDays = [];
    let dayNumber;

    switch(day) {
      case "mo":
        dayNumber = 1;
        break;

      case "tu":
        dayNumber = 2;
        break;

      case "we":
        dayNumber = 3;
        break;

      case "th":
        dayNumber = 4;
        break;

      case "fr":
        dayNumber = 5;
        break;

      case "sa":
        dayNumber = 6;
        break;

      default:
        dayNumber = 0;
        break;
    }

    while (date.getDay() !== dayNumber) {
      date.setDate(date.getDate() + 1);
    }

    while (date.getMonth() === monthNumber) {
      let prefix = date.getDate() < 10 ? "0" : "";
      wantedDays.push(prefix + date.getDate());
      date.setDate(date.getDate() + 7);
    }

    return wantedDays;
  }

  getWantedSeveralDays(year:string, month:string, days:string[]) {
    let final = [];

    for(let day of days) {
      let currentDays = this.getWantedDays(year, month, day);
      final = final.concat(currentDays);

      if(day === days[days.length -1]) {
        final = final.sort();
        final = final.map((date) => year + "-" + month + "-" + date);
        return final;
      }
    }
  }

  mergeArrayWithoutDuplicates(firstArray, secondArray) {
    let mergedArray = firstArray.concat(secondArray);

    for(let i=0; i < mergedArray.length; i++) {
      for(let j=i+1; j < mergedArray.length; j++) {
        if(mergedArray[i] === mergedArray[j]) {
          mergedArray.splice(j, 1);
        }
      }
    }

    return mergedArray;
  }

  checkAdminOrSAdmin() {
    let sessionRole = window.sessionStorage.getItem('role');

    if(!!sessionRole && 
      (sessionRole === "ROLE_ADMIN" || sessionRole === "ROLE_SUPER_ADMIN")
    ) {
      return true;
    }

    return false;
  }

  checkIsAdmin() {
    const authorizedRoles = [
      "ROLE_ADMIN",
      "ROLE_CREATEUR_PARCOURS",
      "ROLE_ALINE",
      "ROLE_FORMATRICES",
      "ROLE_SECRETAIRE_FORMATION",
      "ROLE_SUPER_ADMIN"
    ];

    if(authorizedRoles.includes(window.sessionStorage.getItem('role'))) {
      return true
    }

    return false;
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div-back {
  width: 90%;
  margin-left: 5%;
  text-align: right;
  padding-bottom: 16px;
}

#line-one {
  display: flex;
  justify-content: space-around;
}

#line-two {
  display: flex;
  justify-content: center;
}

.chart-block {
  background-color: darkgrey;
}`, "",{"version":3,"sources":["webpack://./src/app/components/statistiques/statistiques.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,eAAA;EACA,iBAAA;EACA,oBAAA;AACJ;;AAEA;EACI,aAAA;EACA,6BAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;AACJ;;AAEA;EAOI,0BAAA;AALJ","sourcesContent":[".div-back {\r\n    width: 90%;\r\n    margin-left: 5%;\r\n    text-align: right;\r\n    padding-bottom: 16px;\r\n}\r\n\r\n#line-one {\r\n    display: flex;\r\n    justify-content: space-around;\r\n}\r\n\r\n#line-two {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\n.chart-block {\r\n    //fill: #018574; /*couleur txt*/\r\n    // font-size: 0.8em !important;\r\n\r\n    // font-size: 25px !important;\r\n    // stroke: red; /*couleur contour*/\r\n\r\n    background-color: darkgrey;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonModal } from '@ionic/angular';
import { MatTable } from '@angular/material/table';
import { RendezVousIndividuelService } from 'src/app/services/RendezVousIndividuel/rendez-vous-individuel.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Constants } from '../../config/constants';
import { UtilisateurService } from 'src/app/services/Utilisateur/utilisateur.service';
import { GeneralService } from 'src/app/services/General/general.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';
import { ErrorNotificationService } from '../../services/error-notification.service';

@Component({
  selector: 'app-rendez-vous-individuels',
  templateUrl: './rendez-vous-individuels.component.html',
  styleUrls: ['./rendez-vous-individuels.component.scss'],
})
export class RendezVousIndividuelsComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild("rendezVousIndividuelsTable") rendezVousIndividuelsTable!: MatTable<any>;

  userId:number;
  public rendezVousIndividuels:any[];
  displayedColumns: string[] = [
    "dateRdv",
    "present",
    "absenceJustifiee",
    "editForm",
    "deleteForm"
  ];
  creationMode:boolean=true;
  nowLoading:boolean=true;
  public minRdvDate;
  public maxRdvDate;
  public currentSort = "date desc";
  public currentEditId:number;
  public userName="";

  createForm = new FormGroup({
    date: new FormControl(this.generalService.getLocalDateTime()),
    present: new FormControl(false, [Validators.required]),
    absenceJustifiee: new FormControl(false, [Validators.required]),
    justificationAbsence: new FormControl("", []),
    competences: new FormControl("", []),
    notionsTravaillees: new FormControl("", []),
    documentsTravailles: new FormControl("", []),
  });

  editForm = new FormGroup({
    date: new FormControl(this.generalService.getLocalDateTime()),
    present: new FormControl(false, [Validators.required]),
    absenceJustifiee: new FormControl(false, [Validators.required]),
    justificationAbsence: new FormControl("", []),
    competences: new FormControl("", []),
    notionsTravaillees: new FormControl("", []),
    documentsTravailles: new FormControl("", []),
  });

  constructor(private _Activatedroute:ActivatedRoute,
    private rdvService:RendezVousIndividuelService,
    private generalService:GeneralService,
    private loadingService:LoadingService,
    private utilisateurService:UtilisateurService,
    private toastService:ToastService,
    private errorNotificationService:ErrorNotificationService,
    private constants: Constants
  ) { }

  ngOnInit() {
    this.userId=parseInt(this._Activatedroute.snapshot.paramMap.get("id"));
    this.minRdvDate = this.constants.minRdvDate;
    this.maxRdvDate = this.constants.maxRdvDate;
    this.getUserName();
    this.getUserRdvs(this.userId);
  }

  getUserName() {
    this.utilisateurService.getNames(this.userId).subscribe(
      data => {
        let names = data["hydra:member"][0];
        this.userName = names.nom + " " + names.prenom;
      },
      /*error*/() => {
        //console.log(error);
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors de l'enregistrement des données",
          "Ok"
        );
      }
    )
  }

  getUserRdvs(userId:number) {
    this.rdvService.getAllForAUser(userId).subscribe(
      data => {
        this.rendezVousIndividuels = data["hydra:member"];
        this.nowLoading = false;
      },
      /*error*/() => {
        //console.log(error);
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  sortChange(event) {
    this.currentSort = event.detail.value;
    this.sort();
  }

  sort() {
    this.rendezVousIndividuels = this.rendezVousIndividuels.sort((a,b) => {
      if(this.currentSort === "date desc") {
        return this.compare(a.dateRdv, b.dateRdv, false);
      } else {
        return this.compare(a.dateRdv, b.dateRdv, true);
      }
    });
    this.rendezVousIndividuelsTable.renderRows();
  }

  compare(a: any, b: any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  isPresentChangeCreation() {
    let isPresent = this.createForm.value.present;
    let validators = [];

    //si estPresent passe de false à true
    if(!isPresent) {
      validators = [Validators.required];
      this.createForm.patchValue({
        justificationAbsence: ""
      });
      this.createForm.get("justificationAbsence").updateValueAndValidity();
    }

    this.createForm.get("absenceJustifiee").setValidators(validators);
    this.createForm.get("absenceJustifiee").updateValueAndValidity();
  }

  isJustifiedChange() {
    let isJustified = this.createForm.value.absenceJustifiee;
    let validators = [];

    //le booleen absenceJustifiee passe de false à true
    if(isJustified) {
      validators = [Validators.required];
    }

    this.createForm.get("justificationAbsence").setValidators(validators);
    this.createForm.get("justificationAbsence").updateValueAndValidity();
  }

  submitCreation() {
    if(!this.createForm.invalid) {
      this.loadingService.showLoading().then(
        () => {
          this.rdvService.cree(this.createForm.value, this.userId).subscribe(
            data => {
              this.rendezVousIndividuels.push(data);
              this.sort();
              this.createForm.reset();
    
              this.createForm.patchValue({
                "date": this.generalService.getLocalDateTime(),
                "present": false,
                "absenceJustifiee": false,
              });
    
              this.closeModal();
              this.loadingService.dismissLoading();
              this.toastService.presentToast("bottom", "Rendez vous individuel enregistré", "success");
            },
            /*error*/() => {
              //console.log(error);
              this.errorNotificationService.presentGeneralErrorAlert(
                true,
                "Erreur",
                "Un problème a été rencontré lors de l'enregistrement des données",
                "Ok"
              );
              this.loadingService.dismissLoading();
            }
          )
        }
      );
    }
  }

  isPresentChangeEdit() {
    let isPresent = this.editForm.value.present;
    let validators = [];

    //si estPresent passe de false à true
    if(!isPresent) {
      validators = [Validators.required];
      this.editForm.patchValue({
        justificationAbsence: ""
      });
      this.editForm.get("justificationAbsence").updateValueAndValidity();
    }

    this.editForm.get("absenceJustifiee").setValidators(validators);
    this.editForm.get("absenceJustifiee").updateValueAndValidity();
  }

  isJustifiedChangeEdit() {
    let isJustified = this.editForm.value.absenceJustifiee;
    let validators = [];

    //le booleen absenceJustifiee passe de false à true
    if(isJustified) {
      validators = [Validators.required];
    } else { //passe de true à false
      this.editForm.patchValue({
        justificationAbsence: ""
      });
    }

    this.editForm.get("justificationAbsence").setValidators(validators);
    this.editForm.get("justificationAbsence").updateValueAndValidity();
  }

  closeModal() {
    this.modal.dismiss();
  }

  submitEdition() {
    if(!this.editForm.invalid) {
      this.loadingService.showLoading().then(
        () => {
          this.rdvService.edit(this.editForm.value, this.currentEditId).subscribe(
            data => {
              let editedElement = this.rendezVousIndividuels.find(
                element => element.id === data["id"]
              );
              let index = this.rendezVousIndividuels.indexOf(editedElement);
              this.rendezVousIndividuels[index] = data;
              this.sort();
              this.closeModal();
              this.loadingService.dismissLoading();
              this.toastService.presentToast("bottom", "Rendez vous individuel modifié", "success");
            },
            /*error*/() => {
              this.errorNotificationService.presentGeneralErrorAlert(
                true,
                "Erreur",
                "Un problème a été rencontré lors de l'enregistrement des données",
                "Ok"
              );
              this.loadingService.dismissLoading();
            }
          )
        }
      );
    }
  }

  showModal(modeCreation:boolean, element:any=null) {
    this.creationMode = modeCreation;

    if(!modeCreation && element !== null) {
      this.currentEditId = element.id;

      this.editForm.patchValue({
        date: element.dateRdv,
        present: element.present,
        absenceJustifiee: element.absenceJustifiee,
        justificationAbsence: element.justificationAbsence,
        competences: element.competence,
        notionsTravaillees: element.notionsTravaillees,
        documentsTravailles: element.documentsTravailles,
      });
    }

    this.modal.present();
  }

  deleteWarning(elementId:number) {
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur(e) de vouloir supprimer ce rendez vous ?",
      "Oui",
      this.remove.bind(this),
      [elementId]
    );
  }

  remove(rdvId:number) {
    this.loadingService.showLoading().then(
      () => {
        this.rdvService.remove(rdvId[0]).subscribe(
          () => {
            let editedElement = this.rendezVousIndividuels.find(
              element => element.id === rdvId[0]
            );
            let index = this.rendezVousIndividuels.indexOf(editedElement);
            this.rendezVousIndividuels.splice(index, 1);
            this.sort();
            this.closeModal();
            this.loadingService.dismissLoading();
            this.toastService.presentToast("bottom", "Rendez vous individuel supprimé", "success");
          },
          /*error*/() => {
            //console.log(error);
            this.errorNotificationService.presentGeneralErrorAlert(
              true,
              "Erreur",
              "Un problème a été rencontré lors de la suppression du rendez vous",
              "Ok"
            );
            this.loadingService.dismissLoading();
          }
        )
      }
    );
  }
}

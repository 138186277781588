import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Constants } from '../../config/constants';
import { ErrorNotificationService } from '../error-notification.service';

@Injectable({
  providedIn: 'root'
})
export class TokenManagementService {

  constructor(
    private constants: Constants,
    private httpClient: HttpClient,
    private errorNotificationService: ErrorNotificationService,
    private jwtHelperService: JwtHelperService) { }

  setToken(token: string) {
    try {
      window.sessionStorage.setItem('token', token);
    } catch (error) {
      throw Error;
    }
  }

  getToken(): string | never {
    if(this.jwtHelperService.isTokenExpired()) {
      this.refreshToken()
      .subscribe(
        data => {
          const tokenData: any = data;
          this.setToken(tokenData.token);
          return tokenData.token;
        }, refreshTokenError => {
          let d = window.sessionStorage.getItem('refreshToken');
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            'Erreur',
            'Impossible de maintenir la session',
            'Ok'
          );
        }
      );
    } else {
      try {
        return window.sessionStorage.getItem('token');
      } catch {
        throw Error;
      }
    }
  }

  setRefreshToken(refreshToken: string) {
    try {
      window.sessionStorage.setItem('refreshToken', refreshToken);
    } catch {
      throw Error;
    }
  }

  getRefreshToken(): string | never {
    try {
      return window.sessionStorage.getItem('refreshToken');
    } catch {
      throw Error;
    }
  }

  refreshToken() {
    const httpHeaders = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    const url = this.constants.apiEndpoint + '/api/token/refresh';
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.httpClient.post(url, {refresh_token: this.getRefreshToken()}, httpHeaders);
  }
}

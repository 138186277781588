import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { AtelierService } from '../../services/Atelier/atelier.service';
import { IntervenantService } from 'src/app/services/Intervenants/intervenant.service';
// import { TypeObjectifService } from 'src/app/services/TypesObjectif/type-objectif.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';
import { Constants } from '../../config/constants';
import { GeneralService } from 'src/app/services/General/general.service';

@Component({
  selector: 'app-atelier',
  templateUrl: './atelier.component.html',
  styleUrls: ['./atelier.component.scss'],
})
export class AtelierComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;

  intitule_edition:string;
  intitule_nouveau:string;
  url_edition:string;
  index_atelier:number;
  mode_edition:boolean=true;
  public ateliers: any;
  public intervenants: any;
  // private types_objectifs: any;
  private loading_ateliers:boolean = false;
  private loading_intervenants:boolean = false;
  // private loading_types_objectifs:boolean = false;
  minDate:string;
  maxDate:string;

  nouveauForm = new FormGroup({
    intitule_nouveau: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    date_debut_nouveau: new FormControl(this.generalService.getLocalDateTime(), [Validators.required]),
    date_fin_nouveau: new FormControl(this.generalService.getLocalDateTime(), [Validators.required]),
    // type_objectif_nouveau: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    intervenants_nouveau: new FormControl(''),
    adresse_nouveau: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    code_postal_nouveau: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    ville_nouveau: new FormControl('', [Validators.required, Validators.maxLength(100)])
  });

  editionForm = new FormGroup({
    intitule_edition: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    date_debut_edition: new FormControl(this.generalService.getLocalDateTime(), [Validators.required]),
    date_fin_edition: new FormControl(this.generalService.getLocalDateTime(), [Validators.required]),
    // type_objectif_edition: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    intervenants_edition: new FormControl(''),
    adresse_edition: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    code_postal_edition: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    ville_edition: new FormControl('', [Validators.required, Validators.maxLength(100)])
  });

  constructor(private atelierService: AtelierService,
              private intervenantService: IntervenantService,
              // private typeObjectifService: TypeObjectifService,
              private errorNotificationService: ErrorNotificationService,
              private loadingService: LoadingService,
              private generalService:GeneralService,
              private toastService: ToastService,
              private constants: Constants) {
                this.minDate = this.constants.minDate;
                this.maxDate = this.constants.maxDate;
              }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.getAteliers();
        this.getIntervenants();
        // this.getTypesObjectifs();
      }
    );
  }

  getAteliers() {
    this.loading_ateliers = true;
    this.atelierService.getAteliers().subscribe(
      data => {
        let infos = data;
        this.ateliers = infos["hydra:member"];
        this.loading_ateliers = false;
        this.checkLoading();
      },
      () => {
        this.loading_ateliers = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getIntervenants() {
    this.loading_intervenants = true;
    this.intervenantService.getIntervenants().subscribe(
      data => {
        let infos = data;
        this.intervenants = infos["hydra:member"];
        this.loading_intervenants = false;
        this.checkLoading();
      },
      () => {
        this.loading_intervenants = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  // getTypesObjectifs() {
  //   this.loading_types_objectifs = true;
  //   this.typeObjectifService.getTypes().subscribe(
  //     data => {
  //       let infos = data;
  //       this.types_objectifs = infos["hydra:member"];
  //       this.loading_types_objectifs = false;
  //       this.checkLoading();
  //     },
  //     () => {
  //       this.loading_types_objectifs = false;
  //       this.checkLoading();
  //       this.errorNotificationService.presentGeneralErrorAlert(
  //         true,
  //         "Erreur",
  //         "Un problème a été rencontré lors du chargement des données",
  //         "Ok"
  //       );
  //     }
  //   )
  // }

  checkLoading() {
    if(!this.loading_ateliers
      && !this.loading_intervenants
      /*&& !this.loading_types_objectifs*/) {
      this.loadingService.dismissLoading();
    }
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  ouvreModaleNouveau() {
    this.mode_edition = false;
    this.modal.present();
  }

  ouvreModaleEdition(atelier:any) {
    this.mode_edition = true;
    this.intitule_edition = atelier.intitule;
    this.url_edition = atelier["@id"];
    this.editionForm.patchValue({
      "intitule_edition": atelier.intitule,
      "date_debut_edition": atelier.dateDebut,
      "date_fin_edition": atelier.dateFin,
      "intervenants_edition": atelier.intervenants,
      "adresse_edition": atelier.adresse,
      "ville_edition": atelier.ville,
      "code_postal_edition": atelier.codePostal
    });
    this.modal.present();
    this.index_atelier = this.ateliers.lastIndexOf(atelier);
  }

  supprimeAlert(atelier:any) {
    this.index_atelier = this.ateliers.lastIndexOf(atelier);
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.supprime.bind(this),
      [atelier["@id"]]
    );
  }

  submitNouveau() {
    this.loadingService.showLoading();
    const formValues = this.nouveauForm.value;
    // let memo_type_objectif = formValues.type_objectif_nouveau;
    let memo_intervenants = formValues.intervenants_nouveau;

    this.atelierService.cree(formValues).subscribe(
      data => {
        let nouvel_objet:any = data;
        this.loadingService.dismissLoading();

        this.ateliers.push({
          "@id": nouvel_objet["@id"],
          "intitule": nouvel_objet.intitule,
          "dateDebut": nouvel_objet.dateDebut,
          "dateFin":nouvel_objet.dateFin,
          // "typeObjectif": memo_type_objectif,
          "intervenants": memo_intervenants,
          "adresse": nouvel_objet.adresse,
          "codePostal": nouvel_objet.codePostal,
          "ville": nouvel_objet.ville
        });

        this.nouveauForm.reset();
        this.nouveauForm.patchValue({
          "date_debut_nouveau": this.generalService.getLocalDateTime(),
          "date_fin_nouveau": this.generalService.getLocalDateTime(),
        });
        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error["hydra:description"],
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'enregistrement a échoué", "danger");
        }
        this.loadingService.dismissLoading();
      }
    )
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;
    const memo_intervenants = formValues["intervenants_edition"];

    this.atelierService.edite(formValues, this.url_edition).subscribe(
      data => {
        let objet_edite:any = data;
        this.loadingService.dismissLoading();

        this.ateliers[this.index_atelier] = {
          "@id": objet_edite["@id"],
          "intitule": objet_edite.intitule,
          "dateDebut": objet_edite.dateDebut,
          "dateFin": objet_edite.dateFin,
          "intervenants": memo_intervenants,
          "adresse": objet_edite.adresse,
          "codePostal": objet_edite.codePostal,
          "ville": objet_edite.ville
        };

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Modifié", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    )
  }

  supprime(lien:string) {
    this.loadingService.showLoading();
    this.atelierService.supprime(lien[0]).subscribe(
      () => {
        this.loadingService.dismissLoading();
        this.ateliers.splice(this.index_atelier, 1);
        this.toastService.presentToast("bottom", "Supprimé", "success");
      },
      error => {
        this.loadingService.dismissLoading();

        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
        }
      }
    )
  }

  //pour le formulaire d'edition
  //coche les checkboxes des options selectionnees
  compareWith(o1, o2) {
    return o1 && o2 ? o1["@id"] === o2["@id"] : o1 === o2;
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class SituationProfessionnelleService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getSituationsProfessionnelles() {
    const url = this.constants.apiGetPostEndpoint + '/situation_professionnelles';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(situation: any) {
    const url = this.constants.apiGetPostEndpoint + "/situation_professionnelles";
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {situation: situation["situation_pro_nouveau"]}, httpHeaders);
  }

  edite(situation:any, lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();
    return this.httpClient.patch(url, {
    situation: situation["situation_pro_edition"],
    },httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

import { Component, OnInit } from '@angular/core';
import { UtilisateurService } from 'src/app/services/Utilisateur/utilisateur.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-statistiques',
  templateUrl: './statistiques.component.html',
  styleUrls: ['./statistiques.component.scss'],
})
export class StatistiquesComponent implements OnInit {
  nationalityData = [
    {name: "Française", value: 42}
  ];

  villesData = [
    {name: "Roubaix", value: 18}
  ];

  qpvQuantity = [
    {name: "QPV", value: 50}
  ];


  /*colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };*/

  constructor(
    private utilisateurService:UtilisateurService,
    private loadingService:LoadingService
  ) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      ()=> {
        this.getStats();
      }
    )
  }

  getStats() {
    this.utilisateurService.getStats().subscribe(
      data => {
        let rawNationalityData = data["nationalitesQuantity"];
        this.nationalityData = [];

        for(let nationality in rawNationalityData) {
          let nationalityText = nationality === "" ? "Non renseigné" : nationality;

          this.nationalityData.push({
            name: nationalityText,
            value: rawNationalityData[nationality]
          });
        }

        //villes
        let rawVillesData = data["villesQuantity"];
        this.villesData = [];

        for(let ville in rawVillesData) {
          let villeText = ville === "" ? "Non renseigné" : ville;

          this.villesData.push({
            name: villeText,
            value: rawVillesData[ville]
          });
        }

        //QPV
        let totalUsers = data["totalQuantity"];
        let qpvUsersQuantity = data["usersWithQPV"];
        let percent = Math.trunc((qpvUsersQuantity/totalUsers) * 10000);
        percent = percent/100;

        this.qpvQuantity = [
          {name: "QPV", value: percent}
        ];

        this.loadingService.dismissLoading();
      },
      error => {
        console.log(error);
        this.loadingService.dismissLoading();
      }
    );
  }
}

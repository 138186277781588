// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#button-div {
  display: flex;
  justify-content: flex-end;
  padding: 2em 4em 0 4em;
}

#technical-platform-page {
  padding: 0 4em 0 4em;
}

#submit-btn {
  margin-top: 2em;
}

.question-label {
  margin-top: 2em;
  font-size: medium;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/technical-platform/technical-platform.page.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,oBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;EACA,gBAAA;AACJ","sourcesContent":["#button-div {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    padding: 2em 4em 0 4em;\r\n}\r\n\r\n#technical-platform-page{\r\n    padding: 0 4em 0 4em;\r\n}\r\n\r\n#submit-btn{\r\n    margin-top: 2em;\r\n}\r\n\r\n.question-label{\r\n    margin-top: 2em;\r\n    font-size: medium;\r\n    font-weight: 500;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-validation-compte',
  templateUrl: './validation-compte.component.html',
  styleUrls: ['./validation-compte.component.scss'],
})
export class ValidationCompteComponent implements OnInit {
  public validation:boolean;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    let ok = this.activatedRoute.snapshot.paramMap.get("ok");

    if(ok === "true" || ok === "false") {
      this.validation = ok === "true" ? true : false;
    } else {
      this.goToConnexion();
    }
  }

  goToConnexion() {
    this.router.navigate(['connexion']);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class DescriptionCustomService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  create(utilisateurObjectifId:number, name:string) {
    const url = this.constants.apiGetPostEndpoint + "/createDescriptionCustom";
    const httpHeaders = this.headerService.getPostHeader();

    let data = {
      "nom": name,
      "utilisateurObjectifId": utilisateurObjectifId,
    };

    return this.httpClient.post(url, data, httpHeaders);
  }

  edit(id:number, name:string, validated:boolean=false) {
    let url = this.constants.apiPatchDeleteEndpoint;

    switch(this.constants.mode) {
      case "local":
        url += "/editDescriptionCustom/";
        break;

      case "preprod":
        url += "/preprod/api/editDescriptionCustom/";
        break;

      case "emeraude":
        url += "/preprod/api/public/editDescriptionCustom/";
        break;

      case "prod":
        url += "/api/public/editDescriptionCustom/";
        break;
    }
    url += id;
    const httpHeaders = this.headerService.getPatchHeader();

    let data = {
      "nom": name,
      "estValide": validated
    };

    return this.httpClient.patch(url, data, httpHeaders);
  }

  delete(id:number) {
    const url = this.constants.apiPatchDeleteEndpoint + "/deleteDescriptionCustom/" + id;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }

  getByUserId(userId:number) {
    const url = this.constants.apiGetPostEndpoint + "/getForAUSer/" + userId;
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.get(url, httpHeaders);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ReponseAudioService } from 'src/app/services/ReponseAudio/reponse-audio.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { ToastService } from 'src/app/services/Toast/toast.service';

@Component({
  selector: 'app-reponse-audio',
  templateUrl: './reponse-audio.component.html',
  styleUrls: ['./reponse-audio.component.scss'],
})
export class ReponseAudioComponent implements OnInit {

  @Input("reponseAudio") reponseAudio:any;

  audioForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl(null, [Validators.required])
  });

  public mediaRecorder;
  public globalBlob;
  public audioUrl:string;
  public micClass:string="";
  public planeClass:string="";
  public loaderClass:string="display-none";
  public question:string;
  public oldAudioUrl:string;
  public oldBlob;
  public nowRecording:boolean=false;
  public nowSending:boolean = false;

  constructor(
    private reponseAudioService: ReponseAudioService,
    private errorNotificationService: ErrorNotificationService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.question = this.reponseAudio.questionAudio.intitule;

    if(!!this.reponseAudio.fileUrl) {
      this.reponseAudioService.getSound(this.reponseAudio.id).subscribe(
        data => {
          this.oldBlob = data;
          this.oldAudioUrl = URL.createObjectURL(this.oldBlob);
        },
        /*error*/() => {
          this.errorNotificationService.presentGeneralErrorAlert(
            true,
            "Erreur",
            "Le fichier son n'a pas pu etre chargé",
            "Ok"
          );
        }
      )
    }
  }

  recordStart() {
    navigator.mediaDevices.getUserMedia({ audio: true })
    .then(stream => {
      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.start();
      this.globalBlob = null;
      this.audioUrl = null;
      this.oldAudioUrl = null;
      this.micClass = "recording";
      this.nowRecording = true;
      const audioChunks = [];

      this.mediaRecorder.addEventListener("dataavailable", event => {
        audioChunks.push(event.data);
      });

      this.mediaRecorder.addEventListener("stop", () => {
        this.micClass = "";
        this.nowRecording = false;
        this.globalBlob = new Blob(audioChunks, { type: "audio/mpeg-3; codecs=MP3" });
        this.audioUrl = URL.createObjectURL(this.globalBlob);
        this.OnFileSelected();
      });

      setTimeout(() => {
        this.mediaRecorder.stop();
      }, 30000);//duree max 30s
    });
  }

  recordStop() {
    this.mediaRecorder.stop();
  }

  OnFileSelected() {
    const file = new File([this.globalBlob], this.reponseAudio.utilisateur + ".mp3", {
      type: "audio/mpeg-3"
    });

    this.audioForm.patchValue({
      fileSource: file
    });
  }

  submit(){
    this.planeClass = "sending";
    this.loaderClass = "";
    this.nowSending = true;
    const formData = new FormData();
    const fileSourceValue = this.audioForm.get('fileSource')?.value;
    if(fileSourceValue !== null && fileSourceValue !== undefined) {
        formData.append('audioFile', fileSourceValue);
    }

    this.reponseAudioService.soundPost(formData, this.reponseAudio.id).subscribe(
      /*data*/() => {
        this.planeClass = "";
        this.loaderClass = "display-none";
        this.nowSending = false;
        this.toastService.presentToast(
          "top",
          "Réponse enregistrée",
          "success",
          "checkmark-outline"
        );
      },
      /*error*/() => {
        this.planeClass = "";
        this.loaderClass = "display-none";
        this.nowSending = false;
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "L'enregistrement de votre réponse audio a échoué",
          "Ok"
        );
      }
    );
  }
}

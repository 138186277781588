import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class NiveauEtudesService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getNiveauxEtudes() {
    const url = this.constants.apiGetPostEndpoint + '/niveau_etudes';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(niveau: any) {
    const url = this.constants.apiGetPostEndpoint + "/niveau_etudes";
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {niveau: niveau["niveau_nouveau"]}, httpHeaders);
  }

  edite(niveau:any, lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();

    return this.httpClient.patch(url, {
    niveau: niveau["niveau_edition"],
    },httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

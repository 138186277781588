import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-donnees-personnelles',
  templateUrl: './donnees-personnelles.component.html',
  styleUrls: ['./donnees-personnelles.component.scss'],
})
export class DonneesPersonnellesComponent implements OnInit {

  constructor(
    private router:Router,
    private location: Location
  ) {}

  ngOnInit() {}

  scrollTo(fragment): void {
    this.router.navigate([], { fragment: fragment }).then(res => {
      const element = document.getElementById(fragment);
      if (element != undefined) element.scrollIntoView();
    });
  }

  goBack() {
    this.location.back();
  }
}

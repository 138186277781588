import { Formule } from "./formule";

export class Utilisateur {
    id?: number;
    email?: string;
    roles?: string[];
    nom?: string;
    prenom?: string;
    heuresEffectuees?: number;
    formule?: Formule;
    utilisateurNiveaux?: any[];
    etapeNiveaux?:any[];
    adresse?: string;
    codePostal?: string;
    ville?: string;
    telephone?: string;
    merePere?: boolean;
    nombreEnfants?: number;
    aPermisConduire?: boolean;
    departement?: string;
    nationnalite?: string;
    modeDeplacement?: string;
    civilite?: string;
    niveauEtude?: string;
    numeroDemandeurEmploi?: null;
    situationProfessionnelle?: string;
    situationFamiliale?: string;
    situationRsa?: any;
    dateDebut?: Date;
    dateFin?: Date;
    dateNaissance?: Date;
    QPV?: boolean;
    RQTH?: boolean;
    conditionsAcceptees?: boolean;
    dateDerniereConnexion?: Date;
    bilanIntermediaireFait?:boolean;
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { NiveauService } from 'src/app/services/Niveau/niveau.service';
import { FormuleService } from 'src/app/services/Formule/formule.service';
import { TypesFormuleService } from 'src/app/services/TypesFormule/types-formule.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';

@Component({
  selector: 'app-formule',
  templateUrl: './formule.component.html',
  styleUrls: ['./formule.component.scss'],
})
export class FormuleComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;

  url_edition:string;
  index_formule:number;
  mode_edition:boolean=true;
  public formules:any;
  public types_formules: any[];
  public niveaux: any[];
  loading_formules: boolean=false;
  loading_types_formules: boolean=false;
  loading_niveaux:boolean = false;
  getFormuleTimeout:any=null;
  showSearchLoader:boolean=false;
  nombreResultatsRecherche:number;
  foundFormules: any[];
  currentPage:number=1;
  maxPageNumber:number;
  formulesPerPage:number = 5;
  totalQuantityFormules:number;

  nouveauForm = new FormGroup({
    nom_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(45)]),
    type_nouveau: new FormControl('', [Validators.required]),
    niveaux_nouveau: new FormControl('', [Validators.required]),
  });

  editionForm = new FormGroup({
    nom_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(45)]),
    type_edition: new FormControl('', [Validators.required]),
    niveaux_edition: new FormControl('', [Validators.required]),
  });

  searchForm = new FormGroup({
    nom: new FormControl('', [Validators.maxLength(50)]),
    selectedFormule: new FormControl('')
  });

  constructor(private formuleService: FormuleService,
              private typeFormuleService: TypesFormuleService,
              private niveauService: NiveauService,
              private errorNotificationService: ErrorNotificationService,
              private loadingService: LoadingService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.getFormules(this.currentPage, null);
        this.getTypesFormules();
        this.getNiveaux();
      }
    );
  }

  //recuperation des formules enregistrees en bdd
  getFormules(pageNumber?:number, id?:number) {
    this.loading_formules = true;
    this.formuleService.getFormules(id, pageNumber).subscribe(
      data => {
        this.loading_formules = false;
        let infos = data;
        this.formules = infos["hydra:member"];
        this.totalQuantityFormules = infos["hydra:totalItems"];
        this.currentPage = pageNumber;
        this.updateMaxPageNumber();
        this.checkLoading();
      },
      () => {
        this.loading_formules = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getTypesFormules() {
    this.loading_types_formules = true;
    this.typeFormuleService.getTypes().subscribe(
      data => {
        let infos = data;
        this.types_formules = infos["hydra:member"];
        this.loading_types_formules = false;
        this.checkLoading();
      },
      () => {
        this.loading_types_formules = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getNiveaux() {
    this.loading_niveaux = true;
    this.niveauService.getNiveaux().subscribe(
      data => {
        this.loading_niveaux = false;
        let infos = data;
        this.niveaux = infos["hydra:member"];
        this.checkLoading();
      },
      () => {
        this.loading_niveaux = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  checkLoading() {
    if(!this.loading_niveaux && !this.loading_formules && !this.loading_types_formules) {
      this.loadingService.dismissLoading();
    }
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  updateMaxPageNumber() {
    this.maxPageNumber = Math.ceil(this.totalQuantityFormules/this.formulesPerPage);
  }

  changePage(pageNumber:number) {
    this.loadingService.showLoading().then(
      () => {
        this.getFormules(pageNumber, null);
      }
    );
  }

  openDuplicateModal(formule:any) {
    this.nouveauForm.patchValue({
      "nom_nouveau": formule.nom,
      "type_nouveau": formule.typeFormule,
      "niveaux_nouveau": formule.niveaux
    });

    this.ouvreModaleNouveau();
  }

  ouvreModaleNouveau() {
    this.mode_edition = false;
    this.modal.present();
  }

  getFormulesNames() {
    if(this.getFormuleTimeout !== null) {
      clearTimeout(this.getFormuleTimeout);
      this.getFormuleTimeout = null;
    }

    this.getFormuleTimeout = window.setTimeout(() => {
      if(!this.searchForm.invalid) {
        this.showSearchLoader = true;
      }

      //si le champ nom de formule est vide
      if(this.searchForm.value.nom === "") {
        this.searchForm.reset();
        this.searchForm.setValue({
          nom: "",
          selectedFormule: ""
        });
        this.foundFormules = [];
        this.nombreResultatsRecherche = 0;

        this.loadingService.showLoading().then(
          () => {
            this.getFormules(this.currentPage, null);
            this.showSearchLoader = false;
          }
        );
      } else {
        this.formuleService.getFormulesByName(
          this.searchForm.value.nom,
          null
        ).subscribe(
          data => {
            let infos = data;
            this.foundFormules = infos["hydra:member"];
            this.nombreResultatsRecherche = this.foundFormules.length;
            this.showSearchLoader = false;
          },
          () => {
            this.errorNotificationService.presentGeneralErrorAlert(
              true,
              "Erreur",
              "Un problème a été rencontré lors de la recherche des formules",
              "Ok"
            );
            this.showSearchLoader = false;
          }
        )
      }
    }, 150);
  }

  getSearchText() {
    if(typeof(this.nombreResultatsRecherche) !== "undefined" && this.nombreResultatsRecherche > 0) {
      return this.nombreResultatsRecherche + " résultat(s)";
    }

    return "Aucun résultat";
  }

  //fct a lancer pr afficher les details des users pr ces noms
  searchByName(event) {
    if(!this.searchForm.invalid) {
      this.loadingService.showLoading().then(
        () => {
          if(this.searchForm.value.nom !== "") {
            this.getFormules(null, event.detail.value);
          } else {
            this.currentPage = 1;
            this.getFormules(1, null);
          }
        }
      );
    }
  }

  //pour le formulaire d'edition
  //coche les checkboxes des options selectionnees
  compareWith(o1, o2) {
    return o1 && o2 ? o1["@id"] === o2["@id"] : o1 === o2;
  }

  ouvreModaleEdition(formule:any) {
    this.mode_edition = true;
    this.editionForm.patchValue({
      "nom_edition": formule.nom,
      "type_edition": formule.typeFormule,
      "niveaux_edition": formule.niveaux,
    });

    this.url_edition = formule["@id"];
    this.index_formule = this.formules.lastIndexOf(formule);
    this.modal.present();
  }
  
  supprimeAlert(formule:any) {
    this.index_formule = this.formules.lastIndexOf(formule);
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.supprime.bind(this),
      [formule["@id"]]
    );
  }

  customNewFormValid() {
    if(this.nouveauForm.invalid) {
      return true;
    }
    return false;
  }

  customEditFormValid() {
    if(this.editionForm.invalid) {
      return true;
    }
    return false;
  }

  submitNouveau() {
    this.loadingService.showLoading();
    const formValues = this.nouveauForm.value;
    const memo_type = formValues.type_nouveau;
    const memo_niveaux = formValues.niveaux_nouveau;

    this.formuleService.cree(formValues).subscribe(
      data => {
        const nouvel_objet:any = data;
        this.loadingService.dismissLoading();
        let ajout = {
          "@id": nouvel_objet["@id"],
          "nom": nouvel_objet["nom"],
          "duree": nouvel_objet["duree"],
          "typeFormule": memo_type,
          "niveaux": memo_niveaux,
        };

        this.formules.push(ajout);
        this.modal.dismiss();
        this.nouveauForm.reset();
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error["hydra:description"],
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'enregistrement a échoué", "danger");
        }
        this.loadingService.dismissLoading();
      }
    )
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;
    const memo_type = formValues.type_edition;
    const memo_niveaux = formValues.niveaux_edition;

    this.formuleService.edite(formValues, this.url_edition).subscribe(
      data => {
        let objet_edite:any = data;
        this.loadingService.dismissLoading();

        this.formules[this.index_formule] = {
          "@id": objet_edite["@id"],
          "nom": objet_edite.nom,
          "duree": objet_edite.duree,
          "typeFormule": memo_type,
          "niveaux": memo_niveaux,
        };

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Modifié", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    );
  }

  supprime(lien:string) {
    this.loadingService.showLoading();
    this.formuleService.supprime(lien[0]).subscribe(
      () => {
        this.loadingService.dismissLoading();
        this.formules.splice(this.index_formule, 1);
        this.toastService.presentToast("bottom", "Supprimé", "success");
      },
      error => {
        this.loadingService.dismissLoading();

        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
        }
      }
    )
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div-back {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

#main-container {
  display: flex;
  justify-content: space-around;
}

#logs-container {
  width: 75%;
}

@media (max-width: 800px) {
  #main-container {
    flex-direction: column;
    align-items: center;
  }
  #logs-container {
    width: 95%;
    margin-top: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/admin/logs/logs.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,yBAAA;AACJ;;AAEA;EACI,aAAA;EACA,6BAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI;IACI,sBAAA;IACA,mBAAA;EACN;EAEE;IACI,UAAA;IACA,gBAAA;EAAN;AACF","sourcesContent":[".div-back {\r\n    padding: 16px;\r\n    display: flex;\r\n    justify-content: flex-end;\r\n}\r\n\r\n#main-container {\r\n    display: flex;\r\n    justify-content: space-around;\r\n}\r\n\r\n#logs-container {\r\n    width: 75%;\r\n}\r\n\r\n@media (max-width: 800px) {\r\n    #main-container {\r\n        flex-direction: column;\r\n        align-items: center;\r\n    }\r\n\r\n    #logs-container {\r\n        width: 95%;\r\n        margin-top: 20px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PlateauTechniqueService } from 'src/app/services/PlateauTechnique/plateau-technique.service';
import { UtilisateurService } from 'src/app/services/Utilisateur/utilisateur.service';
import { ReponsesService } from 'src/app/services/Reponses/reponses.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { IonModal } from '@ionic/angular';

@Component({
  selector: 'app-plateau-user',
  templateUrl: './plateau-user.component.html',
  styleUrls: ['./plateau-user.component.scss'],
})
export class PlateauUserComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;
  private plateaux: any[];
  private reponses: any[];
  loading_plateaux: boolean=false;
  loading_utilisateur: boolean=false;
  loading_reponses: boolean=false;
  nom_utilisateur: string;
  prenom_utilisateur: string;
  id_user: number;

  constructor(private _Activatedroute:ActivatedRoute,
              private plateauTechniqueService:PlateauTechniqueService,
              private utilisateurService: UtilisateurService,
              private reponseService: ReponsesService,
              private loadingService: LoadingService,
              private errorNotificationService: ErrorNotificationService,) { }

  ngOnInit() {
    this.id_user = parseInt(this._Activatedroute.snapshot.paramMap.get("id"), 10);
    this.loadingService.showLoading().then(
      () => {
        this.getPlateaux(this.id_user);
        this.getUtilisateur(this.id_user);
      }
    );
  }

  getPlateaux(id_user: number) {
    this.loading_plateaux = true;
    this.plateauTechniqueService.getPlateauxTechniquesForOneUtilisateur(id_user).subscribe(
      data => {
        this.loading_plateaux = false;
        let infos = data;
        this.plateaux = infos["hydra:member"];
        this.checkLoading();
      },
      () => {
        this.loading_plateaux = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getUtilisateur(id_user: number) {
    this.loading_utilisateur = true;

    this.utilisateurService.getInfosUnUtilisateur(id_user).subscribe(
      data => {
        this.loading_utilisateur = false;
        let utilisateur = data["hydra:member"];
        this.nom_utilisateur = utilisateur[3];
        this.prenom_utilisateur = utilisateur[4];
        this.checkLoading();
      },
      () => {
        this.loading_utilisateur = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getReponsesOnePlateau(id_plateau: number) {
    this.loadingService.showLoading().then(
      () => {
        this.reponseService.getForOneUserAndOnePlateau(this.id_user, id_plateau).subscribe(
          data => {
            this.loading_utilisateur = false;
            this.reponses = data["hydra:member"];
            this.checkLoading();
            this.modal.present();
          },
          () => {
            this.loading_utilisateur = false;
            this.checkLoading();
            this.errorNotificationService.presentGeneralErrorAlert(
              true,
              "Erreur",
              "Un problème a été rencontré lors du chargement des données",
              "Ok"
            );
          }
        )
      }
    );

    this.loading_utilisateur = true;
  }

  checkLoading() {
    if(!this.loading_plateaux
      && !this.loading_utilisateur
      && !this.loading_reponses) {
        this.loadingService.dismissLoading();
    }
  }

  fermeModale() {
    this.modal.dismiss(null, 'cancel');
  }
}

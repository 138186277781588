// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#div-nouveau {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#div-nouveau ion-button {
  margin-left: 16px;
}

.container-fluid {
  overflow: auto;
}

table {
  width: 90%;
  margin: 0 5% 2% 5%;
}

tr {
  border: 1px solid black;
  text-align: center;
}

th, td {
  padding: 8px 24px 8px 24px;
}

#modal-content {
  --padding-top: 24px;
  --padding-start: 24px;
  --padding-end: 24px;
}

h1 {
  margin-left: 10%;
}

ion-item {
  width: 76%;
  margin-left: 12%;
}

#container-boutons-form {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  margin-left: 10%;
  padding-top: 8px;
}

#container-boutons-form ion-button {
  margin-left: 8px;
  margin-right: 8px;
}

.div-back {
  width: 90%;
  margin-left: 5%;
  text-align: right;
  padding-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/intervenant/intervenant.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,UAAA;EACA,kBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,mBAAA;EACA,qBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,UAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,yBAAA;EACA,UAAA;EACA,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,UAAA;EACA,eAAA;EACA,iBAAA;EACA,oBAAA;AACJ","sourcesContent":["#div-nouveau {\r\n    padding: 16px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n#div-nouveau ion-button {\r\n    margin-left: 16px;\r\n}\r\n\r\n.container-fluid {\r\n    overflow: auto;\r\n}\r\n\r\ntable {\r\n    width: 90%;\r\n    margin: 0 5% 2% 5%;\r\n}\r\n\r\ntr {\r\n    border: 1px solid black;\r\n    text-align: center;\r\n}\r\n\r\nth, td {\r\n    padding: 8px 24px 8px 24px;\r\n}\r\n\r\n#modal-content {\r\n    --padding-top: 24px;\r\n    --padding-start: 24px;\r\n    --padding-end: 24px;\r\n}\r\n\r\nh1 {\r\n    margin-left: 10%;\r\n}\r\n\r\nion-item {\r\n    width: 76%;\r\n    margin-left: 12%;\r\n}\r\n\r\n#container-boutons-form {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    width: 80%;\r\n    margin-left: 10%;\r\n    padding-top: 8px;\r\n}\r\n\r\n#container-boutons-form ion-button {\r\n    margin-left: 8px;\r\n    margin-right: 8px;\r\n}\r\n\r\n.div-back {\r\n    width: 90%;\r\n    margin-left: 5%;\r\n    text-align: right;\r\n    padding-bottom: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { QuestionsService } from 'src/app/services/Questions/questions.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;

  private questions: any[];
  loading_questions: boolean=false;
  mode_edition:boolean=true;
  intitule_nouveau:string;
  intitule_edition:string;
  index_question: number;
  url_edition:string;

  nouveauForm = new FormGroup({
    intitule_nouveau: new FormControl('', [Validators.required, Validators.maxLength(255)]),
  });

  editionForm = new FormGroup({
    intitule_edition: new FormControl('', [Validators.required, Validators.maxLength(255)]),
  });

  constructor(private questionService: QuestionsService,
    private loadingService: LoadingService,
    private errorNotificationService: ErrorNotificationService,
    private toastService: ToastService) { }

    ngOnInit() {
      this.loadingService.showLoading().then(
        () => {
          this.getQuestions()
        }
      );
    }
  
    getQuestions() {
      this.loading_questions = true;
      this.questionService.getQuestions().subscribe(
        data => {
          let infos = data;
          this.questions = infos["hydra:member"];
          this.loading_questions = false;
          this.checkLoading();
        },
        () => {
          this.loading_questions = false;
          this.checkLoading();
          this.errorNotificationService.presentGeneralErrorAlert(
            true,
            "Erreur",
            "Un problème a été rencontré lors du chargement des données",
            "Ok"
          );
        }
      )
    }
  
    checkLoading() {
      if(!this.loading_questions) {
          this.loadingService.dismissLoading();
      }
    }
  
    cancel() {
      this.modal.dismiss(null, 'cancel');
    }
  
    ouvreModaleNouveau() {
      this.mode_edition = false;
      this.modal.present();
    }
  
    ouvreModaleEdition(question:any) {
      this.mode_edition = true;
      this.url_edition = question["@id"];
  
      this.editionForm.patchValue({
        "intitule_edition": question.intitule
      });
  
      this.modal.present();
      this.index_question = this.questions.lastIndexOf(question);
    }
  
    supprimeAlert(question:any) {
      this.index_question = this.questions.lastIndexOf(question);
      this.errorNotificationService.presentGeneralErrorAlert(
        true,
        "Attention",
        "êtes vous sur de vouloir supprimer cet élément ?",
        "Oui",
        this.supprime.bind(this),
        [question["@id"]]
      );
    }
  
    submitNouveau() {
      this.loadingService.showLoading();
      const formValues = this.nouveauForm.value;
  
      this.questionService.cree(formValues).subscribe(
        data => {
          let nouvel_objet:any = data;
          this.loadingService.dismissLoading();
  
          this.questions.push({
            "@id": nouvel_objet["@id"],
            "intitule": nouvel_objet.intitule,
          });
  
          this.modal.dismiss();
          this.nouveauForm.reset();
          this.toastService.presentToast("bottom", "Enregistré", "success");
        },
        error => {
          this.loadingService.dismissLoading();
          if(error.status === 422) {
            this.errorNotificationService.presentGeneralErrorAlert(
              false,
              "Erreur",
              error.error["hydra:description"],
              "Ok"
            );
          } else {
            this.toastService.presentToast("bottom", "Erreur: l'enregistrement a échoué", "danger");
          }
          this.loadingService.dismissLoading();
        }
      )
    }
  
    submitEdition() {
      this.loadingService.showLoading();
      const formValues = this.editionForm.value;
      this.questionService.edite(formValues, this.url_edition).subscribe(
        data => {
          let objet_edite:any = data;
          this.loadingService.dismissLoading();
  
          this.questions[this.index_question] = {
            "@id": objet_edite["@id"],
            "intitule": objet_edite.intitule,
          };
  
          this.modal.dismiss();
          this.toastService.presentToast("bottom", "Modifié", "success");
        },
        error => {
          this.loadingService.dismissLoading();
          if(error.status === 422) {
            this.errorNotificationService.presentGeneralErrorAlert(
              false,
              "Erreur",
              error.error,
              "Ok"
            );
          } else {
            this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
          }
        }
      )
    }
  
    supprime(lien:string) {
      this.loadingService.showLoading();
      this.questionService.supprime(lien[0]).subscribe(
        () => {
          this.loadingService.dismissLoading();
          this.questions.splice(this.index_question, 1);
          this.toastService.presentToast("bottom", "Supprimé", "success");
        },
        error => {
          this.loadingService.dismissLoading();
  
          if(error.status === 422) {
            this.errorNotificationService.presentGeneralErrorAlert(
              false,
              "Erreur",
              error.error,
              "Ok"
            );
          } else {
            this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
          }
        }
      )
    }
  
    getNouveauFormDisplay() {
      if(!this.mode_edition) {
        return "block";
      }
      return "none";
    }
  
    getEditionFormDisplay() {
      if(this.mode_edition) {
        return "block";
      }
      return "none";
    }
}

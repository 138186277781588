import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { ErrorNotificationService } from 'src/app/services/error-notification.service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  password:string;
  token:string;
  refresh_token:string;
  isAuthenticating:boolean=false;

  loginForm = new FormGroup({
    mail: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  constructor(private authenticationService: AuthenticationService,
              private loadingService: LoadingService,
              private userDataService: UserDataService,
              private errorNotificationService: ErrorNotificationService,
              private router:Router) {}

  ngOnInit() { }

  onSubmitFormLogin() {
    this.isAuthenticating = true;
    window.sessionStorage.clear();
    this.loadingService.showLoading();
    const formValues = this.loginForm.value;
    this.authenticationService.login(formValues['mail'], formValues['password']).subscribe(
      data => {
        let infos_log:any = data;
        window.sessionStorage.setItem('token', infos_log.token);
        window.sessionStorage.setItem('refreshToken', infos_log.refresh_token);
        this.userDataService.getUserData().subscribe(
          data => {
            let objet_data = data;
            let objet_user:any = objet_data["data"];
            let roles:any = objet_user.roles;
            this.isAuthenticating = false;

            if(roles.includes("ROLE_ADMIN")) {
              window.sessionStorage.setItem('role', "ROLE_ADMIN");

              if(roles.includes("ROLE_CREATEUR_PARCOURS")) {
                window.sessionStorage.setItem('role', "ROLE_CREATEUR_PARCOURS");
              }
              if(roles.includes("ROLE_ALINE")) {
                window.sessionStorage.setItem('role', "ROLE_ALINE");
              }
              if(roles.includes("ROLE_FORMATRICES")) {
                window.sessionStorage.setItem('role', "ROLE_FORMATRICES");
              }
              if(roles.includes("ROLE_SECRETAIRE_FORMATION")) {
                window.sessionStorage.setItem('role', "ROLE_SECRETAIRE_FORMATION");
              }
              if(roles.includes("ROLE_SUPER_ADMIN")) {
                window.sessionStorage.setItem('role', "ROLE_SUPER_ADMIN");
              }

              this.router.navigate(['admin_main']);//redirection post login
            } else {
                this.errorNotificationService.presentGeneralErrorAlert(
                  false,
                  "Erreur",
                  "Vous n'avez pas les privilèges requis",
                  "Ok"
                  );
            }
            this.loadingService.dismissLoading();
          },
          () => {
            this.isAuthenticating = false;
            this.loadingService.dismissLoading();
            this.errorNotificationService.presentGeneralErrorAlert(
              false,
              "Erreur",
              "identifiants incorrects",
              "Ok"
              );
          }
        )
      },
      error => {
        this.isAuthenticating = false;
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentHttpStatusErrorAlert(error.status);
      }
    )
  }
}


import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { LoadingService } from 'src/app/services/loading.service';
import { DescriptionCustomService } from 'src/app/services/DescriptionCustom/description-custom.service';
import { ToastService } from '../../services/Toast/toast.service';
import { GeneralService } from 'src/app/services/General/general.service';

@Component({
  selector: 'app-template-form-description-custom',
  templateUrl: './template-form-description-custom.component.html',
  styleUrls: ['./template-form-description-custom.component.scss'],
})
export class TemplateFormDescriptionCustomComponent implements OnInit {
  @Input("utilisateurObjectif") utilisateurObjectif:any;
  @Input("descriptionCustomId") descriptionCustomId:any;
  @Input("intitule") intitule:string;
  @Input("EditionMode") editionMode:boolean;
  @Input("isValidated") isValidated?:boolean;
  @Input("adminMode") adminMode?:boolean=false;
  @Output() creationEvent = new EventEmitter();
  @Output() editDoneEvent = new EventEmitter<string>();

  public creationSubmitted:boolean=false;
  public editSubmitted:boolean=false;

  descriptionCustomForm = new FormGroup({
    nom: new FormControl('', [Validators.required, Validators.maxLength(255)])
  });

  constructor(private errorNotificationService:ErrorNotificationService,
    private descriptionCustomService:DescriptionCustomService,
    private loadingService:LoadingService,
    private toastService: ToastService,
    private generalService:GeneralService
  ) { }

  ngOnInit() {
    if(!!this.intitule && (!!this.isValidated || this.adminMode)) {
      this.descriptionCustomForm.patchValue({
        "nom": this.intitule
      });
    }
  }

  warning() {
    if(!this.generalService.checkIsAdmin()) {
      this.errorNotificationService.presentGeneralErrorAlert(
        true,
        "Attention",
        "Tout descriptif personnalisé devra être validé par un administrateur avant d'être visible",
        "Oui",
        this.submit.bind(this),
        [this.editionMode]
      );
    } else {
      this.submit(this.editionMode, true);
    }
  }

  submit(editionMode:boolean, adminMode:boolean=false) {
    this.loadingService.showLoading();

    if(!adminMode) {
      editionMode = editionMode[0]
    }

    if(!editionMode) {
      this.create();
    } else {
      this.edit();
    }    
  }

  create() {
    const formValue = this.descriptionCustomForm.value;

    this.descriptionCustomService.create(this.utilisateurObjectif.id, formValue.nom).subscribe(
      data => {
        this.creationSubmitted = true;
        this.loadingService.dismissLoading();
        this.creationEvent.next(data["data"]);
        this.descriptionCustomForm.patchValue({
          "nom": ""
        });
        this.toastService.presentToast(
          "middle",
          "enregistré",
          "success"
        );
      },
      () => {
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          false,
          "Erreur",
          "L'enregistrement de la description personalisée a échoué",
          "Ok"
        );
      }
    )
  }

  edit() {
    const formValue = this.descriptionCustomForm.value;
    let state = false;

    if(this.adminMode && this.isValidated) {
      state = this.isValidated;
    }

    this.descriptionCustomService.edit(this.descriptionCustomId,formValue.nom, state).subscribe(
      () => {
        this.editSubmitted = true;
        this.loadingService.dismissLoading();
        this.editDoneEvent.next(formValue.nom);
        this.toastService.presentToast(
          "middle",
          "edité",
          "success"
        );
      },
      () => {
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          false,
          "Erreur",
          "L'edition de la description personalisée a échoué",
          "Ok"
        );
      }
    )
  }
}
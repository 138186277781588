import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UtilisateurObjectifService } from 'src/app/services/UtilisateurObjectif/utilisateur-objectif.service';
import { ToastService } from 'src/app/services/Toast/toast.service';
import { DescriptionObjectifCustomService } from 'src/app/services/DescriptionObjectifCustom/description-objectif-custom.service';

@Component({
  selector: 'app-template-form-descriptions-sous-objectifs-atelier',
  templateUrl: './template-form-descriptions-sous-objectifs-atelier.component.html',
  styleUrls: ['./template-form-descriptions-sous-objectifs-atelier.component.scss'],
})
export class TemplateFormDescriptionsSousObjectifsAtelierComponent implements OnInit {
  @Input("descriptionAteliers") descriptionAteliers:any;
  @Input("sousObjectif") sousObjectif:any;
  @Input("idAtelierObjectifPrincipal") idAtelierObjectifPrincipal:number;
  @Input("userId") userId:number;
  @Input("etapeId") etapeId:number;
  @Input("niveauId") niveauId:number;

  private utilisateurObjectifs:any;
  autreDescriptionAtelier:boolean=false;
  autreDescriptionAtelierEdite:boolean=false;

  atelierForm = new FormGroup({
    atelierEdition: new FormControl('')
  });

  descriptionAutreAtelierForm = new FormGroup({
    atelierAutreEdition: new FormControl('', [Validators.required, Validators.maxLength(255)])
  });

  constructor(
    private errorNotificationService:ErrorNotificationService,
    private loadingService:LoadingService,
    private descriptionObjectifCustomService:DescriptionObjectifCustomService,
    private utilisateurObjectifService:UtilisateurObjectifService,
    private toast:ToastService,
  ) { }

  ngOnInit() {
    this.utilisateurObjectifs = this.sousObjectif.UtilisateurObjectifs;
    //on ne garde que les UtilisateurObjectif lies au SousObjectif en cours
    this.utilisateurObjectifs = this.utilisateurObjectifs.filter(element => element.utilisateur.id === this.userId);
    this.utilisateurObjectifs = this.utilisateurObjectifs[0];
    this.setFormValues();
  }

  setFormValues() {
    if(!!this.utilisateurObjectifs) {
      if(!!this.utilisateurObjectifs.descriptionObjectifCustom
        && this.utilisateurObjectifs.descriptionObjectifCustom["estValide"]) {
          this.atelierForm.patchValue({
            "atelierEdition": " Autre "
          });

          this.descriptionAutreAtelierForm.patchValue({
            "atelierAutreEdition": this.utilisateurObjectifs.descriptionObjectifCustom.nom
          });
          this.autreDescriptionAtelier = true;
      } else if(!!this.utilisateurObjectifs.descriptionAtelier) {
        this.atelierForm.patchValue({
          "atelierEdition": this.utilisateurObjectifs.descriptionAtelier
        });
      }
    }
  }

  descriptionAtelierChange(event) {
    if(event.detail.value === " Autre ") {
      this.alertError(
        "",
        "Attention",
        "Vore objectif personnalisé devra être validé par un administrateur pour être visible"
      );
      this.autreDescriptionAtelier = true;
      //reset du textArea
      this.descriptionAutreAtelierForm.patchValue({
        "atelierAutreEdition": ""
      });
    } else { //cas pas "autre"
      this.autreDescriptionAtelier = false;

      //si l'utilisateurObjectif n'existe pas, on le cree
      if(!!!this.utilisateurObjectifs) {
        this.creeDescriptif(event.detail.value.id);
      } else {//sinon, c'est une edition
        //dans le cas ou on passe d'une DescriptionAtelier à une description custom sans valider
        //pour revenir à la DescriptionAtelier de base, on ne fait pas de changement
        if(!!this.utilisateurObjectifs["descriptionAtelier"]
          &&event.detail.value.id !== this.utilisateurObjectifs["descriptionAtelier"]["id"]
          || this.utilisateurObjectifs["descriptionAtelier"]) {
          //si on cherche à lier une descriptionAtelier mais 
          //qu'une descriptionObjectifCustom est deja liee
          //on supprime cette derniere
          this.supprimeDescriptionCustom();
        }

        this.editeDescriptif("atelier", [event.detail.value["@id"]]);
      }
    }
  }

  submitAutreDescriptionAtelier() {
    this.loadingService.showLoading().then(
      () => {
        this.submitAutreDescription(this.descriptionAutreAtelierForm.value.atelierAutreEdition);
      }
    );
  }

  submitAutreDescription(description:string) {
    //si aucune DescriptionObjectifCustom n'a encore été
    //liée à l'ObjectifUtilisateur en cours, on le crée
    if(this.utilisateurObjectifs["descriptionObjectifCustom"] === null) {
      this.descriptionObjectifCustomService.cree(
        this.utilisateurObjectifs["id"],
        description,
        false,
        "user"
      ).subscribe(
        (data) => {
          this.utilisateurObjectifs["descriptionObjectifCustom"] = {
            id: data["id"],
            nom: data["nom"],
            estValide: data["estValide"]
          };

          this.loadingService.dismissLoading();
          this.toast.presentToast("bottom", "Enregistré", "success");
        },
        error => {
          //console.log(error);
          this.loadingService.dismissLoading();
          this.alertError("enregistrement");
        }
      );
    } else {
      this.descriptionObjectifCustomService.edite(
        this.utilisateurObjectifs["descriptionObjectifCustom"].id,
        this.utilisateurObjectifs["id"],
        description,
        false,
        "user"
      ).subscribe(
        () => {
          this.loadingService.dismissLoading();
          this.toast.presentToast("bottom", "Enregistré", "success");
        },
        error => {
          this.loadingService.dismissLoading();
          this.alertError("enregistrement");
        }
      )
    }
  }

  clickOnObjectifCustom(event, atelier:string="atelier") {
    let message = "Toute modification de votre objectif personnalisé devra être validé pour être visible.";
    message += " Dans l'attente de cette validation, l'objectif par défaut sera affiché";

    if(event.target.value !== "" && !this.autreDescriptionAtelierEdite) {
      this.autreDescriptionAtelierEdite = true;
      this.alertError(
        "",
        "Attention",
        message
      );
    }
  }
  
  creeDescriptif(descriptionAtelierId:number) {
    this.loadingService.showLoading();
    this.utilisateurObjectifService.creation(
      descriptionAtelierId,
      "atelier",
      this.sousObjectif.id,
      this.niveauId,
      this.etapeId
    ).subscribe(
      data => {
        this.loadingService.dismissLoading();
      },
      error => {
        this.loadingService.dismissLoading();
        this.alertError("enregistrement");
        this.loadingService.dismissLoading();
      }
    )
  }

  editeDescriptif(type:string, liens:string[]) {
    this.loadingService.showLoading();

    this.utilisateurObjectifService.editeDescriptifs(
      this.utilisateurObjectifs["id"], type, liens, this.idAtelierObjectifPrincipal
    ).subscribe(
      data => {
        //let infos = data;
        this.utilisateurObjectifs["descriptionAtelier"] = data["descriptionAtelier"];
        this.loadingService.dismissLoading();
      },
      error => {
        this.loadingService.dismissLoading();
        this.alertError("chargement");
        this.loadingService.dismissLoading();
      }
    )
  }

  supprimeDescriptionCustom() {
    if(this.utilisateurObjectifs["descriptionObjectifCustom"] !== null) {
      this.descriptionObjectifCustomService.supprime(
        this.utilisateurObjectifs["descriptionObjectifCustom"].id
      ).subscribe(
        data => {
          //let infos = data;
          this.utilisateurObjectifs["descriptionObjectifCustom"] = null;
        },
        error => {
          this.alertError("chargement");
        }
      )
    }
  }

  alertError(type:string, titre?:string, message?:string) {
    titre = type === null ? "Erreur" : titre;

    switch(type) {
      case "enregistrement":
        message = "Un problème a été rencontré lors de l'enregistrement des données";
        break;

      case "chargement":
        message = "Un problème a été rencontré lors du chargement des données";
        break;

      default:
        break;
    }
    
    this.errorNotificationService.presentGeneralErrorAlert(
      false,
      titre,
      message,
      "Ok"
    );
  }

  //pour le formulaire d'edition
  //coche les checkboxes des options selectionnees
  compareWith(o1, o2) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }
}
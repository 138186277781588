// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#div-title {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px 0;
}

h1 {
  margin-top: 16px;
  text-align: center;
}

.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}

#main-container {
  margin: 24px;
  text-align: center;
}

ion-button {
  width: 30%;
  margin: 1%;
  text-transform: none;
}

@media (max-width: 500px) {
  #div-title {
    text-align: center;
    flex-direction: column;
  }
  .disconnect {
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 1200px) {
  ion-button {
    width: 45%;
  }
}
@media (max-width: 800px) {
  ion-button {
    width: 90%;
    margin-bottom: 8px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/admin/main-admin/main-admin.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,sBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,UAAA;EACA,UAAA;EACA,oBAAA;AACJ;;AAEA;EACI;IACI,kBAAA;IACA,sBAAA;EACN;EACE;IACI,gBAAA;IACA,iBAAA;IACA,kBAAA;EACN;AACF;AAEA;EACI;IACI,UAAA;EAAN;AACF;AAGA;EACI;IACI,UAAA;IACA,kBAAA;EADN;AACF","sourcesContent":["#div-title {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    padding: 20px 0 20px 0;\r\n}\r\n\r\nh1 {\r\n    margin-top: 16px;\r\n    text-align: center;\r\n}\r\n\r\n.vertical-center {\r\n    margin-top: auto;\r\n    margin-bottom: auto;\r\n}\r\n\r\n#main-container {\r\n    margin: 24px;\r\n    text-align: center;\r\n}\r\n\r\nion-button {\r\n    width: 30%;\r\n    margin: 1%;\r\n    text-transform: none;\r\n}\r\n\r\n@media (max-width: 500px) {\r\n    #div-title {\r\n        text-align: center;\r\n        flex-direction: column;\r\n    }\r\n    .disconnect {\r\n        margin-top: 16px;\r\n        margin-left: auto;\r\n        margin-right: auto;\r\n    }\r\n}\r\n\r\n@media (max-width: 1200px) {\r\n    ion-button {\r\n        width: 45%;\r\n    }\r\n}\r\n\r\n@media (max-width: 800px) {\r\n    ion-button {\r\n        width: 90%;\r\n        margin-bottom: 8px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { AbstractControl, ValidatorFn } from "@angular/forms";

export function dateRangeValidator(): ValidatorFn {
    let minBirthDate;
    let maxBirthDate;
    let maxAge = 100;
    let minAge = 18;
    
    return (control: AbstractControl): {[key:string]:boolean}|null => {
        getBirthDateLimit();
        
        if(control.value < minBirthDate) {
            return {"old": true};
        } else if(control.value > maxBirthDate) {
            return {"young": true};
        }

        return null;//si controle ok
    }

    function getBirthDateLimit() {
        minBirthDate = new Date();
        minBirthDate.setFullYear(minBirthDate.getFullYear() - maxAge);
        minBirthDate = minBirthDate.getFullYear() + "-" + minBirthDate.getMonth()+1
        +"-" + minBirthDate.getDate();
        
        maxBirthDate = new Date();
        maxBirthDate.setFullYear(maxBirthDate.getFullYear() - minAge);
        maxBirthDate = maxBirthDate.getFullYear() + "-" + maxBirthDate.getMonth()+1
        +"-" + maxBirthDate.getDate();
    }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { MatTable } from '@angular/material/table';
import { DescriptionCompetenceCleService } from 'src/app/services/DescriptionCompetenceCle/description-competence-cle.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastService } from '../../services/Toast/toast.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-description-competence-cle',
  templateUrl: './description-competence-cle.component.html',
  styleUrls: ['./description-competence-cle.component.scss'],
})
export class DescriptionCompetenceCleComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild("competencesClesTable") competencesClesTable!: MatTable<any>;

  private descriptionsCompetencesCles:any[];
  displayedColumns: string[] = [
    "description",
    "editForm",
    "deleteForm"
  ];
  creationMode:boolean=true;
  nowLoading:boolean=true;

  createForm = new FormGroup({
    nom: new FormControl('', [Validators.required, Validators.maxLength(255)]),
  });

  editForm = new FormGroup({
    id: new FormControl(),
    nom: new FormControl('', [Validators.required, Validators.maxLength(255)]),
  });

  constructor(private descriptionCompetenceCleService:DescriptionCompetenceCleService,
    private errorNotificationService:ErrorNotificationService,
    private loadingService:LoadingService,
    private toastService:ToastService) { }

  ngOnInit() {
    this.getAll();
  }

  getAll() { 
    this.descriptionCompetenceCleService.getAll().subscribe(
      data => {
        this.descriptionsCompetencesCles = data["hydra:member"];
        this.nowLoading = false;
      },
      error => {
        //console.log(error);
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
        this.nowLoading = false;
      }
    )
  }

  closeModal() {
    this.modal.dismiss();
  }

  showModal(modeCreation:boolean, element:any=null) {
    this.creationMode = modeCreation;

    if(!modeCreation && element !== null) {
      this.editForm.patchValue({
        "id": element.id,
        "nom": element.nom,
      });
    }

    this.modal.present();
  }

  submitCreation() {
    this.loadingService.showLoading();
    const formValues = this.createForm.value;

    if(!!formValues.nom) {
      this.descriptionCompetenceCleService.create(formValues.nom).subscribe(
        data => {
          this.descriptionsCompetencesCles.push(data);
          this.competencesClesTable.renderRows();
          this.closeModal();
          this.loadingService.dismissLoading();
          this.toastService.presentToast("bottom", "Description enregistrée", "success");
        },
        error => {
          //console.log(error);
          this.errorNotificationService.presentGeneralErrorAlert(
            true,
            "Erreur",
            "Un problème a été rencontré lors de l'enregistrement des données",
            "Ok"
          );
          this.loadingService.dismissLoading();
        }
      )
    }
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editForm.value;

    if(!!formValues.nom && !!formValues.id) {
      this.descriptionCompetenceCleService.edit(formValues.id, formValues.nom).subscribe(
        () => {
          let modifiedElement = this.descriptionsCompetencesCles.find(
            element => element.id === formValues.id
          );
          let index = this.descriptionsCompetencesCles.indexOf(modifiedElement);
          this.descriptionsCompetencesCles[index]["nom"] = formValues.nom;
          this.competencesClesTable.renderRows();
          this.closeModal();
          this.loadingService.dismissLoading();
          this.toastService.presentToast("bottom", "Description editée", "success");
        },
        error => {
          //console.log(error);
          this.errorNotificationService.presentGeneralErrorAlert(
            true,
            "Erreur",
            "Un problème a été rencontré lors de l'enregistrement des données",
            "Ok"
          );
          this.loadingService.dismissLoading();
        }
      )
    }
  }

  deleteWarning(elementId:number) {
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cette description de compétence clé ?",
      "Oui",
      this.remove.bind(this),
      [elementId]
    );
  }

  remove(id:number) {
    this.loadingService.showLoading();
    this.descriptionCompetenceCleService.delete(id[0]).subscribe(
      () => {
        let removedElement = this.descriptionsCompetencesCles.find(
          element => element.id === id[0]
        );
        let index = this.descriptionsCompetencesCles.indexOf(removedElement);
        this.descriptionsCompetencesCles.splice(index, 1);
        this.competencesClesTable.renderRows();
        this.closeModal();
        this.loadingService.dismissLoading();
        this.toastService.presentToast("bottom", "Description supprimée", "success");
      },
      error => {
        //console.log(error);
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors de la suppression des données",
          "Ok"
        );
        this.loadingService.dismissLoading();
      }
    )
  }
}
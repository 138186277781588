import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {

  constructor(private httpClient:HttpClient) { }
  
  getWay(startPoint:string, endPoint:string, travelMode:string) {  
    const url = 'https://routes.googleapis.com/directions/v2:computeRoutes';
    const httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'X-Goog-Api-Key': 'AIzaSyDS9-_jHd3ZJyh0dHWI6MxbmANiVmeutdM',
        'X-Goog-Api-Key': environment.googleMapApiKey,
        'X-Goog-FieldMask': 'routes.distanceMeters,routes.localizedValues.distance.text,routes.localizedValues.duration.text,routes.polyline'
        //'X-Goog-FieldMask': '*'
      })
    };

    //cle 1
    //AIzaSyAxTsB912k6BWaKABP7RdNAFuTpkdkNG9o //fine
    //cle 2
    //AIzaSyDS9-_jHd3ZJyh0dHWI6MxbmANiVmeutdM //fine

    const data = {
      "origin":{
        "address": startPoint
      },
      "destination":{
        "address": endPoint
      },
      "travelMode": travelMode
    };
    /*
    TRAVEL_MODE_UNSPECIFIED 	Aucun mode de transport spécifié. La valeur par défaut est DRIVE.
    DRIVE 	En voiture de passagers.
    BICYCLE 	Voyagez à vélo.
    WALK 	Voyagez à pied.
    TWO_WHEELER 	Véhicule deux-roues à moteur. Par exemple : moto. Notez que cela diffère du mode de transport BICYCLE, qui couvre le mode manuel.
    TRANSIT 	Trajets en transports en commun, le cas échéant.
    */

    return this.httpClient.post(url, data, httpHeaders);
  }

  decodePolyline(value) {
    var values = this.decodeIntegers(value);
    var points = [];

    for( var i = 0; i < values.length; i += 2 ) {
      points.push([
        ( values[ i + 0 ] += ( values[ i - 2 ] || 0 ) ) / 1e5,
        ( values[ i + 1 ] += ( values[ i - 1 ] || 0 ) ) / 1e5,
      ])
    }
  
    return points
  }

  decodeIntegers(value) {
    var values = [];
    var byte = 0;
    var current = 0;
    var bits = 0;

    for(var i = 0; i < value.length; i++) {
      byte = value.charCodeAt( i ) - 63
      current = current | (( byte & 0x1F ) << bits )
      bits = bits + 5
  
      if( byte < 0x20 ) {
        values.push( this.decodeSign( current ) )
        current = 0
        bits = 0
      }
    }
    return values;
  }

  decodeSign(value) {
    return value & 1 ? ~( value >>> 1 ) : ( value >>> 1 );
  }
}

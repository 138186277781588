import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class RecompenseService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

    /**
     * Recupere une promesse de la liste des recompenses.
     * @returns 
     */
    getRecompenses() {
    const url = this.constants.apiGetPostEndpoint + '/recompenses';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  /**
   * Permet de créer une nouvelle recompense.
   * @param recompense 
   * @returns 
   */
  cree(recompense: any) {    
    const url = this.constants.apiGetPostEndpoint + "/recompenses";
    const httpHeaders = this.headerService.getPostHeader();

    return this.httpClient.post(url, {
      "intitule": recompense["intitule_nouveau"],
      "code": recompense["code_nouveau"],
      "typeRecompense": recompense["type_nouveau"]["@id"],
      "entreprise": recompense["entreprise_nouveau"]["@id"],
      "ville": recompense["ville_nouveau"],
      "imageRecompense": recompense["image_recompense_nouveau"],
      "imageCode": recompense["image_code_nouveau"]
    }, httpHeaders);
  }

  /**
   * Permet de d'éditer une nouvelle recompense.
   * @param recompense 
   * @param lien 
   * @returns 
   */
  edite(recompense:any, lien: string) {    
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();

    return this.httpClient.patch(url, {
      "intitule": recompense["intitule_edition"],
      "code": recompense["code_edition"],
      "typeRecompense": recompense["type_edition"],
      "entreprise": recompense["entreprise_edition"],
      "ville": recompense["ville_edition"],
      "imageRecompense": recompense["image_recompense_edition"],
      "imageCode": recompense["image_code_edition"]
    }, httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
/*
regroupe les appels sur les API externes
*/
export class ExternService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getLocation(address:string, town:string) {
    let adressWords = address.split(" ");
    let finalAdress;

    for(let adressWord of adressWords) {
      finalAdress += adressWord + "+";
    }

    finalAdress += town;
    const url = "https://api-adresse.data.gouv.fr/search/?q=" + finalAdress;
    return this.httpClient.get(url);
  }

  getYPKFormateurs() {
    const url = "https://adep-roubaix.hop3team.com/formateurs";
    //const httpHeaders = this.headerService.getEmptyHeader();

    const httpHeaders = {
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };

    return this.httpClient.get(url, httpHeaders);
  }

  getAllYPKUsers() {
    //urlYPK  @TODO: securiser cle API

    /*const url = this.constants.urlYPK + 'learners';
    const httpHeaders = this.headerService.getYPKHeader();
    return this.httpClient.get(url, httpHeaders);*/

    //------------------------------------------




    // return this.httpClient.get("https://adep-roubaix.hop3team.com/api/v1/learners");
    //return this.httpClient.get("https://google.fr"); //nope...


    //marche
    /*let address = "94 rue leon marlot";
    let town = "roubaix";
    let adressWords = address.split(" ");
    let finalAdress;

    for(let adressWord of adressWords) {
      finalAdress += adressWord + "+";
    }

    finalAdress += town;
    const url = "https://api-adresse.data.gouv.fr/search/?q=" + finalAdress;
    return this.httpClient.get(url);*/

    //marche aussi
    // const url = "https://pokeapi.co/api/v2/pokemon/ditto";
    // return this.httpClient.get(url);

    //nope - cors
    // const url = "https://adep-roubaix.hop3team.com/api/v1/learners";
    // return this.httpClient.get(url);

    //test api public -- nope
    // const url = "https://adep-roubaix.hop3team.com/formateurs";
    // const httpHeaders = this.headerService.getYPKHeader();
    // return this.httpClient.get(url, httpHeaders);


    const url = "https://adep-roubaix.hop3team.com/formateurs";
    const httpHeaders = this.headerService.getManipHeader();
    return this.httpClient.get(url, httpHeaders);





  }


  getReservioBusinesses() {
    // const url = this.constants.urlReservio + "businesses/" + this.constants.reservioId;
    // const httpHeaders = this.headerService.getReservioHeader();
    // return this.httpClient.get(url, httpHeaders);


    // return this.httpClient.get('https://pokeapi.co/api/v2/pokemon/ditto');


    return this.httpClient.get('http://loalhost:8000/testReservio');
  }

  //useless ?
  /*getAllLocalReservioEvents() {
    let url = this.constants.apiGetPostEndpoint + '/getAllLocalEvents';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }*/

  getLocalEventsByDateAndResource(datetime:string, resourceIdReservio:string) {
    const url = this.constants.apiGetPostEndpoint + "/customSearchEvent";
    const httpHeaders = this.headerService.getPostHeader();
    let parameters = {
      "resourceIdReservio": resourceIdReservio,
	    "startDateTime": datetime
    };
    return this.httpClient.post(url, parameters, httpHeaders);
  }

  getAllLocalIncommingEvents(resourceId:number=null) {
    let url = this.constants.apiGetPostEndpoint + '/getAllLocalIncommingEvents';

    if(resourceId !== null) {
      url += "/" + resourceId;
    }

    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getAllLocalIncommingEventsForUser(userReservioId:string) {
    let url = this.constants.apiGetPostEndpoint + '/getAllLocalIncommingEventsForUser/' + userReservioId;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getReservioEventsByServiceReservioId(serviceIdReservio:string) {
    let url = this.constants.apiGetPostEndpoint + '/getEventsByService/' + serviceIdReservio;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  //no use ?
  /*getAllLocalEventsWithoutUtilisateur(utilisateurIdReservio:string) {
    let url = this.constants.apiGetPostEndpoint + '/getAllLocalEventsWithoutUtilisateur/' + utilisateurIdReservio;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }*/

  getAllLocalReservioServices() {
    let url = this.constants.apiGetPostEndpoint + '/getAllLocalServices';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  createEventAndBooking(
    startTime:string, endTime:string,
    resourceIdReservio:string, userIdReservio:string
  ) {
    const url = this.constants.apiGetPostEndpoint + "/createEventAndBooking";
    const httpHeaders = this.headerService.getPostHeader();
    let parameters = {
      "startTime": startTime,
	    "endTime": endTime,
      "resourceIdReservio": resourceIdReservio,
      "userIdReservio": userIdReservio
    };

    return this.httpClient.post(url, parameters, httpHeaders);
  }

  deleteEventAndBooking(
    eventId:number, bookingId:number
  ) {
    const url = this.constants.apiGetPostEndpoint + "/deleteEventAndBooking";
    const httpHeaders = this.headerService.getPostHeader();
    let parameters = {
      "eventId": eventId,
	    "bookingId": bookingId
    };

    return this.httpClient.post(url, parameters, httpHeaders);
  }

  createReservioBooking(userIdReservio:string, eventIdReservio:string) {
    const url = this.constants.apiGetPostEndpoint + "/createBooking";
    const httpHeaders = this.headerService.getPostHeader();
    let parameters = {
      "eventIdReservio": eventIdReservio,
	    "clientIdReservio": userIdReservio
    };
    return this.httpClient.post(url, parameters, httpHeaders);
  }

  cancelReservioBooking(bookingReservioId:string) {
    let url = this.constants.apiGetPostEndpoint + '/cancelBooking/' + bookingReservioId;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  editReservioBooking(bookingReservioId:string, state:string, stateChangedMessage:string=null) {
    const url = this.constants.apiPatchDeleteEndpoint + "/editBooking/" + bookingReservioId;
    const httpHeaders = this.headerService.getPatchHeader();
    return this.httpClient.patch(url, {
      state: state,
      stateChangedMessage: stateChangedMessage
    },httpHeaders);
  }

  getFrenchPublicHolidays() {
    let url = this.constants.apiGetPostEndpoint + '/getPublicHolidays';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getBuisinessHolidays() {
    let url = this.constants.apiGetPostEndpoint + '/getBuisinessHolidays';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }
}

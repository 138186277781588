// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connection-form {
  width: 50%;
  height: 80%;
  margin: auto;
  margin-top: 10%;
  margin-right: 25%;
}

.button-container {
  display: inline-block;
}

.example-button-row button,
.example-button-row a {
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/login/login.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;;EAEE,iBAAA;AACF","sourcesContent":[".connection-form {\r\n    width: 50%;\r\n    height: 80%;\r\n    margin: auto;\r\n    margin-top: 10%;\r\n    margin-right: 25%;\r\n}\r\n\r\n.button-container {\r\n    display: inline-block;\r\n}\r\n\r\n.example-button-row button,\r\n.example-button-row a {\r\n  margin-right: 8px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

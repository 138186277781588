import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { TokenManagementService } from '../Token/token-management.service';
import { Constants } from 'src/app/config/constants';

@Injectable({
  providedIn: 'root'
})
export class HeadersService {

  constructor(private tokenManagementService: TokenManagementService,
    private constants: Constants
  ) { }

  getLoginHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  getGetHeader() {
    let token = this.tokenManagementService.getToken();
    return {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
  }

  getPostHeader() {
    let token = this.tokenManagementService.getToken();
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
  }

  getOptions() {
    let token = this.tokenManagementService.getToken();

    return {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'Accept': 'text/plain, */*',
      }),
      responseType: "blob" as "json"
    };

  }

  getPatchHeader() {
    let token = this.tokenManagementService.getToken();
    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/merge-patch+json',
        Accept: 'application/ld+json'
      })
    };
  }

  getPutHeader() {
    let token = this.tokenManagementService.getToken();
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/ld+json'
      })
    };
  }

  getYPKHeader() {
    return {
      headers: new HttpHeaders({
        //'Access-Control-Allow-Origin': 'Allow', //nope
        //'Access-Control-Allow-Origin': '*', //nope
        //'access-control-allow-origin': '*',//nope

        // 'Accept': 'application/json',
        // 'Authorization': 'f46c2960f4aa67990ca67b6ae25a82d6a0b3db639a76ff035f7defc79c2b3a0a9a099cf33d091b1adcac918356c4e957569b7e405e02cc6f429a8fa7ac4b01bc'
      

        //test hop3team
        "Content-Type": "application/json;charset=utf-8",
        "Content_Length": 1417918,
        //"Connection": "keep-alive",
        "X-Frame-Options": "SAMEORIGIN",
        "X-XSS-Protection": 0,
        "X-Content-Type-Options": "nosniff",
        "X-Download-Options": "noopen",
        "X-Permitted-Cross-Domain-Policies": "none",
        "Referer-Policy": "strict-origin-when-cross-origin",
        "Vary": "Accept",
        'Authorization': 'f46c2960f4aa67990ca67b6ae25a82d6a0b3db639a76ff035f7defc79c2b3a0a9a099cf33d091b1adcac918356c4e957569b7e405e02cc6f429a8fa7ac4b01bc',

        //"Access-Control-Allow-Origin": "Allow",
        //"Access-Control-Allow-Origin": "*"
        //"Access-Control-Allow-Origin": "null"
        //"Access-Control-Allow-Origin": "https://adep-roubaix.hop3team.com"
      })
    };
  }

  getEmptyHeader() {
    return {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      }
    )};
  }

  getManipHeader() {
    return {
      headers: new HttpHeaders({
        'Accept': '*/*',
        'Access-Control-Allow-Origin': '*',
        'Host': 'https://adep-roubaix.hop3team.com'
      }
    )};
  }

  getReservioHeader() {
    return {
      headers: new HttpHeaders({
        'Authorization': this.constants.reservioToken,
        //'access-token': this.constants.reservioToken
        //'Access-Control-Allow-Origin': '*',
      })
    };
  }
}
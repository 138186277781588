// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  text-align: center;
  gap: 12px;
  color: white;
}
.header-card .header-card-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}

.color-culture {
  background-color: #D53E35;
}

.color-hobby {
  background-color: #8559DA;
}

.color-purchasing-power {
  background-color: #2D96D0;
}

.color-unknown {
  background-color: seagreen;
}`, "",{"version":3,"sources":["webpack://./src/app/components/reward-card-type/reward-card-type.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,SAAA;EACA,YAAA;AACJ;AAAI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAEN;;AAIE;EACE,yBAAA;AADJ;;AAIE;EACE,yBAAA;AADJ;;AAIE;EACE,yBAAA;AADJ;;AAIE;EACE,0BAAA;AADJ","sourcesContent":[".header-card {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    padding: 16px;\r\n    text-align: center;\r\n    gap: 12px;\r\n    color: white;\r\n    .header-card-title {\r\n      font-weight: 500;\r\n      font-size: 16px;\r\n      line-height: 100%;\r\n    }\r\n\r\n    \r\n  }\r\n  \r\n  .color-culture{\r\n    background-color: #D53E35;\r\n  }\r\n\r\n  .color-hobby{\r\n    background-color: #8559DA;\r\n  }\r\n\r\n  .color-purchasing-power{\r\n    background-color:#2D96D0;\r\n  }\r\n\r\n  .color-unknown{\r\n    background-color:seagreen;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

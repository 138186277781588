import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { NiveauEtudesService } from '../../services/NiveauEtudes/niveau-etudes.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';

@Component({
  selector: 'app-niveau-etudes',
  templateUrl: './niveau-etudes.component.html',
  styleUrls: ['./niveau-etudes.component.scss'],
})
export class NiveauEtudesComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;

  name: string;
  niveau_nouveau:string;
  url_edition:string;
  index_niveaux:number;
  mode_edition:boolean=true;
  private niveaux: any;

  nouveauForm = new FormGroup({
    niveau_nouveau: new FormControl('', [Validators.required, Validators.maxLength(255)]),
  });

  editionForm = new FormGroup({
    niveau_edition: new FormControl('', [Validators.required, Validators.maxLength(255)]),
  });

  constructor(private niveauEtudesService: NiveauEtudesService,
              private errorNotificationService: ErrorNotificationService,
              private loadingService: LoadingService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.getNiveauxEtude();
      }
    );
  }

  getNiveauxEtude() {
    this.niveauEtudesService.getNiveauxEtudes().subscribe(
      data => {
        let infos = data;
        this.niveaux = infos["hydra:member"];
        this.loadingService.dismissLoading();
      },
      error => {
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  ouvreModaleNouveau() {
    this.mode_edition = false;
    this.modal.present();
  }

  ouvreModaleEdition(niveau:any) {
    this.mode_edition = true;
    this.editionForm.patchValue({
      "niveau_edition": niveau.niveau
    });
    this.url_edition = niveau["@id"];
    this.modal.present();
    this.index_niveaux = this.niveaux.lastIndexOf(niveau);
  }

  supprimeAlert(niveau:any) {
    this.index_niveaux = this.niveaux.lastIndexOf(niveau);
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.supprime.bind(this),
      [niveau["@id"]]
    );
  }

  submitNouveau() {
    this.loadingService.showLoading();
    const formValues = this.nouveauForm.value;

    this.niveauEtudesService.cree(formValues).subscribe(
      data => {
        let nouvel_objet:any = data;
        this.loadingService.dismissLoading();

        this.niveaux.push({
          "@id": nouvel_objet["@id"],
          "niveau": nouvel_objet.niveau,
        });

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error["hydra:description"],
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'enregistrement a échoué", "danger");
        }
        this.loadingService.dismissLoading();
      }
    )
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;
    this.niveauEtudesService.edite(formValues, this.url_edition).subscribe(
      data => {
        let objet_edite:any = data;
        this.loadingService.dismissLoading();

        this.niveaux[this.index_niveaux] = {
          "@id": objet_edite["@id"],
          "niveau": objet_edite.niveau
        };

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Modifié", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    )
  }

  supprime(lien:string) {
    this.loadingService.showLoading();
    this.niveauEtudesService.supprime(lien[0]).subscribe(
      () => {
        this.loadingService.dismissLoading();
        this.niveaux.splice(this.index_niveaux, 1);
        this.toastService.presentToast("bottom", "Supprimé", "success");
      },
      error => {
        this.loadingService.dismissLoading();

        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
        }
      }
    )
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Constants } from '../config/constants';

import { Formule } from 'src/app/Models/formule';
import { FormuleType } from 'src/app/Models/formule-type';
import { Niveau } from '../Models/niveau';
import { Utilisateur } from '../Models/utilisateur';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  httpHeaders = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: new HttpHeaders({ Authorization: 'Bearer ' + this.authenticationService.getToken() })
  };

  user = new Utilisateur();
  formule = new Formule();
  formuleType = new FormuleType();
  levels: Niveau[] = [];
  currentLevel = new Niveau();

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private constants: Constants
  ) { }

  /*getUserData() {
    const url = this.constants.apiEndpoint + '/me';
    return this.httpClient.get(url, this.httpHeaders);
  }*/
  getUserData() {

    const httpHeaders2 = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: new HttpHeaders({ Authorization: 'Bearer ' + this.authenticationService.getToken() })
    };
    const url = this.constants.apiEndpoint + '/me';
    return this.httpClient.get(url, httpHeaders2);
  }

  getFormuleType() {
    const url = this.constants.apiEndpoint + this.formule.typeFormule;
    return this.httpClient.get(url, this.httpHeaders);
  }

  getFormule() {
    const url = this.constants.apiEndpoint + this.user.formule;
    return this.httpClient.get(url, this.httpHeaders);
  }

  getLevels() {
    const url = this.constants.apiEndpoint + '/niveaux';
    return this.httpClient.get(url, this.httpHeaders);
  }

  emptyUserData() {
    this.user = new Utilisateur();
    this.formule = new Formule();
    this.formuleType = new FormuleType();
    this.levels = [];
    this.currentLevel = new Niveau();
  }
}



import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { CiviliteService } from 'src/app/services/Civilites/civilite.service';
import { DepartementsService } from 'src/app/services/Departements/departements.service';
import { NationnaliteService } from 'src/app/services/Nationnalites/nationnalite.service';
import { SituationFamilialeService } from 'src/app/services/SituationFamiliale/situation-familiale.service';
import { SituationProfessionnelleService } from 'src/app/services/SituationProfessionnelle/situation-professionnelle.service';
import { NiveauEtudesService } from 'src/app/services/NiveauEtudes/niveau-etudes.service';
import { ModesDeplacementService } from 'src/app/services/ModesDeplacement/modes-deplacement.service';
import { FormuleService } from 'src/app/services/Formule/formule.service';
import { SituationRsaService } from 'src/app/services/SituationRsa/situation-rsa.service';
import { UtilisateurService } from 'src/app/services/Utilisateur/utilisateur.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';
import { Constants } from '../../config/constants';
import { dateRangeValidator } from 'src/app/custom-validators/dateRangeValidator';
import { ExternService } from 'src/app/services/Extern/extern.service';

@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.scss'],
})
export class UtilisateurComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;

  url_edition:string;
  index_utilisateur:number;
  mode_edition:boolean=true;
  private memo_beneficie_rsa;
  private memo_id_rsa;
  public utilisateurs:any;
  public civilites: any[];
  public departements: any[];
  public nationalites: any[];
  public situations_familiales: any[];
  public situations_professionnelles: any[];
  public niveaux_etudes: any[];
  public modes_deplacements: any[];
  public formules: any[];
  public formatricesNames: any[];
  loading_utilisateurs: boolean=false;
  loading_civilites: boolean=false;
  loading_departements: boolean=false;
  loading_nationalites: boolean=false;
  loading_situations_familiales: boolean=false;
  loading_situations_professionnelles: boolean=false;
  loading_niveaux_etudes: boolean=false;
  loading_modes_deplacements: boolean=false;
  loading_formules: boolean=false;
  loading_formatrices_names: boolean=false;
  minDate:string;
  maxDate:string;
  is_super_admin: boolean = false;
  currentPage:number=1;
  totalQuantityUtilisateurs:number;
  maxPageNumber:number;
  usersPerPage:number = 5;
  noUsers:boolean=false;
  nombreResultatsRecherche:number;
  foundUsers: any[];
  showSearchLoader:boolean=false;
  getUserTimeout:any=null;
  private passwordRegex:string="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{12,}$";
  passwordNouveauFocus:boolean=false;
  passwordEditionFocus:boolean=false;
  newPasswordInputType:any = "password";
  editPasswordInputType:any = "password";
  printedUser:any=null;
  readyToPrint:boolean=false;
  minBirthDate;
  maxBirthDate;
  createurParcoursOrSAdmin:boolean = false;

  nouveauForm = new FormGroup({
    nom_nouveau: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
    prenom_nouveau: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
    civilite_nouveau: new FormControl('', [Validators.required]),
    role_nouveau: new FormControl('', [Validators.minLength(1), Validators.maxLength(100)]),
    adresse_nouveau: new FormControl('', [Validators.maxLength(150)]),
    telephone_nouveau: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
    ville_nouveau: new FormControl('', [Validators.maxLength(50)]),
    code_postal_nouveau: new FormControl('', [Validators.maxLength(50)]),
    departement_nouveau: new FormControl(''),
    nationalite_nouveau: new FormControl(''),
    situation_familiale_nouveau: new FormControl(''),
    mere_pere_nouveau: new FormControl(''),    
    password_nouveau: new FormControl(
      '',
      [Validators.required, Validators.pattern(this.passwordRegex)],
    ),
    email_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(180), Validators.email]),
    nombre_enfants_nouveau: new FormControl('', [Validators.min(0), Validators.max(99)]),
    situation_professionnelle_nouveau: new FormControl(''),
    niveau_etudes_nouveau: new FormControl(''),
    numero_emploi_nouveau: new FormControl('', [Validators.maxLength(50)]),
    permis_nouveau: new FormControl(''),
    deplacement_nouveau: new FormControl(''),
    formule_nouveau: new FormControl('', [Validators.required]),
    beneficiaire_rsa_nouveau: new FormControl(''),
    numero_rsa_nouveau: new FormControl('', [Validators.maxLength(50)]),
    date_rsa_nouveau: new FormControl(new Date().toISOString().slice(0, -1)),
    longue_duree_nouveau: new FormControl(''),
    date_debut_nouveau: new FormControl(new Date().toISOString().slice(0, -1)),
    date_fin_nouveau: new FormControl(new Date().toISOString().slice(0, -1)),
    date_naissance_nouveau: new FormControl(this.constants.maxBirthDate, [Validators.required, dateRangeValidator()]),
    QPV_nouveau: new FormControl('', [Validators.required]),
    RQTH_nouveau: new FormControl('', [Validators.required]),
    date_fin_CER_nouveau: new FormControl(new Date().toISOString().slice(0, -1)),
    date_bilan_interediaire_nouveau: new FormControl(),
    date_bilan_final_nouveau: new FormControl(),
    referent_nouveau: new FormControl(''),
  });

  editionForm = new FormGroup({
    nom_edition: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
    prenom_edition: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
    civilite_edition: new FormControl('', [Validators.required]),
    role_edition: new FormControl('', [Validators.minLength(1), Validators.maxLength(100)]),
    adresse_edition: new FormControl('', [Validators.maxLength(150)]),
    telephone_edition: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
    ville_edition: new FormControl('', [Validators.maxLength(50)]),
    code_postal_edition: new FormControl('', [Validators.maxLength(50)]),
    departement_edition: new FormControl(''),
    nationalite_edition: new FormControl(''),
    situation_familiale_edition: new FormControl(''),
    mere_pere_edition: new FormControl(''),    
    password_edition: new FormControl(
      ''/*,
      [Validators.pattern(this.passwordRegex)],*/
    ),
    email_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(180), Validators.email]),
    nombre_enfants_edition: new FormControl('', [Validators.min(0), Validators.max(99)]),
    situation_professionnelle_edition: new FormControl(''),
    niveau_etudes_edition: new FormControl(''),
    numero_emploi_edition: new FormControl('', [Validators.maxLength(50)]),
    permis_edition: new FormControl('', []),
    deplacement_edition: new FormControl(''),
    formule_edition: new FormControl('', [Validators.required]),
    beneficiaire_rsa_edition: new FormControl(''),
    numero_rsa_edition: new FormControl('', [Validators.minLength(3), Validators.maxLength(50)]),
    date_rsa_edition: new FormControl(new Date().toISOString().slice(0, -1)),
    longue_duree_edition: new FormControl(''),
    date_debut_edition: new FormControl(new Date().toISOString().slice(0, -1)),
    date_fin_edition: new FormControl(new Date().toISOString().slice(0, -1)),
    date_naissance_edition: new FormControl(new Date().toISOString().slice(0, -1), [Validators.required, dateRangeValidator()]),
    QPV_edition: new FormControl('', [Validators.required]),
    RQTH_edition: new FormControl('', [Validators.required]),
    date_fin_CER_edition: new FormControl(new Date().toISOString().slice(0, -1)),
    date_bilan_interediaire_edition: new FormControl(new Date().toISOString().slice(0, -1)),
    date_bilan_final_edition: new FormControl(new Date().toISOString().slice(0, -1)),
    referent_edition: new FormControl(''),
  });

  searchForm = new FormGroup({
    nom: new FormControl('', [Validators.maxLength(50)]),
    prenom: new FormControl('', [Validators.maxLength(50)]),
    selectedUser: new FormControl('')
  });

  constructor(private utilisateurService: UtilisateurService,
              private civiliteService: CiviliteService,
              private departementsService: DepartementsService,
              private nationnaliteService: NationnaliteService,
              private situationFamilialeService: SituationFamilialeService,
              private situationProfessionnelleService: SituationProfessionnelleService,
              private niveauEtudesService: NiveauEtudesService,
              private modesDeplacementService: ModesDeplacementService,
              private formuleService: FormuleService,
              private errorNotificationService: ErrorNotificationService,
              private loadingService: LoadingService,
              private situationRsaService: SituationRsaService,
              private toastService: ToastService,
              private externService: ExternService,
              private constants: Constants) {
                this.minDate = this.constants.minDate;
                this.maxDate = this.constants.maxDate;
                this.minBirthDate = this.constants.minBirthDate;
                this.maxBirthDate = this.constants.maxBirthDate;
  }

  ngOnInit() {
    if(typeof(window.sessionStorage.getItem('role') !== "undefined")) {
      if(window.sessionStorage.getItem('role') === "ROLE_SUPER_ADMIN") {
        this.is_super_admin = true;
      }
    }

    this.loadingService.showLoading().then(
      () => {
        this.getUtilisateurs();
        this.getDepartements();
        this.getNationnalites();
        this.getSituationsFamiliales();
        this.getFormules();
        this.getModesDeplacements();
        this.getSituationsProfessionnelles();
        this.getNiveauxEtudes();
        this.getCivilites();
        this.getFormatrices();

        if(window.sessionStorage.getItem("role") === "ROLE_CREATEUR_PARCOURS"
        || window.sessionStorage.getItem("role") === "ROLE_SUPER_ADMIN") {
          this.createurParcoursOrSAdmin = true;
        }
      }
    );
  }

  preparePrint(utilisateur) {
    this.printedUser = utilisateur;
    this.readyToPrint = true;

    setTimeout(function(){
      window.print();
    }, 200);
  }

  //recuperation des formules enregistrees en bdd
  getUtilisateurs(name:string="", firstname:string="", id:number=null) {
    this.loading_utilisateurs = true;
    this.utilisateurService.getUtilisateurs(this.currentPage, "user", name, firstname, id).subscribe(
      data => {
        this.loading_utilisateurs = false;
        let infos = data;
        this.utilisateurs = infos["hydra:member"];

        if(this.utilisateurs.length <= 0) {
          this.noUsers = true;
        } else {
          this.noUsers = false;
        }

        this.totalQuantityUtilisateurs = infos["hydra:totalItems"];
        this.updateMaxPageNumber();
        this.checkLoading();
        this.scrollToTop();
      },
      () => {
        this.loading_utilisateurs = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getFormatrices() {
    this.loading_formatrices_names = true;
    this.utilisateurService.getFormatricesNames().subscribe(
      data => {
        this.formatricesNames = data["hydra:member"];
        this.loading_formatrices_names = false;
        this.checkLoading();
      },
      error => {
        this.errorNotificationService.presentHttpStatusErrorAlert(error.code);
        this.loading_formatrices_names = false;
        this.checkLoading();
      }
    )
  }

  changePage(pageNumber:number) {
    this.currentPage = pageNumber;
    this.loadingService.showLoading().then(
      () => {
        this.getUtilisateurs();
      }
    );
  }

  getSearchText() {
    if(typeof(this.nombreResultatsRecherche) !== "undefined" && this.nombreResultatsRecherche > 0) {
      return this.nombreResultatsRecherche + " résultat(s)";
    }

    return "Aucun résultat";
  }

  getUsersNames() {
    if(this.getUserTimeout !== null) {
      clearTimeout(this.getUserTimeout);
      this.getUserTimeout = null;
    }

    this.getUserTimeout = window.setTimeout(() => {
      if(!this.searchForm.invalid) {
        this.showSearchLoader = true;
  
        //dans le cas ou on vide les champs nom et prenoms du form de recherche
        //on re-affiche tout les résultats
        if(this.searchForm.value.nom === ""
        && this.searchForm.value.prenom === "") {
          this.searchForm.reset();
          this.searchForm.setValue({
            prenom: "",
            nom: "",
            selectedUser: ""
          });
  
          this.foundUsers = [];
          this.nombreResultatsRecherche = 0;
  
          this.loadingService.showLoading().then(
            () => {
              this.currentPage = 1;
              this.getUtilisateurs();
              this.showSearchLoader = false;
            }
          );
        } else {

          this.utilisateurService.getUtilisateursNames(
            //"user",
            this.searchForm.value.nom,
            this.searchForm.value.prenom
          ).subscribe(
            data => {
              let infos = data;
              this.foundUsers = infos["hydra:member"];
              this.nombreResultatsRecherche = this.foundUsers.length;
              this.showSearchLoader = false;
            },
            () => {
              this.errorNotificationService.presentGeneralErrorAlert(
                true,
                "Erreur",
                "Un problème a été rencontré lors de la recherche des utilisateurs",
                "Ok"
              );
              this.showSearchLoader = false;
            }
          )
        }
      }
    }, 150);
  }

  scrollToTop() {
    let anchor = document.getElementById('div-nouveau');

    setTimeout(() => {
      anchor.scrollIntoView();
    }, 300);  //230
  }

  //fct a lancer pr afficher les details des users pr ces noms
  searchByName(event) {
    let noName = this.searchForm.value.nom === "" && this.searchForm.value.prenom === "";

    if(!noName) {
      if(!this.searchForm.invalid) {
        this.loadingService.showLoading().then(
          () => {
            this.currentPage = 1;
            this.getUtilisateurs("", "", event.detail.value);
          }
        );
      }
    }
  }

  getCivilites() {
    this.loading_civilites = true;
    this.civiliteService.getCivilites().subscribe(
      data => {
        let infos = data;
        this.civilites = infos["hydra:member"];
        this.loading_civilites = false;
        this.checkLoading();
      },
      () => {
        this.loading_civilites = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getDepartements() {
    this.loading_departements = true;
    this.departementsService.getDepartements().subscribe(
      data => {
        let infos = data;
        this.departements = infos["hydra:member"];
        this.loading_departements = false;
        this.checkLoading();
      },
      () => {
        this.loading_departements = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getNationnalites() {
    this.loading_nationalites = true;
    this.nationnaliteService.getNationnalites().subscribe(
      data => {
        let infos = data;
        this.nationalites = infos["hydra:member"];
        this.loading_nationalites = false;
        this.checkLoading();
      },
      () => {
        this.loading_nationalites = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getSituationsFamiliales() {
    this.loading_situations_familiales = true;
    this.situationFamilialeService.getSituationsFamiliales().subscribe(
      data => {
        let infos = data;
        this.situations_familiales = infos["hydra:member"];
        this.loading_situations_familiales = false;
        this.checkLoading();
      },
      () => {
        this.loading_situations_familiales = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getSituationsProfessionnelles() {
    this.loading_situations_professionnelles = true;
    this.situationProfessionnelleService.getSituationsProfessionnelles().subscribe(
      data => {
        let infos = data;
        this.situations_professionnelles = infos["hydra:member"];
        this.loading_situations_professionnelles = false;
        this.checkLoading();
      },
      () => {
        this.loading_situations_professionnelles = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getNiveauxEtudes() {
    this.loading_niveaux_etudes = true;
    this.niveauEtudesService.getNiveauxEtudes().subscribe(
      data => {
        let infos = data;
        this.niveaux_etudes = infos["hydra:member"];
        this.loading_niveaux_etudes = false;
        this.checkLoading();
      },
      () => {
        this.loading_niveaux_etudes = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getModesDeplacements() {
    this.loading_modes_deplacements = true;
    this.modesDeplacementService.getModesDeplacement().subscribe(
      data => {
        let infos = data;
        this.modes_deplacements = infos["hydra:member"];
        this.loading_modes_deplacements = false;
        this.checkLoading();
      },
      () => {
        this.loading_modes_deplacements = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getFormules() {
    this.loading_formules = true;
    this.formuleService.getFormules().subscribe(
      data => {
        let infos = data;
        this.formules = infos["hydra:member"];
        this.loading_formules = false;
        this.checkLoading();
      },
      () => {
        this.loading_formules = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  manageNouveauPasswordFocus(value:boolean) {
    this.passwordNouveauFocus = value;
  }

  manageEditionPasswordFocus(value:boolean) {
    if(!!this.editionForm.get("password_edition").value === false) {
      this.passwordEditionFocus = false;
    } else {
      this.passwordEditionFocus = value;
    }
  }

  changeInputEditionPassword(event) {
    //si le champ password_edition n'est pas undefined, null, false, ""
    //on affecte la valeur dans le formulaire et on applique la contrainte
    //des mots de passe
    if(!!event.detail.value) {
      this.editionForm.patchValue({
        "password_edition": event.detail.value,
      });
      this.editionForm.get("password_edition").setValidators([Validators.pattern(this.passwordRegex)]);
    } else { 
      //si le champ password_edition est "", undefined, null, false
      //le champ du formuliare est mis a undefined
      //et on enleve les contraintes
      this.editionForm.patchValue({
        "password_edition": undefined,
      });
      this.editionForm.get("password_edition").setValidators([]);
    }

    //on met à jour les contraintes du formulaire
    this.editionForm.get("password_edition").updateValueAndValidity();
    //cette methode gerera l'affichage (ou non) du message des contraintes des mots de passe
    this.manageEditionPasswordFocus(true);
  }

  getNouveauPasswordMessageStyle() {
    return this.getColorStyle(this.nouveauForm.get("password_nouveau").status);
  }

  getEditionPasswordMessageStyle() {
    return this.getColorStyle(this.editionForm.get("password_edition").status);
  }

  getColorStyle(status:string) {
    if(status === "VALID") {
      return "#28A745";
    } else {
      return "#DC3545";
    }
  }

  //modes attendus: 'nouveau' et 'edition'
  checkRegex(regex:string, mode:string) {
    let regexes = {
      "12_char": /^(?=.*).{12,}$/,
      "1_min": /^(?=.*[a-z]).{1,}$/,
      "1_maj": /^(?=.*[A-Z]).{1,}$/,
      "1_number": /^(?=.*[0-9]).{1,}$/,
      "1_special": /^(?=.*[!@#$%^&*_=+-]).{1,}$/
    };

    let wantedRegex = regexes[regex];

    if(mode === "nouveau") {
      return wantedRegex.test(this.nouveauForm.get("password_nouveau").value);
    } else if(mode === "edition") {
      return wantedRegex.test(this.editionForm.get("password_edition").value);
    }
  }

  checkLoading() {
    if(!this.loading_utilisateurs && !this.loading_civilites
      && !this.loading_departements && !this.loading_nationalites
      && !this.loading_situations_familiales && !this.loading_situations_professionnelles
      && !this.loading_niveaux_etudes && !this.loading_modes_deplacements
      && !this.loading_formules && !this.loading_formatrices_names) {
        this.loadingService.dismissLoading();
    }
  }

  isItLoading() {
    return (this.loading_utilisateurs || this.loading_civilites
      || this.loading_departements || this.loading_nationalites
      || this.loading_situations_familiales || this.loading_situations_professionnelles
      || this.loading_niveaux_etudes || this.loading_modes_deplacements
      || this.loading_formules);
  }

  getRole(roles:any) {
    if(roles.includes("ROLE_ADMIN")) {
      if(roles.includes("ROLE_CREATEUR_PARCOURS")) {
        return "Créateur de parcours";
      }

      if(roles.includes("ROLE_ALINE")) {
        return "Aline";
      }

      if(roles.includes("ROLE_FORMATRICES")) {
        return "Formateur / Formatrice";
      }

      if(roles.includes("ROLE_SECRETAIRE_FORMATION")) {
        return "Secrétaire de formation";
      }

      return "Administrateur";
    }

    return "Usager";
  }

  updateMaxPageNumber() {
    this.maxPageNumber = this.totalQuantityUtilisateurs/this.usersPerPage;
    this.maxPageNumber = Math.ceil(this.maxPageNumber);
  }

  securitePresenceFormule() {
    let authorizedRoles = [
      "Administrateur",
      "Créateur de parcours",
      "Aline",
      "Formateur / Formatrice",
      "Secrétaire de formation",
    ];

    return authorizedRoles.includes(this.nouveauForm.value.role_nouveau);
  }

  getPereMere(merePere:boolean) {
    if(merePere) {
      return "Oui";
    }
    return "Non";
  }

  getAPermis(aPermis:boolean) {
    if(aPermis) {
      return "Oui";
    }
    return "Non";
  }

  RsaMoinsDeuxAns(dureeRecherche: boolean) {
    if(dureeRecherche) {
      return "Oui";
    }
    return "Non";
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  ouvreModaleNouveau() {
    this.mode_edition = false;
    this.modal.present();
  }

  updateFormuleValidationRule(mode:string) {
    if(mode === "nouveau") {
      if(this.nouveauForm.value.role_nouveau === "Usager") {
        this.nouveauForm.get('formule_nouveau').setValidators([Validators.required]);
      } else {
        this.nouveauForm.get('formule_nouveau').setValidators([]);
      }
  
      this.nouveauForm.get('formule_nouveau').updateValueAndValidity();
    } else {
      if(this.editionForm.value.role_edition === "Usager") {
        this.editionForm.get('formule_edition').setValidators([Validators.required]);
      } else {
        this.editionForm.get('formule_edition').setValidators([]);
      }
  
      this.editionForm.get('formule_edition').updateValueAndValidity();
    }
  }

  //pour le formulaire d'edition
  //coche les checkboxes des options selectionnees
  compareWith(o1, o2) {
    return o1 && o2 ? o1["@id"] === o2["@id"] : o1 === o2;
  }

  compareWithIds(o1, o2) {
    return o1 && o2 ? o1["id"] === o2["id"] : o1 === o2;
  }

  compareWithRoles(o1, o2) {
    if((o1 && o2) && (o1 === o2)) {
      return true;
    }
    return false;
  }

  ouvreModaleEdition(utilisateur:any) {
    let numero_rsa = null;
    let date_inscription_rsa = null;
    let longue_duree_rsa = null;
    this.memo_beneficie_rsa = false;
    let dateNaissance = utilisateur.dateNaissance.split("T");
    dateNaissance = dateNaissance[0];
    let dateFinCER = null;
    let dateBilanIntermediaire = null;
    let dateBilanFinal = null;

    if(!!utilisateur.dateFinCER) {
      dateFinCER = utilisateur.dateFinCER.split("T");
      dateFinCER = dateFinCER[0];
    }

    if(!!utilisateur.dateBilanIntermediaire) {
      dateBilanIntermediaire = utilisateur.dateBilanIntermediaire.split("T");
      dateBilanIntermediaire = dateBilanIntermediaire[0];
    }

    if(!!utilisateur.dateBilanFinal) {
      dateBilanFinal = utilisateur.dateBilanFinal.split("T");
      dateBilanFinal = dateBilanFinal[0];
    }

    if(typeof(utilisateur.situationRsa) !== "undefined" && utilisateur.situationRsa !== null) {
      numero_rsa = utilisateur.situationRsa.identifiantRsa,
      longue_duree_rsa = utilisateur.situationRsa.enRechercheDepuisMoinsDeuxAns,
      date_inscription_rsa = utilisateur.situationRsa.dateInscription.split("T");
      date_inscription_rsa = date_inscription_rsa[0];
      this.memo_beneficie_rsa = true;
      this.memo_id_rsa = utilisateur.situationRsa.id;
    }

    this.mode_edition = true;
    this.editionForm.patchValue({
      "nom_edition": utilisateur.nom,
      "prenom_edition": utilisateur.prenom,
      "civilite_edition": utilisateur.civilite,
      "role_edition": this.getRole(utilisateur.roles),
      "adresse_edition": typeof(utilisateur.adresse) == "undefined" ? "" : utilisateur.adresse,
      "telephone_edition": utilisateur.telephone,
      "ville_edition": typeof(utilisateur.ville) == "undefined" ? "" : utilisateur.ville,
      "code_postal_edition": typeof(utilisateur.codePostal) == "undefined" ? "" : utilisateur.codePostal,
      "departement_edition": !!utilisateur.departement ? utilisateur.departement.nom : "",
      "nationalite_edition": !!utilisateur.nationnalite ? utilisateur.nationnalite.nom : "",
      "situation_familiale_edition": utilisateur.situationFamiliale,
      "mere_pere_edition": utilisateur.merePere,
      "password_edition": utilisateur.password,
      "email_edition": utilisateur.email,
      "nombre_enfants_edition": typeof(utilisateur.nombreEnfants) == "undefined" ? null : utilisateur.nombreEnfants,
      "situation_professionnelle_edition": utilisateur.situationProfessionnelle,
      "niveau_etudes_edition": utilisateur.niveauEtude,
      "numero_emploi_edition": typeof(utilisateur.numeroDemandeurEmploi) == "undefined" ? null : utilisateur.numeroDemandeurEmploi,
      "permis_edition": utilisateur.aPermisConduire,
      "deplacement_edition": utilisateur.modeDeplacement,
      "formule_edition": utilisateur.formule,
      "beneficiaire_rsa_edition": this.memo_beneficie_rsa,
      "numero_rsa_edition": typeof(numero_rsa) == "undefined" ? null : numero_rsa,
      "date_rsa_edition": date_inscription_rsa,
      "longue_duree_edition": longue_duree_rsa,
      "date_debut_edition": utilisateur.dateDebut,
      "date_fin_edition": utilisateur.dateFin,
      "date_naissance_edition": dateNaissance,
      "QPV_edition": utilisateur.QPV,
      "RQTH_edition": utilisateur.RQTH,
      "date_fin_CER_edition": dateFinCER,
      "date_bilan_interediaire_edition": dateBilanIntermediaire,
      "date_bilan_final_edition": dateBilanFinal,
      "referent_edition": utilisateur.formateurReferent,
    });

    this.updateFormuleValidationRule("edition");
    this.url_edition = utilisateur["@id"];
    this.index_utilisateur = this.utilisateurs.lastIndexOf(utilisateur);
    this.modal.present();
  }
  
  supprimeAlert(utilisateur:any) {
    this.index_utilisateur = this.utilisateurs.lastIndexOf(utilisateur);
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.supprime.bind(this),
      [utilisateur]
    );
  }

  customNewFormValid() {
    if(this.nouveauForm.invalid || !this.testCoherenceRsaNouveau()) {
      return true;
    } else {
      return false;
    }
  }

  testCoherenceRsaNouveau() {
    // le champ 'beneficiaire_rsa_nouveau' est renseigne
    // et est à true
    if(typeof(this.nouveauForm.controls["beneficiaire_rsa_nouveau"].value) === "boolean"
      && this.nouveauForm.controls["beneficiaire_rsa_nouveau"].value === true) {
      if((this.nouveauForm.controls["numero_rsa_nouveau"].value === ""
        ||this.nouveauForm.controls["date_rsa_nouveau"].value === ""
        ||this.nouveauForm.controls["longue_duree_nouveau"].value === "")) {
          return false;
      }
    }
    return true;
  }

  customEditFormValid() {
    if(this.editionForm.invalid || !this.testCoherenceRsaEdition()) {
      return true;
    } else {
      return false;
    }
  }

  testCoherenceRsaEdition() {
    // le champ 'beneficiaire_rsa_nouveau' est renseigne
    // et est à true
    if(typeof(this.editionForm.controls["beneficiaire_rsa_edition"].value) === "boolean"
      && this.editionForm.controls["beneficiaire_rsa_edition"].value === true) {
      if((this.editionForm.controls["numero_rsa_edition"].value === ""
        ||this.editionForm.controls["date_rsa_edition"].value === ""
        ||this.editionForm.controls["longue_duree_edition"].value === "")) {
          return false;
      }
    }
    return true;
  }

  submitNouveau() {
    this.loadingService.showLoading();
    const formValues = this.nouveauForm.value;

    if(formValues["beneficiaire_rsa_nouveau"]) {
      const rsa_data = {
        date_inscription: formValues["date_rsa_nouveau"] === "" ? null : formValues["date_rsa_nouveau"],
        numero: formValues["numero_rsa_nouveau"],
        moins_deux_ans: formValues["longue_duree_nouveau"] === "" ? null :  formValues["longue_duree_nouveau"]
      };
      this.situationRsaService.cree(rsa_data).subscribe(
        data => {
          this.creeUtilisateur(formValues, data["@id"]);
        },
        error => {
          this.messageErreurSauvegarde(error);
        }
      );
    } else {
      this.creeUtilisateur(formValues, null);
    }
  }

  creeUtilisateur(formValues, url_situation_rsa) {
    let departementName = formValues.departement_nouveau;

    if(!!departementName) {
      departementName = departementName[0].toUpperCase() + departementName.slice(1);
    }

    const memo_departement = this.departements.find((element) => {
      return element.nom === departementName
    });

    if(!!memo_departement) {
      formValues.departement_nouveau = memo_departement;
    } else {
      formValues.departement_nouveau = "";
    }

    let nationnaliteName = formValues.nationalite_nouveau;

    if(!!nationnaliteName) {
      nationnaliteName = nationnaliteName[0].toUpperCase() + nationnaliteName.slice(1);
    }

    const memo_nationalite = this.nationalites.find((element) => {
      return element.nom === nationnaliteName
    });

    if(!!memo_nationalite) {
      formValues.nationalite_nouveau = memo_nationalite;
    } else {
      formValues.nationalite_nouveau = "";
    }

    const memo_civilite = formValues.civilite_nouveau;
    const memo_deplacement = formValues.deplacement_nouveau;
    const memo_formule = formValues.formule_nouveau;
    const memo_niveau_etudes = formValues.niveau_etudes_nouveau;
    const memo_situation_familiale = formValues.situation_familiale_nouveau
    const memo_situation_professionnelle = formValues.situation_professionnelle_nouveau;
    const memo_referent = formValues.referent_nouveau;

    this.utilisateurService.cree(formValues, url_situation_rsa).subscribe(
      data => {
        const nouvel_objet:any = data;
        this.loadingService.dismissLoading();

        let ajout = {
          "@id": nouvel_objet["@id"],
          "aPermisConduire": nouvel_objet["aPermisConduire"],
          "adresse": nouvel_objet["adresse"],
          "civilite": memo_civilite,
          "codePostal": nouvel_objet["codePostal"],
          "departement": memo_departement,
          "email": nouvel_objet["email"],
          "formule": memo_formule,
          "heuresEffectuees": nouvel_objet["heuresEffectuees"],
          "merePere": nouvel_objet["merePere"],
          "modeDeplacement": memo_deplacement,
          "nationnalite": memo_nationalite,
          "niveauEtude": memo_niveau_etudes,
          "nom": nouvel_objet["nom"],
          "nombreEnfants": nouvel_objet["nombreEnfants"],
          "numeroDemandeurEmploi": nouvel_objet["numeroDemandeurEmploi"],
          "prenom": nouvel_objet["prenom"],
          "roles": nouvel_objet["roles"],
          "situationFamiliale": memo_situation_familiale,
          "situationProfessionnelle": memo_situation_professionnelle,
          "situationRsa": url_situation_rsa,
          "telephone": nouvel_objet["telephone"],
          "ville": nouvel_objet["ville"],
          "dateDebut": nouvel_objet["dateDebut"],
          "dateFin": nouvel_objet["dateFin"],
          "dateNaissance": nouvel_objet["dateNaissance"],
          "QPV": nouvel_objet["QPV"],
          "RQTH": nouvel_objet["RQTH"],
          "dateFinCER": nouvel_objet["dateFinCER"],
          "dateBilanIntermediaire": nouvel_objet["dateBilanIntermediaire"],
          "dateBilanFinal": nouvel_objet["dateBilanFinal"],
          "formateurReferent": memo_referent,
        };

        if(this.utilisateurs.length < 5) {
          this.utilisateurs.push(ajout);
        }
        this.totalQuantityUtilisateurs++;
        this.updateMaxPageNumber();
        this.modal.dismiss();
        this.nouveauForm.reset();
        this.nouveauForm.patchValue({
          "date_debut_nouveau": new Date().toISOString().slice(0, -1),
          "date_fin_nouveau": new Date().toISOString().slice(0, -1),
          "date_naissance_nouveau": this.constants.maxBirthDate,
          "nom_nouveau": "",
          "prenom_nouveau": "",
          "civilite_nouveau": "",
          "role_nouveau": "",
          "adresse_nouveau": "",
          "telephone_nouveau": "",
          "ville_nouveau": "",
          "code_postal_nouveau": "",
          "departement_nouveau": "",
          "nationalite_nouveau": "",
          "situation_familiale_nouveau": "",
          "mere_pere_nouveau": "",
          "password_nouveau": "",
          "email_nouveau": "",
          "nombre_enfants_nouveau": "",
          "situation_professionnelle_nouveau": "",
          "niveau_etudes_nouveau": "",
          "numero_emploi_nouveau": "",
          "permis_nouveau": "",
          "deplacement_nouveau": "",
          "formule_nouveau": "",
          "beneficiaire_rsa_nouveau": "",
          "numero_rsa_nouveau": "",
          "date_rsa_nouveau": new Date().toISOString().slice(0, -1),
          "longue_duree_nouveau": "",
          "date_fin_CER_nouveau": new Date().toISOString().slice(0, -1),
          "date_bilan_interediaire_nouveau": null,
          "date_bilan_final_nouveau": null,
          "referent_nouveau": "",
        });
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.messageErreurSauvegarde(error);
      }
    );
  }

  togglePasswordInputType(type:string) {
    if(type === "new") {
      if(this.newPasswordInputType === "password") {
        this.newPasswordInputType = "text";
      } else {
        this.newPasswordInputType = "password";
      }
    } else {
      if(this.editPasswordInputType === "password") {
        this.editPasswordInputType = "text";
      } else {
        this.editPasswordInputType = "password";
      }
    }
  }

  isPasswordVisible(type:string) {
    if(type === "new") {
      if(this.newPasswordInputType === "password") {
        return false
      }
      return true;
    }

    if(this.editPasswordInputType === "password") {
      return false
    }
    return true;
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;
    // edition de la situation rsa de l'utilisateur
    if(this.memo_beneficie_rsa && formValues["beneficiaire_rsa_edition"]) {
      const rsa_data = {
        date_inscription: formValues["date_rsa_edition"],
        numero: formValues["numero_rsa_edition"],
        moins_deux_ans: formValues["longue_duree_edition"]
      };

      let rsaLink = this.memo_id_rsa;
      this.situationRsaService.edite(rsa_data, rsaLink).subscribe(
        () => {
          this.editeUtilisateur(formValues, {mode: "edition", situationRsa: null});
          this.utilisateurs[this.index_utilisateur].situationRsa.dateInscription = rsa_data.date_inscription;
          this.utilisateurs[this.index_utilisateur].situationRsa.enRechercheDepuisMoinsDeuxAns = rsa_data.moins_deux_ans;
          this.utilisateurs[this.index_utilisateur].situationRsa.identifiantRsa = rsa_data.numero;          
        },
        error => {
          this.messageErreurSauvegarde(error);
        }
      );
    } else if(this.memo_beneficie_rsa && !formValues["beneficiaire_rsa_edition"]) {
      // suppression de la situation rsa de l'utilisateur
      this.situationRsaService.supprime(this.memo_id_rsa).subscribe(
        () => {
          this.editeUtilisateur(formValues, {mode: "suppression", situationRsa: null});
        },
        (error) => {
          this.messageErreurSauvegarde(error);
        }
      )
    } else if(!this.memo_beneficie_rsa && formValues["beneficiaire_rsa_edition"]) {
      // création d'une situation rsa pour l'utilisateur
      const rsa_data = {
        date_inscription: formValues["date_rsa_edition"],
        numero: formValues["numero_rsa_edition"],
        moins_deux_ans: formValues["longue_duree_edition"]
      };
      this.situationRsaService.cree(rsa_data).subscribe(
        data => {
          this.editeUtilisateur(formValues, {mode: "ajout", situationRsa: data});
        },
        error => {
          this.messageErreurSauvegarde(error);
        }
      );
    } else {
      // l'utilisateur ne beneficie pas du rsa de base
      // et n'en beneficie toujours pas après edition
      this.editeUtilisateur(formValues, {mode: null, url: null});
    }
  }

  editeUtilisateur(formValues, situation_rsa) {
    const memo_civilite = formValues.civilite_edition;
    const memo_deplacement = formValues.deplacement_edition;
    const memo_formule = formValues.formule_edition;
    const memo_niveau_etudes = formValues.niveau_etudes_edition;
    const memo_situation_familiale = formValues.situation_familiale_edition;
    const memo_situation_professionnelle = formValues.situation_professionnelle_edition;
    const memo_referent = formValues.referent_edition;
    let departementName = formValues.departement_edition;

    if(!!departementName) {
      departementName = departementName[0].toUpperCase() + departementName.slice(1);
    }

    const memo_departement = this.departements.find((element) => {
      return element.nom === departementName
    });

    if(!!memo_departement) {
      formValues.departement_edition = memo_departement;
    } else {
      formValues.departement_edition = "";
    }

    let nationnaliteName = formValues.nationalite_edition;

    if(!!nationnaliteName) {
      nationnaliteName = nationnaliteName[0].toUpperCase() + nationnaliteName.slice(1);
    }

    const memo_nationalite = this.nationalites.find((element) => {
      return element.nom === nationnaliteName
    });

    if(!!memo_nationalite) {
      formValues.nationalite_edition = memo_nationalite;
    } else {
      formValues.nationalite_edition = "";
    }

    this.utilisateurService.edite(formValues, this.url_edition, situation_rsa).subscribe(
      data => {
        const nouvel_objet:any = data;
        this.loadingService.dismissLoading();

        let data_edite = {
          "@id": nouvel_objet["@id"],
          "@type": nouvel_objet["@type"],
          "aPermisConduire": nouvel_objet["aPermisConduire"],
          "adresse": nouvel_objet["adresse"],
          "bilanIntermediaireFait": nouvel_objet["bilanIntermediaireFait"],
          "civilite": memo_civilite,
          "codePostal": nouvel_objet["codePostal"],
          "departement": memo_departement,
          "email": nouvel_objet["email"],
          "formule": memo_formule,
          "heuresEffectuees": nouvel_objet["heuresEffectuees"],
          "id": nouvel_objet["id"],
          "merePere": nouvel_objet["merePere"],
          "modeDeplacement": memo_deplacement,
          "nationnalite": memo_nationalite,
          "niveauEtude": memo_niveau_etudes,
          "nom": nouvel_objet["nom"],
          "nombreEnfants": nouvel_objet["nombreEnfants"],
          "numeroDemandeurEmploi": nouvel_objet["numeroDemandeurEmploi"],
          "prenom": nouvel_objet["prenom"],
          "roles": nouvel_objet["roles"],
          "situationFamiliale": memo_situation_familiale,
          "situationProfessionnelle": memo_situation_professionnelle,
          "telephone": nouvel_objet["telephone"],
          "ville": nouvel_objet["ville"],
          "dateDebut": nouvel_objet["dateDebut"],
          "dateFin": nouvel_objet["dateFin"],
          "dateNaissance": nouvel_objet["dateNaissance"],
          "QPV": nouvel_objet["QPV"],
          "RQTH": nouvel_objet["RQTH"],
          "dateFinCER": nouvel_objet["dateFinCER"],
          "dateBilanIntermediaire": nouvel_objet["dateBilanIntermediaire"],
          "dateBilanFinal": nouvel_objet["dateBilanFinal"],
          "formateurReferent": memo_referent,
        };

        if(situation_rsa.situationRsa !== null) {
          data_edite["situationRsa"] = situation_rsa.situationRsa;
        } else {
          //permet de garder les infos de base sur la situation rsa
          data_edite["situationRsa"] = this.utilisateurs[this.index_utilisateur]["situationRsa"];
        }

        this.utilisateurs[this.index_utilisateur] = data_edite;
        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.messageErreurSauvegarde(error);
      }
    );
  }

  supprime(utilisateur) {
    this.loadingService.showLoading();

    if(typeof(utilisateur[0].situationRsa) !== "undefined" && utilisateur[0].situationRsa !== null) {




      //this.situationRsaService.supprime(utilisateur[0].situationRsa["@id"]).subscribe( //preprod ?
      this.situationRsaService.supprime(utilisateur[0].situationRsa["id"]).subscribe( //prod & local
        () => {
          this.supprimeUtilisateur(utilisateur[0]["@id"]);
        },
        error => {
          this.messageErreurSauvegarde(error);
        }
      )
    } else {
      this.supprimeUtilisateur(utilisateur[0]["@id"]);
    }
  }

  supprimeUtilisateur(lien:string) {    
    this.utilisateurService.supprime(lien).subscribe(
      () => {
        this.loadingService.dismissLoading();
        this.utilisateurs.splice(this.index_utilisateur, 1);
        this.totalQuantityUtilisateurs--;
        this.updateMaxPageNumber();

        if(this.utilisateurs.length < 1) {
          this.loadingService.showLoading().then(
            () => {
              if(this.currentPage > 1) {
                this.currentPage--;
              }
              this.getUtilisateurs();
            }
          );
        }

        this.toastService.presentToast("bottom", "Supprimé", "success");
      },
      error => {
        this.messageErreurSauvegarde(error);
      }
    )
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getNouveauRsaFormDisplay() {    
    if(this.nouveauForm.controls["beneficiaire_rsa_nouveau"].value) {
      return "block";
    }
    return "none";
  }

  getEditionRsaFormDisplay() {    
    if(this.editionForm.controls["beneficiaire_rsa_edition"].value) {
      return "block";
    }
    return "none";
  }

  messageErreurSauvegarde(error) {
    this.loadingService.dismissLoading();
    if(error.status === 422) {
      let erreur = typeof(error.error["hydra:description"]) !=="undefined" ? error.error["hydra:description"] : error.error;
      this.errorNotificationService.presentGeneralErrorAlert(
        false,
        "Erreur",
        erreur,
        "Ok"
      );
    } else {
      this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
    }
  }

  WarningFormuleChange($event) {
    this.errorNotificationService.presentGeneralErrorAlert(
      false,
      "Attention",
      "Modifier la formule liée à l'usager remettra à zéro les informations liées à son parcours",
      "Ok",
    );
  }

  resendConfirmationLink(userId:number, userRoles: string[]) {
    if(userRoles.includes("ROLE_VERIFIED")) {
      //msg no use
      this.errorNotificationService.presentGeneralErrorAlert(
        false,
        "",
        "Le compte de cet utilisateur a déjà été confirmé",
        "Ok",
      );
    } else {
      //resend with id
      this.loadingService.showLoading();
      this.utilisateurService.resendConfirmationLink(userId).subscribe(
        () => {
          this.toastService.presentToast("bottom", "Lien envoyé", "success");
          this.loadingService.dismissLoading();
        },
        error => {
          if(error.status ===  200) {
            this.toastService.presentToast("bottom", "Lien envoyé", "success");
          } else {
            this.errorNotificationService.presentGeneralErrorAlert(
              false,
              "Erreur",
              "Le lien de confirmation n'a pas pu être envoyé",
              "Ok",
            );
          }
          this.loadingService.dismissLoading();
        }
      );
    }
  }

  //---xp begin
  /*getYpkKey() {
    this.utilisateurService.getYpkKey().subscribe(
      data => {
        console.log(data); //string de la cle
      },
      error => {
        console.log(error);
      }
    )
  }*/
  proviUpdateUsers() {
    this.externService.getAllYPKUsers().subscribe(
      data => {
        console.log(data);
        //let users = data[""]
      },
      error => {
        console.log(error);
      }
    )

    /*this.externService.getYPKFormateurs().subscribe(
      data => {
        console.log(data);
        //let users = data[""]
      },
      error => {
        console.log(error);
      }
    )*/
  }
  //---xp end
}
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Recompense } from 'src/app/Models/recompense';
import { UtilisateurService } from 'src/app/services/Utilisateur/utilisateur.service';
import { UtilisateurNiveauxService } from 'src/app/services/UtilisateurNiveaux/utilisateur-niveaux.service';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';
import { ErrorNotificationService } from '../../services/error-notification.service';

@Component({
  selector: 'app-modal-detail-reward',
  templateUrl: './modal-detail-reward.component.html',
  styleUrls: ['./modal-detail-reward.component.scss'],
})
export class ModalDetailRewardComponent implements OnInit {
  @Input("recompense") recompense: Recompense;
  donneesChargees = false;
  constructor(private modalCtrl: ModalController,
              private utilisateurService: UtilisateurService,
              private utilisateurNiveauxServices: UtilisateurNiveauxService,
              private loadingService: LoadingService,
              private toastService: ToastService,
              private errorNotificationService: ErrorNotificationService,
              private router: Router) {}

  dismissModal() {
    this.router.navigate(['/tabs/tab1']).then( () => {
      this.modalCtrl.dismiss();
      // reload complet pour l'instant, solution rapide mais peu élégante
      location.reload(); 
    });
  }
  
  ngOnInit() {
    this.loadingService.showLoading().then( () => {
      this.utilisateurService.getMe().subscribe(
        (data) => {
          let user = data["data"];
          let niveauId = this.router.url.slice(this.router.url.lastIndexOf('/') + 1);
          let utilisateurNiveauxId = user.utilisateurNiveaux.find(element => element.niveau.id == niveauId).id.toString();
          let recompenseId = this.recompense["@id"].split("/recompenses/")[1];
          this.utilisateurNiveauxServices.setRecompense(recompenseId,utilisateurNiveauxId).subscribe(
            data => {
              this.loadingService.dismissLoading();
              this.donneesChargees = true;
            },
            error => {
              this.loadingService.dismissLoading();
              if(error.status === 422) {
                this.errorNotificationService.presentGeneralErrorAlert(
                  false,
                  "Erreur",
                  error.error,
                  "Ok"
                );
              } else {
                this.toastService.presentToast("bottom", "Erreur: la récompense n'a pas pu être choisie", "danger");
              }
            }
          );
        }
      );
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class SituationRsaService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  cree(situation: any) {    
    const url = this.constants.apiEndpoint + "/situation_rsa";
    const httpHeaders = this.headerService.getPostHeader();

    return this.httpClient.post(url, {
      identifiantRsa: situation["numero"],
      dateInscription: situation["date_inscription"],
      enRechercheDepuisMoinsDeuxAns: situation["moins_deux_ans"]
    }, httpHeaders);
  }

  edite(situation:any, lien: string) {    
    const url = this.constants.apiEndpoint + "/situation_rsa/" + lien;
    const httpHeaders = this.headerService.getPatchHeader();

    return this.httpClient.patch(url, {
      identifiantRsa: situation["numero"],
      dateInscription: situation["date_inscription"],
      enRechercheDepuisMoinsDeuxAns: situation["moins_deux_ans"]
    }, httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiEndpoint + "/situation_rsa/" + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}
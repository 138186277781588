import { Component, OnInit } from '@angular/core';
import { UtilisateurService } from '../services/Utilisateur/utilisateur.service';
import { ExternService } from '../services/Extern/extern.service';
import { GeneralService } from '../services/General/general.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ErrorNotificationService } from '../services/error-notification.service';

@Component({
  selector: 'app-tab4',
  templateUrl: './tab4.component.html',
  styleUrls: ['./tab4.component.scss'],
})
export class Tab4Component implements OnInit {
  private userIdReservio:string;
  public loadingServices:boolean=false;
  public loadingIncommingEvents:boolean=false;
  public services = [];
  public currentAvailableService=null;
  public firstService;
  public selectedResource=null;
  public selectedResourceDate;
  public selectedResourceStartTime;
  public selectedResourceEndTime;
  public minCalendar;
  public maxCalendar;
  private currentMonth:number|string;
  private nextMonth:number|string;
  private currentYear:number|string;
  public includedAvailableDates = [];
  public incommingEvents=[];
  public incommingDates=[];
  public displayedIncommingEvents=[];
  public incommingSelectedDate:string="";
  public loadingAvailableCalendar:boolean=false;
  public loadingHolidays:boolean=false;
  public closedDates=[];
  public loadingBuisinessHolidays:boolean=false;
  public buisinessHolidays=[];

  constructor(private utilisateurService: UtilisateurService,
    private generalService: GeneralService,
    private externService:ExternService,
    private loadingService: LoadingService,
    private errorNotificationService: ErrorNotificationService
  ) { }

  ngOnInit() {
    let standardDate = new Date();
    this.currentMonth = standardDate.getMonth()+1;
    this.nextMonth = this.currentMonth + 1;
    this.currentMonth = this.currentMonth < 10 ? "0" + this.currentMonth : this.currentMonth;
    this.nextMonth = this.nextMonth < 10 ? "0" + this.nextMonth : this.nextMonth;
    this.currentYear = standardDate.getFullYear();
    this.userIdReservio= window.sessionStorage.getItem('idReservio');
    let today:any = new Date().toLocaleDateString("fr-FR");
    today = today.split("/");
    today = today[2] + "-" + today[1] + "-" + today[0];
    this.incommingSelectedDate = today;
    let minDate = new Date();
    let maxDate = new Date();
    maxDate.setDate(minDate.getDate() + 10);
    this.minCalendar = this.generalService.convertDateToUSFormat(minDate);
    this.maxCalendar = this.generalService.convertDateToUSFormat(maxDate);

    this.loadingService.showLoading().then(
      () => {
        this.getClosedDates();
        this.getBuisinessHolidays();
        this.getLocalAllReservioServices();
        this.getIncommingEvents();
      }
    );
  }

  getClosedDates() {
    this.loadingHolidays = true;

    this.externService.getFrenchPublicHolidays().subscribe(
      data => {
        this.closedDates = data["currentYear"].concat(data["nextYear"]);
        this.loadingHolidays = false;
        this.checkLoading();
      },
      () => {
        this.loadingHolidays = false;
        this.checkLoading();
      }
    );
  }

  getBuisinessHolidays() {
    this.loadingBuisinessHolidays = true;

    this.externService.getBuisinessHolidays().subscribe(
      data => {
        let received:any = data;
        this.buisinessHolidays = received;
        this.loadingBuisinessHolidays = false;
        this.checkLoading();
      },
      () => {
        this.loadingBuisinessHolidays = false;
        this.checkLoading();
      }
    );
  }

  getIncommingEvents() {
    this.loadingIncommingEvents = true;
    this.externService.getAllLocalIncommingEventsForUser(this.userIdReservio).subscribe(
      data => {
        this.incommingEvents = data["events"];

        for(let event of this.incommingEvents) {
          let shortDate = this.generalService.convertDateTimeToLocal(event.start);

          if(!this.incommingDates.includes(shortDate)) {
            this.incommingDates.push(shortDate);
          }
        }

        this.incommingDateChange();
        this.loadingIncommingEvents = false;
        this.checkLoading();
      },
      () => {
        this.loadingIncommingEvents = false;
        this.checkLoading();

        this.errorNotificationService.presentGeneralErrorAlert(
          false,
          "Erreur",
          "un problème a été rencontré lors du chargement des evenements à venir",
          "Ok"
        );
      }
    )
  }

  getLocalAllReservioServices() {
    this.loadingServices = true;
    this.externService.getAllLocalReservioServices().subscribe(
      data => {
        this.services = data["services"];        
        this.loadingServices = false;
        this.checkLoading();
      },
      () => {
        this.loadingServices = false;
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          false,
          "Erreur",
          "un problème a été rencontré lors du chargement des services",
          "Ok"
        );
      }
    )
  }

  serviceChange(event=null, initMode:boolean=false) {
    this.includedAvailableDates = [];
    this.selectedResourceStartTime = null;
    this.selectedResourceEndTime = null;

    if(initMode) {
      this.firstService = this.services[0];

      if(this.currentAvailableService === null) {
        this.currentAvailableService = this.firstService;
      }
    } else {
      this.currentAvailableService = event.detail.value;
    }
  }

  checkLoading() {
    if(!this.loadingServices && !this.loadingIncommingEvents
      && !this.loadingHolidays && !this.loadingBuisinessHolidays) {
      this.loadingService.dismissLoading();
      this.serviceChange(null, true);
    }
  }

  //determine les jours actives/desactives sur le calendrier des resources dispos
  isAvailableDateEnabled = (dateIsoString: string): boolean => {
    if(
      this.includedAvailableDates.includes(dateIsoString)
      && !this.closedDates.includes(dateIsoString) //les jours feries ne sont pas dispos
      && !this.buisinessHolidays.includes(dateIsoString)
    ) {
      return true;
    } else {
      return false;
    }
  };

  //determine les jours actives/desactives sur le calendrier des rendez vous à venir
  isIncommingDateEnabled = (dateIsoString: string): boolean => {
    if(this.incommingDates.includes(dateIsoString)) {
      return true;
    } else {
      return false;
    }
  };

  resourceSelection(item, element) {
    //retrait de la classe selected de tous les events affiches dans la liste
    let eventItems = document.getElementsByClassName("resourceItem");

    Array.from(eventItems).forEach(function(eventItem){
      eventItem["classList"].remove("selected");
    });

    //ajout de la classe selected à l'element cliqué dans la liste
    element.target.classList.add("selected");
    this.selectedResource = item;
    this.showLoaderAvailableCalendar(true);
    this.selectedResourceStartTime = null;
    this.selectedResourceEndTime = null;
    this.getAvailableIncludedDates();      
  }

  getAvailableIncludedDates() {
    this.includedAvailableDates = []; //reset
    let localIncludedAvailableDates = [];
    let days = [];

    for(let openingHour of this.selectedResource.openingHours) {
      days = days.concat(openingHour.days);

      if(openingHour === this.selectedResource.openingHours[this.selectedResource.openingHours.length - 1]) {
        localIncludedAvailableDates = this.generalService.getAllWantedDays(
          this.currentYear, this.currentMonth, days
        );
        this.getAvailableExcludedDates(localIncludedAvailableDates);
      }
    }

    if(this.selectedResource.openingHours.length < 1) {
      this.showLoaderAvailableCalendar(false);
    }
  }

  getAvailableExcludedDates(includedDates:string[]) {
    let count = 0;

    if(this.selectedResource !== null) {
      //on recupere tout les events lies à la resource selectionnee dont la date est
      //entre aujourd'hui et dans 10 jours
      this.externService.getAllLocalIncommingEvents(this.selectedResource.id).subscribe(
        data => {
          for(let event of data["events"]) {
            //pour chaque event trouvé, on recupere sa date
            let date = this.generalService.convertDateTimeToLocal(event.start);
            count++;

            //si la date en cours est presente dans le tableau
            //des dates dispos, on la retire de la
            if(includedDates.includes(date)) {
              let index = includedDates.indexOf(date);

              if(index > -1) {
                includedDates.splice(index, 1);
              }
            }

            //dernier passage dans boucle
            if(count === data["events"].length) {
              includedDates = includedDates.filter((element) => {
                return element >= this.minCalendar && element <= this.maxCalendar;
              });

              this.includedAvailableDates = includedDates; 
              this.showLoaderAvailableCalendar(false);
            }
          }

          if(data["events"].length < 1) {
            this.includedAvailableDates = includedDates;
            this.showLoaderAvailableCalendar(false);
          }
        },
        () => {
          this.showLoaderAvailableCalendar(false);
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            "un problème a été rencontré lors du chargement des données",
            "Ok"
          );
        }
      )
    }
  }

  showLoaderAvailableCalendar(show:boolean) {
    this.loadingAvailableCalendar = show;
  }

  availableDateChange(event) {
    let selectedDate = this.generalService.convertDateTimeToLocal(event.detail.value);
    let selectedDay = this.generalService.dateToShortDay(selectedDate);
    let startNumber;
    let endNumber;

    for(let openingHour of this.selectedResource.openingHours) {
      if(openingHour.days.includes(selectedDay)) {
        startNumber = openingHour.start;
        endNumber = openingHour.end;
        break;
      }
    }

    this.selectedResourceDate = selectedDate;
    this.selectedResourceStartTime = this.generalService.secondsToTime(startNumber);
    this.selectedResourceEndTime = this.generalService.secondsToTime(endNumber);
  }

  incommingDateChange(event=null) {    
    let eventDate = this.incommingSelectedDate;

    if(!!event) {
      eventDate = this.generalService.convertDateTimeToLocal(event.detail.value);
    }
    
    this.displayedIncommingEvents = this.incommingEvents.filter((element) => {
      let shortDate = this.generalService.convertDateTimeToLocal(element.start);
      return shortDate === eventDate;
    });
  }

  createBookingAndEvent() {
    this.loadingService.showLoading();

    let includedDatesClone = [].concat(this.includedAvailableDates);
    this.includedAvailableDates = [];

    let startDate = this.selectedResourceDate + " " + this.selectedResourceStartTime.hour 
      + ":" + this.selectedResourceStartTime.min;

    let endDate = this.selectedResourceDate + " " + this.selectedResourceEndTime.hour 
      + ":" + this.selectedResourceEndTime.min;

    this.externService.createEventAndBooking(
      startDate, endDate, this.selectedResource.idReservio, this.userIdReservio
    ).subscribe(
      data => {
        //retrait du creneau de la liste des dates dispos
        let index = includedDatesClone.indexOf(this.selectedResourceDate);

        if(index > -1) {
          includedDatesClone.splice(index, 1);
        }

        this.includedAvailableDates = includedDatesClone;

        //retrait de la resource de la liste si plus de creneau dispo
        let includedDates = this.includedAvailableDates.filter((element) => {
          return element >= this.minCalendar && element <= this.maxCalendar;
        });

        this.incommingEvents.push(data["event"]);
        let cloneIncommingDates = [].concat(this.incommingDates);
        this.incommingDates = [];

        for(let event of this.incommingEvents) {
          let shortDate = this.generalService.convertDateTimeToLocal(event.start);

          if(!cloneIncommingDates.includes(shortDate)) {
            cloneIncommingDates.push(shortDate);
          }

          //au dernier element parcouru
          if(event === this.incommingEvents[this.incommingEvents.length - 1]) {
            this.incommingDates = cloneIncommingDates;
            this.incommingDatesQuickChange();

            //si la date de l'evenement ajouté à la liste des evenements à venir
            //est la meme que la date actuellement selectionée dans le calendrier concerne
            //on met à jour les détails des infos affichées
            let newIncommingEventDate = this.generalService.convertDateTimeToLocal(
              data["event"].start
            );

            if(newIncommingEventDate === this.incommingSelectedDate) {
              this.incommingDateChange();
            }

            this.loadingService.dismissLoading();
          }
        }

        if(this.incommingEvents.length < 1) {
          this.loadingService.dismissLoading();
        }
      },
      () => {
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          false,
          "Erreur",
          "un problème a été rencontré lors de la création du rendez-vous",
          "Ok"
        );
      }
    );
  }

  incommingDatesQuickChange() {
    //changement rapide de la date du ion-datetime des events à venir
    //provoque MAJ des dates dispos affichées
    let dateBase = this.incommingSelectedDate;
    let explodedDate = dateBase.split("-");
    let nextDay:string|number = parseInt(explodedDate[2]);

    if(nextDay > 1) {
      nextDay--;
    } else {
      nextDay++;
    }

    if(nextDay < 10) {
      nextDay = "0" + nextDay
    }

    let nextDate = explodedDate[0] + "-" + explodedDate[1] + "-" + nextDay;
    this.incommingSelectedDate = nextDate;
  }

  cancelBookingAndEvent(eventId:number, bookingId:number, startDate:Date, resource) {
    this.loadingService.showLoading();

    this.externService.deleteEventAndBooking(eventId, bookingId).subscribe(
      () => {
        //retrait de la classe selected de tous les events affiches dans la liste
        let eventItems = document.getElementsByClassName("resourceItem");

        Array.from(eventItems).forEach(function(eventItem){
          eventItem["classList"].remove("selected");
        });
        
        //tranfert date annulée vers dates dispos        
        //retrait de la liste des rdvs a venir
        let elementToRemove = this.incommingEvents.filter((element) => {
          return element.id === eventId
        });

        let eventIndexToRemove = this.incommingEvents.indexOf(elementToRemove[0]);

        if(eventIndexToRemove > -1) {
          this.incommingEvents.splice(eventIndexToRemove, 1);
        }

        //s'il n'y a qu'un seul event avec cette date dans la liste des evenements
        //à venir, on retire cette date du ion-datetime
        if(!this.isDateMultipleInIncommingEvents(startDate)) {
          let startDateToRemove = this.generalService.convertDateTimeToLocal(startDate);
          let dateIndexToRemove = this.incommingDates.indexOf(startDateToRemove);

          if(dateIndexToRemove > -1) {
            this.incommingDates.splice(dateIndexToRemove, 1);
          }

          this.incommingDatesQuickChange();
        }

        //on rajoute la date du rdv que l'on vient d'annuler
        //à la liste des dates disponibles
        if(
          this.selectedResource.id === resource.id
          && this.includedAvailableDates.length > 0
        ) {
          let newAvailableDate = this.generalService.convertDateTimeToLocal(startDate);
          let clone = [].concat(this.includedAvailableDates);
          this.includedAvailableDates = [];
          clone.push(newAvailableDate);

          this.loadingAvailableCalendar = true;

          setTimeout(() => {
            this.includedAvailableDates = [].concat(clone);
            this.loadingAvailableCalendar = false;
          }, 100);
        }

        this.incommingDateChange();
        this.loadingService.dismissLoading();
      },
      () =>{
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          false,
          "Erreur",
          "un problème a été rencontré lors de l'annulation du rendez-vous",
          "Ok"
        );
      }
    );
  }

  isDateMultipleInIncommingEvents(wantedDate:Date) {
    let filteredEvents = this.incommingEvents.filter((event) => {
      return event.start === wantedDate;
    });

    return filteredEvents.length > 1;
  }
}
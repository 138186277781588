// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main-content {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
}

p {
  font-weight: 400;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

#circle-check {
  height: 24px;
  width: 24px;
  display: block;
}

.out-checkbox {
  height: 24px;
  width: 24px;
  border: 2px solid #8559DA;
  border-radius: 8px;
  padding: 3px;
}

.in-checkbox {
  width: 14px;
  height: 14px;
  left: calc(50% - 7px);
  top: calc(50% - 7px);
  background: #8559DA;
  border-radius: 4px;
}

.text-crossed {
  text-decoration-line: line-through;
}

.speaker-line {
  font-size: 9px;
}

.width-100 {
  width: 100%;
}

.pending-description {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-detail-activity/session-row.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,uBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,UAAA;EACA,SAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,cAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,qBAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kCAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,iBAAA;EACA,kBAAA;EACA,gBAAA;AACJ","sourcesContent":["#main-content{\r\n    display:flex;\r\n    flex-direction:row;\r\n    gap:8px;\r\n    align-items: flex-start;\r\n}\r\n\r\np{\r\n    font-weight: 400;\r\n    font-size: 14px;\r\n    padding:0;\r\n    margin:0;\r\n}\r\n\r\n#circle-check{\r\n    height:24px;\r\n    width:24px;\r\n    display:block;\r\n}\r\n\r\n.out-checkbox{\r\n    height:24px;\r\n    width:24px;\r\n    border: 2px solid #8559DA;\r\n    border-radius: 8px;\r\n    padding:3px\r\n}\r\n\r\n.in-checkbox{\r\n    width: 14px;\r\n    height: 14px;\r\n    left: calc(50% - 14px/2);\r\n    top: calc(50% - 14px/2);\r\n    background: #8559DA;\r\n    border-radius: 4px;\r\n}\r\n\r\n.text-crossed{\r\n    text-decoration-line: line-through;\r\n}\r\n\r\n.speaker-line{\r\n    font-size:9px\r\n}\r\n\r\n.width-100 {\r\n    width: 100%;\r\n}\r\n\r\n.pending-description {\r\n    margin-left:16px;\r\n    margin-right: 16px;\r\n    margin-top: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

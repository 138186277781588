// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-textarea {
  color: black;
  background-color: white;
  border-color: grey;
  border-radius: 5px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/template-form-description-custom/template-form-description-custom.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;AACJ","sourcesContent":["ion-textarea {\r\n    color: black;\r\n    background-color: white;\r\n    border-color: grey;\r\n    border-radius: 5px;\r\n}\r\n\r\n.button-container {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    padding-right: 12px;\r\n    padding-top: 12px;\r\n    padding-bottom: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

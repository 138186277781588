import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class SituationFamilialeService {
  constructor(private httpClient: HttpClient,
              private constants: Constants,
              private headerService: HeadersService) { }

  getSituationsFamiliales() {
    const url = this.constants.apiGetPostEndpoint + '/situation_familiales';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(intitule: string) {
    const url = this.constants.apiGetPostEndpoint + "/situation_familiales";
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {situation: intitule}, httpHeaders);
  }

  edite(intitule: string, lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();
    return this.httpClient.patch(url, {situation: intitule}, httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class SoftSkillService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

    getSoftSkills() {
    const url = this.constants.apiGetPostEndpoint + '/soft_skills';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(competence: any) {
    const url = this.constants.apiGetPostEndpoint + "/soft_skills";
    const httpHeaders = this.headerService.getPostHeader();
    const urls_intervenants = this.extraitIntervenantsUrls(competence["intervenants_nouveau"]);

    return this.httpClient.post(url, {
      intitule: competence["competence_nouveau"],
      dateDebut: competence["date_debut_nouveau"],
      dateFin: competence["date_fin_nouveau"],
      adresse: competence["adresse_nouveau"],
      ville: competence["ville_nouveau"],
      codePostal: competence["code_postal_nouveau"],
      intervenants: urls_intervenants
    }, httpHeaders);
  }

  edite(competence:any, lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();
    const urls_intervenants = this.extraitIntervenantsUrls(competence["intervenants_edition"]);

    return this.httpClient.patch(url, {
      intitule: competence["competence_edition"],
      dateDebut: competence["date_debut_edition"],
      dateFin: competence["date_fin_edition"],
      adresse: competence["adresse_edition"],
      ville: competence["ville_edition"],
      codePostal: competence["code_postal_edition"],
      intervenants: urls_intervenants
    },httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }

  extraitIntervenantsUrls(intervenants: any) {
    let urls = [];
    for(let intervenant of intervenants) {
      urls.push(intervenant["@id"]);
    }
    return urls;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getQuestions() {
    const url = this.constants.apiGetPostEndpoint + '/questions';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(question: any) {
    const url = this.constants.apiGetPostEndpoint + "/questions";
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {
      intitule: question["intitule_nouveau"],
    }, httpHeaders);
  }

  edite(question:any, lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();

    return this.httpClient.patch(url, {
      intitule: question["intitule_edition"],
    },httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

import { Objectif } from "./objectif";

export class Etape {
    id?:string;
    nom?:string;
    numeroEtape?:number;
    duree?:number;
    descriptionDebut?:string;
    descriptionFin?:string;
    imageMonument?:string;
    objectifs?:Objectif[];


    //
    termine?:boolean;
    enCours?:boolean;
    estLienDescriptionDebut?:boolean;
}
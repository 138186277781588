import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObjectifsUtilisateurService } from 'src/app/services/ObjectifsUtilisateurs/objectifs-utilisateur.service';
import { EtapeService } from 'src/app/services/etape/etape.service';
import { ObjectifService } from 'src/app/services/Objectif/objectif.service';
import { UtilisateurNiveauxService } from 'src/app/services/UtilisateurNiveaux/utilisateur-niveaux.service';
import { TypeObjectifService } from 'src/app/services/TypesObjectif/type-objectif.service';
import { AtelierService } from 'src/app/services/Atelier/atelier.service';
import { SoftSkillService } from 'src/app/services/SoftSkill/soft-skill.service';
import { EntrepriseStageService } from 'src/app/services/EntreprisesStage/entreprise-stage.service';
import { CompetencesCleService } from 'src/app/services/CompetencesCle/competences-cle.service';
import { PlateauTechniqueService } from 'src/app/services/PlateauTechnique/plateau-technique.service';
import { IonModal } from '@ionic/angular';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';
import { UtilisateurService } from 'src/app/services/Utilisateur/utilisateur.service';
import { DescriptionCustomService } from 'src/app/services/DescriptionCustom/description-custom.service';

@Component({
  selector: 'app-objectifs-utilisateurs',
  templateUrl: './objectifs-utilisateurs.component.html',
  styleUrls: ['./objectifs-utilisateurs.component.scss'],
})
export class ObjectifsUtilisateursComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  utilisateur = {
    "id": null,
    "nom": null,
    "prenom": null,
    "bilanIntermediaireFait": false
  };
  id:string;
  url_edition:string;
  index_objectif_utilisateur:number;
  mode_edition:boolean=true;
  public objectifs_utilisateur: any;
  private etapes: any;
  private objectifs: any;
  private types_objectifs: any;
  public ateliers: any;
  public soft_skills: any;
  public entreprises_stage: any;
  public competences_cle: any;
  public plateaux: any;
  loading_objectifs_utilisateur: boolean=false;
  loading_etapes: boolean=false;
  loading_objectifs: boolean=false;
  loading_types_objectifs: boolean = false;
  loading_ateliers: boolean=false;
  loading_soft_skills: boolean=false;
  loading_entreprises_stage: boolean=false;
  loading_competences_cle: boolean=false;
  loading_plateaux: boolean=false;
  // loading_utilisateur_sous_objectifs: boolean=false;
  changing_validated_checkbox: boolean=false;
  liste_elements_edition: any;
  current_element: string;
  objectif_en_cours: string;
  type_objectif_en_cours: string;
  // sous_objectifs_associes = [];
  noObjectif:boolean=false;
  currentNiveau:number=1;
  etatActiviteNiveaux = {};
  toggleState:boolean=false;
  objectifsCustomMode:boolean=false;
  displayedDescriptionsCustom=[];
  descriptionCustomMode:boolean=false;
  currentDisplayedDescriptionsCustomIndex:number;
  currentIndexCurrentEditedDescriptionCustom:number;
  currentEtapeName:string;

  editionForm = new FormGroup({
    element_edition: new FormControl('', [Validators.required]),
    objectif_atteint_edition: new FormControl(false, [Validators.required]),
  });

  descriptionCustomForm = new FormGroup({
    nom: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    estValide: new FormControl(false, [Validators.required])
  });

  constructor(private _Activatedroute:ActivatedRoute,
              private objectifsUtilisateurService: ObjectifsUtilisateurService,
              private etapeService: EtapeService,
              private objectifService: ObjectifService,
              private typeObjectifService: TypeObjectifService,
              private atelierService: AtelierService,
              private softSkillService: SoftSkillService,
              private entrepriseStageService: EntrepriseStageService,
              private competencesCleService: CompetencesCleService,
              private plateauTechniqueService: PlateauTechniqueService,
              private utilisateurNiveauxService:UtilisateurNiveauxService,
              private errorNotificationService: ErrorNotificationService,
              private loadingService: LoadingService,
              private toastService: ToastService,
              private utilisateurService: UtilisateurService,
              private descriptionCustomService:DescriptionCustomService) { }

  ngOnInit() {
    this.id=this._Activatedroute.snapshot.paramMap.get("id");
    this.loadingService.showLoading().then(
      () => {
        this.getObjectifsUtilisateur();
        this.getAteliers();
        this.getSoftSkills();
        this.getEntreprisesStage();
        this.getCompetencesCle();
        this.getPlateaux();
      }
    );
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  //recuperation des liaisons objectifsUtilisateur enregistres en bdd
  getObjectifsUtilisateur() {
    const id_utilisateur:number = +this.id; //convertion string en number
    this.loading_objectifs_utilisateur = true;
    this.objectifsUtilisateurService.getUtilisateurObjectifs(id_utilisateur, null, null, this.currentNiveau).subscribe(
      data => {
        let infos = data;
        this.objectifs_utilisateur = infos["hydra:member"];

        //empeche un loader plus long que necessaire si l'utilisateur n'a pas d'objectif
        if(this.objectifs_utilisateur.length === 0) {
          this.noObjectif = true;
        } else {
          this.noObjectif = false;
          this.utilisateur.id = this.objectifs_utilisateur[0].utilisateur.id;
          this.utilisateur.nom = this.objectifs_utilisateur[0].utilisateur.nom;
          this.utilisateur.prenom = this.objectifs_utilisateur[0].utilisateur.prenom;
          this.utilisateur.bilanIntermediaireFait = this.objectifs_utilisateur[0].utilisateur.bilanIntermediaireFait;
          this.getNiveauxActifs(this.objectifs_utilisateur[0].utilisateur.utilisateurNiveaux);
        }

        this.loading_objectifs_utilisateur = false;
        this.checkLoading();
      },
      () => {
        this.loading_objectifs_utilisateur = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getNiveauxActifs(utilisateurNiveaux) {
    utilisateurNiveaux.forEach(utilisateurNiveau => {
      this.etatActiviteNiveaux[utilisateurNiveau.niveau.numeroNiveau] = utilisateurNiveau.desactive;
    });

    if(this.etatActiviteNiveaux[this.currentNiveau] != this.toggleState) {
      this.toggleState = !this.toggleState;
    }
  }

  getDisableStyle() {
    if(this.etatActiviteNiveaux[this.currentNiveau]) {
      return "custom-disabled";
    }
  }

  isFirstNiveau() {
    if(this.currentNiveau === 1) {
      return true;
    }
    return false;
  }

  toggleSwitch() {
    this.toggleState = !this.toggleState;
    this.etatActiviteNiveaux[this.currentNiveau] = !this.etatActiviteNiveaux[this.currentNiveau];
    let utilisateurNiveau = this.objectifs_utilisateur[0].utilisateur.utilisateurNiveaux[this.currentNiveau-1];
    this.loadingService.showLoading();

    this.utilisateurNiveauxService.editDesactive(utilisateurNiveau.id, this.toggleState).subscribe(
      () => {
        this.loadingService.dismissLoading();
        //si le niveau est desactive, ses objectifs sont invalides
        if(this.toggleState) {
          for(let objectifUtilisateur of this.objectifs_utilisateur) {
            objectifUtilisateur.estValide = false;
          }
        }
      },
      () => {
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors de l'enregistrement des données",
          "Ok"
        );
      }
    )
  }

  //recuperation des donnees des objectifs enregistres en bdd
  getObjectifs() {
    this.loading_objectifs = true;
    this.objectifService.getObjectifs().subscribe(
      data => {
        let infos = data;
        this.objectifs = infos["hydra:member"];
        this.loading_objectifs = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
      },
      () => {
        this.loading_objectifs = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  //recuperation des donnees des types d'objectifs enregistres en bdd
  getTypesObjectifs() {
    this.loading_types_objectifs = true;
    this.typeObjectifService.getTypes().subscribe(
      data => {
        let infos = data;
        this.types_objectifs = infos["hydra:member"];
        this.loading_types_objectifs = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
      },
      () => {
        this.loading_types_objectifs = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  //recuperation des donnees des ateliers enregistres en bdd
  getAteliers() {
    this.loading_ateliers = true;
    this.atelierService.getAteliers().subscribe(
      data => {
        let infos = data;
        this.ateliers = infos["hydra:member"];
        this.loading_ateliers = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
      },
      () => {
        this.loading_ateliers = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  //recuperation des donnees des soft skills enregistres en bdd
  getSoftSkills() {
    this.loading_soft_skills = true;
    this.softSkillService.getSoftSkills().subscribe(
      data => {
        let infos = data;
        this.soft_skills = infos["hydra:member"];
        this.loading_soft_skills = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
      },
      () => {
        this.loading_soft_skills = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  //recuperation des donnees des entreprises stage enregistres en bdd
  getEntreprisesStage() {
    this.loading_entreprises_stage = true;
    this.entrepriseStageService.getEntreprises().subscribe(
      data => {
        let infos = data;
        this.entreprises_stage = infos["hydra:member"];
        this.loading_entreprises_stage = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
      },
      () => {
        this.loading_entreprises_stage = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  //recuperation des donnees des entreprises stage enregistres en bdd
  getCompetencesCle() {
    this.loading_competences_cle = true;
    this.competencesCleService.getCompetences().subscribe(
      data => {
        let infos = data;
        this.competences_cle = infos["hydra:member"];
        this.loading_competences_cle = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
      },
      () => {
        this.loading_competences_cle = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  //recuperation des donnees des plateaux techniques enregistrees en bdd
  getPlateaux() {
    this.loading_plateaux = true;
    this.plateauTechniqueService.getPlateauxTechniques().subscribe(
      data => {
        let infos = data;
        this.plateaux = infos["hydra:member"];
        this.loading_plateaux = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
      },
      () => {
        this.loading_plateaux = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  validatedChange() {
    this.loadingService.showLoading();
    this.utilisateur.bilanIntermediaireFait = !this.utilisateur.bilanIntermediaireFait;
    let userId = this.utilisateur.id;
    let done = this.utilisateur.bilanIntermediaireFait;
    this.changing_validated_checkbox = true;

    this.utilisateurService.updateEtatBilanIntermediaire(userId, done).subscribe (
      () => {
        this.changing_validated_checkbox = false;
        this.checkLoading();
      },
      () => {
        this.changing_validated_checkbox = false;
        this.checkLoading();
        //en cas d'erreur, la checkbox retrouve sa valeur de base
        this.utilisateur.bilanIntermediaireFait = !this.utilisateur.bilanIntermediaireFait;
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors de l'enregistrement des données",
          "Ok"
        );
      }
    );
  }

  checkLoading() {
    if((!this.loading_objectifs_utilisateur && !this.changing_validated_checkbox
      && !this.loading_objectifs && !this.loading_ateliers
      && !this.loading_soft_skills && !this.loading_entreprises_stage
      && !this.loading_competences_cle && !this.loading_types_objectifs
      && !this.loading_plateaux)
      /*&& !this.loading_utilisateur_sous_objectifs)*/ || this.noObjectif) {
        this.loadingService.dismissLoading();
    }
  }

  compareFn(e1, e2): boolean {
    return e1 && e2 ? e1.estValide == e2.estValide : e1 == e2;
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  //pour le formulaire d'edition
  //coche les checkboxes des options selectionnees
  compareWith(o1, o2) {
    return o1 && o2 ? o1.intitule === o2.intitule : o1 === o2;
  }

  niveauChange(event) {
    this.currentNiveau = parseInt(event.detail.value);
    this.loadingService.showLoading().then(
      () => {
        this.getObjectifsUtilisateur();
      }
    );
  }

  ouvreModaleEdition(objectif_utilisateur:any) {
    this.objectifsCustomMode = false;
    this.mode_edition = true;
    this.url_edition = "/utilisateur_objectifs/" + objectif_utilisateur.id;
    this.modal.present();
    let element;
    this.objectif_en_cours = objectif_utilisateur.objectif.nom;
    this.type_objectif_en_cours = objectif_utilisateur.objectif.typeObjectif.intitule;

    if(!!objectif_utilisateur.atelier) { 
      element = objectif_utilisateur.atelier;
    } else if(!!objectif_utilisateur.competenceCle) {
      element = objectif_utilisateur.competenceCle;
    } else if(!!objectif_utilisateur.entrepriseStage) {
      element = objectif_utilisateur.entrepriseStage;
    } else if(!!objectif_utilisateur.softSkill) {
      element = objectif_utilisateur.softSkill;
    } else if(!!objectif_utilisateur.PlateauTechnique) {
      element = objectif_utilisateur.PlateauTechnique;
    }

    //let sous_objectifs_ids = [];
    let id_etape:number;
    //this.sous_objectifs_associes = []; //reset

    /*for(let sous_objectif of objectif_utilisateur.objectif.sousObjectifs) {
      this.sous_objectifs_associes.push({
        "id": sous_objectif.id,
        "nom": sous_objectif.nom,
        "estValide": false
      });

      id_etape = objectif_utilisateur.etape.id;
      sous_objectifs_ids.push(sous_objectif.id);
    }

    if(sous_objectifs_ids.length > 0) {
      this.getUtilisateurSousObjectifs(this.utilisateur.id, sous_objectifs_ids, id_etape);
    }*/

    this.current_element = this.type_objectif_en_cours;
    this.editionForm.patchValue({
      "element_edition": element,
      "objectif_atteint_edition": objectif_utilisateur.estValide,
    });

    this.index_objectif_utilisateur = this.objectifs_utilisateur.lastIndexOf(objectif_utilisateur);
  }

  onWillDismiss() {
    //this.sous_objectifs_associes = [];
    this.objectifsCustomMode=false;
    this.displayedDescriptionsCustom=[];
    this.descriptionCustomMode=false;
  }

  /*getUtilisateurSousObjectifs(utilisateur_id, sous_objectifs_ids, etape_id) {
    this.loading_utilisateur_sous_objectifs = true;
    this.objectifsUtilisateurService.getUtilisateurSousObjectifs(utilisateur_id, sous_objectifs_ids, etape_id).subscribe(
      data => {
        let infos = data;
        let sous_objectifs = infos["hydra:member"];

        for(let sous_objectif of sous_objectifs) {
          this.sous_objectifs_associes.find(element => element.id === sous_objectif.objectif.id).estValide = sous_objectif.estValide;
        }

        this.loading_utilisateur_sous_objectifs = false;
        this.checkLoading();
      },
      () => {
        this.loading_utilisateur_sous_objectifs = false;
        if(!this.noObjectif) {
          this.checkLoading();
        }
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }*/

  /*objectifChange(event) {
    for(let index in this.sous_objectifs_associes) {
      this.sous_objectifs_associes[index].estValide = event.detail.value;
    }
  }*/

  /*sousObjectifChange(sous_objectif, event) {
    this.sous_objectifs_associes.find(element => element.id === sous_objectif.id).estValide = (event.detail.value === "true");
    let cpt = 0;
    
    for(sous_objectif of this.sous_objectifs_associes) {
      if(!sous_objectif.estValide) {
        cpt++;
      }
    }

    if(cpt === 0) {
      this.editionForm.patchValue({
        "objectif_atteint_edition": true,
      });
    } else {
      this.editionForm.patchValue({
        "objectif_atteint_edition": false,
      });
    }
  }*/

  customEditFormValid() {
    return this.editionForm.invalid;
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;
    const memo_element = this.editionForm.controls["element_edition"].value;

    this.objectifsUtilisateurService.edite(formValues, /*this.sous_objectifs_associes,*/ this.url_edition).subscribe(
      data => {
        let objet_edite:any = data;
        this.loadingService.dismissLoading();
        this.objectifs_utilisateur[this.index_objectif_utilisateur]["atelier"] = objet_edite["atelier"];
        this.objectifs_utilisateur[this.index_objectif_utilisateur]["competenceCle"] = objet_edite["competenceCle"];
        this.objectifs_utilisateur[this.index_objectif_utilisateur]["entrepriseStage"] = objet_edite["entrepriseStage"];
        this.objectifs_utilisateur[this.index_objectif_utilisateur]["softSkill"] = objet_edite["softSkill"];
        this.objectifs_utilisateur[this.index_objectif_utilisateur]["PlateauTechnique"] = objet_edite["PlateauTechnique"];
        this.objectifs_utilisateur[this.index_objectif_utilisateur]["estValide"] = objet_edite["estValide"];

        switch(this.current_element) {
          case "Compétence clé":
            this.objectifs_utilisateur[this.index_objectif_utilisateur].competenceCle = memo_element;
            break;

          case "E-culture":
            this.objectifs_utilisateur[this.index_objectif_utilisateur].atelier = memo_element;
            break;

          case "Soft skill":
            this.objectifs_utilisateur[this.index_objectif_utilisateur].softSkill = memo_element;
            break;

          case "Stage entreprise":
            this.objectifs_utilisateur[this.index_objectif_utilisateur].entrepriseStage = memo_element;
            break;

          case "Visite plateau technique":
            this.objectifs_utilisateur[this.index_objectif_utilisateur].PlateauTechnique = memo_element;
            break;
        }        

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Modifié", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    );
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }

  showDescriptionsCustom(element, currentEtapeName) {
    this.currentEtapeName = currentEtapeName;
    this.objectifsCustomMode = true;
    this.displayedDescriptionsCustom = element.descriptionCustoms; //array vide si nada
    this.modal.present();
  }

  showDescriptionCustomDetails(selectedElement, index) {
    this.currentDisplayedDescriptionsCustomIndex = index;
    this.currentIndexCurrentEditedDescriptionCustom = selectedElement.id;
    this.descriptionCustomMode = true;
  
    this.descriptionCustomForm.patchValue({
      "nom": selectedElement.nom,
      "estValide": selectedElement.estValide
    });
  }

  backToDescriptions() {
    this.descriptionCustomMode = false;
  }

  editDescriptionCustom() {
    const formValue = this.descriptionCustomForm.value;

    this.descriptionCustomService.edit(
      this.currentIndexCurrentEditedDescriptionCustom,
      formValue.nom,
      formValue.estValide
    ).subscribe(
      () => {
        let index = this.currentDisplayedDescriptionsCustomIndex;
        this.displayedDescriptionsCustom[index].nom = formValue.nom;
        this.displayedDescriptionsCustom[index].estValide = formValue.estValide;
        this.backToDescriptions();
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    )
  }

  closeModal() {
    this.modal.dismiss();
  }
}

import { Component, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Objectif } from 'src/app/Models/objectif';
import { TechnicalPlatformPage } from 'src/app/pages/technical-platform/technical-platform.page';
import { ToastService } from 'src/app/services/Toast/toast.service';
import { ModalDetailObjectifComponent } from '../modal-detail-objectif/modal-detail-objectif.component';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-session-row',
  templateUrl: './session-row.component.html',
  styleUrls: ['./session-row.component.scss'],
})
export class SessionRowComponent {
  @Input() sessionComplete: boolean;
  @Input() text: string;
  @Input() speaker?: string;
  @Input() sessionDate?: Date;
  @Input() objectif: Objectif;//!!! ceci contient 1 entité UtilisateurObjectif de l'API - bad rabbit case
  @Input() userId: any;

  typesObjectifs = [
    'atelier',
    'entrepriseStage',
    'competenceCle',
    'softSkill',
    'PlateauTechnique',
  ];

  technicalPlatformPage = TechnicalPlatformPage;
  technicalPlatformId:any;
  // etatSousObjectifs={};
  // sousObjectifsReady:boolean=false;
  descriptionSoftSkills:any[];
  descriptionAteliers:any[];
  descriptionCompetenceCles:any[];
  autreDescriptionSoftSkill:boolean=false;
  autreDescriptionAtelier:boolean=false;
  descriptionsAteliersLoading:boolean=false;
  descriptionsSoftSkillsLoading:boolean=false;
  descriptionsCompetenceClesLoading:boolean=false;
  autreDescriptionCompetenceCle1:boolean=false;
  autreDescriptionCompetenceCle2:boolean=false;
  autreDescriptionCompetenceCle3:boolean=false;
  autreDescriptionCompetenceCle4:boolean=false;
  champsAutresEdites = {
    "atelier": false,
    "softSkill": false,
    "competenceCle1": false,
    "competenceCle2": false,
    "competenceCle3": false,
    "competenceCle4": false
  };

  public freeInputQuantity:number=4;

  testForm = new FormGroup({
    nom: new FormControl('', [Validators.required, Validators.maxLength(255)])
  });

  constructor(
    private modalCtrl: ModalController, 
    private toast:ToastService,
    private route:Router,
  ) {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if(this.objIsAssign(this.objectif) && this.objIsTechnicalPlatform(this.objectif)){
      this.technicalPlatformId = this.objectif.PlateauTechnique;
    }
  }

  async openObjectifModal() {
    if (this.objIsAssign(this.objectif)) {
      const modal = await this.modalCtrl.create({
        component: ModalDetailObjectifComponent,
        cssClass: 'transparent-modal',
        componentProps: {
          objectif: this.objectif,
        },
      });
      modal.present();
      this.cancel();
    } else {
      // A VOIR - pierre
      console.log(
        this.toast.presentToast("bottom", "Votre objectif n'a pas encore été fixé par votre conseiller", "#C64B7F")
      );
    }
  }

  openTechnicalPlatformSurvey(objectif:Objectif, type:string){
    this.cancel();//ferme la modale

    if(type === "PlateauTechnique") {
      const navigationExtras: NavigationExtras = {
        state: {
            "userId": this.userId,
            "plateauTechnique": {
              "id": objectif.PlateauTechnique.id,
              "nom": objectif.PlateauTechnique.intitule,
              "adresse": objectif.PlateauTechnique.adresse,
              "ville": objectif.PlateauTechnique.ville,
              "codePostal": objectif.PlateauTechnique.codePostal,
              "referent": objectif.PlateauTechnique.referentStructure,
              "dateVisite": objectif.PlateauTechnique.date
            }
        }
      };
      this.route.navigate(['/user_plateau_technique'], navigationExtras);
    } else if(type === "entrepriseStage") {
      const navigationExtras: NavigationExtras = {
        state: {
            "userId": this.userId,
            "entrepriseStage": {
              "id": objectif["entrepriseStage"].id,
              "nom": objectif["entrepriseStage"].intitule,
              "dateDebut": objectif["entrepriseStage"].dateDebut,
              "dateFin": objectif["entrepriseStage"].dateFin,
              "referent": objectif["entrepriseStage"].referent,
              "email": objectif["entrepriseStage"].email,
              "telephone": objectif["entrepriseStage"].telephone,
              "adresse": objectif["entrepriseStage"].adresse,
              "codePostal": objectif["entrepriseStage"].codePostal,
              "ville": objectif["entrepriseStage"].ville,
              "description": objectif["entrepriseStage"].description
            }
        }
      };

      this.route.navigate(['/user_stage'], navigationExtras);
    }
  }

  onObjClick(){
    if(this.objIsAssign(this.objectif)){
      this.typesObjectifs.forEach(type => {
        if(this.objectif[type] != null){
          switch(type) {
            case "PlateauTechnique":
              this.openTechnicalPlatformSurvey(this.objectif, type);
              break;

            case "entrepriseStage":
              if(!this.objectif.estValide) {
                this.openTechnicalPlatformSurvey(this.objectif, type);
              } else {
                this.openObjectifModal();
              }
              break;

            default:
              this.openObjectifModal();
              break;
          }
        }
      });
    }
  }

  objIsTechnicalPlatform(objectif:Objectif){
    let check:boolean = false;
    if(this.objIsAssign(this.objectif)){
      this.typesObjectifs.forEach(type => {
        if(this.objectif[type] != null){
          if(type === this.typesObjectifs[4]){
            check = true;
          } 
        }
      });
    }
    return check;
  }

  objIsAssign(objectif:Objectif):boolean{
    let check:boolean = false;
    this.typesObjectifs.forEach(type => {
      if(objectif[type] != null){
        check = true;
      }
    });
    return check;
  }

  public SpeakerOrDateCheck() {
    if (this.speaker != null && this.sessionDate != null) {
      return true;
    } else {
      return false;
    }
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  postDescriptionCustomCreation(newDescriptionCustom) {
    this.objectif["descriptionCustoms"].push(newDescriptionCustom);
  }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { ModesDeplacementService } from '../../services/ModesDeplacement/modes-deplacement.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';

@Component({
  selector: 'app-mode-deplacement',
  templateUrl: './mode-deplacement.component.html',
  styleUrls: ['./mode-deplacement.component.scss'],
})
export class ModeDeplacementComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  nom_nouveau:string;
  url_edition:string;
  index_mode:number;
  mode_edition:boolean=true;
  private modes: any;

  nouveauForm = new FormGroup({
    nom_nouveau: new FormControl('', [Validators.required, Validators.maxLength(45)]),
  });

  editionForm = new FormGroup({
    nom_edition: new FormControl('', [Validators.required, Validators.maxLength(45)]),
  });

  constructor(private modeDeplacementService: ModesDeplacementService,
              private errorNotificationService: ErrorNotificationService,
              private loadingService: LoadingService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.getModesDeplacement();
      }
    );
  }

  getModesDeplacement() {
    this.modeDeplacementService.getModesDeplacement().subscribe(
      data => {
        let infos = data;
        this.modes = infos["hydra:member"];
        this.loadingService.dismissLoading();
      },
      error => {
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  ouvreModaleNouveau() {
    this.mode_edition = false;
    this.modal.present();
  }

  ouvreModaleEdition(mode:any) {
    this.mode_edition = true;
    this.editionForm.patchValue({
      "nom_edition": mode.nom
    });
    this.url_edition = mode["@id"];
    this.modal.present();
    this.index_mode = this.modes.lastIndexOf(mode);
  }

  supprimeAlert(mode:any) {
    this.index_mode = this.modes.lastIndexOf(mode);
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.supprime.bind(this),
      [mode["@id"]]
    );
  }

  submitNouveau() {
    this.loadingService.showLoading();
    const formValues = this.nouveauForm.value;

    this.modeDeplacementService.cree(formValues).subscribe(
      data => {
        let nouvel_objet:any = data;
        this.loadingService.dismissLoading();

        this.modes.push({
          "@id": nouvel_objet["@id"],
          "nom": nouvel_objet.nom,
        });

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error["hydra:description"],
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'enregistrement a échoué", "danger");
        }
        this.loadingService.dismissLoading();
      }
    )
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;
    this.modeDeplacementService.edite(formValues, this.url_edition).subscribe(
      data => {
        let objet_edite:any = data;
        this.loadingService.dismissLoading();

        this.modes[this.index_mode] = {
          "@id": objet_edite["@id"],
          "nom": objet_edite.nom,
        };

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Modifié", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    )
  }

  supprime(lien:string) {
    this.loadingService.showLoading();
    this.modeDeplacementService.supprime(lien[0]).subscribe(
      () => {
        this.loadingService.dismissLoading();
        this.modes.splice(this.index_mode, 1);
        this.toastService.presentToast("bottom", "Supprimé", "success");
      },
      error => {
        this.loadingService.dismissLoading();

        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
        }
      }
    )
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }
}

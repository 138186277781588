import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class ConditionsUtilisationGuard  {
  constructor(private router:Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {    
    let conditionsAcceptees:any = window.sessionStorage.getItem('conditionsAcceptees');

    if(conditionsAcceptees !== true && conditionsAcceptees !== "true") {
      return this.router.navigateByUrl("/");
    }
    
    return true;
  }
}

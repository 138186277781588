import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class EntrepriseStageService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getEntreprises() {
    const url = this.constants.apiGetPostEndpoint + '/entreprise_stages';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(entreprise: any) {
    const url = this.constants.apiGetPostEndpoint + "/entreprise_stages";
    const httpHeaders = this.headerService.getPostHeader();
    const urls_intervenants = this.extraitIntervenantsUrls(entreprise["intervenants_nouveau"]);

    return this.httpClient.post(url, {
      intitule: entreprise["nom_nouveau"],
      dateDebut: entreprise["date_debut_nouveau"],
      dateFin: entreprise["date_fin_nouveau"],
      intervenants: urls_intervenants,
      referent: entreprise["referent_nouveau"],
      email: entreprise["email_nouveau"],
      telephone: entreprise["telephone_nouveau"],
      adresse: entreprise["adresse_nouveau"],
      codePostal: entreprise["code_postal_nouveau"],
      ville: entreprise["ville_nouveau"],
      logo: entreprise["logo_nouveau"],
      description: entreprise["description_nouveau"],
    }, httpHeaders);
  }

  edite(entreprise:any, lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();
    const urls_intervenants = this.extraitIntervenantsUrls(entreprise["intervenants_edition"]);

    return this.httpClient.patch(url, {
      intitule: entreprise["nom_edition"],
      dateDebut: entreprise["date_debut_edition"],
      dateFin: entreprise["date_fin_edition"],
      intervenants: urls_intervenants,
      referent: entreprise["referent_edition"],
      email: entreprise["email_edition"],
      telephone: entreprise["telephone_edition"],
      adresse: entreprise["adresse_edition"],
      codePostal: entreprise["code_postal_edition"],
      ville: entreprise["ville_edition"],
      logo: entreprise["logo_edition"],
      description: entreprise["description_edition"],
    },httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }

  extraitIntervenantsUrls(intervenants: any) {
    let urls = [];
    for(let intervenant of intervenants) {
      urls.push(intervenant["@id"]);
    }
    return urls;
  }
}

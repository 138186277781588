import { Injectable } from '@angular/core';
import { TypeRecompense } from '../Models/typeRecompense';

@Injectable({
  providedIn: 'root'
})
export class RewardsTypeService {

  static TYPES: TypeRecompense[];

  constructor() { }

  TYPES:TypeRecompense[] = [
    {
      enName:"culture",
      frName:"Culture",
      color:"#D53E35"
    },
    {
      enName:"hobby",
      frName:"Loisir",
      color:"#8559DA"
    },
    {
      enName:"purchasing-power",
      frName:"Pouvoir d'achat",
      color:"#2D96D0"
    },
  ]
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { ObjectifService } from '../../services/Objectif/objectif.service'
import { TypeObjectifService } from '../../services/TypesObjectif/type-objectif.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';

@Component({
  selector: 'app-objectif',
  templateUrl: './objectif.component.html',
  styleUrls: ['./objectif.component.scss'],
})
export class ObjectifComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  url_edition:string;
  index_objectif:number;
  mode_edition:boolean=true;
  public objectifs: any;
  public types: any;
  // private sous_objectifs: any;
  loading_types: boolean=false;
  loading_objectifs:boolean = false;
  // loading_sous_objectifs:boolean = false;
  type_edition_text:string;
  // presence_sous_objectifs:boolean = false;
  nouveau_champ_heures_disponible:boolean = true;
  edition_champ_heures_disponible:boolean = true;
  currentPage:number=1;
  totalQuantityObjectifs:number;
  maxPageNumber:number;
  objectifsPerPage:number = 7;
  nombreResultatsRecherche:number;
  foundObjectifs: any[];
  showSearchLoader:boolean=false;
  getObjectifsTimeout:any=null;
  sousObjectifsTries: object={};
  newObjectifType:string="";
  editObjectifType:string="";

  nouveauForm = new FormGroup({
    nom_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    is_sous_objectif_nouveau: new FormControl(false, [Validators.required]),
    duree_nouveau: new FormControl('', [Validators.required, Validators.min(0), Validators.max(999)]),
    type_nouveau: new FormControl('', [Validators.required]),
    sous_objectifs_nouveau: new FormControl(''),
    description_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
  });

  editionForm = new FormGroup({
    nom_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    is_sous_objectif_edition: new FormControl('', [Validators.required]),
    duree_edition: new FormControl('', [Validators.required, Validators.min(0), Validators.max(999)]),
    type_edition: new FormControl('', [Validators.required]),
    sous_objectifs_edition: new FormControl(''),
    description_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
  });

  searchForm = new FormGroup({
    nom: new FormControl('', [Validators.maxLength(50)]),
    selectedObjectif: new FormControl('')
  });

  constructor(private objectifService: ObjectifService,
              private typeObjectifService: TypeObjectifService,
              private errorNotificationService: ErrorNotificationService,
              private loadingService: LoadingService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.getObjectifs(this.currentPage);
        //this.getSousObjectifs();
        this.getTypes();
      }
    );
  }

  //recuperation des types d'objectifs enregistres en bdd
  getObjectifs(pageNumber:number=1, nom?:string, id?:number) {
    this.loading_objectifs = true;
    this.objectifService.getObjectifs(pageNumber, nom, id).subscribe(
      data => {
        this.loading_objectifs = false;
        let infos = data;
        this.currentPage = pageNumber;
        this.totalQuantityObjectifs = infos["hydra:totalItems"];
        this.updateMaxPageNumber();
        this.objectifs = infos["hydra:member"];
        this.checkLoading();
      },
      () => {
        this.loading_objectifs = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  /*getSousObjectifsByTypes() {
    let typesNames = [];
    this.types.map(function(element) {
      typesNames.push(element.intitule);
    });

    for(let type of typesNames) {
      this.sousObjectifsTries[type] = this.sous_objectifs.filter(
        element => element.typeObjectif.intitule === type
      );
    }
  }*/

  nouveauTypeChange(event) {
    this.newObjectifType = event.detail.value.intitule;
  }

  editionTypeChange(event) {
    this.editObjectifType = event.detail.value.intitule;
  }

  updateMaxPageNumber() {
    this.maxPageNumber = this.totalQuantityObjectifs/this.objectifsPerPage;
    this.maxPageNumber = Math.ceil(this.maxPageNumber);
  }

  //recuperation des sous objectifs enregistres en bdd
  /*getSousObjectifs() {
    this.loading_sous_objectifs = true;
    this.objectifService.getSousObjectifs().subscribe(
      data => {
        this.loading_sous_objectifs = false;
        let infos = data;
        this.sous_objectifs = infos["hydra:member"];
        this.checkLoading();

        if(!!this.types) {
          this.getSousObjectifsByTypes();
        }
      },
      () => {
        this.loading_sous_objectifs = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }*/

  getTypes() {
    this.loading_types = true;
    this.typeObjectifService.getTypes().subscribe(
      data => {
        let infos = data;
        this.types = infos["hydra:member"];
        this.loading_types = false;
        this.checkLoading();

        /*if(!!this.sous_objectifs) {
          this.getSousObjectifsByTypes();
        }*/
      },
      () => {
        this.loading_types = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getObjectifsNames() {
    if(this.getObjectifsTimeout !== null) {
      clearTimeout(this.getObjectifsTimeout);
      this.getObjectifsTimeout = null;
    }

    this.getObjectifsTimeout = window.setTimeout(() => {
      if(!this.searchForm.invalid) {
        this.showSearchLoader = true;
      }

      //si le champ nom d'objectif est vide
      if(this.searchForm.value.nom === "") {
        this.searchForm.reset();
        this.searchForm.setValue({
          nom: "",
          selectedObjectif: ""
        });
        this.foundObjectifs = [];
        this.nombreResultatsRecherche = 0;

        this.loadingService.showLoading().then(
          () => {
            this.getObjectifs();
            this.showSearchLoader = false;
          }
        );
      } else {
        this.objectifService.getObjectifsByName(
          this.searchForm.value.nom,
        ).subscribe(
          data => {
            let infos = data;
            this.foundObjectifs = infos["hydra:member"];
            this.nombreResultatsRecherche = this.foundObjectifs.length;
            this.showSearchLoader = false;
          },
          () => {
            this.errorNotificationService.presentGeneralErrorAlert(
              true,
              "Erreur",
              "Un problème a été rencontré lors de la recherche des objectifs",
              "Ok"
            );
            this.showSearchLoader = false;
          }
        )
      }
    }, 150);
  }

  getSearchText() {
    if(typeof(this.nombreResultatsRecherche) !== "undefined" && this.nombreResultatsRecherche > 0) {
      return this.nombreResultatsRecherche + " résultat(s)";
    }

    return "Aucun résultat";
  }

  //fct a lancer pr afficher les details des objectifs pr ces noms
  searchByName(event) {
    if(this.searchForm.value.nom !== "") {
      if(!this.searchForm.invalid) {
        this.loadingService.showLoading().then(
          () => {
            this.getObjectifs(1, null, event.detail.value);
          }
        );
      }
    }
  }

  changePage(pageNumber:number) {
    this.loadingService.showLoading().then(
      () => {
        this.getObjectifs(pageNumber);
      }
    );
  }

  checkLoading() {
    if(!this.loading_objectifs && !this.loading_types /*&& !this.loading_sous_objectifs*/) {
        this.loadingService.dismissLoading();
    }
  }

  isItLoading() {
    return this.loading_objectifs || this.loading_types /*|| this.loading_sous_objectifs*/;
  }

  //pour le formulaire d'edition
  //coche les checkboxes des options selectionnees
  compareWith(o1, o2) {
    return o1 && o2 ? o1["@id"] === o2["@id"] : o1 === o2;
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  ouvreModaleNouveau() {
    this.mode_edition = false;
    this.modal.present();
  }

  ouvreModaleEdition(objectif:any) {
    // this.edition_champ_heures_disponible = objectif.sousObjectifs.length <= 0;
    this.edition_champ_heures_disponible = true;

    this.mode_edition = true;

    this.editionForm.patchValue({
      "nom_edition": objectif.nom,
      "duree_edition": objectif.duree,
      "type_edition": objectif.typeObjectif,
      //"sous_objectifs_edition": objectif.sousObjectifs,
      "description_edition": objectif.description,
      //"is_sous_objectif_edition": objectif.IsSousObjectif
    });

    this.editObjectifType = objectif.typeObjectif.intitule;
    this.type_edition_text = objectif.typeObjectif.intitule;
    this.url_edition = objectif["@id"];
    this.index_objectif = this.objectifs.lastIndexOf(objectif);
    this.modal.present();
  }

  supprimeAlert(objectif:any) {
    this.index_objectif = this.objectifs.lastIndexOf(objectif);
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.supprime.bind(this),
      [objectif["@id"]]
    );
  }

  /*IsSousObjectifsNouveauChange() {
    // si l'objectif n'est pas un sous objectif et que des sous objectifs lui sont liés
    // on rend le champ duree indisponible (le calcul de la durée est automatique)
    if(!this.nouveauForm.controls['is_sous_objectif_nouveau'].value
    && !!this.nouveauForm.controls["sous_objectifs_nouveau"].value
    && this.nouveauForm.controls["sous_objectifs_nouveau"].value.length > 0) {
      this.nouveau_champ_heures_disponible = false;
    } else {
      // sinon le champ duree est disponible et on reset la liste des sous objectifs lies
      this.nouveau_champ_heures_disponible = true;
      this.nouveauForm.patchValue({
        "sous_objectifs_nouveau": ""
      });
    }
  }*/

  /*IsSousObjectifsEditionChange() {
    // si l'objectif n'est pas un sous objectif et que des sous objectifs lui sont liés
    // on rend le champ duree indisponible (le calcul de la durée est automatique)
    if(!this.editionForm.controls['is_sous_objectif_edition'].value
    && this.editionForm.controls["sous_objectifs_edition"].value.length > 0) {
      this.edition_champ_heures_disponible = false;
    } else {
      // sinon le champ duree est disponible et on reset la liste des sous objectifs lies
      this.edition_champ_heures_disponible = true;
      this.editionForm.patchValue({
        "sous_objectifs_edition": ""
      });
    }
  }*/

  typeEditionChange() {
    this.type_edition_text = this.editionForm.controls["type_edition"].value["intitule"];
  }

  /*nouveauSousObjectifsChange() {
    if(this.nouveauForm.controls["sous_objectifs_nouveau"].value !== null) {
      if(this.nouveauForm.controls["sous_objectifs_nouveau"].value.length > 0) {
        this.nouveau_champ_heures_disponible = false;
        let duree_totale = 0;
  
        for(let sous_objectif of this.nouveauForm.controls["sous_objectifs_nouveau"].value) {
          duree_totale += sous_objectif["duree"];
        }
  
        this.nouveauForm.patchValue({
          "duree_nouveau": duree_totale + ""
        });
      } else {
        this.nouveau_champ_heures_disponible = true;
      }
    }
  }*/

  /*editionSousObjectifsChange() {
    if(this.editionForm.controls["sous_objectifs_edition"].value !== null) {
      if(this.editionForm.controls["sous_objectifs_edition"].value.length > 0) {
        this.edition_champ_heures_disponible = false;
        let duree_totale = 0;
  
        for(let sous_objectif of this.editionForm.controls["sous_objectifs_edition"].value) {
          duree_totale += sous_objectif["duree"];
        }
  
        this.editionForm.patchValue({
          "duree_edition": duree_totale + ""
        });
      } else {
        this.edition_champ_heures_disponible = true;
      }
    }
  }*/

  customNewFormValid() {
    if(this.nouveauForm.controls["type_nouveau"].value === ""
    || this.nouveauForm.invalid) {
      return true;
    }
    return false;
  }

  customEditFormValid() {
    if(this.editionForm.controls["type_edition"].value === ""
    || this.editionForm.invalid) {
      return true;
    }
    return false;
  }

  submitNouveau() {
    this.loadingService.showLoading();
    const formValues = this.nouveauForm.value;
    const memo_type = formValues["type_nouveau"];

    this.objectifService.cree(formValues).subscribe(
      data => {
        const nouvel_objet:any = data;
        this.loadingService.dismissLoading();

        let ajout = {
          "@id": nouvel_objet["@id"],
          "nom": nouvel_objet["nom"],
          "duree": nouvel_objet.duree,
          "typeObjectif": memo_type,
          "description": nouvel_objet["description"],
          // "IsSousObjectif": nouvel_objet["isSousObjectif"],
          // "sousObjectifs": nouvel_objet["sousObjectifs"]
        };

        this.objectifs.push(ajout);

        /*if(nouvel_objet["isSousObjectif"]) {
          this.sous_objectifs.push(ajout);
          this.getSousObjectifsByTypes();
        }*/

        this.totalQuantityObjectifs++;
        this.updateMaxPageNumber();
        this.modal.dismiss();
        this.nouveauForm.controls["nom_nouveau"].reset();
        this.nouveauForm.controls["duree_nouveau"].reset();
        this.nouveauForm.controls["type_nouveau"].reset();
        this.nouveauForm.controls["sous_objectifs_nouveau"].reset();
        this.nouveauForm.controls["description_nouveau"].reset();
        this.nouveauForm.patchValue({"is_sous_objectif_nouveau": false});
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error["hydra:description"],
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'enregistrement a échoué", "danger");
        }
        this.loadingService.dismissLoading();
      }
    )
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;
    const memo_type = formValues["type_edition"];
    //const memo_sous_objectifs = formValues["sous_objectifs_edition"];

    this.objectifService.edite(formValues, this.url_edition).subscribe(
      data => {
        let objet_edite:any = data;
        this.loadingService.dismissLoading();
        this.objectifs[this.index_objectif] = {
          "@id": objet_edite["@id"],
          "nom": objet_edite.nom,
          "duree": objet_edite.duree,
          "typeObjectif": memo_type,
          // "sousObjectifs": memo_sous_objectifs,
          "description": objet_edite["description"],
          // "IsSousObjectif": objet_edite["IsSousObjectif"],
        };

        /*if(objet_edite["isSousObjectif"]) {
          let edited = this.sous_objectifs.find((element) => {
            return element.id == objet_edite["id"]
          });
          edited.nom = objet_edite.nom;
        }*/

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Modifié", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    );
  }

  supprime(lien:string) {
    this.loadingService.showLoading();
    this.objectifService.supprime(lien[0]).subscribe(
      () => {
        this.totalQuantityObjectifs--;
        this.updateMaxPageNumber();
        this.loadingService.dismissLoading();
        this.objectifs.splice(this.index_objectif, 1);
        this.toastService.presentToast("bottom", "Supprimé", "success");
      },
      error => {
        this.loadingService.dismissLoading();

        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
        }
      }
    )
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }
}
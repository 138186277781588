import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class DescriptionObjectifCustomService {
  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getAll(pageNumber?:number) {
    let url = this.constants.apiGetPostEndpoint + '/descriptionsObjectifsCustoms';
    const httpHeaders = this.headerService.getGetHeader();

    if(!!pageNumber) {
      url += "?page=" + pageNumber;
    }

    return this.httpClient.get(url, httpHeaders);
  }

  getTotalQuantity() {
    let url = this.constants.apiGetPostEndpoint + '/getTotalQuantityObjectifsCustoms';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getAllByUser(userId:number, pageNumber?:number) {
    let url = this.constants.apiGetPostEndpoint + '/descriptionsObjectifCustomByUserName';
    const httpHeaders = this.headerService.getPostHeader();

    if(!!pageNumber) {
      url += "?page=" + pageNumber;
    }

    return this.httpClient.post(url, {"UtilisateurId":userId}, httpHeaders);
  }

  getQuantityForAUSer(userId:number) {
    let url = this.constants.apiGetPostEndpoint + '/descriptionsObjectifCustomQuantityByUserName';
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {"UtilisateurId":userId}, httpHeaders);
  }

  cree(idUtilisateurObjectif:number, descriptif:string, estValide:boolean, mode:string="user") {
    const url = this.constants.apiGetPostEndpoint + "/descriptionObjectifCustom";
    const httpHeaders = this.headerService.getPostHeader();

    let data = {
      "nom": descriptif,
      "utilisateurObjectif": "/utilisateur_objectifs/" + idUtilisateurObjectif
    }

    if(mode==="admin") {
      data["estValide"] = estValide;
    } else {
      data["estValide"] = false;
    }

    console.log("pass cree service");

    return this.httpClient.post(url, data, httpHeaders);
  }

  edite(idDescriptionObjectifCustom:number,
    idUtilisateurObjectif:number,
    descriptif:string,
    estValide:boolean,
    mode:string="user"
  ) {    
    let url = this.constants.apiPatchDeleteEndpoint;
    //local
    //url += "/descriptionObjectifCustom/";
    //prod
    //url += "/api/public/descriptionObjectifCustom/";
    //preprod
    //url += "/preprod/api/public/descriptionObjectifCustom/";

    switch(this.constants.mode) {
      case "local":
        url += "/descriptionObjectifCustom/";
        break;

      case "preprod":
        url += "/preprod/api/public/descriptionObjectifCustom/";
        break;

      case "emeraude":
        url += "/preprod/api/public/descriptionObjectifCustom/";
        break;

      case "prod":
        url += "/api/public/descriptionObjectifCustom/";
        break;
    }

    url += idDescriptionObjectifCustom;
    const httpHeaders = this.headerService.getPatchHeader();
    let data = {};

    if(!!descriptif) {
      data["nom"] = descriptif;
    }

    if(!!idUtilisateurObjectif) {
      data["utilisateurObjectif"] = "/utilisateur_objectifs/" + idUtilisateurObjectif;
    }

    if(mode==="admin") {
      data["estValide"] = estValide;
    }
    
    return this.httpClient.patch(url, data, httpHeaders);
  }

  supprime(idDescriptionObjectifCustom:number) {
    const url = this.constants.apiPatchDeleteEndpoint + "/descriptionObjectifCustom/" + idDescriptionObjectifCustom;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

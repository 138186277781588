// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#top-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.top-button {
  margin-right: 2%;
  margin-top: 12px;
}

#descriptions-table {
  width: 96%;
  margin: 48px 2% 48px 2%;
}

#loading-container {
  width: 96%;
  margin-left: 2%;
  margin-top: 48px;
  margin-bottom: 48px;
}

.skeleton-row {
  border: 1px solid rgb(224, 224, 224);
  background-color: white;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeleton {
  height: 85%;
  width: 99%;
}

ion-skeleton-text {
  --background-rgb: 90, 90, 90;
}

#modal {
  padding: 5%;
}

#modal h1 {
  text-align: center;
}

.form-input-div {
  margin-top: 5%;
}

.form-input-div ion-item {
  margin: 16px 5% 16px 5%;
}

.container-boutons-form {
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  margin-top: 5%;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/description-atelier/description-atelier.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,uBAAA;AACJ;;AACA;EACI,gBAAA;EACA,gBAAA;AAEJ;;AACA;EACI,UAAA;EACA,uBAAA;AAEJ;;AACA;EACI,UAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;;AACA;EACI,oCAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEJ;;AACA;EACI,WAAA;EACA,UAAA;AAEJ;;AACA;EACI,4BAAA;AAEJ;;AACA;EACI,WAAA;AAEJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,cAAA;AAEJ;;AACA;EACI,uBAAA;AAEJ;;AACA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,cAAA;AAEJ","sourcesContent":["#top-div {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: flex-end;\r\n    justify-content: center;\r\n}\r\n.top-button {\r\n    margin-right: 2%;\r\n    margin-top: 12px;\r\n}\r\n\r\n#descriptions-table {\r\n    width: 96%;\r\n    margin: 48px 2% 48px 2%;\r\n}\r\n\r\n#loading-container {\r\n    width: 96%;\r\n    margin-left: 2%;\r\n    margin-top: 48px;\r\n    margin-bottom: 48px;\r\n}\r\n\r\n.skeleton-row {\r\n    border: 1px solid rgb(224, 224, 224);\r\n    background-color: white;\r\n    height: 59px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.skeleton {\r\n    height: 85%;\r\n    width: 99%;\r\n}\r\n\r\nion-skeleton-text {\r\n    --background-rgb: 90, 90, 90;\r\n}\r\n\r\n#modal {\r\n    padding: 5%;\r\n}\r\n\r\n#modal h1 {\r\n    text-align: center\r\n}\r\n\r\n.form-input-div {\r\n    margin-top: 5%;\r\n}\r\n\r\n.form-input-div ion-item {\r\n    margin: 16px 5% 16px 5%;\r\n}\r\n\r\n.container-boutons-form {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    padding-right: 16px;\r\n    margin-top: 5%\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { RewardCardTypeComponent } from '../components/reward-card-type/reward-card-type.component';

@NgModule({
  declarations: [RewardCardTypeComponent],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule],
  exports: [RewardCardTypeComponent],
})
export class SharedComponentModule {}

import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { CiviliteService } from '../../services/Civilites/civilite.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';

@Component({
  selector: 'app-civilite',
  templateUrl: './civilite.component.html',
  styleUrls: ['./civilite.component.scss'],
})
export class CiviliteComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;
  civilite_nouveau:string;
  url_edition:string;
  index_civilite:number;
  mode_edition:boolean=true;
  public civilites: any;

  nouveauForm = new FormGroup({
    civilite_nouveau: new FormControl('', [Validators.required, Validators.maxLength(50)]),
  });

  editionForm = new FormGroup({
    civilite_edition: new FormControl('', [Validators.required, Validators.maxLength(50)]),
  });

  constructor(private civiliteService: CiviliteService,
              private errorNotificationService: ErrorNotificationService,
              private loadingService: LoadingService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.getCivilites();
      }
    );
  }

  getCivilites() {
    this.civiliteService.getCivilites().subscribe(
      data => {
        let infos = data;
        this.civilites = infos["hydra:member"];
        this.loadingService.dismissLoading();
      },
      error => {
        this.loadingService.dismissLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  ouvreModaleNouveau() {
    this.mode_edition = false;
    this.modal.present();
  }

  ouvreModaleEdition(civilite:any) {
    this.mode_edition = true;
    this.editionForm.patchValue({
      "civilite_edition": civilite.civilite
    });
    this.url_edition = civilite["@id"];
    this.modal.present();
    this.index_civilite = this.civilites.lastIndexOf(civilite);
  }

  supprimeAlert(civilite:any) {
    this.index_civilite = this.civilites.lastIndexOf(civilite);
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.supprime.bind(this),
      [civilite["@id"]]
    );
  }

  submitNouveau() {
    this.loadingService.showLoading();
    const formValues = this.nouveauForm.value;

    this.civiliteService.cree(formValues).subscribe(
      data => {
        let nouvel_objet:any = data;
        this.loadingService.dismissLoading();

        this.civilites.push({
          "@id": nouvel_objet["@id"],
          "civilite": nouvel_objet.civilite,
        });

        this.modal.dismiss();
        this.nouveauForm.reset();
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error["hydra:description"],
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'enregistrement a échoué", "danger");
        }
        this.loadingService.dismissLoading();
      }
    )
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;
    this.civiliteService.edite(formValues, this.url_edition).subscribe(
      data => {
        let objet_edite:any = data;
        this.loadingService.dismissLoading();

        this.civilites[this.index_civilite] = {
          "@id": objet_edite["@id"],
          "civilite": objet_edite.civilite,
        };

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Modifié", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    )
  }

  supprime(lien:string) {
    this.loadingService.showLoading();
    this.civiliteService.supprime(lien[0]).subscribe(
      () => {
        this.loadingService.dismissLoading();
        this.civilites.splice(this.index_civilite, 1);
        this.toastService.presentToast("bottom", "Supprimé", "success");
      },
      error => {
        this.loadingService.dismissLoading();

        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
        }
      }
    )
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class AtelierService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getAteliers() {
    const url = this.constants.apiGetPostEndpoint + '/ateliers';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(atelier: any) {
    const url = this.constants.apiGetPostEndpoint + "/ateliers";
    const httpHeaders = this.headerService.getPostHeader();
    const urls_intervenants = this.extraitIntervenantsUrls(atelier["intervenants_nouveau"]);

    return this.httpClient.post(url, {
      intitule: atelier["intitule_nouveau"],
      dateDebut: atelier["date_debut_nouveau"],
      dateFin: atelier["date_fin_nouveau"],
      intervenants: urls_intervenants,
      adresse: atelier["adresse_nouveau"],
      ville: atelier["ville_nouveau"],
      codePostal: atelier["code_postal_nouveau"]
    }, httpHeaders);
  }

  edite(atelier:any, lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();
    const urls_intervenants = this.extraitIntervenantsUrls(atelier["intervenants_edition"]);

    return this.httpClient.patch(url, {
      intitule: atelier["intitule_edition"],
      dateDebut: atelier["date_debut_edition"],
      dateFin: atelier["date_fin_edition"],
      intervenants: urls_intervenants,
      adresse: atelier["adresse_edition"],
      ville: atelier["ville_edition"],
      codePostal: atelier["code_postal_edition"]
    },httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }

  extraitIntervenantsUrls(intervenants: any) {
    let urls = [];
    for(let intervenant of intervenants) {
      urls.push(intervenant["@id"]);
    }
    return urls;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

    usersType = {
      "user": '["ROLE_USER"]&roles[]=["ROLE_USER","ROLE_VERIFIED"]',
      "admin": '["ROLE_USER","ROLE_ADMIN"]&roles[]=["ROLE_USER","ROLE_ADMIN","ROLE_VERIFIED"]',
      "sadmin": '["ROLE_USER","ROLE_ADMIN","ROLE_SUPER_ADMIN"]&roles[]=["ROLE_USER","ROLE_ADMIN","ROLE_SUPER_ADMIN","ROLE_VERIFIED"]',
    };

    getUtilisateurs(pageNumber:number, role:string="user", nom:string, prenom:string, id:number) {
    let roles = this.convertRolesForDatabase(role);
    const httpHeaders = this.headerService.getGetHeader();
    let url = this.constants.apiGetPostEndpoint + '/utilisateurs?page=' + pageNumber;

    //a moins d'etre identifie avec un role super admin, on ne voit que les comptes
    //des simples utilisateurs
    if(window.sessionStorage.getItem('role') !== "ROLE_SUPER_ADMIN") {
      url += '&roles[]=' + roles;
    }

    if(nom !== "undefined" && nom !== "") {
      url += "&nom=" + nom
    }

    if(prenom !== "undefined" && prenom !== "") {
      url += "&prenom=" + prenom
    }

    if(id !== null) {
      url += "&id=" + id
    }

    return this.httpClient.get(url, httpHeaders);
  }

  getUtilisateursNames(nom:string, prenom:string) {
    const httpHeaders = this.headerService.getPostHeader();
    let url = this.constants.apiGetPostEndpoint + "/searchUtilisateursNames";
    let data={};

    if(!!nom) {
      data["nom"] = nom;
    }

    if(!!prenom) {
      data["prenom"] = prenom;
    }

    return this.httpClient.post(url, data, httpHeaders);
  }

  getFormatricesNames() {
    const httpHeaders = this.headerService.getGetHeader();
    let url = this.constants.apiGetPostEndpoint + '/getFormatricesNames';
    return this.httpClient.get(url, httpHeaders);
  }

  getStats() {
    const httpHeaders = this.headerService.getGetHeader();
    let url = this.constants.apiGetPostEndpoint + '/getStats';
    return this.httpClient.get(url, httpHeaders);
  }

  cree(utilisateur: any, url_situation_rsa: string) {
    const url = this.constants.apiGetPostEndpoint + "/utilisateurs";
    const httpHeaders = this.headerService.getPostHeader();
    let referentUrl

    if(!!utilisateur["referent_nouveau"]) {
      referentUrl = "/utilisateurs/" + utilisateur["referent_nouveau"].id;
    }

    let data = {
      email: utilisateur["email_nouveau"],
      nom: utilisateur["nom_nouveau"],
      prenom: utilisateur["prenom_nouveau"],
      plainPassword: utilisateur["password_nouveau"],
      adresse: utilisateur["adresse_nouveau"],
      ville: utilisateur["ville_nouveau"],
      telephone: utilisateur["telephone_nouveau"],
      codePostal: utilisateur["code_postal_nouveau"],
      merePere: utilisateur["mere_pere_nouveau"],
      nombreEnfants: utilisateur["nombre_enfants_nouveau"],
      aPermisConduire: utilisateur["permis_nouveau"],
      departement: !!!utilisateur["departement_nouveau"] ? null :utilisateur["departement_nouveau"]["@id"],
      nationnalite: !!!utilisateur["nationalite_nouveau"] ? null : utilisateur["nationalite_nouveau"]["@id"],
      modeDeplacement: !!!utilisateur["deplacement_nouveau"] ? null : utilisateur["deplacement_nouveau"]["@id"],
      civilite: !!!utilisateur["civilite_nouveau"] ? null : utilisateur["civilite_nouveau"]["@id"],
      niveauEtude: !!!utilisateur["niveau_etudes_nouveau"] ? null : utilisateur["niveau_etudes_nouveau"]["@id"],
      situationProfessionnelle: !!!utilisateur["situation_professionnelle_nouveau"] ? null : utilisateur["situation_professionnelle_nouveau"]["@id"],
      situationFamiliale: !!!utilisateur["situation_familiale_nouveau"] ? null : utilisateur["situation_familiale_nouveau"]["@id"],
      numeroDemandeurEmploi: utilisateur["numero_emploi_nouveau"],      
      formule: !!!utilisateur["formule_nouveau"] ? null : utilisateur["formule_nouveau"]["@id"],
      situationRsa: url_situation_rsa,
      roles: this.formatteRoles(utilisateur["role_nouveau"]),
      dateDebut: utilisateur["date_debut_nouveau"],
      dateFin: utilisateur["date_fin_nouveau"],
      dateNaissance: utilisateur["date_naissance_nouveau"],
      QPV: utilisateur["QPV_nouveau"],
      RQTH: utilisateur["RQTH_nouveau"],
      dateFinCER: utilisateur["date_fin_CER_nouveau"],
      dateBilanIntermediaire: utilisateur["date_bilan_interediaire_nouveau"],
      dateBilanFinal: utilisateur["date_bilan_final_nouveau"],
    };

    if(!!referentUrl) {
      data["formateurReferent"] = referentUrl;
    }

    data = this.controleChamps(data);
    return this.httpClient.post(url, data, httpHeaders);
  }

  getReponsesAudio(userId:number, etapeId:number) {
    const url = this.constants.apiGetPostEndpoint + "/reponsesAudioByEtapeId";
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {
      userId: userId,
      etapeId: etapeId
    }, httpHeaders);
  }

  edite(utilisateur:any, lien:string, situation_rsa) {    
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();
    let referentUrl

    if(!!utilisateur["referent_edition"] && utilisateur["referent_edition"] !== '') {
      referentUrl = "/utilisateurs/" + utilisateur["referent_edition"].id;
    }

    let data_utilisateur = {
      email: utilisateur["email_edition"],
      nom: utilisateur["nom_edition"],
      prenom: utilisateur["prenom_edition"],
      plainPassword: !!utilisateur["password_edition"] == false ? null : utilisateur["password_edition"],
      adresse: utilisateur["adresse_edition"],
      ville: utilisateur["ville_edition"],
      telephone: utilisateur["telephone_edition"],
      codePostal: utilisateur["code_postal_edition"],
      merePere: utilisateur["mere_pere_edition"],
      nombreEnfants: utilisateur["nombre_enfants_edition"],
      aPermisConduire: utilisateur["permis_edition"],
      departement: !!!utilisateur["departement_edition"] ? null :utilisateur["departement_edition"]["@id"],
      nationnalite: !!!utilisateur["nationalite_edition"] ? null : utilisateur["nationalite_edition"]["@id"],
      modeDeplacement: !!!utilisateur["deplacement_edition"] ? null : utilisateur["deplacement_edition"]["@id"],
      civilite: !!!utilisateur["civilite_edition"] ? null : utilisateur["civilite_edition"]["@id"],
      niveauEtude: !!!utilisateur["niveau_etudes_edition"] ? null : utilisateur["niveau_etudes_edition"]["@id"],
      situationProfessionnelle: !!!utilisateur["situation_professionnelle_edition"] ? null : utilisateur["situation_professionnelle_edition"]["@id"],
      situationFamiliale: !!!utilisateur["situation_familiale_edition"] ? null : utilisateur["situation_familiale_edition"]["@id"],
      numeroDemandeurEmploi: utilisateur["numero_emploi_edition"],
      formule: !!!utilisateur["formule_edition"] ? null : utilisateur["formule_edition"]["@id"],
      roles: this.formatteRoles(utilisateur["role_edition"]),
      dateDebut: utilisateur["date_debut_edition"],
      dateFin: utilisateur["date_fin_edition"],
      dateNaissance: utilisateur["date_naissance_edition"],
      QPV: utilisateur["QPV_edition"],
      RQTH: utilisateur["RQTH_edition"],
      dateFinCER: utilisateur["date_fin_CER_edition"],
      dateBilanIntermediaire: utilisateur["date_bilan_interediaire_edition"],
      dateBilanFinal: utilisateur["date_bilan_final_edition"],
    }

    if(!!referentUrl) {
      data_utilisateur["formateurReferent"] = referentUrl;
    }

    data_utilisateur = this.controleChamps(data_utilisateur);

    switch(situation_rsa.mode) {
      case "ajout":
        data_utilisateur["situationRsa"] = situation_rsa.situationRsa["@id"];
        break;

      case "suppression":
        data_utilisateur["situationRsa"] = null;
        break;
    }

    return this.httpClient.patch(url, data_utilisateur, httpHeaders);
  }

  missingWarningValidation(userId:number) {
    const url = this.constants.apiPatchDeleteEndpoint + "/utilisateurs/" + userId;
    const httpHeaders = this.headerService.getPatchHeader();
    return this.httpClient.patch(url, {showMissingWarning: false}, httpHeaders);
  }

  updateEtatBilanIntermediaire(userId:number, done:boolean) {
    let url = ""

    switch(this.constants.mode) {
      case "local":
        url = this.constants.apiPatchDeleteEndpoint + "/utilisateurs/" + userId;
        break;

      case "preprod":
        url = this.constants.apiPatchDeleteEndpoint + "/preprod/api/public/utilisateurs/" + userId;
        break;

      case "emeraude":
        url = this.constants.apiPatchDeleteEndpoint + "/emeraude/api/public/utilisateurs/" + userId;
        break;

      case "prod":
        url = this.constants.apiPatchDeleteEndpoint + "/api/public/utilisateurs/" + userId;
        break;
    }

    const httpHeaders = this.headerService.getPatchHeader();
    return this.httpClient.patch(url, {bilanIntermediaireFait: done}, httpHeaders);
  }

  userAcceptsTerms(userId:number) {
    let url = "";
    switch(this.constants.mode) {
      case "local":
        url = this.constants.apiPatchDeleteEndpoint + "/utilisateurs/" + userId;
        break;

      case "preprod":
        url = this.constants.apiPatchDeleteEndpoint + "/preprod/api/public/utilisateurs/" + userId;
        break;

      case "emeraude":
        url = this.constants.apiPatchDeleteEndpoint + "/emeraude/api/public/utilisateurs/" + userId;
        break;

      case "prod":
        url = this.constants.apiPatchDeleteEndpoint + "/api/public/utilisateurs/" + userId;
        break;
    }

    const httpHeaders = this.headerService.getPatchHeader();
    return this.httpClient.patch(url, {"conditionsAcceptees": true}, httpHeaders);
  }

  convertRolesForDatabase(role:string) {
    let dbRoles = this.usersType[role];
    dbRoles = dbRoles === "undefined" ? '["ROLE_USER"]' : dbRoles;
    return dbRoles;
  }

  // elimine les champs optionnels non renseignes
  // pour poser moins de problemes cote API
  controleChamps(data) {
    let champs_sensibles = [
      "merePere", "nombreEnfants", "nombreEnfants", "aPermisConduire", "departement", "nationnalite",
      "modeDeplacement", "civilite", "niveauEtude", "situationProfessionnelle", "situationFamilial",
      "roles", "dateDebut", "dateFin", "dateNaissance", "QPV", "RQTH", "plainPassword"
    ];

    for(let index in data) {
      if(data[index] === "" || typeof(data[index]) == "undefined" || data[index] === null) {
        if(champs_sensibles.indexOf(index) != -1) {
          delete data[index];
        }
      }
    }
    return data;
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }

  getInfosUnUtilisateur(id: number) {
    const url = this.constants.apiGetPostEndpoint + '/utilisateurs/' + id;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getMe() {
    const url = this.constants.apiGetPostEndpoint + '/me';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  formatteRoles(role_soumis) {
    let roles;
    switch(role_soumis) {
      case "Usager":
        roles = ["ROLE_USER"];
        break;

      case "Administrateur":
        roles = ["ROLE_USER","ROLE_ADMIN"];
        break;

      case "Créateur de parcours":
        roles = ["ROLE_USER","ROLE_ADMIN","ROLE_CREATEUR_PARCOURS"];
        break;

      case "Aline":
        roles = ["ROLE_USER","ROLE_ADMIN","ROLE_ALINE"];
        break;

      case "Formateur / Formatrice":
        roles = ["ROLE_USER","ROLE_ADMIN","ROLE_FORMATRICES"];
        break;

      case "Secrétaire de formation":
        roles = ["ROLE_USER","ROLE_ADMIN","ROLE_SECRETAIRE_FORMATION"];
        break;

      default:
        roles = ["ROLE_USER"];
        break;
    }

    return roles;
  }

  resendConfirmationLink(userId:number) {
    const url = this.constants.apiGetPostEndpoint + '/resendConfirmationLink';
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {"userId": userId}, httpHeaders);
  }

  getReponsesAudioList(userId:number) {
    const httpHeaders = this.headerService.getGetHeader();
    let url = this.constants.apiGetPostEndpoint + '/utilisateurListeReponseAudio/' + userId;
    return this.httpClient.get(url, httpHeaders);
  }

  getNames(userId:number) {
    const httpHeaders = this.headerService.getGetHeader();
    let url = this.constants.apiGetPostEndpoint + '/utilisateurNames/' + userId;
    return this.httpClient.get(url, httpHeaders);
  }

  getYpkKey() {
    const httpHeaders = this.headerService.getGetHeader();
    let url = this.constants.apiGetPostEndpoint + '/getYPKKey';
    return this.httpClient.get(url, httpHeaders);
  }

  getAvatar(userId:number) {
    const url = this.constants.apiGetPostEndpoint + '/getAvatar/' + userId;
    const options = this.headerService.getOptions();
    return this.httpClient.get(url, options);
  }

  postAvatar(avatar:any, userId:number) {
    const url = this.constants.apiGetPostEndpoint + "/avatarUtilisateur/" + userId + "/avatar";
    return this.httpClient.post(url, avatar);
  }

  getAllLimitedData() {
    const url = this.constants.apiGetPostEndpoint + "/getAllLimited";
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }
}
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLocaleData } from '@angular/common';
import * as fr from '@angular/common/locales/fr';

registerLocaleData(fr.default);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

if(window.location.protocol != "https:") {
  window.location.protocol = "https";
}

//ajout google maps SDK dans index.html  -- PROVI - voir @TODO
// var head= document.getElementsByTagName('head')[0];
// var script= document.createElement('script');
// script.type= 'text/javascript';
// script.src= "https://maps.googleapis.com/maps/api/js?key=" + environment.googleMapApiKey + "&libraries=visualization";
// head.appendChild(script);

/*@TODO: securiser la cle api pr restriction avec url d'appel et repasser ces lignes dans index.php (fin de la balise head)
<!-- google maps SDK -->
    <!-- <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDS9-_jHd3ZJyh0dHWI6MxbmANiVmeutdM&libraries=visualization"></script> -->
*/
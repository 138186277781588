import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})

export class AdminGuard  {
  constructor(private router:Router) {}

  authorizedRoles = [
    "ROLE_SUPER_ADMIN",
    "ROLE_ADMIN",
    "ROLE_CREATEUR_PARCOURS",
    "ROLE_ALINE",
    "ROLE_FORMATRICES",
    "ROLE_SECRETAIRE_FORMATION"
  ];

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if(!this.authorizedRoles.includes(window.sessionStorage.getItem('role'))) {
      return this.router.navigateByUrl("/");
    }
    return true;
  }
}

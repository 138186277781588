import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class CompetenceCleCustomService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getAll(pageNumber?:number) {
    let url = this.constants.apiGetPostEndpoint + '/descriptionsCompetenceCleCustom';
    const httpHeaders = this.headerService.getGetHeader();

    if(!!pageNumber) {
      url += "?page=" + pageNumber;
    }

    return this.httpClient.get(url, httpHeaders);
  }

  getTotalQuantity() {
    let url = this.constants.apiGetPostEndpoint + '/getTotalQuantityCompetenceCleCustoms';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getAllByUser(userId:number, pageNumber?:number) {
    let url = this.constants.apiGetPostEndpoint + '/descriptionsCompetenceCleCustomByUserName';
    const httpHeaders = this.headerService.getPostHeader();

    if(!!pageNumber) {
      url += "?page=" + pageNumber;
    }

    return this.httpClient.post(url, {"UtilisateurId":userId}, httpHeaders);
  }

  getQuantityForAUSer(userId:number) {
    let url = this.constants.apiGetPostEndpoint + '/descriptionsCompetenceCleCustomQuantityByUserName';
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {"UtilisateurId":userId}, httpHeaders);
  }

  cree(
    nom:string,
    urlUtilisateurObjectif:string,
    urlDescriptionCompetenceCle?:string,
    estValide:boolean=false,
    mode:string="user"
  ) {
    const url = this.constants.apiGetPostEndpoint + "/descriptionCompetenceCleCustom";
    const httpHeaders = this.headerService.getPostHeader();

    let data = {
      "nom": nom,
      "utilisateurObjectif": urlUtilisateurObjectif,
      "descriptionCompetenceCle": urlDescriptionCompetenceCle
    }

    if(mode==="admin") {
      data["estValide"] = estValide;
    } else {
      data["estValide"] = false;
    }

    return this.httpClient.post(url, data, httpHeaders);
  }

  edite(
    idDescriptionCompetenceCleCustom:number,
    nom:string,
    urlUtilisateurObjectif?:string,
    urlDescriptionCompetenceCle?:string,
    estValide:boolean=false,
    mode:string="user"
  ) {
    //const url = this.constants.apiPatchDeleteEndpoint + "/descriptionCompetenceCleCustom/" + idDescriptionCompetenceCleCustom;
    let url = this.constants.apiPatchDeleteEndpoint;
    //local
    //url += "/descriptionCompetenceCleCustom/";
    //prod
    //url += "/api/public/descriptionCompetenceCleCustom/";
    //preprod
    //url += "/preprod/api/public/descriptionCompetenceCleCustom/";
    //url += idDescriptionCompetenceCleCustom; 

    switch(this.constants.mode) {
      case "local":
        url += "/descriptionCompetenceCleCustom/";
        break;

      case "preprod":
        url += "/preprod/api/public/descriptionCompetenceCleCustom/";
        break;

      case "emeraude":
        url += "/preprod/api/public/descriptionCompetenceCleCustom/";
        break;

      case "prod":
        url += "/api/public/descriptionCompetenceCleCustom/";
        break;
    }
    url += idDescriptionCompetenceCleCustom;
    const httpHeaders = this.headerService.getPatchHeader();

    let data = {
      "nom": nom,
    }

    if(!!urlUtilisateurObjectif) {
      data["utilisateurObjectif"] = urlUtilisateurObjectif;
    }

    if(!!urlDescriptionCompetenceCle) {
      data["descriptionCompetenceCle"] = urlDescriptionCompetenceCle;
    }

    if(mode==="admin") {
      data["estValide"] = estValide;
    } else {
      data["estValide"] = false;
    }

    return this.httpClient.patch(url, data, httpHeaders);
  }

  nonValideEditeCompetenceCleLiee(indexesDescriptionCompetenceCle:{}) {
    const url = this.constants.apiGetPostEndpoint + "/editeCompetenceCleSurNonValide";
    const httpHeaders = this.headerService.getPostHeader();

    let data = {
      "idUtilisateurObjectif": indexesDescriptionCompetenceCle["idUtilisateurObjectif"],
      "idAncienneDescriptionCompetenceCle": indexesDescriptionCompetenceCle["old"],
      "idNouvelleDescriptionCompetenceCle": indexesDescriptionCompetenceCle["new"]
    }

    return this.httpClient.post(url, data, httpHeaders);
  }

  supprime(id: number) {
    const url = this.constants.apiPatchDeleteEndpoint + "/descriptionCompetenceCleCustom/" + id;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class IntervenantService {
  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getIntervenants() {
    const url = this.constants.apiGetPostEndpoint + '/intervenants';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(intervenant: any) {    
    const url = this.constants.apiGetPostEndpoint + "/intervenants";
    const httpHeaders = this.headerService.getPostHeader();

    return this.httpClient.post(url, {
      nom: intervenant["nom_nouveau"],
      prenom: intervenant["prenom_nouveau"],
    }, httpHeaders);
  }

  edite(intervenant:any, lien: string) {    
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();

    return this.httpClient.patch(url, {
      "nom": intervenant["nom_edition"],
      "prenom": intervenant["prenom_edition"]
    }, httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

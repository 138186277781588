// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: rgba(123, 179, 34, 1);
}

.grass-background {
  background-image: url('adep_bg_repeatXY_grass.png');
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  height: 100%;
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(123, 179, 34);
  border: 1px solid rgba(1, 108, 21, 0.4);
  border-radius: 5px;
  padding: 10px;
}

#connexion-button {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/validation-compte/validation-compte.component.scss"],"names":[],"mappings":"AAAA;EACI,mCAAA;AACJ;;AAEA;EACI,mDAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mCAAA;EACA,uCAAA;EACA,kBAAA;EACA,aAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":["ion-content {\r\n    --background: rgba(123, 179, 34, 1);\r\n}\r\n\r\n.grass-background {\r\n    background-image: url(../../../assets/img/Grass/adep_bg_repeatXY_grass.png);\r\n    background-repeat: repeat;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin-bottom: 0px;\r\n    height: 100%;\r\n}\r\n\r\n.message {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-color: rgb(123, 179, 34);\r\n    border: 1px solid rgba(1, 108, 21, 0.4);\r\n    border-radius: 5px;\r\n    padding: 10px;\r\n}\r\n\r\n#connexion-button {\r\n    margin-top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class DepartementsService {
  constructor(private httpClient: HttpClient,
              private constants: Constants,
              private headerService: HeadersService) { }

  getDepartements() {
    const url = this.constants.apiGetPostEndpoint + '/departements';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(departement: any) {
    const url = this.constants.apiGetPostEndpoint + "/departements";
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {nom: departement["nom_nouveau"], code: departement["code_nouveau"]}, httpHeaders);
  }

  edite(departement:any, lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();

    return this.httpClient.patch(url, {
      nom: departement["nom_edition"],
      code: departement["code_edition"]
    },httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}
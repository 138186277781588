// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header {
  margin-bottom: 32px;
}

.div-back {
  width: 90%;
  margin-left: 5%;
  text-align: right;
  padding-bottom: 16px;
}

#etape-select {
  width: 90%;
  margin: 0 5% 2% 5%;
}

table {
  width: 90%;
  margin: 0 5% 2% 5%;
}

tr {
  border: 1px solid black;
  text-align: center;
}

th, td {
  padding: 8px 24px 8px 24px;
  height: 70px;
}

.skeleton {
  height: 50px;
}

.record-player {
  max-width: 100%;
  margin: 5px;
  border-radius: 7px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/reponses-audio-user/reponses-audio-user.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,UAAA;EACA,eAAA;EACA,iBAAA;EACA,oBAAA;AACJ;;AAEA;EACI,UAAA;EACA,kBAAA;AACJ;;AAEA;EACI,UAAA;EACA,kBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,0BAAA;EACA,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,WAAA;EACA,kBAAA;AACJ","sourcesContent":["ion-header {\r\n    margin-bottom: 32px;\r\n}\r\n\r\n.div-back {\r\n    width: 90%;\r\n    margin-left: 5%;\r\n    text-align: right;\r\n    padding-bottom: 16px;\r\n}\r\n\r\n#etape-select {\r\n    width: 90%;\r\n    margin: 0 5% 2% 5%;\r\n}\r\n\r\ntable {\r\n    width: 90%;\r\n    margin: 0 5% 2% 5%;\r\n}\r\n\r\ntr {\r\n    border: 1px solid black;\r\n    text-align: center;\r\n}\r\n\r\nth, td {\r\n    padding: 8px 24px 8px 24px;\r\n    height: 70px;\r\n}\r\n\r\n.skeleton {\r\n    height: 50px;\r\n}\r\n\r\n.record-player {\r\n    max-width: 100%;\r\n    margin: 5px;\r\n    border-radius: 7px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

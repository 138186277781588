import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class FormuleService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getFormules(id?:number, pageNumber?:number) {
    if(!!id) {
      return this.getFormuleById(id);
    } else if(!!pageNumber) {
      return this.getPaginatedFomules(pageNumber);
    }else {
      return this.getAllFormules();
    }
  }

  getAllFormules() {
    let url = this.constants.apiGetPostEndpoint + '/formules';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getFormuleById(id:number) {
    let url = this.constants.apiGetPostEndpoint + '/formules?id=' + id;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getPaginatedFomules(pageNumber:number=1) {
    let url = this.constants.apiGetPostEndpoint + '/formulesPaginated?page=' + pageNumber;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getFormulesByName(nom:string, id:number) {
    const httpHeaders = this.headerService.getGetHeader();
    let url = this.constants.apiGetPostEndpoint + '/searchFormulesByName?';

    if(!!id || !!nom) {
      if(!!id) {
        url += "&id=" + id;
      }

      if(!!nom) {
        url += "&nom=" + nom;
      }
    }

    return this.httpClient.get(url, httpHeaders);
  }

  cree(formule: any) {    
    const url = this.constants.apiGetPostEndpoint + "/formules";
    const httpHeaders = this.headerService.getPostHeader();
    let urls_niveaux = [];

    for(let niveau of formule["niveaux_nouveau"]) {
      urls_niveaux.push(niveau["@id"]);
    }

    return this.httpClient.post(url, {
      nom: formule["nom_nouveau"],
      typeFormule: formule["type_nouveau"]["@id"],
      niveaux: urls_niveaux
    }, httpHeaders);
  }

  edite(formule:any, lien: string) {    
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();
    let urls_niveaux = [];

    for(let niveau of formule["niveaux_edition"]) {
      urls_niveaux.push(niveau["@id"]);
    }

    return this.httpClient.patch(url, {
      nom: formule["nom_edition"],
      typeFormule: formule["type_edition"]["@id"],
      niveaux: urls_niveaux
    }, httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class DescriptionCompetenceCleService {
  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getAll() {
    const url = this.constants.apiGetPostEndpoint + '/descriptionsCompetenceCle';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  create(nom:string) {
    const url = this.constants.apiEndpoint + "/descriptionCompetenceCle";
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {
      nom: nom,
    }, httpHeaders);
  }

  edit(id:number, nom:string) {
    const url = this.constants.apiGetPostEndpoint + '/descriptionCompetenceCle/' + id;
    const httpHeaders = this.headerService.getPatchHeader();
    return this.httpClient.patch(url, {"nom": nom}, httpHeaders);
  }

  delete(id:number) {
    const url = this.constants.apiEndpoint + "/descriptionCompetenceCle/" + id;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Etape } from 'src/app/Models/etape';
import { Objectif } from 'src/app/Models/objectif';
import { EtapeService } from 'src/app/services/etape/etape.service';

@Component({
  selector: 'app-modal-detail-objectif',
  templateUrl: './modal-detail-objectif.component.html',
  styleUrls: ['../modal-detail-activity/modal-detail-activity.component.scss'],
})
export class ModalDetailObjectifComponent implements OnInit {
  objectif: any;
  cEtape: Etape;
  macaronClass: string = 'imgGrayscale';
  objectifString: string;
  typeObjectif: any;
  dateDebut: Date;
  dateFin: Date;
  nomObjectif:string;

  typesObjectifs = [
    'atelier',
    'entrepriseStage',
    'competenceCle',
    'softSkill',
    'PlateauTechnique',
  ];

  constructor(
    private modalCtrl: ModalController,
    private etapeService: EtapeService
  ) {}

  ngOnInit() {
    this.checkTypeObjectif(this.objectif);
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  /**
   *
   * @param objectif
   */
  checkTypeObjectif(objectif: any) {
    this.typesObjectifs.forEach((to) => {
      if (objectif[to] != undefined) {
        this.typeObjectif = objectif[to];
        this.dateDebut = new Date(this.typeObjectif.dateDebut);
        this.dateFin = new Date(this.typeObjectif.dateFin);
        if(this.objectif["entrepriseStage"] != null){
          this.nomObjectif = this.typeObjectif.nom;
        } else {
          this.nomObjectif = this.typeObjectif.intitule
        }
      }
    });
  }
}

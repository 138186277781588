import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { ErrorNotificationService } from '../../services/error-notification.service';

@Component({
  selector: 'app-main-admin',
  templateUrl: './main-admin.component.html',
  styleUrls: ['./main-admin.component.scss'],
})
export class MainAdminComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,
              private errorNotificationService: ErrorNotificationService,
              private router: Router) { }

  currentRoles:string;
  menus = {
    "ROLE_CREATEUR_PARCOURS": [
      "Stages en entreprise","Compétences clés","Soft skills",
      "E-culture / E-transition","Plateaux techniques",
      "Questions visites plateaux techniques","Types de formules",
      "Types d'objectifs","Objectifs","Étapes",
      "Niveaux","Formules","Utilisateurs","Intervenants",
      "Descriptions personnalisees",
      "Descriptions atelier", "Descriptions soft skill",
      "Descriptions competence cle"
    ],
    "ROLE_ALINE": [
      "Stages en entreprise","Cashback","Plateaux techniques",
      "Questions visites plateaux techniques","Utilisateurs",
      "Intervenants", "Questions audio"
    ],
    "ROLE_FORMATRICES": [
      "Compétences clés","Soft skills","E-culture / E-transition",
      "Types de formules","Types d'objectifs","Objectifs",
      "Étapes","Niveaux","Formules",
      "Utilisateurs","Intervenants", "Descriptions personnalisees",
      "Descriptions atelier", "Descriptions soft skill",
      "Descriptions competence cle"
    ],
    "ROLE_SECRETAIRE_FORMATION": [
      "Situation familiale","Département","Nationalité",
      "Niveau d'étude","Situation professionnelle",
      "Civilité","Mode de déplacement",
      "Utilisateurs","Intervenants", "Rendez-vous Reservio"
    ],
    "ROLE_ADMIN": [
      "Situation familiale","Département","Nationalité",
      "Niveau d'étude","Situation professionnelle",
      "Civilité","Mode de déplacement","Stages en entreprise",
      "Compétences clés","Soft skills","E-culture / E-transition",
      "Types de récompenses","Cashback","Plateaux techniques",
      "Questions visites plateaux techniques","Types de formules",
      "Types d'objectifs","Objectifs","Étapes",
      "Récompenses","Niveaux","Formules",
      "Utilisateurs","Intervenants", "Descriptions personnalisees",
      "Descriptions atelier", "Descriptions soft skill",
      "Descriptions competence cle"
    ],
    "ROLE_SUPER_ADMIN": [
      "Situation familiale","Département","Nationalité",
      "Niveau d'étude","Situation professionnelle",
      "Civilité","Mode de déplacement","Stages en entreprise",
      "Compétences clés","Soft skills","E-culture / E-transition",
      "Types de récompenses","Cashback","Plateaux techniques",
      "Questions visites plateaux techniques","Types de formules",
      "Types d'objectifs","Objectifs","Étapes",
      "Récompenses","Niveaux","Formules",
      "Utilisateurs","Intervenants", "Descriptions personnalisees",
      "Descriptions atelier", "Descriptions soft skill",
      "Descriptions competence cle", "Statistiques", "Logs", "Questions audio",
      "Rendez-vous Reservio"
    ]
  };

  ngOnInit() {
    this.currentRoles = window.sessionStorage.getItem('role');
  }

  availableMenu(name:string) {
    let menuList = this.menus[this.currentRoles];
    return menuList.includes(name);
  }

  deconnexion() {
    this.authenticationService.logout().subscribe(
      () => {
        window.sessionStorage.clear();
        window.sessionStorage.setItem('logged', '1');
        return this.router.navigateByUrl("/");
      },
      () => {
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Votre tentative de déconnexion a échoué",
          "Ok"
        );
      }
    );
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { SoftSkillService } from '../../services/SoftSkill/soft-skill.service';
import { IntervenantService } from 'src/app/services/Intervenants/intervenant.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';
import { Constants } from '../../config/constants';
import { GeneralService } from 'src/app/services/General/general.service';

@Component({
  selector: 'app-soft-skill',
  templateUrl: './soft-skill.component.html',
  styleUrls: ['./soft-skill.component.scss'],
})
export class SoftSkillComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;

  competence_edition:string;
  competence_nouveau:string;
  url_edition:string;
  index_competence:number;
  mode_edition:boolean=true;
  private competences: any;
  private intervenants: any;
  private loading_competences:boolean = false;
  private loading_intervenants:boolean = false;
  minDate:string;
  maxDate:string;

  nouveauForm = new FormGroup({
    competence_nouveau: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    date_debut_nouveau: new FormControl(this.generalService.getLocalDateTime(), [Validators.required]),
    date_fin_nouveau: new FormControl(this.generalService.getLocalDateTime(), [Validators.required]),
    intervenants_nouveau: new FormControl(''),
    adresse_nouveau: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    code_postal_nouveau: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    ville_nouveau: new FormControl('', [Validators.required, Validators.maxLength(100)])
  });

  editionForm = new FormGroup({
    competence_edition: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    date_debut_edition: new FormControl(this.generalService.getLocalDateTime(), [Validators.required]),
    date_fin_edition: new FormControl(this.generalService.getLocalDateTime(), [Validators.required]),
    intervenants_edition: new FormControl(''),
    adresse_edition: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    code_postal_edition: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    ville_edition: new FormControl('', [Validators.required, Validators.maxLength(100)])
  });

  constructor(private softSkillService: SoftSkillService,
              private intervenantService: IntervenantService,
              private errorNotificationService: ErrorNotificationService,
              private loadingService: LoadingService,
              private generalService:GeneralService,
              private toastService: ToastService,
              private constants: Constants) {
                this.minDate = this.constants.minDate;
                this.maxDate = this.constants.maxDate;
              }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.getSoftSkills();
        this.getIntervenants();
      }
    );
  }

  getSoftSkills() {
    this.loading_competences = true;
    this.softSkillService.getSoftSkills().subscribe(
      data => {
        let infos = data;
        this.competences = infos["hydra:member"];
        this.loading_competences = false;
        this.checkLoading();
      },
      () => {
        this.loading_competences = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getIntervenants() {
    this.loading_intervenants = true;
    this.intervenantService.getIntervenants().subscribe(
      data => {
        let infos = data;
        this.intervenants = infos["hydra:member"];
        this.loading_intervenants = false;
        this.checkLoading();
      },
      () => {
        this.loading_intervenants = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  checkLoading() {
    if(!this.loading_competences
      && !this.loading_intervenants) {
      this.loadingService.dismissLoading();
    }
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  ouvreModaleNouveau() {
    this.mode_edition = false;
    this.modal.present();
  }

  ouvreModaleEdition(competence:any) {
    this.mode_edition = true;
    this.competence_edition = competence.intitule;
    this.url_edition = competence["@id"];
    this.editionForm.patchValue({
      "competence_edition": competence.intitule,
      "date_debut_edition": competence.dateDebut,
      "date_fin_edition": competence.dateFin,
      "intervenants_edition": competence.intervenants,
      "adresse_edition": competence.adresse,
      "ville_edition": competence.ville,
      "code_postal_edition": competence.codePostal
    });
    this.modal.present();
    this.index_competence = this.competences.lastIndexOf(competence);
  }

  supprimeAlert(competence:any) {
    this.index_competence = this.competences.lastIndexOf(competence);
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.supprime.bind(this),
      [competence["@id"]]
    );
  }

  submitNouveau() {
    this.loadingService.showLoading();
    const formValues = this.nouveauForm.value;
    let memo_intervenants = formValues.intervenants_nouveau;

    this.softSkillService.cree(formValues).subscribe(
      data => {
        let nouvel_objet:any = data;
        this.loadingService.dismissLoading();

        this.competences.push({
          "@id": nouvel_objet["@id"],
          "intitule": nouvel_objet.intitule,
          "dateDebut": nouvel_objet.dateDebut,
          "dateFin":nouvel_objet.dateFin,
          "intervenants": memo_intervenants,
          "adresse": nouvel_objet.adresse,
          "codePostal": nouvel_objet.codePostal,
          "ville": nouvel_objet.ville
        });

        this.nouveauForm.reset();
        this.nouveauForm.patchValue({
          "date_debut_nouveau": this.generalService.getLocalDateTime(),
          "date_fin_nouveau": this.generalService.getLocalDateTime(),
        });
        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error["hydra:description"],
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'enregistrement a échoué", "danger");
        }
        this.loadingService.dismissLoading();
      }
    )
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;
    const memo_intervenants = formValues["intervenants_edition"];

    this.softSkillService.edite(formValues, this.url_edition).subscribe(
      data => {
        let objet_edite:any = data;
        this.loadingService.dismissLoading();

        this.competences[this.index_competence] = {
          "@id": objet_edite["@id"],
          "intitule": objet_edite.intitule,
          "dateDebut": objet_edite.dateDebut,
          "dateFin": objet_edite.dateFin,
          "intervenants": memo_intervenants,
          "adresse": objet_edite.adresse,
          "codePostal": objet_edite.codePostal,
          "ville": objet_edite.ville
        };

        this.modal.dismiss();
        this.toastService.presentToast("bottom", "Modifié", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    )
  }

  supprime(lien:string) {
    this.loadingService.showLoading();
    this.softSkillService.supprime(lien[0]).subscribe(
      () => {
        this.loadingService.dismissLoading();
        this.competences.splice(this.index_competence, 1);
        this.toastService.presentToast("bottom", "Supprimé", "success");
      },
      error => {
        this.loadingService.dismissLoading();

        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
        }
      }
    )
  }

  //pour le formulaire d'edition
  //coche les checkboxes des options selectionnees
  compareWith(o1, o2) {
    return o1 && o2 ? o1["@id"] === o2["@id"] : o1 === o2;
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { EtapeService } from '../../services/etape/etape.service';
import { ObjectifService } from '../../services/Objectif/objectif.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { QuestionAudioService } from 'src/app/services/QuestionsAudio/question-audio.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from '../../services/Toast/toast.service';

@Component({
  selector: 'app-etape',
  templateUrl: './etape.component.html',
  styleUrls: ['./etape.component.scss'],
})
export class EtapeComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  url_edition:string;
  index_etape:number;
  mode_edition:boolean=true;
  public etapes:any;
  public objectifs: any[];
  loading_objectifs: boolean=false;
  loading_etapes:boolean = false;
  currentPage:number=1;
  totalQuantityEtapes:number;
  maxPageNumber:number;
  etapesPerPage:number = 10;
  noEtapes:boolean=false;
  nombreResultatsRecherche:number;
  foundEtapes: any[];
  showSearchLoader:boolean=false;
  getEtapeTimeout:any=null;
  questionsAudio:any;
  loadingQuestionAudio:boolean=false;

  nouveauForm = new FormGroup({
    nom_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    numero_nouveau: new FormControl('', [Validators.required, Validators.min(1), Validators.max(999)]),
    objectifs_nouveau: new FormControl('', [Validators.required]),
    description_debut_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    est_lien_description_debut_nouveau: new FormControl(false, [Validators.required]),
    description_fin_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    image_monument_nouveau: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    questions_audio_nouveau: new FormControl('', [/*Validators.required*/]),
  });

  editionForm = new FormGroup({
    nom_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    numero_edition: new FormControl('', [Validators.required, Validators.min(1), Validators.max(999)]),
    objectifs_edition: new FormControl('', [Validators.required]),
    description_debut_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    est_lien_description_debut_edition: new FormControl(false, [Validators.required]),
    description_fin_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    image_monument_edition: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    questions_audio_edition: new FormControl('', [/*Validators.required*/]),
  });

  searchForm = new FormGroup({
    nom: new FormControl('', [Validators.maxLength(50)]),
    selectedEtape: new FormControl('')
  });

  constructor(private etapeService: EtapeService,
              private objectifService: ObjectifService,
              private errorNotificationService: ErrorNotificationService,
              private questionAudioService:QuestionAudioService,
              private loadingService: LoadingService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.getEtapes();
        this.getObjectifs();
        this.getQuestionsAudio();
      }
    );
  }

  getEtapes(pageNumber?:number, nom?:string) {
    this.loading_etapes = true;
    this.etapeService.getEtapes(pageNumber, nom).subscribe(
      data => {
        this.loading_etapes = false;
        let infos = data;
        this.etapes = infos["hydra:member"];
        
        if(this.etapes.length <= 0) {
          this.noEtapes = true;
        } else {
          this.noEtapes = false;
        }

        this.totalQuantityEtapes = infos["hydra:totalItems"];
        this.updateMaxPageNumber();
        this.checkLoading();
        this.scrollToTop();
      },
      () => {
        this.loading_etapes = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  //recuperation des types d'objectifs enregistres en bdd
  getObjectifs() {
    this.loading_objectifs = true;
    this.objectifService.getObjectifsPrincipaux().subscribe(
      data => {
        let infos = data;
        this.objectifs = infos["hydra:member"];
        this.loading_objectifs = false;
        this.checkLoading();
      },
      () => {
        this.loading_objectifs = false;
        this.checkLoading();
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
      }
    )
  }

  getQuestionsAudio() {
    this.loadingQuestionAudio = true;
    this.questionAudioService.getAll().subscribe(
      data => {
        this.questionsAudio = data["hydra:member"];
        this.loadingQuestionAudio = false;
        this.checkLoading();
      },
      /*error*/() => {
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
        this.loadingQuestionAudio = false;
        this.checkLoading();
      }
    )
  }

  getSearchText() {
    if(typeof(this.nombreResultatsRecherche) !== "undefined" && this.nombreResultatsRecherche > 0) {
      return this.nombreResultatsRecherche + " résultat(s)";
    }

    return "Aucun résultat";
  }

  getEtapesNames() {
    if(this.getEtapeTimeout !== null) {
      clearTimeout(this.getEtapeTimeout);
      this.getEtapeTimeout = null;
    }

    this.getEtapeTimeout = window.setTimeout(() => {
      if(!this.searchForm.invalid) {
        this.showSearchLoader = true;
  
        //dans le cas ou on vide le champ nom du form de recherche
        //on re-affiche tout les résultats
        if(this.searchForm.value.nom === "") {
          this.searchForm.reset();
          this.searchForm.setValue({
            nom: "",
            selectedEtape: ""
          });
  
          this.foundEtapes = [];
          this.nombreResultatsRecherche = 0;
  
          this.loadingService.showLoading().then(
            () => {
              this.currentPage = 1;
              this.getEtapes();
              this.showSearchLoader = false;
            }
          );
        } else {
          this.etapeService.getEtapesNames(
            this.searchForm.value.nom,
          ).subscribe(
            data => {
              let infos = data;
              this.foundEtapes = infos["hydra:member"];
              this.nombreResultatsRecherche = this.foundEtapes.length;
              this.showSearchLoader = false;
            },
            () => {
              this.errorNotificationService.presentGeneralErrorAlert(
                true,
                "Erreur",
                "Un problème a été rencontré lors de la recherche des utilisateurs",
                "Ok"
              );
              this.showSearchLoader = false;
            }
          )
        }
      }
    }, 150);
  }

  //fct a lancer pr afficher les details des users pr ces noms
  searchByName(event) {
    if(this.searchForm.value.nom !== "") {
      if(!this.searchForm.invalid) {
        this.loadingService.showLoading().then(
          () => {
            this.currentPage = 1;
            this.getEtapes(null, event.detail.value.trim());
          }
        );
      }
    }
  }

  scrollToTop() {
    let anchor = document.getElementById('div-nouveau');

    setTimeout(() => {
      anchor.scrollIntoView();
    }, 300);
  }

  updateMaxPageNumber() {
    this.maxPageNumber = this.totalQuantityEtapes/this.etapesPerPage;
    this.maxPageNumber = Math.ceil(this.maxPageNumber);
  }

  checkLoading() {
    if(!this.loading_objectifs && !this.loading_etapes && !this.loadingQuestionAudio) {
        this.loadingService.dismissLoading();
    }
  }

  isItLoading() {
    return this.loading_objectifs || this.loading_etapes
  }

  changePage(pageNumber:number) {
    this.currentPage = pageNumber;
    this.loadingService.showLoading().then(
      () => {
        this.getEtapes(pageNumber, null);
      }
    );
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
    this.nouveauForm.reset();
  }

  ouvreModaleNouveau() {
    this.mode_edition = false;
    this.modal.present();
  }

  ouvreModaleDuplication(etape:any) {
    this.nouveauForm.patchValue({
      "nom_nouveau": etape.nom,
      "numero_nouveau": etape.numeroEtape,
      "objectifs_nouveau": etape.objectifs,
      "description_debut_nouveau": etape.descriptionDebut,
      "est_lien_description_debut_nouveau": etape.estLienDescriptionDebut,
      "description_fin_nouveau": etape.descriptionFin,
      "image_monument_nouveau": etape.imageMonument,
      "questions_audio_nouveau": etape.questionsAudio,
    });

    this.mode_edition = false;
    this.modal.present();
  }

  //pour le formulaire d'edition
  //coche les checkboxes des options selectionnees
  compareWith(o1, o2) {
    return o1 && o2 ? o1["@id"] === o2["@id"] : o1 === o2;
  }

  ouvreModaleEdition(etape:any) {
    this.mode_edition = true;
    this.editionForm.patchValue({
      "nom_edition": etape.nom,
      "numero_edition": etape.numeroEtape,
      "objectifs_edition": etape.objectifs,
      "description_debut_edition": etape.descriptionDebut,
      "est_lien_description_debut_edition": etape.estLienDescriptionDebut,
      "description_fin_edition": etape.descriptionFin,
      "image_monument_edition": etape.imageMonument,
      "questions_audio_edition": etape.questionsAudio,
    });

    this.url_edition = etape["@id"];
    this.index_etape = this.etapes.lastIndexOf(etape);
    this.modal.present();
  }
  
  supprimeAlert(etape:any) {
    this.index_etape = this.etapes.lastIndexOf(etape);
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cet élément ?",
      "Oui",
      this.supprime.bind(this),
      [etape["@id"]]
    );
  }

  submitNouveau() {
    this.loadingService.showLoading();
    const formValues = this.nouveauForm.value;
    let memo_type = formValues.objectifs_nouveau;
    const memo_questions_audio = formValues.questions_audio_nouveau;

    this.etapeService.cree(formValues).subscribe(
      data => {
        const nouvel_objet:any = data;
        this.loadingService.dismissLoading();
        //la duree est calculee automatiquement cote API
        let ajout = {
          "@id": nouvel_objet["@id"],
          "nom": nouvel_objet["nom"],
          "numeroEtape": nouvel_objet["numeroEtape"],
          "duree": nouvel_objet["duree"],
          "objectifs": memo_type,
          "descriptionDebut": nouvel_objet["descriptionDebut"],
          "estLienDescriptionDebut": nouvel_objet["est_lien_description_debut_nouveau"],
          "descriptionFin": nouvel_objet["descriptionFin"],
          "imageMonument": nouvel_objet["imageMonument"],
          "questionsAudio": memo_questions_audio,
        };

        this.etapes.push(ajout);
        this.modal.dismiss();
        this.nouveauForm.reset();
        this.toastService.presentToast("bottom", "Enregistré", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error["hydra:description"],
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'enregistrement a échoué", "danger");
        }
        this.loadingService.dismissLoading();
      }
    )
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editionForm.value;
    const memo_objectifs = formValues["objectifs_edition"];
    const memo_questions_audio = formValues["questions_audio_edition"];

    this.etapeService.edite(formValues, this.url_edition).subscribe(
      data => {
        let objet_edite:any = data;
        this.loadingService.dismissLoading();
        //la duree est calculee automatiquement cote API
        this.etapes[this.index_etape] = {
          "@id": objet_edite["@id"],
          "nom": objet_edite.nom,
          "numeroEtape": objet_edite["numeroEtape"],
          "duree": objet_edite["duree"],
          "objectifs": memo_objectifs,
          "descriptionDebut": objet_edite["descriptionDebut"],
          "estLienDescriptionDebut": objet_edite["estLienDescriptionDebut"],
          "descriptionFin": objet_edite["descriptionFin"],
          "imageMonument": objet_edite["imageMonument"],
          "questionsAudio": memo_questions_audio,
        };

        this.modal.dismiss();
        this.nouveauForm.reset();
        this.toastService.presentToast("bottom", "Modifié", "success");
      },
      error => {
        this.loadingService.dismissLoading();
        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: l'édition à échoué", "danger");
        }
      }
    );
  }

  supprime(lien:string) {
    this.loadingService.showLoading();
    this.etapeService.supprime(lien[0]).subscribe(
      () => {
        this.loadingService.dismissLoading();
        this.etapes.splice(this.index_etape, 1);
        this.toastService.presentToast("bottom", "Supprimé", "success");
      },
      error => {
        this.loadingService.dismissLoading();

        if(error.status === 422) {
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
        } else {
          this.toastService.presentToast("bottom", "Erreur: la suppression a échoué", "danger");
        }
      }
    )
  }

  getNouveauFormDisplay() {
    if(!this.mode_edition) {
      return "block";
    }
    return "none";
  }

  getEditionFormDisplay() {
    if(this.mode_edition) {
      return "block";
    }
    return "none";
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';
import { GeneralService } from '../General/general.service';

@Injectable({
  providedIn: 'root'
})
export class RendezVousIndividuelService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private generalService: GeneralService,
    private headerService: HeadersService) { }

  getAllForAUser(userId:number) {
    const httpHeaders = this.headerService.getGetHeader();
    let url = this.constants.apiGetPostEndpoint + '/rendezVousIndividuels/' + userId;
    return this.httpClient.get(url, httpHeaders);
  }

  cree(formData:any, userId:number) {
    const url = this.constants.apiGetPostEndpoint + "/rendezVousIndividuel";
    const httpHeaders = this.headerService.getPostHeader();
    //secu : si formData.justificationAbsence ne contient que des espaces
    //alors ce champ vaudra null
    formData.justificationAbsence = this.generalService.onlySpaceStringSecurity(formData.justificationAbsence);

    let data = {
      "utilisateur": "/utilisateurs/" + userId,
      "dateRdv": formData.date,
      "competence": formData.competences === "" ? null : formData.competences,
      "notionsTravaillees": formData.notionsTravaillees === "" ? null : formData.notionsTravaillees,
      "documentsTravailles": formData.documentsTravailles === "" ? null : formData.documentsTravailles,
      "present": formData.present,
      "absenceJustifiee": formData.absenceJustifiee,
      "justificationAbsence": formData.justificationAbsence === "" ? null : formData.justificationAbsence
    }

    return this.httpClient.post(url, data, httpHeaders);
  }

  edit(formData:any, rdvId:number) {    
    const url = this.constants.apiPatchDeleteEndpoint + "/rendezVousIndividuel/" + rdvId;
    const httpHeaders = this.headerService.getPatchHeader();
    //secu : si formData.justificationAbsence ne contient que des espaces
    //alors ce champ vaudra null
    formData.justificationAbsence = this.generalService.onlySpaceStringSecurity(formData.justificationAbsence);

    let data = {
      "dateRdv": formData.date,
      "competence": formData.competences === "" ? null : formData.competences,
      "notionsTravaillees": formData.notionsTravaillees === "" ? null : formData.notionsTravaillees,
      "documentsTravailles": formData.documentsTravailles === "" ? null : formData.documentsTravailles,
      "present": formData.present,
      "absenceJustifiee": formData.absenceJustifiee,
      "justificationAbsence": formData.justificationAbsence === "" ? null : formData.justificationAbsence
    }

    return this.httpClient.patch(url, data, httpHeaders);
  }

  remove(rdvId:number) {
    const url = this.constants.apiPatchDeleteEndpoint + "/rendezVousIndividuel/" + rdvId;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

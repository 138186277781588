import { Injectable } from '@angular/core';
import { AlertController, AlertButton, AlertOptions } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})

//Service permettant de notifier l'utilisateur de certaines erreurs (pop up d'alerte).
export class ErrorNotificationService {

  constructor(private alertController: AlertController) { }

  /*async presentGeneralErrorAlert(header: string, buttonText: string) {
    const alert = this.alertController.create({
      header,
      buttons: [
        {
          text: buttonText
        }
      ]
    });

    await (await alert).present();
  }*/

  generateHttpErrorSubHeader(httpStatusCode: number): string {
    if (httpStatusCode >= 400 && httpStatusCode < 500) {
      switch (httpStatusCode) {
        case 401: {
          return 'Identifiants invalides.';
        }
        case 403: {
          return 'Accès non autorisé.';
        }
        case 404: {
          return 'Ressources introuvables.';
        }
        default: {
          return 'Erreur client';
        }
      }
    } else if (httpStatusCode >= 500 && httpStatusCode < 600) {
      switch(httpStatusCode) {
        case 500: {
          return 'Erreur interne.';
        }
        case 501: {
          return 'Requête non supportée.';
        }
        case 502: {
          return 'Reponse invalide.';
        }
        case 503: {
          return 'Le serveur est temporairement indisponible';
        }
        default: {
          return 'Erreur serveur';
        }
      }
    } else {
      return 'Erreur inconnue';
    }
  }

  async presentHttpStatusErrorAlert(httpStatusCode: number) {
    const subHeader = this.generateHttpErrorSubHeader(httpStatusCode);
    const alert = this.alertController.create({
      header: `Erreur (${httpStatusCode})`,
      subHeader,
      buttons: [
        {
          text: 'OK'
        }
      ]
    });

    await (await alert).present();
  }

  /*async presentGeneralErrorAlert(
    title: string,
    message: string,
    buttonText: string,
    action: Function,
    parameters: any[]
  ) {
    const header = title;
    const subHeader = message;
    const alert = this.alertController.create({
      header,
      subHeader,
      buttons: [
        {
          text: buttonText,
          handler: () => {
            action(parameters);
          }
        },

        {
          text: 'Annuler',
          role: 'cancel'
        }
      ]
    });

    await (await alert).present();
  }*/

  async presentGeneralErrorAlert(
    showCancelButton: boolean,
    title?: string,
    message?: string,
    actionButtonText?: string,
    action?: Function,
    parameters?: any[],
  ) {

    const header = title;
    const subHeader = message;
    let actionButton:any;

    if(typeof(action) !== "undefined") {
      actionButton =
      {
        text: actionButtonText,
        handler: () => {
          action(parameters);
        }
      }
    } else {
      actionButton =
      {
        text: actionButtonText
      }
    }

    let buttons: AlertOptions["buttons"] = [actionButton];

    const cancelButton: AlertButton =
    {
      text: 'Annuler',
      role: 'cancel'
    }

    if (showCancelButton) {
      buttons.push(cancelButton);
    }

    const alert = this.alertController.create({
      header,
      subHeader,
      buttons: buttons
    });

    await (await alert).present();
  }

}

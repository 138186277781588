import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class PlateauTechniqueService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getPlateauxTechniques() {
    const url = this.constants.apiGetPostEndpoint + '/plateau_techniques';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getPlateauxTechniquesForOneUtilisateur(user_id: number) {
    const url = this.constants.apiGetPostEndpoint + '/plateau_technique_user/' + user_id;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(plateau: any) {
    const url = this.constants.apiGetPostEndpoint + "/plateau_techniques";
    const httpHeaders = this.headerService.getPostHeader();
    let url_questions = [];
    let url_intervenants = [];

    if(typeof(plateau["questions_nouveau"]) !== "undefined" && plateau["questions_nouveau"].length > 0) {
      url_questions = this.prepareUrl(plateau["questions_nouveau"]);
    }

    if(typeof(plateau["intervenants_nouveau"]) !== "undefined" && plateau["intervenants_nouveau"].length > 0) {
      url_intervenants = this.prepareUrl(plateau["intervenants_nouveau"]);
    }

    return this.httpClient.post(url, {
      intitule: plateau["nom_nouveau"],
      referentStructure: plateau["referent_nouveau"],
      adresse: plateau["adresse_nouveau"],
      ville: plateau["ville_nouveau"],
      codePostal: plateau["code_postal_nouveau"],
      date: plateau["date_nouveau"],
      intervenants: url_intervenants,
      questions: url_questions,
      description: plateau["description_nouveau"],
    }, httpHeaders);
  }

  edite(plateau:any, lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();
    let url_questions = [];
    let url_intervenants = [];

    if(typeof(plateau["questions_edition"]) !== "undefined" && plateau["questions_edition"].length > 0) {
      url_questions = this.prepareUrl(plateau["questions_edition"]);
    }

    if(typeof(plateau["intervenants_edition"]) !== "undefined" && plateau["intervenants_edition"].length > 0) {
      url_intervenants = this.prepareUrl(plateau["intervenants_edition"]);
    }

    return this.httpClient.patch(url, {
      intitule: plateau["nom_edition"],
      referentStructure: plateau["referent_edition"],
      adresse: plateau["adresse_edition"],
      ville: plateau["ville_edition"],
      codePostal: plateau["code_postal_edition"],
      date: plateau["date_edition"],
      intervenants: url_intervenants,
      description: plateau["description_edition"],
      questions: url_questions
    },httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }

  prepareUrl(objets) {
    let urls = [];
    for(let objet of objets) {
      urls.push(objet["@id"]);
    }
    return urls;
  }
}
import { NgModule } from '@angular/core';
import {
  ExtraOptions,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import { AuthGuard } from './helpers/auth-guard';
import { LoginComponent } from './admin/login/login.component';
import { MainAdminComponent } from './admin/main-admin/main-admin.component';
import { SituationFamilialeComponent } from './admin/situation-familiale/situation-familiale.component';
import { DepartementComponent } from './admin/departement/departement.component';
import { NationnaliteComponent } from './admin/nationnalite/nationnalite.component';
import { NiveauEtudesComponent } from './admin/niveau-etudes/niveau-etudes.component';
import { CiviliteComponent } from './admin/civilite/civilite.component';
import { ModeDeplacementComponent } from './admin/mode-deplacement/mode-deplacement.component';
import { EntrepriseStageComponent } from './admin/entreprise-stage/entreprise-stage.component';
import { CompetenceCleComponent } from './admin/competence-cle/competence-cle.component';
import { SoftSkillComponent } from './admin/soft-skill/soft-skill.component';
import { AtelierComponent } from './admin/atelier/atelier.component';
import { TypeRecompenseComponent } from './admin/type-recompense/type-recompense.component';
import { EntrepriseRecompenseComponent } from './admin/entreprise-recompense/entreprise-recompense.component';
import { TypeFormuleComponent } from './admin/type-formule/type-formule.component';
import { TypeObjectifComponent } from './admin/type-objectif/type-objectif.component';
import { SituationProfessionnelleComponent } from './admin/situation-professionnelle/situation-professionnelle.component';
import { EtapeComponent } from './admin/etape/etape.component';
import { RecompenseComponent } from './admin/recompense/recompense.component';
import { NiveauComponent } from './admin/niveau/niveau.component';
import { FormuleComponent } from './admin/formule/formule.component';
import { ObjectifComponent } from './admin/objectif/objectif.component';
import { UtilisateurComponent } from './admin/utilisateur/utilisateur.component';
import { PlateauTechniqueComponent } from './admin/plateau-technique/plateau-technique.component';
import { QuestionsComponent } from './admin/questions/questions.component';
import { PlateauUserComponent } from './admin/plateau-user/plateau-user.component';
import { ObjectifsUtilisateursComponent } from './admin/objectifs-utilisateurs/objectifs-utilisateurs.component';
import { IntervenantComponent } from './admin/intervenant/intervenant.component';
import { DonneesPersonnellesComponent } from './donnees-personnelles/donnees-personnelles.component';
import { DescriptionPersonnaliseeComponent } from './admin/description-personnalisee/description-personnalisee.component';
import { DescriptionAtelierComponent } from './admin/description-atelier/description-atelier.component';
import { DescriptionSoftSkillComponent } from './admin/description-soft-skill/description-soft-skill.component';
import { DescriptionCompetenceCleComponent } from './admin/description-competence-cle/description-competence-cle.component';
import { StatistiquesComponent } from './components/statistiques/statistiques.component';
import { LogsComponent } from './admin/logs/logs.component';
import { AdminGuard } from './guards/admin.guard';
import { ConditionsUtilisationGuard } from './guards/conditionsUtilisation.guard';
import { PlateauTechniqueUserComponent } from './pages/plateau-technique/plateau-technique.component';
import { StageUserComponent } from './pages/stage-user/stage-user.component';
import { ValidationCompteComponent } from './components/validation-compte/validation-compte.component';
import { QuestionsAudioComponent } from './admin/questions-audio/questions-audio.component';
import { ReponsesAudioUserComponent } from './admin/reponses-audio-user/reponses-audio-user.component';
import { RendezVousIndividuelsComponent } from './admin/rendez-vous-individuels/rendez-vous-individuels.component';
import { BookingsComponent } from './admin/bookings/bookings.component';
import { DescriptionCustomComponent } from './admin/description-custom/description-custom.component';

const routes: Routes = [
  {
    path: 'connexion',
    loadChildren: () =>
      import('./connection/connection.module').then(
        (m) => m.ConnectionPageModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard, ConditionsUtilisationGuard],
  },

  {
    path: 'donneesPersonnelles',
    component: DonneesPersonnellesComponent,
  },

  /*{
    path: '',
    // redirectTo: '/admin_main',
    redirectTo: '/connexion',
    pathMatch: 'full'
  },*/

  {
    path: 'type/:type/:idNiveau',
    loadChildren: () =>
      import('./pages/rewards-type/rewards-type.module').then(
        (m) => m.RewardsTypePageModule
      ),
  },
  // {
  //   path: 'type/:type',
  //   loadChildren: () =>
  //     import('./pages/rewards-type/rewards-type.module').then(
  //       (m) => m.RewardsTypePageModule
  //     ), data:{idNiveau:"mon test d'id de niveau"}
  // },

  {
    path: 'technical-platform/:userId/:technicalPlatformId',
    loadChildren: () =>
      import('./pages/technical-platform/technical-platform.module').then(
        (m) => m.TechnicalPlatformPageModule
      ),
  },
  {
    path: 'admin_login',
    component: LoginComponent,
  },
  {
    path: 'admin_main',
    canActivate: [AdminGuard],
    component: MainAdminComponent,
  },
  {
    path: 'admin_situation_familiale',
    canActivate: [AdminGuard],
    component: SituationFamilialeComponent,
  },
  {
    path: 'admin_departement',
    canActivate: [AdminGuard],
    component: DepartementComponent,
  },
  {
    path: 'admin_nationnalite',
    canActivate: [AdminGuard],
    component: NationnaliteComponent,
  },
  {
    path: 'admin_niveau_etudes',
    canActivate: [AdminGuard],
    component: NiveauEtudesComponent,
  },
  {
    path: 'admin_situation_professionnelle',
    canActivate: [AdminGuard],
    component: SituationProfessionnelleComponent,
  },
  {
    path: 'admin_civilite',
    canActivate: [AdminGuard],
    component: CiviliteComponent,
  },
  {
    path: 'admin_mode_deplacement',
    canActivate: [AdminGuard],
    component: ModeDeplacementComponent,
  },
  {
    path: 'admin_stage_entreprise',
    canActivate: [AdminGuard],
    component: EntrepriseStageComponent,
  },
  {
    path: 'admin_competences_cle',
    canActivate: [AdminGuard],
    component: CompetenceCleComponent,
  },
  {
    path: 'admin_soft_skill',
    canActivate: [AdminGuard],
    component: SoftSkillComponent,
  },
  {
    path: 'admin_eculture',
    canActivate: [AdminGuard],
    component: AtelierComponent,
  },
  {
    path: 'admin_types_recompenses',
    canActivate: [AdminGuard],
    component: TypeRecompenseComponent,
  },
  {
    path: 'admin_cashback',
    canActivate: [AdminGuard],
    component: EntrepriseRecompenseComponent,
  },
  {
    path: 'admin_types_formule',
    canActivate: [AdminGuard],
    component: TypeFormuleComponent,
  },
  {
    path: 'admin_types_objectif',
    canActivate: [AdminGuard],
    component: TypeObjectifComponent,
  },
  {
    path: 'admin_objectif',
    canActivate: [AdminGuard],
    component: ObjectifComponent,
  },
  {
    path: 'admin_etapes',
    canActivate: [AdminGuard],
    component: EtapeComponent,
  },
  {
    path: 'admin_recompenses',
    canActivate: [AdminGuard],
    component: RecompenseComponent,
  },
  {
    path: 'admin_niveaux',
    canActivate: [AdminGuard],
    component: NiveauComponent,
  },
  {
    path: 'admin_formules',
    canActivate: [AdminGuard],
    component: FormuleComponent,
  },
  {
    path: 'admin_utilisateurs',
    canActivate: [AdminGuard],
    component: UtilisateurComponent,
  },
  {
    path: 'admin_objectifs_utilisateur/:id',
    canActivate: [AdminGuard],
    component: ObjectifsUtilisateursComponent,
  },
  {
    path: 'admin_rdv_individuels_utilisateur/:id',
    canActivate: [AdminGuard],
    component: RendezVousIndividuelsComponent,
  },
  {
    path: 'admin_intervenants',
    canActivate: [AdminGuard],
    component: IntervenantComponent,
  },
  {
    path: 'admin_plateaux_techniques',
    canActivate: [AdminGuard],
    component: PlateauTechniqueComponent,
  },
  {
    path: 'admin_questions',
    canActivate: [AdminGuard],
    component: QuestionsComponent,
  },
  {
    path: 'admin_descriptions_personnalisees',
    canActivate:  [AdminGuard],
    component:  DescriptionPersonnaliseeComponent
  },
  {
    path: 'admin_reponsesUtilisateur_plateau/:id',
    canActivate: [AdminGuard],
    component: PlateauUserComponent,
  },
  {
    path: 'admin_description_atelier',
    canActivate: [AdminGuard],
    component: DescriptionAtelierComponent,
  },
  {
    path: 'admin_description_soft_skill',
    canActivate: [AdminGuard],
    component: DescriptionSoftSkillComponent,
  },
  {
    path: 'admin_description_competence_cle',
    canActivate: [AdminGuard],
    component: DescriptionCompetenceCleComponent,
  },
  {
    path: 'admin_statistiques',
    canActivate: [AdminGuard],
    component: StatistiquesComponent,
  },
  {
    path: 'admin_logs',
    canActivate: [AdminGuard],
    component: LogsComponent,
  },
  {
    path: 'admin_questions_audio',
    canActivate: [AdminGuard],
    component: QuestionsAudioComponent,
  },
  {
    path: 'admin_bookings',
    canActivate: [AdminGuard],
    component: BookingsComponent,
  },
  {
    path: 'admin_descriptions_custom/:id',
    canActivate: [AdminGuard],
    component: DescriptionCustomComponent,
  },
  {
    path: 'user_plateau_technique',
    component: PlateauTechniqueUserComponent,
  },
  {
    path: 'admin_reponses_audio_utilisateur/:id',
    canActivate: [AdminGuard],
    component: ReponsesAudioUserComponent,
  },
  {
    path: 'user_stage',
    component: StageUserComponent,
  },
  {
    path: 'validationCompte/:ok',
    component: ValidationCompteComponent
  },
  {
    path: '**',
    //remplacer la page par un notfound component
    loadChildren: () =>
      import('./not-found-page/not-found-page.module').then(
        (m) => m.NotFoundPagePageModule
      ),
  },
];

// const routerOptions: ExtraOptions = {
//   scrollPositionRestoration: 'enabled',
//   anchorScrolling:'enabled'
// };

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

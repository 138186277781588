import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorNotificationService } from './error-notification.service';
import { Constants } from '../config/constants';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  httpHeaders = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  //Statut d'authentification
  isLoggedIn = false;

  constructor(
    private httpClient: HttpClient,
    private errorNotificationService: ErrorNotificationService,
    private constants: Constants
  ) {}

  //Requête connexion
  login(email: string, password: string) {
    const url = this.constants.apiEndpoint + '/apiLogin';
    return this.httpClient.post(url, { email, password }, this.httpHeaders);
  }

  //Requête déconnexion
  logout() {
    const url = this.constants.apiEndpoint + '/apiLogout';
    return this.httpClient.post(url, this.httpHeaders);
  }

  //Méthodes liées aux tokens

  //Vérifie l'existence du token
  //Retourne true si le token existe, false dans le cas contraire
  verifyTokenExistence(): boolean {
    return window.sessionStorage.getItem('token') != null;
  }

  //Efface le token de connexion du session storage
  removeToken() {
    window.sessionStorage.removeItem('token');
  }

  getToken(): string {
    return window.sessionStorage.getItem('token');
  }

  //Enregistre le token dans sessionStorage
  setToken(token: string) {
    try {
      window.sessionStorage.setItem('token', token);
    } catch (error) {
      throw Error;
    }
  }

  setRefreshToken(refreshToken: string) {
    try {
      window.sessionStorage.setItem('refreshToken', refreshToken);
    } catch {
      throw Error;
    }
  }

  refreshToken() {
    const url = this.constants.apiEndpoint + '/api/token/refresh';
    return this.httpClient.post(url, this.httpHeaders);
  }

  // isLoggedIn(): boolean {
  //   return this.verifyTokenExistence()
  // }
}

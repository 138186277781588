import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UtilisateurObjectifService } from 'src/app/services/UtilisateurObjectif/utilisateur-objectif.service';
import { ToastService } from 'src/app/services/Toast/toast.service';
import { DescriptionObjectifCustomService } from 'src/app/services/DescriptionObjectifCustom/description-objectif-custom.service';

@Component({
  selector: 'app-template-form-descriptions-sous-objectifs-soft-skill',
  templateUrl: './template-form-descriptions-sous-objectifs-soft-skill.component.html',
  styleUrls: ['./template-form-descriptions-sous-objectifs-soft-skill.component.scss'],
})
export class TemplateFormDescriptionsSousObjectifsSoftSkillComponent implements OnInit {
  @Input("descriptionSoftSkills") descriptionSoftSkills:any;
  @Input("sousObjectif") sousObjectif:any;
  @Input("idSoftSkillObjectifPrincipal") idSoftSkillObjectifPrincipal:number;
  @Input("userId") userId:number;
  @Input("etapeId") etapeId:number;
  @Input("niveauId") niveauId:number;

  private utilisateurObjectifs:any;
  autreDescriptionSoftSkill:boolean=false;
  autreDescriptionSoftSkillEdite:boolean=false;

  softSkillForm = new FormGroup({
    softSkillEdition: new FormControl('')
  });

  descriptionAutreSoftSkillForm = new FormGroup({
    softSkillAutreEdition: new FormControl('', [Validators.required, Validators.maxLength(255)])
  });

  constructor(
    private errorNotificationService:ErrorNotificationService,
    private loadingService:LoadingService,
    private descriptionObjectifCustomService:DescriptionObjectifCustomService,
    private utilisateurObjectifService:UtilisateurObjectifService,
    private toast:ToastService,
  ) { }

  ngOnInit() {
    this.utilisateurObjectifs = this.sousObjectif.UtilisateurObjectifs;
    //on ne garde que les UtilisateurObjectif lies au SousObjectif en cours
    //base
    this.utilisateurObjectifs = this.utilisateurObjectifs.filter(element => element.utilisateur.id === this.userId);
    this.utilisateurObjectifs = this.utilisateurObjectifs[0];
    this.setFormValues();
  }

  setFormValues() {
    if(!!this.utilisateurObjectifs) {
      if(!!this.utilisateurObjectifs.descriptionObjectifCustom
        && this.utilisateurObjectifs.descriptionObjectifCustom["estValide"]) {
          this.softSkillForm.patchValue({
            "softSkillEdition": " Autre "
          });

          this.descriptionAutreSoftSkillForm.patchValue({
            "softSkillAutreEdition": this.utilisateurObjectifs.descriptionObjectifCustom.nom
          });
          this.autreDescriptionSoftSkill = true;
      } else if(!!this.utilisateurObjectifs.descriptionSoftSkill) {
        this.softSkillForm.patchValue({
          "softSkillEdition": this.utilisateurObjectifs.descriptionSoftSkill
        });
      }
    }
  }

  descriptionSoftSkillChange(event) {
    if(event.detail.value === " Autre ") {
      this.alertError(
        "",
        "Attention",
        "Vore objectif personnalisé devra être validé par un administrateur pour être visible"
      );
      this.autreDescriptionSoftSkill = true;
      //reset du textArea
      this.descriptionAutreSoftSkillForm.patchValue({
        "softSkillAutreEdition": ""
      });
    } else { //cas pas "autre"
      this.autreDescriptionSoftSkill = false;

      //si l'utilisateurObjectif n'existe pas, on le cree
      if(!!!this.utilisateurObjectifs) {
        this.creeDescriptif(event.detail.value.id);
      } else {//sinon, c'est une edition
        //dans le cas ou on passe d'une DescriptionSoftSkill à une description custom sans valider
        //pour revenir à la DescriptionSoftSkill de base, on ne fait pas de changement
        if(!!this.utilisateurObjectifs["descriptionSoftSkill"]
          && event.detail.value.id !== this.utilisateurObjectifs["descriptionSoftSkill"]["id"]
          || this.utilisateurObjectifs["descriptionSoftSkill"]) {
          //si on cherche à lier une descriptionSoftSkill mais 
          //qu'une descriptionObjectifCustom est deja liee
          //on supprime cette derniere
          this.supprimeDescriptionCustom();
        }
        this.editeDescriptif("softSkill", [event.detail.value["@id"]]);
      }
    }
  }

  submitAutreDescriptionSoftSkill() {
    this.loadingService.showLoading().then(
      () => {
        this.submitAutreDescription(this.descriptionAutreSoftSkillForm.value.softSkillAutreEdition);
      }
    );
  }

  submitAutreDescription(description:string) {
    //si aucune DescriptionObjectifCustom n'a encore été
    //liée à l'ObjectifUtilisateur en cours, on le crée
    if(this.utilisateurObjectifs["descriptionObjectifCustom"] === null) {
      this.descriptionObjectifCustomService.cree(
        this.utilisateurObjectifs["id"],
        description,
        false,
        "user"
      ).subscribe(
        () => {
          this.loadingService.dismissLoading();
          this.toast.presentToast("bottom", "Enregistré", "success");
        },
        error => {
          //console.log(error);
          this.loadingService.dismissLoading();
          this.alertError("enregistrement");
        }
      );
    } else {
      this.descriptionObjectifCustomService.edite(
        this.utilisateurObjectifs["descriptionObjectifCustom"].id,
        this.utilisateurObjectifs["id"],
        description,
        false,
        "user"
      ).subscribe(
        () => {
          this.loadingService.dismissLoading();
          this.toast.presentToast("bottom", "Enregistré", "success");
        },
        error => {
          this.loadingService.dismissLoading();
          this.alertError("enregistrement");
        }
      )
    }
  }

  clickOnObjectifCustom(event/*, atelier:string="atelier"*/) {
    let message = "Toute modification de votre objectif personnalisé devra être validé pour être visible.";
    message += " Dans l'attente de cette validation, l'objectif par défaut sera affiché";

    if(event.target.value !== "" && !this.autreDescriptionSoftSkillEdite) {
      this.autreDescriptionSoftSkillEdite = true;
      this.alertError(
        "",
        "Attention",
        message
      );
    }
  }

  creeDescriptif(descriptionSoftSkillId:number) {
    this.loadingService.showLoading();
    this.utilisateurObjectifService.creation(
      descriptionSoftSkillId,
      "softSkill",
      this.sousObjectif.id,
      this.niveauId,
      this.etapeId
    ).subscribe(
      data => {
        this.loadingService.dismissLoading();
      },
      error => {
        this.loadingService.dismissLoading();
        this.alertError("enregistrement");
        this.loadingService.dismissLoading();
      }
    )
  }

  editeDescriptif(type:string, liens:string[]) {
    this.loadingService.showLoading();
    this.utilisateurObjectifService.editeDescriptifs(
      this.utilisateurObjectifs["id"], type, liens, this.idSoftSkillObjectifPrincipal
    ).subscribe(
      data => {
        //let infos = data;
        this.utilisateurObjectifs["descriptionSoftSkill"] = data["descriptionSoftSkill"];
        this.loadingService.dismissLoading();
      },
      error => {
        this.loadingService.dismissLoading();
        this.alertError("chargement");
        this.loadingService.dismissLoading();
      }
    )
  }

  supprimeDescriptionCustom() {
    if(this.utilisateurObjectifs["descriptionObjectifCustom"] !== null) {
      this.descriptionObjectifCustomService.supprime(
        this.utilisateurObjectifs["descriptionObjectifCustom"].id
      ).subscribe(
        data => {
          //let infos = data;
          this.utilisateurObjectifs["descriptionObjectifCustom"] = null;
        },
        error => {
          this.alertError("chargement");
        }
      )
    }
  }

  alertError(type:string, titre?:string, message?:string) {
    titre = type === null ? "Erreur" : titre;

    switch(type) {
      case "enregistrement":
        message = "Un problème a été rencontré lors de l'enregistrement des données";
        break;

      case "chargement":
        message = "Un problème a été rencontré lors du chargement des données";
        break;

      default:
        break;
    }
    
    this.errorNotificationService.presentGeneralErrorAlert(
      false,
      titre,
      message,
      "Ok"
    );
  }

  //pour le formulaire d'edition
  //coche les checkboxes des options selectionnees
  compareWith(o1, o2) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionAudioService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) {}

  getAll() {
    const url = this.constants.apiGetPostEndpoint + '/questionsAudio';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  create(intitule:string) {
    const url = this.constants.apiGetPostEndpoint + "/questionAudio";
    const httpHeaders = this.headerService.getPostHeader();

    return this.httpClient.post(url, {
      intitule: intitule
    }, httpHeaders);
  }

  edit(id:number, intitule:string) {
    const url = this.constants.apiPatchDeleteEndpoint + "/questionAudio/" + id;
    const httpHeaders = this.headerService.getPatchHeader();

    return this.httpClient.patch(url, {
      intitule: intitule
    }, httpHeaders);
  }

  delete(id:number) {
    const url = this.constants.apiPatchDeleteEndpoint + "/questionAudio/" + id;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

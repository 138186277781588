import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { MatTable } from '@angular/material/table';
import { DescriptionCompetenceCleService } from 'src/app/services/DescriptionCompetenceCle/description-competence-cle.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastService } from '../../services/Toast/toast.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { LoadingService } from 'src/app/services/loading.service';
import { QuestionAudioService } from 'src/app/services/QuestionsAudio/question-audio.service';

@Component({
  selector: 'app-questions-audio',
  templateUrl: './questions-audio.component.html',
  styleUrls: ['./questions-audio.component.scss'],
})
export class QuestionsAudioComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild("questionsAudioTable") questionsAudioTable!: MatTable<any>;

  public questionsAudio:any[];
  displayedColumns: string[] = [
    "intitule",
    "editForm",
    "deleteForm"
  ];
  creationMode:boolean=true;
  nowLoading:boolean=true;

  createForm = new FormGroup({
    intitule: new FormControl('', [Validators.required, Validators.maxLength(100)]),
  });

  editForm = new FormGroup({
    id: new FormControl(),
    intitule: new FormControl('', [Validators.required, Validators.maxLength(100)]),
  });

  constructor(private questionAudioService:QuestionAudioService,
    private errorNotificationService:ErrorNotificationService,
    private loadingService:LoadingService,
    private toastService:ToastService) { }

  ngOnInit() {
    this.getAll();
  }

  getAll() {
    this.questionAudioService.getAll().subscribe(
      data => {
        this.questionsAudio = data["hydra:member"];
        this.nowLoading = false;
      },
      /*error*/() => {
        //console.log(error);
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors du chargement des données",
          "Ok"
        );
        this.nowLoading = false;
      }
    )
  }

  submitCreation() {
    this.loadingService.showLoading();
    const formValues = this.createForm.value;

    if(!!formValues.intitule) {
      this.questionAudioService.create(formValues.intitule).subscribe(
        data => {
          this.questionsAudio.push(data);
          this.questionsAudioTable.renderRows();
          this.closeModal();
          this.createForm.reset();
          this.loadingService.dismissLoading();
          this.toastService.presentToast("bottom", "Question audio enregistrée", "success");
        },
        error => {
          //console.log(error);
          this.errorNotificationService.presentGeneralErrorAlert(
            true,
            "Erreur",
            "Un problème a été rencontré lors de l'enregistrement des données",
            "Ok"
          );
          this.loadingService.dismissLoading();
        }
      )
    }
  }

  submitEdition() {
    this.loadingService.showLoading();
    const formValues = this.editForm.value;

    if(!!formValues.intitule && !!formValues.id) {
      this.questionAudioService.edit(formValues.id, formValues.intitule).subscribe(
        () => {
          let modifiedElement = this.questionsAudio.find(
            element => element.id === formValues.id
          );
          let index = this.questionsAudio.indexOf(modifiedElement);
          this.questionsAudio[index]["intitule"] = formValues.intitule;
          this.questionsAudioTable.renderRows();
          this.closeModal();
          this.loadingService.dismissLoading();
          this.toastService.presentToast("bottom", "Questions audio editée", "success");
        },
        error => {
          //console.log(error);
          this.errorNotificationService.presentGeneralErrorAlert(
            true,
            "Erreur",
            "Un problème a été rencontré lors de l'enregistrement des données",
            "Ok"
          );
          this.loadingService.dismissLoading();
        }
      )
    }
  }

  deleteWarning(elementId:number) {
    this.errorNotificationService.presentGeneralErrorAlert(
      true,
      "Attention",
      "êtes vous sur de vouloir supprimer cette question audio ?",
      "Oui",
      this.remove.bind(this),
      [elementId]
    );
  }

  remove(id:number) {
    this.loadingService.showLoading();
    this.questionAudioService.delete(id[0]).subscribe(
      () => {
        let removedElement = this.questionsAudio.find(
          element => element.id === id[0]
        );
        let index = this.questionsAudio.indexOf(removedElement);
        this.questionsAudio.splice(index, 1);
        this.questionsAudioTable.renderRows();
        this.closeModal();
        this.loadingService.dismissLoading();
        this.toastService.presentToast("bottom", "Question audio supprimée", "success");
      },
      error => {
        //console.log(error);
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "Un problème a été rencontré lors de la suppression des données",
          "Ok"
        );
        this.loadingService.dismissLoading();
      }
    )
  }

  closeModal() {
    this.modal.dismiss();
  }

  showModal(modeCreation:boolean, element:any=null) {
    this.creationMode = modeCreation;

    if(!modeCreation && element !== null) {
      this.editForm.patchValue({
        "id": element.id,
        "intitule": element.intitule,
      });
    }

    this.modal.present();
  }

}

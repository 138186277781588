import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenManagementService } from '../services/token-management.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private jwtHelperService: JwtHelperService,
    private tokenManagementService: TokenManagementService,
    private router:Router
  ) { }

  async canActivate() {
    const token = this.tokenManagementService.getToken();

    if (token != null && !this.jwtHelperService.isTokenExpired(token)) {
      return true;
    }
    return this.router.navigateByUrl("/");
  }
}

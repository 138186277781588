import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class NiveauService {
  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getNiveaux() {
    const url = this.constants.apiGetPostEndpoint + '/niveaux';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getPaginatedNiveaux(pageNumber:number=1) {
    let url = this.constants.apiGetPostEndpoint + '/niveauxPaginated?page=' + pageNumber;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(niveau: any) {    
    const url = this.constants.apiGetPostEndpoint + "/niveaux";
    const httpHeaders = this.headerService.getPostHeader();
    let urls_etapes = [];
    let urls_recompenses = [];

    for(let etape of niveau["etapes_nouveau"]) {
      urls_etapes.push(etape["@id"]);
    }

    for(let recompense of niveau["recompenses_nouveau"]) {
      urls_recompenses.push(recompense["@id"]);
    }

    return this.httpClient.post(url, {
      numeroNiveau: niveau["numero_nouveau"],
      nom: niveau["nom_nouveau"],
      description: niveau["description_nouveau"],
      etapes: urls_etapes,
      recompenses: urls_recompenses,
      ville: niveau["ville_nouveau"]
    }, httpHeaders);
  }

  edite(niveau:any, lien: string) {    
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();
    let urls_etapes = [];
    let urls_recompenses = [];

    for(let etape of niveau["etapes_edition"]) {
      urls_etapes.push(etape["@id"]);
    }

    for(let recompense of niveau["recompenses_edition"]) {
      urls_recompenses.push(recompense["@id"]);
    }

    return this.httpClient.patch(url, {
      numeroNiveau: niveau["numero_edition"],
      nom: niveau["nom_edition"],
      description: niveau["description_edition"],
      etapes: urls_etapes,
      recompenses: urls_recompenses,
      ville: niveau["ville_edition"]
    }, httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}

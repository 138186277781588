import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(private httpClient: HttpClient,
    private constants: Constants,
    private headerService: HeadersService) { }

  getDates() {
    const url = this.constants.apiGetPostEndpoint + '/apiLogs';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  getLogs(date:string) {
    const splittedDate = date.split("-");
    const fileName = "logs du " + splittedDate[2] + "_" + splittedDate[1] + "_" + splittedDate[0] + ".txt";
    const url = this.constants.apiGetPostEndpoint + "/apiGetLog";
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {fileName: fileName,}, httpHeaders);
  }
}

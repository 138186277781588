import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DescriptionCustomService } from 'src/app/services/DescriptionCustom/description-custom.service';
import { MatTable } from '@angular/material/table';
import { IonModal } from '@ionic/angular';
import { LoadingService } from 'src/app/services/loading.service';
import { ErrorNotificationService } from '../../services/error-notification.service';

@Component({
  selector: 'app-description-custom',
  templateUrl: './description-custom.component.html',
  styleUrls: ['./description-custom.component.scss'],
})
export class DescriptionCustomComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild("descriptionsTable") descriptionsTable!: MatTable<any>;

  public userId;
  public descriptionsCustom=[];
  public currentDescriptionsCustom;
  public currentEditedId:number;
  public levels=[];
  public firstLevelNumber:string;
  public descriptionsAteliers:any[];
  public loading:boolean=true;
  public emptyItems=new Array<boolean>(8);
  displayedColumns: string[] = [
    "numéro de l'étape",
    "nom de l'étape",
    "intitulé de l'objectif",
    "descriptif personnalisé",
    "descriptif validé",
    "edition etat",
    "edition texte"
  ];
  public currentEdit = {
    "utilisateurObjectif": null,
    "descriptionCustomId": null,
    "isValidated": null,
    "intitule": null
  }

  constructor(private _Activatedroute:ActivatedRoute,
    private descriptionCustomService:DescriptionCustomService,
    private loadingService:LoadingService,
    private errorNotificationService: ErrorNotificationService
  ) { }

  ngOnInit() {
    this.userId=this._Activatedroute.snapshot.paramMap.get("id");
    this.getDescriptionsCustom();    
  }

  getDescriptionsCustom() {
    this.descriptionCustomService.getByUserId(this.userId).subscribe(
      data => {
        this.descriptionsCustom = data["data"];

        //if(this.descriptionsCustom.length > 1) { //edited
        if(this.descriptionsCustom.length > 0) {
          this.extractLevels();
        } else {
          this.loading = false;
        }
      },
      error => {
        error => {
          let message = "Erreur: le chargement des données échoué";
          if(error.status === 422) {
            message = error.error;
          }
  
          this.errorNotificationService.presentGeneralErrorAlert(
            false,
            "Erreur",
            error.error,
            "Ok"
          );
  
          this.loadingService.dismissLoading();
        }
      }
    )
  }

  extractLevels() {
    let spottedLevels = [];
    for(let descriptionCustom of this.descriptionsCustom) {
      let currentLevel = descriptionCustom.utilisateurObjectif.niveau;

      if(!spottedLevels.includes(currentLevel.numeroNiveau)) {
        spottedLevels.push(currentLevel.numeroNiveau);
        this.levels.push({
          "number": currentLevel.numeroNiveau,
          "name": currentLevel.nom
        });
      }
    }

    this.sortDescriptionsCustomByLevel();
  }

  sortDescriptionsCustomByLevel() {
    this.descriptionsCustom.sort(function(a,b) {
      if(a.utilisateurObjectif.niveau.numeroNiveau < b.utilisateurObjectif.niveau.numeroNiveau) {
        return -1
      };

      if(a.utilisateurObjectif.niveau.numeroNiveau > b.utilisateurObjectif.niveau.numeroNiveau) {
        return 1
      };
    });

    this.firstLevelNumber = this.descriptionsCustom[0].utilisateurObjectif.niveau.numeroNiveau;
    this.selectCurrentDescriptionsCustom(parseInt(this.firstLevelNumber));
  }

  selectCurrentDescriptionsCustom(levelNumber:number) {
    this.currentDescriptionsCustom = this.descriptionsCustom.filter((element) => {
      return element.utilisateurObjectif.niveau.numeroNiveau === levelNumber;
    });

    this.sortCurrentDescriptionsCustomByStep();
  }

  sortCurrentDescriptionsCustomByStep() {
    this.currentDescriptionsCustom.sort(function(a,b) {
      if(a.utilisateurObjectif.etape.numeroEtape < b.utilisateurObjectif.etape.numeroEtape) {
        return -1
      };

      if(a.utilisateurObjectif.etape.numeroEtape > b.utilisateurObjectif.etape.numeroEtape) {
        return 1
      };
    });

    this.loading = false;
  }

  levelChange(event) {
    this.selectCurrentDescriptionsCustom(event.detail.value);
  }

  getIntituleObjectif(descriptionCustom) {
    let utilisateurObjectif = descriptionCustom.utilisateurObjectif;

    if(!!utilisateurObjectif.PlateauTechnique) {
      return utilisateurObjectif.PlateauTechnique.intitule
    }

    if(!!utilisateurObjectif.atelier) {
      return utilisateurObjectif.atelier.intitule
    }

    if(!!utilisateurObjectif.competenceCle) {
      return utilisateurObjectif.competenceCle.intitule
    }

    if(!!utilisateurObjectif.entrepriseStage) {
      return utilisateurObjectif.entrepriseStage.intitule
    }

    if(!!utilisateurObjectif.softSkill) {
      return utilisateurObjectif.softSkill.intitule
    }
  }

  editState(selectedElement) {
    this.loadingService.showLoading();
    let newState = !selectedElement.estValide;

    this.descriptionCustomService.edit(selectedElement.id, selectedElement.name, newState).subscribe(
      data => {
        let infos = data["data"];
        /*let modifiedElement = this.currentDescriptionsCustom.find(element => {
          return element.id === infos.id
        });

        if(!!modifiedElement) {
          let index = this.currentDescriptionsCustom.indexOf(modifiedElement);

          if(index > -1) {
            this.currentDescriptionsCustom[index].estValide = newState;            
          }
        }*/

        let modifiedElement = this.descriptionsCustom.find(element => {
          return element.id === infos.id
        });

        if(!!modifiedElement) {
          let index = this.descriptionsCustom.indexOf(modifiedElement);

          if(index > -1) {
            this.descriptionsCustom[index].estValide = newState;            
          }
        }

        this.loadingService.dismissLoading();
      },
      error => {
        let message = "Erreur: l'édition a échoué";
        if(error.status === 422) {
          message = error.error;
        }

        this.errorNotificationService.presentGeneralErrorAlert(
          false,
          "Erreur",
          error.error,
          "Ok"
        );

        this.loadingService.dismissLoading();
      }
    );
  }

  showEditModal(selectedElement) {
    this.currentEditedId = selectedElement.id;
    this.currentEdit["utilisateurObjectif"] = selectedElement.utilisateurObjectif;
    this.currentEdit["descriptionCustomId"] = selectedElement.id;
    this.currentEdit["isValidated"] = selectedElement.estValide;
    this.currentEdit["intitule"] = selectedElement.nom;
    this.modal.present();
  }

  descriptionEdited(event:string) {
    /*let modifiedElement = this.currentDescriptionsCustom.find(element => {
      return element.id === this.currentEditedId
    });

    if(!!modifiedElement) {
      let index = this.currentDescriptionsCustom.indexOf(modifiedElement);

      if(index > -1) {
        this.currentDescriptionsCustom[index].nom = event;
        this.descriptionsTable.renderRows();
      }
    }*/

    let modifiedElement = this.descriptionsCustom.find(element => {
      return element.id === this.currentEditedId
    });

    if(!!modifiedElement) {
      let index = this.descriptionsCustom.indexOf(modifiedElement);

      if(index > -1) {
        this.descriptionsCustom[index].nom = event;
        this.descriptionsTable.renderRows();
      }
    }

    this.closeModal();
  }

  closeModal() {
    this.modal.dismiss();
  }
}

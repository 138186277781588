import { Component, OnInit } from '@angular/core';
import { /*Router, */ActivatedRoute } from '@angular/router';
import { UtilisateurService } from 'src/app/services/Utilisateur/utilisateur.service';
import { ReponseAudioService } from 'src/app/services/ReponseAudio/reponse-audio.service';
import { ErrorNotificationService } from '../../services/error-notification.service';

@Component({
  selector: 'app-reponses-audio-user',
  templateUrl: './reponses-audio-user.component.html',
  styleUrls: ['./reponses-audio-user.component.scss'],
})
export class ReponsesAudioUserComponent implements OnInit {
  userId:number;
  userFirstname:string;
  userName:string;
  etapes=[];
  currentEtape;
  reponsesAudioData;
  questionsQuantity:number=3;
  isLoading:boolean=true;
  noData:boolean=false;

  constructor(private _Activatedroute:ActivatedRoute,
    private utilisateurService:UtilisateurService,
    private reponseAudioService:ReponseAudioService,
    private errorNotificationService: ErrorNotificationService) { }

  ngOnInit() {
    this.userId = parseInt(this._Activatedroute.snapshot.paramMap.get("id"), 10);
    this.getReponsesAudioList();
  }

  getReponsesAudioList() {
    this.utilisateurService.getReponsesAudioList(this.userId).subscribe(
      data => {
       let listeReponsesAudio = data["reponsesAudio"];
       this.userName = data["nom"];
       this.userFirstname = data["prenom"];

        for(let reponseAudio of listeReponsesAudio) {
          let currentEtape = reponseAudio.etape;
          let alreadyHere = false;

          for(let etape of this.etapes) {
            if(etape.id === currentEtape.id) {
              alreadyHere = true;
            }
          }

          if(!alreadyHere) {
            this.etapes.push({
              id: currentEtape.id,
              nom: currentEtape.nom,
              numeroEtape: currentEtape.numeroEtape
            });
          }
        }
        
        this.etapes.sort((a, b) => a.id - b.id); //tri par id croissant
        this.currentEtape = this.etapes[0];
        this.getReponsesAudioData();
      },
      /*error*/() => {
        //console.log(error);
        this.isLoading = false;
        this.errorNotificationService.presentGeneralErrorAlert(
          true,
          "Erreur",
          "La liste des réponses audio n'a pas pu etre chargée",
          "Ok"
        );
      }
    )
  }

  getReponsesAudioData() {
    if(!!this.currentEtape && !!this.currentEtape.id) {
      this.utilisateurService.getReponsesAudio(this.userId, this.currentEtape.id).subscribe(
        data => {
          this.reponsesAudioData = data["hydra:member"];
          this.questionsQuantity = data["hydra:totalItems"];
          this.getAudioFile();
        },
        () => {
          this.isLoading = false;
          this.errorNotificationService.presentGeneralErrorAlert(
            true,
            "Erreur",
            "Les données des réponses audio n'ont pas pu etre chargées",
            "Ok"
          );
        }
      )
    } else {
      this.isLoading = false;
      this.noData = true;
    }
  }

  etapeChange(event) {
    this.isLoading = true;
    for(let etape of this.etapes) {
      if(etape.id === event.detail.value.id) {
        this.currentEtape = etape;
        this.getReponsesAudioData();
        break;
      }
    }
  }

  getAudioFile() {
    for(let reponse of this.reponsesAudioData) {
      this.reponseAudioService.getSound(reponse.id).subscribe(
        data => {
          let blob:any = data;
          let audioUrl = URL.createObjectURL(blob);
          reponse.audioUrl = audioUrl;
          this.isLoading = false;
        },
        error => {
          //si l'API renvoie une 404 pour cette requete, c'est qu'il
          //n'y a pas de fichier son enregistré
          if(error.status !== 404) {
            this.errorNotificationService.presentGeneralErrorAlert(
              true,
              "Erreur",
              "Le fichier son n'a pas pu etre chargé",
              "Ok"
            );
          }
          this.isLoading = false;
        }
      )
    }
  }
}
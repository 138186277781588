import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../config/constants';
import { HeadersService } from '../Headers/headers.service';

@Injectable({
  providedIn: 'root'
})
export class NationnaliteService {

  constructor(private httpClient: HttpClient,
              private constants: Constants,
              private headerService: HeadersService) { }

  getNationnalites() {
    const url = this.constants.apiGetPostEndpoint + '/nationnalites';
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.get(url, httpHeaders);
  }

  cree(nationnalite: any) {
    const url = this.constants.apiGetPostEndpoint + "/nationnalites";
    const httpHeaders = this.headerService.getPostHeader();
    return this.httpClient.post(url, {nom: nationnalite["nom_nouveau"]}, httpHeaders);
  }

  edite(nationnalite:any, lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getPatchHeader();

    return this.httpClient.patch(url, {
    nom: nationnalite["nom_edition"],
    },httpHeaders);
  }

  supprime(lien: string) {
    const url = this.constants.apiPatchDeleteEndpoint + lien;
    const httpHeaders = this.headerService.getGetHeader();
    return this.httpClient.delete(url, httpHeaders);
  }
}
